import React, { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { fetchDataAgnosticWithoutCredentials } from './Services/dataApi';

const UserNotification = () => {
  const [notificaton, setNotification] = useState(null);
  useEffect(() => {
    fetchDataAgnosticWithoutCredentials('groupInfo/notifications')
      .then((res) => {
        if (res.data && res.data.ShouldDisplay) {
          setNotification(res.data);
        }
      })
      .catch((err) => {});
  }, []);
  if (!notificaton) return <div></div>;

  return (
    <div style={{ margin: 0 }}>
      <Alert style={{ margin: 0 }} color={notificaton.TypeOfAlert || 'info'}>
        {notificaton.MessageToUser}
      </Alert>
    </div>
  );
};

export default UserNotification;
