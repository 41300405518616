var firstDay = new Date(Date.now());

const days = [
  new Date(firstDay.getTime() + 7 * 24 * 60 * 60 * 1000),
  new Date(firstDay.getTime() + 8 * 24 * 60 * 60 * 1000),
  new Date(firstDay.getTime() + 9 * 24 * 60 * 60 * 1000),
  new Date(firstDay.getTime() + 10 * 24 * 60 * 60 * 1000),
  new Date(firstDay.getTime() + 11 * 24 * 60 * 60 * 1000),
  new Date(firstDay.getTime() + 12 * 24 * 60 * 60 * 1000),
  new Date(firstDay.getTime() + 13 * 24 * 60 * 60 * 1000),
];

const noWeekend = days.filter((date) => {
  if (date.getDay() !== 6 && date.getDay() !== 0) return true;
});

const demoInterviewDates4Portal = [
  { recordId: 1, InterviewDates4PortalDate: noWeekend[0], InterviewDates4Portal__pkUUID_InterviewDate: 394 },
  { recordId: 2, InterviewDates4PortalDate: noWeekend[1], InterviewDates4Portal__pkUUID_InterviewDate: 234 },
  { recordId: 4, InterviewDates4PortalDate: noWeekend[2], InterviewDates4Portal__pkUUID_InterviewDate: 345 },
  { recordId: 4, InterviewDates4PortalDate: noWeekend[3], InterviewDates4Portal__pkUUID_InterviewDate: 345 },
  { recordId: 4, InterviewDates4PortalDate: noWeekend[4], InterviewDates4Portal__pkUUID_InterviewDate: 345 },
];

const demoUserObject = {
  Candidates4PortalNameFirst: 'Candidate Name Here',
  Departments4PortalName: 'RezRATE',
  Departments4PortalWebsite: 'https://rezrate.com',
  demo: true,
};

const setNextDay = (hour, minute) => {
  let date = new Date(Date.now());
  date.setHours(hour, minute, 0, 0);
  return date;
  let increase = 0;
  while (date.getDay() !== 5) {
    date = new Date(firstDay.getTime() + increase * 24 * 60 * 60 * 1000);
    increase++;
    if (increase > 50) {
      return date;
    }
  }
  date.setHours(hour, minute, 0, 0);
  return date;
};

const getNextFriday = () => {
  let date = new Date(Date.now());
  return date;
  let increase = 0;
  while (date.getDay() !== 5) {
    date = new Date(firstDay.getTime() + increase * 24 * 60 * 60 * 1000);
    increase++;
    if (increase > 50) {
      return date;
    }
  }
  return date;
};

// // Test with start time before 9 a.m.
// var d1 = new Date(2018, 10, 7, 7);
// setNextDay9(d1);

// // Test with start time after 9 a.m.
// var d2 = new Date(2018, 10, 7, 11);
// setNextDay9(d2);

const mockScheduledTimes = [
  {
    time: setNextDay(13, 0),
    message: 'Intro',
    TimeSlots4PortalAltRoom: 'Room 8b',
    timeSlotStartObject: setNextDay(13, 0),
    timeSlotEndObject: setNextDay(13, 15),
  },
  {
    time: setNextDay(13, 10),
    message: 'Intro',
    TimeSlots4PortalAltRoom: 'Room 8b',
    timeSlotStartObject: setNextDay(13, 10),
    timeSlotEndObject: setNextDay(13, 20),
  },
  {
    time: setNextDay(13, 20),
    message: 'Intro',
    TimeSlots4PortalAltRoom: 'Room 8b',
    timeSlotStartObject: setNextDay(13, 20),
    timeSlotEndObject: setNextDay(13, 30),
  },
  {
    time: setNextDay(13, 30),
    message: 'Intro',
    TimeSlots4PortalAltRoom: 'Room 8b',
    timeSlotStartObject: setNextDay(13, 30),
    timeSlotEndObject: setNextDay(13, 40),
  },
  {
    time: setNextDay(13, 40),
    message: 'Tour',
    TimeSlots4PortalAltRoom: 'Room 8b',
    timeSlotStartObject: setNextDay(13, 40),
    timeSlotEndObject: setNextDay(13, 50),
  },
  {
    time: setNextDay(13, 50),
    message: 'Dr. J. Stalk',
    TimeSlots4PortalAltRoom: 'Room 5c',
    timeSlotStartObject: setNextDay(13, 50),
    timeSlotEndObject: setNextDay(14, 0),
  },
  {
    time: setNextDay(14, 0),
    message: 'Lunch',
    timeSlotStartObject: setNextDay(14, 0),
    timeSlotEndObject: setNextDay(14, 10),
  },
  {
    time: setNextDay(14, 10),
    message: 'Special Session',
    TimeSlots4PortalAltRoom: 'Room 5c',
    timeSlotStartObject: setNextDay(14, 10),
    timeSlotEndObject: setNextDay(14, 20),
  },
  {
    time: setNextDay(14, 20),
    message: 'Dr. V. Thomas',
    timeSlotStartObject: setNextDay(14, 20),
    timeSlotEndObject: setNextDay(14, 30),
  },
  {
    time: setNextDay(14, 30),
    message: 'Break',
    timeSlotStartObject: setNextDay(14, 30),
    timeSlotEndObject: setNextDay(14, 40),
  },
  {
    time: setNextDay(14, 40),
    message: 'Dr. V. Apgar',
    TimeSlots4PortalAltRoom: 'Room 4a',
    timeSlotStartObject: setNextDay(14, 40),
    timeSlotEndObject: setNextDay(14, 50),
  },
  {
    time: setNextDay(14, 50),
    message: 'Break',
    timeSlotStartObject: setNextDay(14, 50),
    timeSlotEndObject: setNextDay(3, 0),
  },
  {
    time: setNextDay(15, 0),
    message: 'Dr. E. Jenner',
    TimeSlots4PortalAltRoom: 'Room 4b',
    timeSlotStartObject: setNextDay(15, 0),
    timeSlotEndObject: setNextDay(15, 10),
  },
  {
    time: setNextDay(15, 20),
    message: 'Dinner',
    timeSlotStartObject: setNextDay(15, 20),
    timeSlotEndObject: setNextDay(15, 30),
  },
  {
    time: setNextDay(15, 30),
    message: 'Mixer',
    TimeSlots4PortalAltRoom: 'Room 7b',
    timeSlotStartObject: setNextDay(15, 30),
    timeSlotEndObject: setNextDay(15, 40),
  },
  {
    time: setNextDay(15, 40),
    message: 'Presentation',
    TimeSlots4PortalAltRoom: 'Room 8b',
    timeSlotStartObject: setNextDay(15, 40),
    timeSlotEndObject: setNextDay(15, 50),
  },
];

export { demoUserObject, demoInterviewDates4Portal, mockScheduledTimes, getNextFriday };
