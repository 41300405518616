import styled from 'styled-components';

const UserInformationStyle = {
  Wrapper: styled.div`
    color: #fff;
    display: flex;
    justify-content: space-between;
    & img {
      height: 70px;
      width: auto;
    },
  `,
  TitleWrapper: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 5px 5px 5px 0px;
  `,
  CogWrapper: styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
  `,
};

export default UserInformationStyle;
