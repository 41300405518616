export const setUser = (payload) => {
  return {
    type: 'SET_USER',
    payload,
  };
};

export const setBreakUrl = (payload) => {
  return {
    type: 'SET_BREAK_URL',
    payload,
  };
};
