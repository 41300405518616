import AnimationAction from './ListAnimation.action';
const initialState = {
  listArray: [],
  order: null,
};

const AnimationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DATE_LIST':
      return {
        ...state,
        listArray: action.payload,
      };
    case 'SET_ORDER':
      return {
        ...state,
        order: action.payload,
      };
    default:
      return state;
  }
};

export default AnimationReducer;
