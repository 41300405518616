const initialState = {
  userInfo: null,
  breakUrl: { type: 1, url: '', buttonText: '', descriptoinUrl: '' },
};

const UserInformationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        userInfo: action.payload,
      };
    case 'SET_BREAK_URL':
      return {
        ...state,
        breakUrl: action.payload,
      };
    default:
      return state;
  }
};

export default UserInformationReducer;
