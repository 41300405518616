/* eslint-disable no-console */
import React from 'react';
import styled from 'styled-components';

const Cover = styled.div`
  position: relative;
  left: -12px;
  height: 2px;
  top: -1px;
`;

const Circle = styled.div`
  position: relative;
  top: calc(50% - 5px);
  left: -6px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  border: 1px solid rgb(51, 51, 51);
  width: 10px;
  height: 10px;
`;

const createTimeTracker = (heights, i, difference, color, coverBorder, needsPadding, isPassingPeriod) => {
  const padding = needsPadding ? '12px 0 0 20px' : 0;

  return (
    <td style={{ padding, position: 'relative' }}>
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          left: '50%',
          justifyContent: 'center',
          height: heights ? heights[i] + 'px' : 'auto',
          padding: 0,
        }}
      >
        <div className="difference" style={difference} />
        {heights && !isPassingPeriod && heights[i] > 0 && <Circle style={{ backgroundColor: color }} />}
        <Cover style={{ border: coverBorder }} />
      </div>
    </td>
  );
};
/* eslint-enable */

export { createTimeTracker };
