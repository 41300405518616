import { faBullseye, faCheckSquare, faSpinner, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { post } from 'axios';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Popup from 'reactjs-popup';
import { Col, Form, FormGroup, Input, Row } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { isDev } from '../../Common.functions';
import { fetchDataAgnosticWithoutCredentials } from '../../Services/dataApi';
import Axios from 'axios';
import BulkUploadPreview from './BulkUploadPreview';
import EmbededPdf from './Pdf/EmbededPdf/EmbededPdf';
import SingleCandidateDragAndDrop from './SingleCandidateDragAndDrop';

const SLASH_REPLACE_KEY = 'FSPlaceholder';

const mockTypes = ['Application', 'Letters of Recommendation (LoR)', 'Personal Statements'];

const PopupWrapper = styled.div`
  // .popup-overlay {
  //   .popup-content {
  //     max-height: 100%;
  //     overflow: scroll;
  //   }
  // }
`;

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 150,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

const getUrlParameter = (name) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

const DragAndDropFiles = (props, { embedded }) => {
  const { uploadLogo, uploadAvatar } = props;
  const [files, setFiles] = useState([]);
  const [secret, setSecret] = useState(props.location.pathname.split('/')[2]);
  const [finished, setFinished] = useState(false);
  const [numOfErrors, setNumOfErrors] = useState(0);
  const [currentSet, setCurrentSet] = useState(0);
  const [isPosting, setIsPosting] = useState(false);
  const [fileType, setFileType] = useState();
  const [matchType, setMatchType] = useState(null);
  const [pk_UUID_FM, setPk_UUID_FM] = useState(null);
  const [documentTypes, setDocumentTypes] = useState([]);
  const [error, setError] = useState(false);
  const [department, setDepartment] = useState(null);
  const [candidatePk, setCandidatePk] = useState(null);
  const [candidateFiles, setCandidateFiles] = useState([]);
  const [currentLogo, setCurrentLogo] = useState({});
  const [hideSchool, setHideSchool] = useState(false);
  const [hideHobbies, setHideHobbies] = useState(false);
  const [hideLanguages, setHideLanguages] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [showFileList, setShowFileList] = useState(false);
  const [currentAvatar, setCurrentAvatar] = useState({});
  const [candidateInfo, setCandidateInfo] = useState(null);
  const [sessionID, setSessionID] = useState(null);
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [tempToken, setTempToken] = useState(null);
  const [isSingleCandidate, setIsSingleCandidate] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [itemPreviewPopup, setItemPreviewPopup] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const getLogoInformation = async (paramDepartment) => {
    const chosenDepartment = paramDepartment || department;
    const logoObject = await getLogo(chosenDepartment);
    setCurrentLogo(logoObject);
    setCandidateFiles([logoObject]);
    setFiles([logoObject]);
  };

  const getAvatarInformation = async (paramDepartment, paramCandidate) => {
    const chosenDepartment = paramDepartment || department;
    const chosenCandidate = paramCandidate || candidatePk;
    const avatarObject = await getAvatar(chosenDepartment, chosenCandidate);
    setCurrentAvatar(avatarObject);
    setCandidateFiles([avatarObject]);
    setFiles([avatarObject]);
  };

  const getIDByCondition = (documentName) => {
    const nameSplit = documentName.split('_');
    for (let i = 0; i < nameSplit.length; i++) {
      const currentItem = nameSplit[i];
      const isNum = /^\d+$/.test(currentItem);
      if (currentItem.length > 4 && currentItem.length < 10 && isNum) {
        return currentItem;
      }
    }
    return false;
  };

  const getLogo = (department) => {
    return fetchDataAgnosticWithoutCredentials('files/logo', { departmentUUID: department })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {});
  };

  const getAvatar = (department, candidate) => {
    return fetchDataAgnosticWithoutCredentials('files/avatar', { departmentUUID: department, candidateUUID: candidate })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const { department, organization, pk_UUID_FM, candidate, seasonID, tempToken } = props.match.params;
    if (uploadLogo) {
      getLogoInformation(department);
    }

    if (uploadAvatar) {
      getAvatarInformation(department, candidate);
    }
    getDepartmentInfo(department);
    getDocumentTypes(department);
    const checkIfUserIsAuthenticated = async () => {
      const tempTokenIsValid = await checkTempToken(tempToken);

      if (tempTokenIsValid) {
        setTempToken(tempToken);
        setPk_UUID_FM(pk_UUID_FM);
        setDepartment(department);
        setCandidatePk(candidate);
        setOrganization(organization);
        setSessionID(seasonID);
        if (uploadLogo || uploadAvatar) {
          setIsSingleCandidate(false);
        } else if (pk_UUID_FM.toUpperCase() === 'BULK') {
          setIsSingleCandidate(false);
        } else {
          setIsSingleCandidate(true);
        }
        setIsLoading(false);
      } else {
        setIsAuthenticated(false);
      }
    };
    checkIfUserIsAuthenticated();
  }, []);

  const getDepartmentInfo = (department) => {
    fetchDataAgnosticWithoutCredentials('department/departmentInfoByFM', { department }).then((res) => {
      if (res.data && res.data[0] && res.data[0].MatchType.toUpperCase() === 'SFMATCH') {
        setMatchType('sfMatch');
      }
    });
  };

  const getDocumentTypes = (department) => {
    fetchDataAgnosticWithoutCredentials('documentTypes', { department })
      .then((res) => {
        setSelectedDocumentType(res.data[0].pk_DocumentType);
        setDocumentTypes(res.data);
      })
      .catch((err) => {});
  };

  const postToFM = (uri) => {
    const url = `https://tools.rezrate.com/api/v2/candidate/photo/?id=${candidatePk}`;

    const formData = new FormData();

    formData.append('photoURI', uri);
    Axios.post(url, formData)
      .then((result) => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  };

  const checkTempToken = (tempToken) => {
    return fetchDataAgnosticWithoutCredentials('tempToken/group', { tempToken, dev: isDev() })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return false;
      });
  };

  // TODO: This funciton was copied from another file for a quick production fix, needs to be combined to avoid repitition
  const avatarUpload = (formData) => {
    // return;
    // const url = 'https://rezrateapi.azurewebsites.net/files';
    const isLocal = window.location.href.includes('localhost:3000/');
    const url = isLocal
      ? 'http://localhost:8080/files/candidate'
      : 'https://rezrateapi.azurewebsites.net/files/candidate';
    setIsPosting(true);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setCurrentProgress(percentCompleted);
      },
    };
    return Axios.post(url, formData, config)
      .then((res) => {
        setCurrentAvatar((prevState) => {
          const copy = JSON.parse(JSON.stringify(prevState));
          copy.avatarUrl = res.data.uri;
          copy.PhotoUrl = res.data.name;
          return copy;
        });
        postToFM(res.data.uri);
      })
      .catch((err) => {
        Swal.fire('Oops', 'Something went wrong. Please try again or contact support', 'error').then(() => {});
        return false;
      })
      .finally(() => {
        setIsPosting(false);
      });
  };

  // get user information based off url parameters
  const getCandidateInformation = () => {
    // if user is uploading a logo no need to get candidate info
    if (props.uploadLogo || props.uploadAvatar) return;

    fetchDataAgnosticWithoutCredentials('candidate', {
      candidateFMUUID: pk_UUID_FM,
      department,
      organization,
      sessionID,
      dev: isDev(),
    })
      .then((res) => {
        setCandidateInfo(res.data);
        fetchDataAgnosticWithoutCredentials('candidate/files', { pk_Candidate: res.data.pk_Candidate })
          .then((res) => {
            setCandidateFiles(res.data);
          })
          .catch((err) => {
            setError(true);
          });
      })
      .catch((err) => {
        setError(true);
      });
  };

  useEffect(() => {
    // if isSingleCandidate isn't set or they're uploading a logo just return
    if (isSingleCandidate === null) return;

    // pk_UUID_FM has user id so get user information
    if (isSingleCandidate) {
      getCandidateInformation();
    }
  }, [isSingleCandidate]);

  const uploadFiles = (files) => {
    const formData = multiCandidateObject(files);
    fileUpload(formData);
  };

  const initiateFileUpload = (files, documentTypePk) => {
    if (files.length > 0 && isPosting === false) {
      if (uploadLogo) {
        const formData = logoObject(files, documentTypePk);
        return fileUpload(formData);
      } else if (uploadAvatar) {
        const formData = avatarObject(files);
        return avatarUpload(formData);
      } else if (isSingleCandidate) {
        const formData = singleCandidateObject(files, documentTypePk);

        return fileUpload(formData);
      } else {
        if (matchType === 'sfMatch') {
          checkIfUserExistsSFMatch(files);
        } else {
          checkIfUserExistsAAMCID(files);
          // return false;
          const formData = multiCandidateObject(files);
          if (formData.has('files')) {
            fileUpload(formData);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (files[currentSet] !== undefined) {
      fileUpload();
    }
  }, [currentSet]);

  const checkIfUserExistsSFMatch = (allFiles) => {
    const selectedFiles = allFiles[currentProgress];
    const deepCloneFile = _.cloneDeep(selectedFiles);
    const id_Array = [];
    deepCloneFile.forEach((file) => {
      const SFMatchID = getIDByCondition(file.name);
      id_Array.push(SFMatchID);
    });
    const { department, organization } = props.match.params;
    fetchDataAgnosticWithoutCredentials('candidate/all/byExternalID', {
      id_Array,
      selectedDocumentType,
      type: 'sfMatch',
      department,
      organization,
    })
      .then((results) => {
        deepCloneFile.forEach((file) => {
          let userAssociated = false;
          const SFMatchID = getIDByCondition(file.name);
          results.data.forEach((resultItem) => {
            if (resultItem.SFMatchID === SFMatchID) {
              file['userInfo'] = resultItem;
              userAssociated = true;
              if (resultItem.pk_File) {
                file['existingFile'] = true;
              }
              file['checked'] = true;
            }
          });
          file['userAssociated'] = userAssociated;
        });

        const deepCloneAllFiles = _.cloneDeep(files);
        deepCloneAllFiles[currentProgress] = deepCloneFile;
        setFiles(deepCloneAllFiles);
        setItemPreviewPopup(true);
      })
      .catch((err) => {});
  };

  const checkIfUserExistsAAMCID = (allFiles) => {
    const selectedFiles = allFiles[currentProgress];
    const deepCloneFile = _.cloneDeep(selectedFiles);
    const id_Array = [];
    deepCloneFile.forEach((file) => {
      const AAMCID = getIDByCondition(file.name);
      id_Array.push(AAMCID);
    });

    const { department, organization } = props.match.params;
    fetchDataAgnosticWithoutCredentials('candidate/all/byExternalID', {
      id_Array,
      selectedDocumentType,
      type: 'AAMCID',
      department,
      organization,
    })
      .then((results) => {
        deepCloneFile.forEach((file) => {
          let userAssociated = false;
          const AAMCID = getIDByCondition(file.name);
          results.data.forEach((resultItem) => {
            if (resultItem.AAMCID === AAMCID) {
              file['userInfo'] = resultItem;
              userAssociated = true;
              if (resultItem.pk_File) {
                file['existingFile'] = true;
              }
              file['checked'] = true;
            }
          });
          file['userAssociated'] = userAssociated;
        });

        const deepCloneAllFiles = _.cloneDeep(files);
        deepCloneAllFiles[currentProgress] = deepCloneFile;
        setFiles(deepCloneAllFiles);
        setItemPreviewPopup(true);
      })
      .catch((err) => {});
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const filesCopy = files.slice();
      filesCopy.push(acceptedFiles);

      setFiles(filesCopy);
      initiateFileUpload(filesCopy);
    },
    [selectedDocumentType],
  );
  // useEffect(() => {
  //   const secretUUID = getUrlParameter('allow');
  //   setSecret(secretUUID);
  // }, []);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const onFormSubmit = (e) => {
    e.preventDefault(); // Stop form submit
    this.fileUpload(this.state.file).then((response) => {});
  };
  const onChange = (e) => {
    this.setState({ file: e.target.files[0] });
  };

  const determineContainer = () => {
    if (uploadLogo) {
      return 'logos';
    }

    if (uploadAvatar) {
      return 'avatars';
    }

    return 'test';
  };

  const multiCandidateObject = (candidates) => {
    const last = candidates.length - 1;
    const candidate = candidates[last];
    const formData = new FormData();
    for (var i in candidate) {
      const currentFile = candidate[i];

      // json breaks when ther's a forward slash, so temporarily getting rid of it until it's over tot he api
      let typeWithoutSlash = currentFile.type;
      typeWithoutSlash = typeWithoutSlash.replace('/', SLASH_REPLACE_KEY);
      const { userInfo } = currentFile;
      if (userInfo) {
        if (matchType === 'sfMatch') {
          const { existingFile } = userInfo;
          const name = getIDByCondition(currentFile.name);
          if (name) {
            const SFMatchID = name;
            formData.append(
              `files`,
              currentFile,
              `{ "name":"${currentFile.name}", "SFMatchID":"${SFMatchID}", "department":"${
                userInfo.fk_Department
              }", "container":"test", "checked": "${currentFile.checked}", "existingFile": "${
                currentFile.existingFile
              }", "pk_File": "${existingFile ? existingFile.pk_File : 'null'}", "pk_Candidate": "${
                userInfo.pk_Candidate
              }", "fk_DocumentType": "${selectedDocumentType}", "mimetype": "${typeWithoutSlash}", "slashReplace": "${SLASH_REPLACE_KEY}" }`,
            );
          } else {
            currentFile.error = true;
            setNumOfErrors((prevNumOfErrs) => prevNumOfErrs + 1);
          }
        } else {
          const { existingFile } = userInfo;
          const name = getIDByCondition(currentFile.name);
          if (name) {
            const AAMCID = name;
            formData.append(
              `files`,
              currentFile,
              `{ "name":"${currentFile.name}", "AAMCID":"${AAMCID}", "department":"${
                userInfo.fk_Department
              }", "container":"test", "checked": "${currentFile.checked}", "existingFile": "${
                currentFile.existingFile
              }", "pk_File": "${existingFile ? existingFile.pk_File : 'null'}", "pk_Candidate": "${
                userInfo.pk_Candidate
              }", "fk_DocumentType": "${selectedDocumentType}", "mimetype": "${typeWithoutSlash}", "slashReplace": "${SLASH_REPLACE_KEY}" }`,
            );
          } else {
            currentFile.error = true;
            setNumOfErrors((prevNumOfErrs) => prevNumOfErrs + 1);
          }
        }
      }
    }
    return formData;
  };

  const singleCandidateObject = (files, documentTypePk) => {
    const file = files[0];
    const formData = new FormData();
    for (var i in file) {
      const jsonToSend = `{ "name":"${file[i].name}", "checked": "true", "department":"${
        candidateInfo.pk_Department
      }", "container":"${determineContainer()}", "candidatePk_UUID_FM": "${pk_UUID_FM}", "fk_DocumentType": "${documentTypePk}"}`;

      formData.append(`files`, file[i], jsonToSend);
    }
    return formData;
  };

  const logoObject = (files, documentTypePk) => {
    const file = files[0];
    const formData = new FormData();
    for (var i in file) {
      const jsonToSend = `{ "name":"${
        file[i].name
      }", "checked": "true", "department":"${department}", "container":"${determineContainer()}", "logo": "true"}`;
      formData.append(`files`, file[i], jsonToSend);
    }
    return formData;
  };

  const avatarObject = (files, documentTypePk) => {
    const file = files[0];
    const formData = new FormData();
    for (var i in file) {
      const jsonToSend = `{ "name":"${
        file[i].name
      }", "checked": "true", "candidateId": "${candidatePk}", "department":"${department}", "container":"${determineContainer()}", "avatar": "true"}`;
      formData.append(`files`, file[i], jsonToSend);
    }
    return formData;
  };

  const clearQueue = () => {
    setFiles([]);
    setCurrentSet(0);
    setNumOfErrors(0);
  };

  const fileUpload = (formData) => {
    // const url = 'https://rezrateapi.azurewebsites.net/files';
    const isLocal = process.env.NODE_ENV !== 'production';
    const url = isLocal ? 'http://localhost:8080/files' : 'https://rezrateapi.azurewebsites.net/files';
    setIsPosting(true);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        secret,
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setCurrentProgress(percentCompleted);
      },
    };
    return post(url, formData, config)
      .then((res) => {
        Swal.fire('Great!', 'Your files have been successfully uploaded', 'success').then(() => {
          clearQueue();
        });
        setCurrentProgress(0);
        if (uploadLogo) {
          getLogoInformation();
        } else if (uploadAvatar) {
          getAvatarInformation();
        } else {
          getCandidateInformation();
        }
        return true;
      })
      .catch((err) => {
        Swal.fire('Oops', 'Something went wrong. Please try again or contact support', 'error').then(() => {
          clearQueue();
        });
        return false;
      })
      .finally(() => {
        setIsPosting(false);
      });
  };

  const progressBar = (i, thumbnail) => {
    if (thumbnail && thumbnail.error) {
      return <FontAwesomeIcon size="3x" color="#e50000" icon={faTimes} style={{ paddingBottom: '15px' }} />;
    }
    let progress = 0;
    if (Number(i) === currentSet) {
      progress = currentProgress;
    } else if (Number(i) < currentSet) {
      progress = 100;
    } else {
      progress = 0;
    }
    if (progress === 100) {
      return <FontAwesomeIcon size="3x" color="#228b22" icon={faCheckSquare} style={{ paddingBottom: '15px' }} />;
    }
    return (
      <div className="progress-bar">
        <div style={{ width: `${progress}%` }}></div>
      </div>
    );
  };

  const cancelCurrentSet = () => {
    // take the items off the array if they cancel the transaction
    const fileClone = [...files];
    fileClone.splice(currentProgress, 1);
    setFiles(fileClone);
  };

  const thumbs = () => {
    if (files.length === 0) {
      return;
    }
    let thumbnails = [];
    for (var i in files) {
      thumbnails[i] = files[i].map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });
    }

    const previewNodes = [];
    for (var i in thumbnails) {
      const nodes = thumbnails[i].map((thumbnail) => {
        if (thumbnail.type.includes('pdf')) {
          return (
            <div key={thumbnail.name} style={{ display: 'flex', flexDirection: 'row' }}>
              <EmbededPdf thumbnail={thumbnail} />
              <div style={{ paddingLeft: '10px' }}>
                <p>{thumbnail.name}</p>
                {progressBar(i, thumbnail)}
              </div>
            </div>
          );
        }
        if (thumbnail.type.includes('msword')) {
          return (
            <div key={thumbnail.name}>
              <iframe src={`https://docs.google.com/gview?url=${thumbnail.preview}&embedded=true`}></iframe>
            </div>
          );
        }
        return (
          <div key={thumbnail.name} style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={thumb} key={thumbnail.name}>
              <div style={thumbInner}>
                <img src={thumbnail.preview} style={img} />
              </div>
            </div>
            <div>
              <p>{thumbnail.name}</p>
              {progressBar(i, thumbnail)}
            </div>
          </div>
        );
      });
      previewNodes.push(nodes);
    }
    return previewNodes;
  };

  // if (props.uploadLogo) {
  //   return (
  //     <LogoUploader
  //       documentTypes={documentTypes}
  //       setDocumentTypes={setDocumentTypes}
  //       singleCandidateObject={singleCandidateObject}
  //       fileUpload={fileUpload}
  //       initiateFileUpload={initiateFileUpload}
  //       setSelectedDocumentType={setSelectedDocumentType}
  //       getCandidateInformation={getCandidateInformation}
  //       setFiles={setFiles}
  //       currentSet={currentSet}
  //       candidateFiles={candidateFiles}
  //       setCandidateFiles={setCandidateInfo}
  //       urlObject={{ pk_UUID_FM, department, organization, sessionID, tempToken }}
  //     />
  //   );
  // }
  if (isLoading || (isSingleCandidate && !candidateInfo)) {
    return <div></div>;
  }

  if (isSingleCandidate || props.uploadLogo || props.uploadAvatar) {
    return (
      <SingleCandidateDragAndDrop
        documentTypes={documentTypes}
        uploadLogo={uploadLogo}
        uploadAvatar={uploadAvatar}
        setDocumentTypes={setDocumentTypes}
        avatarUpload={avatarUpload}
        singleCandidateObject={singleCandidateObject}
        fileUpload={fileUpload}
        initiateFileUpload={initiateFileUpload}
        setSelectedDocumentType={setSelectedDocumentType}
        getCandidateInformation={getCandidateInformation}
        setFiles={setFiles}
        currentSet={currentSet}
        currentLogo={currentLogo}
        currentAvatar={currentAvatar}
        candidateFiles={candidateFiles}
        setCandidateFiles={setCandidateInfo}
        urlObject={{ pk_UUID_FM, department, organization, sessionID, tempToken }}
      />
    );
  }

  const icon = isDragActive ? faBullseye : faUpload;
  return (
    <Col>
      <div id="drag_and_drop_overlay" className={!isPosting ? 'display_none' : ''}>
        <FontAwesomeIcon icon={faSpinner} spin size="3x" />
      </div>
      <PopupWrapper>
        <Popup closeOnDocumentClick={false} open={itemPreviewPopup} id="bulk-upload-popup">
          <BulkUploadPreview
            cancelCurrentSet={cancelCurrentSet}
            files={files[currentProgress]}
            allFiles={files}
            setIsPosting={setIsPosting}
            isPosting={isPosting}
            setFiles={setFiles}
            uploadFiles={uploadFiles}
            currentSet={currentSet}
            setItemPreviewPopup={setItemPreviewPopup}
          />
        </Popup>
      </PopupWrapper>
      {/* {candidateInfo && (
        <Row>
          <Col>
            <img style={{ width: '150px', height: 'auto' }} src={candidateInfo.uri} />
          </Col>
        </Row>
      )} */}
      <Row style={{ height: `calc(100vh - ${!embedded ? '0' : '50'}px)`, maxWidth: '100vw' }}>
        <Col md={4} style={{ height: '100%' }}>
          <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            {/* <h2 style={{ paddingTop: '15px', paddingBottom: '50px', textAlign: 'center' }}>Upload Files</h2> */}
            <div style={{ paddingTop: '15px', paddingBottom: '15px' }}>
              <h4>Document Type</h4>
              <Form>
                <FormGroup>
                  <Input
                    type="select"
                    name="select"
                    id="exampleSelect"
                    onChange={(e) => {
                      const { value } = e.target;
                      setSelectedDocumentType(Number(value));
                    }}
                  >
                    {documentTypes.map((item) => (
                      <option key={item.pk_DocumentType} value={item.pk_DocumentType}>
                        {item.DocumentTypeName}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Form>
            </div>
            <div style={{ height: '429px' }}>
              <h4>Upload Queue</h4>
              <div style={{ height: '90%', overflowY: 'scroll' }}>
                <aside style={thumbsContainer}>{thumbs()}</aside>
              </div>
            </div>
          </div>
        </Col>
        <Col md={8} style={{ height: '100%' }}>
          <div style={{ width: '100%', height: '100%' }}>
            <div {...getRootProps()} style={{ height: '100%' }}>
              <input {...getInputProps()} />
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <FontAwesomeIcon icon={icon} size="10x" style={{ paddingBottom: '15px' }} />
                {isDragActive ? <h4>Drop the files here ...</h4> : <h4>Click to browse or drag and drop files here</h4>}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Col>
  );
};

export default DragAndDropFiles;
