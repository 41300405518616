import { faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import Moment from 'react-moment';
import { Button, Input } from 'reactstrap';
// import io from 'socket.io-client';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import {
  adaptToTimeZone,
  addColors,
  getMeetingIdFromUrl,
  getNeededDataFromUser,
  getPercentageTime,
  isArrayEqual,
  isDev,
  isValidUser,
  MEETING_STATUS,
  shouldJoinMeetingButtonBeVisisble,
  zoomPopupJSX,
} from '../../../Common.functions';
import { createTimeTracker } from '../../../Common.jsx';
import { fetchDataAgnosticWithoutCredentials, fetchDataWithoutCredentials } from '../../../Services/dataApi';
import { determineMeetingSound } from '../../../Sound/MeetingStatusSounds';
import { cssSizes } from '../../Common.style';
import { checkForSound, createAlarms, handleSounds } from '../../RezRATE/Body/Evaluate/ScheduleAlerts';
import Error from '../../Statuses/Error';
import Loading from '../../Statuses/Loading';
import { demoUserObject, getNextFriday, mockScheduledTimes } from '../demo';
import { CustomCard, CustomCardHeader, CustomCol } from '../InterviewDatesWrapper.styles';
import { CustomTable } from './InterviewDateConfirm.styles';

function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

const replaceWithNumber = '|###|';
// const defaultAlarms = [
//   { minutesRelativeToEnd: 13, sound: bellsound, message: 'it is 13 minutes past interview end time' },
//   { minutesRelativeToEnd: 15, sound: bellsound, message: 'it is 13 minutes past interview end time' },
//   { minutesRelativeToEnd: 17, sound: bellsound, message: 'it is 13 minutes past interview end time' },
//   { minutesRelativeToStart: 0, sound: bellsound },
// ];

const Cover = styled.div`
  position: relative;
  left: -12px;
  height: 2px;
  top: -1px;
`;

const PopupWrapper = styled.div`
  .popup-overlay {
    .popup-content {
      width: 90vw !important;
      max-width: 800px !important;
    }
  }
`;

const Circle = styled.div`
  position: relative;
  top: calc(50% - 5px);
  left: -6px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  border: 1px solid rgb(51, 51, 51);
  width: 10px;
  height: 10px;
`;

const dotColors = [
  '#084C61',
  '#DB504A',
  '#E3B505',
  '#4F6D7A',
  '#56A3A6',
  '#084C61',
  '#DB504A',
  '#E3B505',
  '#4F6D7A',
  '#56A3A6',
  '#084C61',
  '#DB504A',
  '#E3B505',
  '#4F6D7A',
  '#56A3A6',
];

const TimeWrapper = styled.div`
  width: 100%
  position: absolute;
`;

const TDPadding = styled.td`
  width: 25%;
  ${cssSizes.phoneLandscape`
    width: 0;
  `}
  ${cssSizes.tabletLandscape`
    width: 10vw;
  `}
`;

const formattedMessage = (message, minutes) => {
  return message.replace(replaceWithNumber, Math.abs(minutes));
};

const determineMessage = (eventObject) => {
  if (eventObject.Schedules4PortalEvaluatorNamesOnly && eventObject.Schedules4PortalEvaluatorNamesOnly !== '') {
    return eventObject.Schedules4PortalEvaluatorNamesOnly;
  }
  if (eventObject.Schedules4PortalAltText && eventObject.Schedules4PortalAltText !== '') {
    return eventObject.Schedules4PortalAltText;
  }
  if (eventObject.TimeSlots4PortalAltText && eventObject.TimeSlots4PortalAltText !== '') {
    return eventObject.TimeSlots4PortalAltText;
  }
  return 'Break';
};

function sameDay(schedule, rightNow) {
  const buffer = 30;
  const firstEntry = schedule[0].timeSlotStartObject;
  const lastEntry = schedule[schedule.length - 1].timeSlotEndObject;

  const firstEntryCopy = new Date(firstEntry);
  const lastEntryCopy = new Date(lastEntry);

  firstEntryCopy.setMinutes(firstEntryCopy.getMinutes() - buffer);
  lastEntryCopy.setMinutes(lastEntry.getMinutes() + buffer);

  return firstEntryCopy < rightNow && rightNow < lastEntryCopy;
}

const InterviewSchedule = (props) => {
  const alert = useAlert();
  React.useEffect(() => {
    var timerID = setInterval(() => tick(), 500);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  const handleZoomClick = (url, mobileNumbers, zoomPassword) => {
    const meetingId = getMeetingIdFromUrl(url);
    setMeetingId(meetingId);
    // if there are mobile numbers set them.  If not,
    // make sure zoom info resets so it doesn't display
    // info from previous meeting
    if (zoomPassword && zoomPassword !== '') {
      setZoomPassword(zoomPassword);
    } else {
      setZoomPassword('');
    }

    if (mobileNumbers && mobileNumbers.length > 0) {
      setZoomInfo(mobileNumbers);
    } else {
      setZoomInfo([]);
    }

    const zoomForm = document.getElementById('zoom_form');
    localStorage.setItem('zoomUrl', url);
    zoomForm.action = '/zoom';
    setZoomLink(url);
    setShowZoomLink(true);
    setMainRoomDescription();
    setTimeout(() => {
      setShowZoomLink(false);
    }, 3 * 60 * 1000);
    zoomForm.submit();
  };

  const formatSchedule = (data, date) => {
    const itemsToPop = [];
    const { Schedules4Portal } = data;
    const { TimeSlots4Portal } = data;
    const timeslot4PortalCopy = TimeSlots4Portal.slice();
    const schedule4PortalCopy = Schedules4Portal.slice();
    timeslot4PortalCopy.map((timeSlotItem) => {
      schedule4PortalCopy.map((scheduleItem, i) => {
        if (scheduleItem.Schedules4Portal_fkUUID_Timeslot === timeSlotItem.TimeSlots4Portal__pkUUID_Timeslot) {
          if (scheduleItem.Schedules4PortalRoomNumber !== '' && hasRoomNumber === false) {
            // setHasRoomNumber(true);
          }
          const url =
            scheduleItem.Schedules4PortalJoinManualURL !== undefined
              ? scheduleItem.Schedules4PortalJoinManualURL
              : timeSlotItem.Schedules4PortalJoinManualURL;

          timeSlotItem.Schedules4PortalAltText = scheduleItem.Schedules4PortalAltText;
          timeSlotItem.Schedules4PortalEvaluatorNamesOnly = scheduleItem.Schedules4PortalEvaluatorNamesOnly;
          timeSlotItem.Schedules4PortalRoomNumber = scheduleItem.Schedules4PortalRoomNumber;
          timeSlotItem.Schedules4PortalJoinManualURL = url;
          timeSlotItem.Schedules4PortalTimeDuration = scheduleItem.Schedules4PortalTimeDuration;
          timeSlotItem.Schedules4PortalJoinManualDescription = scheduleItem.Schedules4PortalJoinManualDescription;
          // timeSlotItem.Schedules4PortalTimeEnd = scheduleItem.Schedules4PortalTimeEnd;
          timeSlotItem.Schedules4Portal__pkUUID_Schedule = scheduleItem.Schedules4Portal__pkUUID_Schedule;
          itemsToPop.unshift(i);
        }
      });
    });

    for (let i = 0; i < itemsToPop.length; i++) {
      schedule4PortalCopy.splice(itemsToPop[i], 1);
    }
    const combined = timeslot4PortalCopy.concat(schedule4PortalCopy);

    combined.forEach((item) => {
      if (item.timeSlotStartObject) {
        item.time = item.timeSlotStartObject;
      }
      if (item.Schedules4PortalTime) {
        item.time = item.timeSlotStartObject;
      }
      if (item.TimeSlots4PortalCustomAlerts && item.TimeSlots4PortalCustomAlerts !== '') {
        const alertAsJson = item.TimeSlots4PortalCustomAlerts;

        alertAsJson.forEach((alert) => {
          alert['soundAsFile'] = determineMeetingSound(alert.sound);
        });
        item['alertsAsJson'] = alertAsJson;
        // item.alertsAsJson.forEach(alert => {
        // })
      }
      item.message = determineMessage(item);
    });
    combined.sort((a, b) => {
      return a.time - b.time;
    });
    return combined;
  };

  const tick = () => {
    const rightNow = new Date();
    if (scheduledTimes && document.getElementById('tableRow0')) {
      let heights = [];
      scheduledTimes.forEach((element, i) => {
        checkForSound(
          element.timeSlotEndObject,
          rightNow,
          element.alertAsJson,
          alarms,
          formattedMessage,
          setAlarms,
          interviewDate,
          element.isPassingPeriod,
          element.hasPassingPeriod,
          interviewIsToday,
        );
        const height = document.getElementById('tableRow' + i).clientHeight;
        heights.push(height);
      });

      setHeights(heights);
    }

    if (rightNow < beginningTimeMinusMDisplayMettingTime) {
      setDisplayJoinMeetingButtonInfo(true);
    } else {
      setDisplayJoinMeetingButtonInfo(false);
    }
    // if (interviewIsToday && rightNow > beginningTimeMinusMDisplayMettingTime && rightNow < endingTimePlus10Minutes) {
    //   if (!props.displayBreakUrl) {
    //     props.setDisplayBreakUrl(true);
    //   }
    // } else {
    //   if (props.displayBreakUrl) {
    //     props.setDisplayBreakUrl(false);
    //   }
    // }
    if (demoTimeDifference && demo) {
      rightNow.setSeconds(rightNow.getSeconds() + demoTimeDifference);
      setCurrentTime(rightNow);
    } else {
      setCurrentTime(new Date());
    }
  };

  const checkIfTimesMatch = (localTime, clientTime) => {
    const FIVE_MIN = 5 * 60 * 1000;
    if (localTime - clientTime > FIVE_MIN || clientTime - localTime > FIVE_MIN) {
      return false;
    }
    return true;
  };

  const isDemo = () => {
    if (props && props.match && props.match.params && props.match.params.id.toUpperCase() === 'DEMO') {
      return true;
    }
    return false;
  };
  const clientID = props.match.params.id;
  const [demo, setDemo] = useState(isDemo());
  let elementsRef;
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [topOpacity, setTopOpacity] = useState(0);
  const [headerOpacity, setHeaderOpacity] = useState(0);
  const [heights, setHeights] = useState(null);
  const [isLocalTime, setIsLocalTime] = useState(true);
  const [passWord, setPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [scheduledTimes, setScheduledTimes] = useState(null);
  const [firstBlockEarlyJoin, setFirstBlockEarlyJoin] = useState(0);
  const [interviewIsToday, setInterviewIsToday] = useState(false);
  const [meetingType, setMeetingType] = useState(MEETING_STATUS.Standard);
  const [error, setError] = useState(false);
  const [mainRoomDescription, setMainRoomDescription] = useState(null);
  const [descriptionToUse, setDescriptionToUse] = useState(null);
  const [scheduleReturned, setScheduleReturned] = useState([]);
  const [displayJoinMeetingButonInfo, setDisplayJoinMeetingButtonInfo] = useState(false);
  const [copied, setCopied] = useState(false);
  const [meetingId, setMeetingId] = useState(null);
  const [endingTimePlus10Minutes, setEndingTimePlus10Minutes] = useState(null);
  const [beginningTimeMinusMDisplayMettingTime, setBeginningTimeMinusMDisplayMettingTime] = useState(null);
  const [interviewDate, setInterviewDate] = useState(null);
  const [noData, setNoData] = useState(false);
  const [showRed, setShowRed] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [showZoomLink, setShowZoomLink] = useState(false);
  const [tableRowOpacity, setTableRowOpacity] = useState(0);
  const [differences, setDifferences] = useState([]);
  const [demoTimeDifference, setDemoTimeDifference] = useState(null);
  const [globalSettings, setGlobalSettings] = useState({});
  const [globalSettingsLoading, setGlobalSettingsLoading] = useState(true);
  const [departmentName, setDepartmentName] = useState('');
  const [interviewDateUUID, setInterviewDateUUID] = useState(null);
  const [zoomPassword, setZoomPassword] = useState('');
  const [incorrectUUID, setIncorrectUUID] = useState(false);
  const [alarms, setAlarms] = useState(null);
  const [showDots, setShowDots] = useState(false);
  const [preventJoinMeetingClick, setPreventJoinMeetingClick] = useState(false);
  const [hasRoomNumber, setHasRoomNumber] = useState(false);
  const [zoomInfo, setZoomInfo] = useState([]);
  const [zoomLink, setZoomLink] = useState('');
  const [passingPeriod, setPassingPeriod] = useState(null);
  // const [socket, setSocket] = useState(io.connect('http://localhost:8080/'));
  const [allDayRoom, setAllDayRoom] = useState(null);
  const headerRef = useRef(null);
  const tableHeadRef = useRef(null);
  const tableRowRef = useRef(null);

  useEffect(() => {
    const url = 'groupInfo/settings/global';
    fetchDataAgnosticWithoutCredentials(url, null)
      .then((result) => {
        setGlobalSettings(result.data);
      })
      .catch((err) => {})
      .finally(() => {
        setGlobalSettingsLoading(false);
      });
  }, []);

  const requestZoomMeeting = (Schedules4Portal__pkUUID_Schedule) => {
    let url = `https://tools.rezrate.com/rezrate/api/candidatejoinmeeting/?id=${props.match.params.id}&scheduleblockid=${Schedules4Portal__pkUUID_Schedule}`;

    if (globalSettings && globalSettings.RelayCandidateZoom) {
      url = `https://tools2.rezrate.com/api/v2/relay/?id=${props.match.params.id}&scheduleblockid=${Schedules4Portal__pkUUID_Schedule}&endpoint=rezrate/api/candidatejoinmeeting`;
    }

    setPreventJoinMeetingClick(true);

    fetchDataWithoutCredentials(url)
      .then((res) => {
        if (res.data && res.data.error && res.data.error === 429) {
          Swal.fire('Warning.', res.data.message, 'warning');
          return;
        }
        if (res && res.data && res.data.joinURL && res.data.dialInNumbers) {
          handleZoomClick(res.data.joinURL, JSON.parse(res.data.dialInNumbers), res.data.password);
        }
      })
      .catch((err) => {})
      .finally(() => {
        setPreventJoinMeetingClick(false);
      });
  };

  const showJoinMeetingButton = (message, url, priorityUrl, Schedules4Portal__pkUUID_Schedule, item, duration) => {
    const { Schedules4PortalJoinManualDescription, TimeSlots4PortalJoinManualDescription } = item;
    let description = null;
    if (Schedules4PortalJoinManualDescription !== 'undefined' && Schedules4PortalJoinManualDescription !== '') {
      description = Schedules4PortalJoinManualDescription;
    } else if (TimeSlots4PortalJoinManualDescription !== 'undefined' && TimeSlots4PortalJoinManualDescription !== '') {
      description = TimeSlots4PortalJoinManualDescription;
    }
    if (message === 'BREAK')
      return (
        <Fragment>
          <br />
          {meetingType !== 0 && (
            <Button
              color={meetingType === 2 ? 'success' : 'warning'}
              size="sm"
              onClick={(e) => {
                e.preventDefault();
                if (!demo) {
                  handleZoomClick(allDayRoom);
                } else {
                  Swal.fire('This is a demo!', 'This would normally join your meeting.');
                }
              }}
            >
              {meetingType === 2 ? 'Join Meeting' : 'Visit Break Room'}
            </Button>
          )}
        </Fragment>
      );

    return (
      <Fragment>
        <br />
        {meetingType !== 0 && (
          <Button
            color="success"
            size="sm"
            disabled={preventJoinMeetingClick}
            style={{ minWidth: '95px' }}
            onClick={(e) => {
              e.preventDefault();
              if (!demo) {
                const { AzureZoom } = globalSettings;
                if (typeof priorityUrl !== 'undefined' && priorityUrl === '') {
                  if (AzureZoom) {
                    if (!Schedules4Portal__pkUUID_Schedule) {
                      handleZoomClick(allDayRoom);
                      return;
                    }
                    setPreventJoinMeetingClick(true);
                    fetchDataAgnosticWithoutCredentials('schedule/season/candidate/interviewDate/virtualMeeting', {
                      scheduleId: Schedules4Portal__pkUUID_Schedule,
                      candidateId: props.match.params.id,
                      duration,
                      departmentName,
                    })
                      .then((result) => {
                        const { JoinURL, DialInNumbers, Password } = result.data;
                        handleZoomClick(JoinURL, DialInNumbers, Password);
                      })
                      .catch((err) => {
                        Swal.fire('Error', 'Something went wrong... please try again or contact support.', 'error');
                      })
                      .finally(() => {
                        setPreventJoinMeetingClick(false);
                      });
                    return;
                  }
                  requestZoomMeeting(Schedules4Portal__pkUUID_Schedule);
                  setDescriptionToUse('');
                } else if (typeof priorityUrl !== 'undefined' && priorityUrl !== '') {
                  handleZoomClick(priorityUrl);
                  setDescriptionToUse(Schedules4PortalJoinManualDescription);
                } else if (typeof url !== 'undefined' && url === '') {
                  handleZoomClick(allDayRoom);
                  setDescriptionToUse(mainRoomDescription);
                } else if (typeof url !== 'undefined' && url !== '') {
                  setDescriptionToUse(TimeSlots4PortalJoinManualDescription);
                  handleZoomClick(url);
                } else {
                  setDescriptionToUse(mainRoomDescription);
                  handleZoomClick(allDayRoom);
                }
              } else {
                Swal.fire('This is a demo!', 'This would normally join your meeting.');
              }
            }}
          >
            {preventJoinMeetingClick ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Join Meeting'}
          </Button>
        )}
      </Fragment>
    );
  };

  const addPassingPeriods = (schedule, passingPeriod) => {
    for (var i = schedule.length - 1; i >= 0; i--) {
      try {
        const item = schedule[i];
        const passingPeriod = item.TimeSlots4PortalTimePassingPeriod;
        if (passingPeriod > 0) {
          const end = new Date(item.timeSlotEndObject);
          const endBeforePassing = new Date(end);
          end.setMinutes(end.getMinutes() - passingPeriod);
          const dateAsString = moment(end).format('YYYY-MM-DD[T]HH:mm:ss');
          const dateSplit = dateAsString.split('T');
          const time = dateSplit[1].split('-')[0];
          const dateSplitToReform = dateSplit[0].split('-');
          const dateReformed = `${dateSplitToReform[1]}/${dateSplitToReform[2]}/${dateSplitToReform[0]}`;
          item.TimeSlots4PortalTimeEnd = time;
          item.TimeSlots4PortalTimestampEnd = `${dateReformed} ${time}`;
          item.timeSlotEndObject = end;
          item.hasPassingPeriod = true;

          const passingDateAsString = moment(endBeforePassing).format('YYYY-MM-DD[T]HH:mm:ss');
          const passingDateSplit = passingDateAsString.split('T');
          const passingTime = passingDateSplit[1].split('-')[0];
          const passingDateSplitToReform = passingDateSplit[0].split('-');
          const passingDateReformed = `${passingDateSplitToReform[1]}/${passingDateSplitToReform[2]}/${passingDateSplitToReform[0]}`;
          const passingPeriodItem = {
            TimeSlots4PortalAltRoom: '',
            TimeSlots4PortalAltText: '',
            TimeSlots4PortalCandidateScheduleBlockID: '',
            TimeSlots4PortalCustomAlerts: '',
            TimeSlots4PortalDuration: passingPeriod,
            TimeSlots4PortalJoinManualURL: '',
            isPassingPeriod: true,
            message: 'Passing Period',
            color: item.color,
            TimeSlots4PortalTime: time,
            TimeSlots4PortalTimeDuration: passingPeriod,
            TimeSlots4PortalTimeEnd: passingTime,
            TimeSlots4PortalTimestamp: `${dateReformed} ${time}`,
            TimeSlots4PortalTimestampEnd: `${passingDateReformed} ${passingTime}`,
            time: end,
            timeSlotEndObject: endBeforePassing,
            timeSlotStartObject: end,
            TimeSlots4PortalVirtualRoomState: '',
            TimeSlots4Portal__pkUUID_Timeslot: '',
          };
          schedule.splice(i + 1, 0, passingPeriodItem);
        }
      } catch (err) {}
    }
    return schedule;
  };

  // const addColors = (schedule) => {
  //   schedule.forEach((item, i) => {
  //     const color = i % 2 == 0 ? '#FFFFFF' : '#f2f2f2';
  //     item.color = color;
  //   });
  //   return schedule;
  // };

  const filterRepeatItems = (schedule) => {
    let tempTimeEnd;
    let tempTimeStampEnd;
    let tempRecordId;
    let tempTimeSlotEnd;
    for (var i = schedule.length - 1; i >= 0; i--) {
      const item = schedule[i];
      // Check to see if previous item exists
      if (schedule[i - 1]) {
        // if the previous item has these same attributes we can combine them into one
        const previousItem = schedule[i - 1];
        const sameMessageAsPrevious =
          previousItem.Schedules4PortalAltText === item.Schedules4PortalAltText &&
          previousItem.Schedules4PortalEvaluatorNamesOnly === item.Schedules4PortalEvaluatorNamesOnly &&
          previousItem.message === item.message;

        const sameZoomLinkAsPrevious =
          previousItem.Schedules4PortalJoinManualURL === item.Schedules4PortalJoinManualURL &&
          previousItem.TimeSlots4PortalJoinManualURL === item.TimeSlots4PortalJoinManualURL;

        // if previous item matches with current item
        if (sameMessageAsPrevious && sameZoomLinkAsPrevious) {
          if (schedule[i + 1]) {
            //Prepare items to be compared
            const nextItem = schedule[i + 1];
            const differentMessageAsNext =
              nextItem.Schedules4PortalAltText !== item.Schedules4PortalAltText ||
              nextItem.Schedules4PortalEvaluatorNamesOnly !== item.Schedules4PortalEvaluatorNamesOnly ||
              nextItem.message !== item.message;
            const differentZoomLinkAsNext =
              nextItem.Schedules4PortalJoinManualURL !== item.Schedules4PortalJoinManualURL ||
              nextItem.TimeSlots4PortalJoinManualURL !== item.TimeSlots4PortalJoinManualURL;

            //if any items are different set current item to the temporary items
            if (differentMessageAsNext || differentZoomLinkAsNext) {
              tempTimeEnd = item.TimeSlots4PortalTimeEnd;
              tempTimeStampEnd = item.TimeSlots4PortalTimestampEnd;
              tempRecordId = item.recordId;
              tempTimeSlotEnd = item.timeSlotEndObject;
            }

            // of this is the very last item then set them as the temporary item
          } else {
            tempTimeEnd = item.TimeSlots4PortalTimeEnd;
            tempTimeStampEnd = item.TimeSlots4PortalTimestampEnd;
            tempRecordId = item.recordId;
            tempTimeSlotEnd = item.timeSlotEndObject;
          }

          // use this later to filter out items (see below).  Can't do this now because each item must be aware of the previous and next item
          item.removeItem = true;

          // if current item does NOT match with current item
        } else {
          if (schedule[i + 1]) {
            const nextItem = schedule[i + 1];
            const sameMessageAsNext =
              nextItem.Schedules4PortalAltText === item.Schedules4PortalAltText &&
              nextItem.Schedules4PortalEvaluatorNamesOnly === item.Schedules4PortalEvaluatorNamesOnly &&
              nextItem.message === item.message;
            const sameZoomLinkAsNext =
              nextItem.Schedules4PortalJoinManualURL === item.Schedules4PortalJoinManualURL &&
              nextItem.TimeSlots4PortalJoinManualURL === item.TimeSlots4PortalJoinManualURL;

            if (sameMessageAsNext && sameZoomLinkAsNext) {
              item.TimeSlots4PortalTimeEnd = tempTimeEnd;
              item.TimeSlots4PortalTimestampEnd = tempTimeStampEnd;
              item.recordId = tempRecordId;
              item.timeSlotEndObject = tempTimeSlotEnd;
            }
          }
        }
      }
    }
    schedule = schedule.filter((item) => {
      if (item.removeItem) {
        return false;
      }
      return true;
    });
    return schedule;
  };

  const extractFirstAndLastDayTime = (array, timeBeforeFirstMeeting = 0) => {
    const first = array[0];
    const last = array[array.length - 1];
    let beginning = new Date(first.timeSlotStartObject);
    beginning.setMinutes(beginning.getMinutes() - timeBeforeFirstMeeting);
    setBeginningTimeMinusMDisplayMettingTime(beginning);
    let end = new Date(last.timeSlotEndObject);
    end.setMinutes(end.getMinutes + 30);
    setEndingTimePlus10Minutes(last.timeSlotEndObject);
  };

  const createDateObjects = (TimeSlots4Portal) => {
    TimeSlots4Portal.forEach((item) => {
      const start = new Date(item.TimeSlots4PortalTimestamp);
      const end = new Date(item.TimeSlots4PortalTimestampEnd);

      item.timeSlotStartObject = start;
      item.timeSlotEndObject = end;
    });
    return TimeSlots4Portal;
  };

  const addFlexEvents = (schedule, FlexEvents4Portal) => {
    FlexEvents4Portal.forEach((flexItem) => {
      const { FlexEvents4PortalTimeStart, FlexEvents4PortalTimeEnd } = flexItem;
      const flexEventStartObjectAsMoment = moment(new Date(FlexEvents4PortalTimeStart));
      const flexEventEndObjectAsMoment = moment(new Date(FlexEvents4PortalTimeEnd));

      const scheduleInitialLength = schedule.length;

      for (var i = schedule.length - 1; i >= 0; i--) {
        const item = schedule[i];

        const { TimeSlots4PortalTimestamp, TimeSlots4PortalTimestampEnd } = item;
        const startScheduleTimeAsMoment = moment(new Date(TimeSlots4PortalTimestamp));
        const endScheduleTimeAsMomemnt = moment(new Date(TimeSlots4PortalTimestampEnd));

        const isBetweenBeginning = startScheduleTimeAsMoment.isBetween(
          flexEventStartObjectAsMoment,
          flexEventEndObjectAsMoment,
        );
        const isBetweenEnd = endScheduleTimeAsMomemnt.isBetween(
          flexEventStartObjectAsMoment,
          flexEventEndObjectAsMoment,
        );

        const isBetweenEndFlex = flexEventEndObjectAsMoment.isBetween(
          startScheduleTimeAsMoment,
          endScheduleTimeAsMomemnt,
        );

        const isBetweenBeginningFlex = flexEventStartObjectAsMoment.isBetween(
          startScheduleTimeAsMoment,
          endScheduleTimeAsMomemnt,
        );

        const startsAtSameTime = startScheduleTimeAsMoment.isSame(flexEventStartObjectAsMoment);
        const endsAtSameTime = endScheduleTimeAsMomemnt.isSame(flexEventEndObjectAsMoment);

        //condition for it being at the very beginning before anything starts
        if (isBetweenBeginning && i === 0) {
          const {
            TimeSlots4PortalTimestamp,
            TimeSlots4PortalTime,
            TimeSlots4Portal__pkUUID_Timeslot,
            TimeSlots4PortalTimeEnd,
            TimeSlots4PortalTimestampEnd,
          } = item;
          const {
            FlexEvents4PortalVirtualRoomURL,
            FlexEvents4PortalVirtualRoomDescription,
            FlexEvents4PortalPassingPeriod,
            FlexEvents4PortalTitle,
            TimeSlots4PortalTimePassingPeriod,
            FlexEvents4PortalTimeEnd,
            FlexEvents4PortalTimeEndTimeOnly,
            FlexEvents4PortalTimeStart,
            FlexEvents4PortalTimeStartTimeOnly,
          } = flexItem;
          const itemToadd = {
            TimeSlots4PortalAltRoom: '',
            TimeSlots4PortalAltText: FlexEvents4PortalTitle,
            TimeSlots4PortalCandidateScheduleBlockID: '',
            TimeSlots4PortalCustomAlerts: '',
            TimeSlots4PortalDuration: '',
            TimeSlots4PortalJoinManualDescription: FlexEvents4PortalVirtualRoomDescription,
            TimeSlots4PortalJoinManualURL: FlexEvents4PortalVirtualRoomURL,
            TimeSlots4PortalTime: FlexEvents4PortalTimeStartTimeOnly,
            TimeSlots4PortalTimeEnd: TimeSlots4PortalTimeEnd,
            TimeSlots4PortalTimePassingPeriod: FlexEvents4PortalPassingPeriod,
            TimeSlots4PortalTimestamp: FlexEvents4PortalTimeStart,
            TimeSlots4PortalTimestampEnd: TimeSlots4PortalTimestampEnd,
            TimeSlots4PortalVirtualRoomState: '',
          };

          schedule.unshift(itemToadd);
        }

        if (determineMessage(item) === 'Break') {
          const isExactSameTime = startsAtSameTime && endsAtSameTime;
          const overlapsEntireBlock =
            isExactSameTime ||
            (isBetweenBeginning && isBetweenEnd) ||
            (isBetweenBeginning && endsAtSameTime) ||
            (startsAtSameTime && isBetweenEnd);

          const overlapsPartOfEnd = (startsAtSameTime && isBetweenEndFlex) || (isBetweenBeginning && isBetweenEndFlex);

          const overlapsPartOfBeginning =
            (endsAtSameTime && isBetweenBeginningFlex) || (isBetweenEnd && isBetweenBeginningFlex);

          const overlapsPartofBlock = isBetweenBeginningFlex && isBetweenEndFlex;

          const {
            FlexEvents4PortalVirtualRoomURL,
            FlexEvents4PortalVirtualRoomDescription,
            FlexEvents4PortalPassingPeriod,
            FlexEvents4PortalTitle,
            TimeSlots4PortalTimePassingPeriod,
            FlexEvents4PortalTimeEnd,
            FlexEvents4PortalTimeStart,
          } = flexItem;

          if (overlapsEntireBlock) {
            const {
              TimeSlots4PortalTimestamp,
              TimeSlots4PortalTime,
              TimeSlots4Portal__pkUUID_Timeslot,
              TimeSlots4PortalTimeEnd,
              TimeSlots4PortalTimestampEnd,
            } = item;
            const itemToadd = {
              TimeSlots4PortalAltRoom: '',
              TimeSlots4PortalAltText: FlexEvents4PortalTitle,
              TimeSlots4PortalCandidateScheduleBlockID: '',
              TimeSlots4PortalCustomAlerts: '',
              TimeSlots4PortalDuration: '',
              TimeSlots4PortalJoinManualDescription: FlexEvents4PortalVirtualRoomDescription,
              TimeSlots4PortalJoinManualURL: FlexEvents4PortalVirtualRoomURL,
              TimeSlots4PortalTime: TimeSlots4PortalTime,
              TimeSlots4PortalTimeEnd: TimeSlots4PortalTimeEnd,
              TimeSlots4PortalTimePassingPeriod: FlexEvents4PortalPassingPeriod,
              TimeSlots4PortalTimestamp: TimeSlots4PortalTimestamp,
              TimeSlots4Portal__pkUUID_Timeslot: TimeSlots4Portal__pkUUID_Timeslot,
              TimeSlots4PortalTimestampEnd: TimeSlots4PortalTimestampEnd,
              TimeSlots4PortalVirtualRoomState: '',
            };
            schedule.splice(i, 1, itemToadd);
          } else if (isBetweenBeginning) {
            const { TimeSlots4PortalTimestamp, TimeSlots4PortalTime, TimeSlots4Portal__pkUUID_Timeslot } = item;
            const itemToadd = {
              TimeSlots4PortalAltRoom: '',
              TimeSlots4PortalAltText: FlexEvents4PortalTitle,
              TimeSlots4PortalCandidateScheduleBlockID: '',
              TimeSlots4PortalCustomAlerts: '',
              TimeSlots4PortalDuration: '',
              TimeSlots4PortalJoinManualDescription: FlexEvents4PortalVirtualRoomDescription,
              TimeSlots4PortalJoinManualURL: FlexEvents4PortalVirtualRoomURL,
              TimeSlots4Portal__pkUUID_Timeslot: TimeSlots4Portal__pkUUID_Timeslot,
              TimeSlots4PortalTime: TimeSlots4PortalTime,
              TimeSlots4PortalTimeEnd: FlexEvents4PortalTimeEnd.split(' ')[1],
              TimeSlots4PortalTimePassingPeriod: FlexEvents4PortalPassingPeriod,
              TimeSlots4PortalTimestamp: TimeSlots4PortalTimestamp,
              TimeSlots4PortalTimestampEnd: FlexEvents4PortalTimeEnd,
              TimeSlots4PortalVirtualRoomState: '',
            };

            item.TimeSlots4PortalTimestamp = FlexEvents4PortalTimeEnd;
            item.TimeSlots4PortalTime = FlexEvents4PortalTimeEnd.split(' ')[1];

            schedule.splice(i, 0, itemToadd);
          } else if (isBetweenEnd) {
            const { TimeSlots4PortalTimestamp, TimeSlots4PortalTime, TimeSlots4Portal__pkUUID_Timeslot } = item;
            const itemToadd = {
              TimeSlots4PortalAltRoom: '',
              TimeSlots4PortalAltText: FlexEvents4PortalTitle,
              TimeSlots4PortalCandidateScheduleBlockID: '',
              TimeSlots4PortalCustomAlerts: '',
              TimeSlots4PortalDuration: '',
              TimeSlots4PortalJoinManualDescription: FlexEvents4PortalVirtualRoomDescription,
              TimeSlots4PortalJoinManualURL: FlexEvents4PortalVirtualRoomURL,
              TimeSlots4Portal__pkUUID_Timeslot: TimeSlots4Portal__pkUUID_Timeslot,
              TimeSlots4PortalTime: FlexEvents4PortalTimeStart.split(' ')[1],
              TimeSlots4PortalTimeEnd: FlexEvents4PortalTimeEnd.split(' ')[1],
              TimeSlots4PortalTimePassingPeriod: FlexEvents4PortalPassingPeriod,
              TimeSlots4PortalTimestamp: FlexEvents4PortalTimeStart,
              TimeSlots4PortalTimestampEnd: FlexEvents4PortalTimeEnd,
              TimeSlots4PortalVirtualRoomState: '',
            };

            item.TimeSlots4PortalTimestampEnd = FlexEvents4PortalTimeStart;
            item.TimeSlots4PortalTimeEnd = FlexEvents4PortalTimeStart.split(' ')[1];

            schedule.splice(i + 1, 0, itemToadd);
          } else if (overlapsPartOfEnd) {
            const {
              TimeSlots4PortalAltRoom,
              TimeSlots4PortalAltText,
              TimeSlots4PortalCandidateScheduleBlockID,
              TimeSlots4PortalCustomAlerts,
              TimeSlots4PortalDuration,
              TimeSlots4PortalJoinManualDescription,
              TimeSlots4PortalJoinManualURL,
              TimeSlots4PortalTime,
              TimeSlots4PortalTimeEnd,
              TimeSlots4PortalTimePassingPeriod,
              TimeSlots4PortalTimestamp,
              TimeSlots4PortalTimestampEnd,
              TimeSlots4PortalVirtualRoomState,
              TimeSlots4Portal__pkUUID_Timeslot,
            } = item;
            const itemToadd = {
              TimeSlots4PortalAltRoom: '',
              TimeSlots4PortalAltText: FlexEvents4PortalTitle,
              TimeSlots4PortalCandidateScheduleBlockID: '',
              TimeSlots4PortalCustomAlerts: '',
              TimeSlots4PortalDuration: '',
              TimeSlots4PortalJoinManualDescription: FlexEvents4PortalVirtualRoomDescription,
              TimeSlots4PortalJoinManualURL: FlexEvents4PortalVirtualRoomURL,
              TimeSlots4Portal__pkUUID_Timeslot: uuidv4(),
              TimeSlots4PortalTime: FlexEvents4PortalTimeStart.split(' ')[1],
              TimeSlots4PortalTimeEnd: FlexEvents4PortalTimeEnd.split(' ')[1],
              TimeSlots4PortalTimePassingPeriod: FlexEvents4PortalPassingPeriod,
              TimeSlots4PortalTimestamp: FlexEvents4PortalTimeStart,
              TimeSlots4PortalTimestampEnd: FlexEvents4PortalTimeEnd,
              TimeSlots4PortalVirtualRoomState: '',
            };

            item.TimeSlots4PortalTime = FlexEvents4PortalTimeEnd.split(' ')[1];
            item.TimeSlots4PortalTimestamp = FlexEvents4PortalTimeEnd;
            // Determine if remaining time is less than passing period.  if it is, just remove the passing period
            const beginningAsTimeObject = moment(new Date(item.TimeSlots4PortalTimestamp));
            const endAsTimeObject = moment(new Date(item.TimeSlots4PortalTimestampEnd));

            const duration = moment.duration(endAsTimeObject.diff(beginningAsTimeObject));
            const minuteDifference = duration.asMinutes();

            if (minuteDifference <= TimeSlots4PortalTimePassingPeriod) {
              item.TimeSlots4PortalTimePassingPeriod = minuteDifference;
            }

            schedule.splice(i, 0, itemToadd);
          } else if (overlapsPartOfBeginning) {
            const {
              TimeSlots4PortalAltRoom,
              TimeSlots4PortalAltText,
              TimeSlots4PortalCandidateScheduleBlockID,
              TimeSlots4PortalCustomAlerts,
              TimeSlots4PortalDuration,
              TimeSlots4PortalJoinManualDescription,
              TimeSlots4PortalJoinManualURL,
              TimeSlots4PortalTime,
              TimeSlots4PortalTimeEnd,
              TimeSlots4PortalTimePassingPeriod,
              TimeSlots4PortalTimestamp,
              TimeSlots4PortalTimestampEnd,
              TimeSlots4PortalVirtualRoomState,
              TimeSlots4Portal__pkUUID_Timeslot,
            } = item;
            const itemToadd = {
              TimeSlots4PortalAltRoom: '',
              TimeSlots4PortalAltText: FlexEvents4PortalTitle,
              TimeSlots4PortalCandidateScheduleBlockID: '',
              TimeSlots4PortalCustomAlerts: '',
              TimeSlots4PortalDuration: '',
              TimeSlots4PortalJoinManualDescription: FlexEvents4PortalVirtualRoomDescription,
              TimeSlots4PortalJoinManualURL: FlexEvents4PortalVirtualRoomURL,
              TimeSlots4Portal__pkUUID_Timeslot: uuidv4(),
              TimeSlots4PortalTime: FlexEvents4PortalTimeStart.split(' ')[1],
              TimeSlots4PortalTimeEnd: FlexEvents4PortalTimeEnd.split(' ')[1],
              TimeSlots4PortalTimePassingPeriod: FlexEvents4PortalPassingPeriod,
              TimeSlots4PortalTimestamp: FlexEvents4PortalTimeStart,
              TimeSlots4PortalTimestampEnd: FlexEvents4PortalTimeEnd,
              TimeSlots4PortalVirtualRoomState: '',
            };

            item.TimeSlots4PortalTimeEnd = FlexEvents4PortalTimeStart.split(' ')[1];
            item.TimeSlots4PortalTimestampEnd = FlexEvents4PortalTimeStart;
            // Determine if remaining time is less than passing period.  if it is, just remove the passing period
            const beginningAsTimeObject = moment(new Date(item.TimeSlots4PortalTimestamp));
            const endAsTimeObject = moment(new Date(item.TimeSlots4PortalTimestampEnd));

            const duration = moment.duration(endAsTimeObject.diff(beginningAsTimeObject));
            const minuteDifference = duration.asMinutes();

            if (minuteDifference <= TimeSlots4PortalTimePassingPeriod) {
              item.TimeSlots4PortalTimePassingPeriod = minuteDifference;
            }

            schedule.splice(i + 1, 0, itemToadd);
          } else if (overlapsPartofBlock) {
            const {
              TimeSlots4PortalAltRoom,
              TimeSlots4PortalAltText,
              TimeSlots4PortalCandidateScheduleBlockID,
              TimeSlots4PortalCustomAlerts,
              TimeSlots4PortalDuration,
              TimeSlots4PortalJoinManualDescription,
              TimeSlots4PortalJoinManualURL,
              TimeSlots4PortalTime,
              TimeSlots4PortalTimeEnd,
              TimeSlots4PortalTimePassingPeriod,
              TimeSlots4PortalTimestamp,
              TimeSlots4PortalTimestampEnd,
              TimeSlots4PortalVirtualRoomState,
              TimeSlots4Portal__pkUUID_Timeslot,
            } = item;

            const newItemSplit = {
              TimeSlots4PortalAltRoom,
              TimeSlots4PortalAltText,
              TimeSlots4PortalCandidateScheduleBlockID,
              TimeSlots4PortalCustomAlerts,
              TimeSlots4PortalDuration,
              TimeSlots4PortalJoinManualDescription,
              TimeSlots4PortalJoinManualURL,
              TimeSlots4PortalTime,
              TimeSlots4PortalTimeEnd,
              TimeSlots4PortalTimePassingPeriod,
              TimeSlots4PortalTimestamp,
              TimeSlots4PortalTimestampEnd,
              TimeSlots4PortalVirtualRoomState,
              TimeSlots4Portal__pkUUID_Timeslot,
            };
            const itemToadd = {
              TimeSlots4PortalAltRoom: '',
              TimeSlots4PortalAltText: FlexEvents4PortalTitle,
              TimeSlots4PortalCandidateScheduleBlockID: '',
              TimeSlots4PortalCustomAlerts: '',
              TimeSlots4PortalDuration: '',
              TimeSlots4PortalJoinManualDescription: FlexEvents4PortalVirtualRoomDescription,
              TimeSlots4PortalJoinManualURL: FlexEvents4PortalVirtualRoomURL,
              TimeSlots4Portal__pkUUID_Timeslot: uuidv4(),
              TimeSlots4PortalTime: FlexEvents4PortalTimeStart.split(' ')[1],
              TimeSlots4PortalTimeEnd: FlexEvents4PortalTimeEnd.split(' ')[1],
              TimeSlots4PortalTimePassingPeriod: FlexEvents4PortalPassingPeriod,
              TimeSlots4PortalTimestamp: FlexEvents4PortalTimeStart,
              TimeSlots4PortalTimestampEnd: FlexEvents4PortalTimeEnd,
              TimeSlots4PortalVirtualRoomState: '',
            };

            item.TimeSlots4PortalTime = FlexEvents4PortalTimeEnd.split(' ')[1];
            item.TimeSlots4PortalTimestamp = FlexEvents4PortalTimeEnd;

            newItemSplit.TimeSlots4PortalTimeEnd = FlexEvents4PortalTimeStart.split(' ')[1];
            newItemSplit.TimeSlots4PortalTimestampEnd = FlexEvents4PortalTimeStart;

            const itemBeginningAsTimeObject = moment(new Date(item.TimeSlots4PortalTimestamp));
            const itemEndAsTimeObject = moment(new Date(item.TimeSlots4PortalTimestampEnd));

            const itemDuration = moment.duration(itemEndAsTimeObject.diff(itemBeginningAsTimeObject));
            const itemMinuteDifference = itemDuration.asMinutes();

            if (itemMinuteDifference <= TimeSlots4PortalTimePassingPeriod) {
              item.TimeSlots4PortalTimePassingPeriod = itemMinuteDifference;
            }

            const newItemSplitBeginningAsTimeObject = moment(new Date(newItemSplit.TimeSlots4PortalTimestamp));
            const newItemSplitEndAsTimeObject = moment(new Date(newItemSplit.TimeSlots4PortalTimestampEnd));

            const newItemSplitDuration = moment.duration(
              newItemSplitEndAsTimeObject.diff(newItemSplitBeginningAsTimeObject),
            );
            const newItemSplitMinuteDifference = newItemSplitDuration.asMinutes();

            if (newItemSplitMinuteDifference <= TimeSlots4PortalTimePassingPeriod) {
              newItemSplit.TimeSlots4PortalTimePassingPeriod = newItemSplitMinuteDifference;
            }

            schedule.splice(i, 0, itemToadd);
            schedule.splice(i, 0, newItemSplit);
          }
        }

        // condition for it being past the schedule
        if (isBetweenEnd && i === schedule.length - 1) {
          const {
            TimeSlots4PortalTimestamp,
            TimeSlots4PortalTime,
            TimeSlots4Portal__pkUUID_Timeslot,
            TimeSlots4PortalTimeEnd,
            TimeSlots4PortalTimestampEnd,
          } = item;
          const {
            FlexEvents4PortalVirtualRoomURL,
            FlexEvents4PortalVirtualRoomDescription,
            FlexEvents4PortalPassingPeriod,
            FlexEvents4PortalTitle,
            TimeSlots4PortalTimePassingPeriod,
            FlexEvents4PortalTimeEnd,
            FlexEvents4PortalTimeEndTimeOnly,
            FlexEvents4PortalTimeStart,
            FlexEvents4PortalTimeStartTimeOnly,
          } = flexItem;
          const itemToadd = {
            TimeSlots4PortalAltRoom: '',
            TimeSlots4PortalAltText: FlexEvents4PortalTitle,
            TimeSlots4PortalCandidateScheduleBlockID: '',
            TimeSlots4PortalCustomAlerts: '',
            TimeSlots4PortalDuration: '',
            TimeSlots4PortalJoinManualDescription: FlexEvents4PortalVirtualRoomDescription,
            TimeSlots4PortalJoinManualURL: FlexEvents4PortalVirtualRoomURL,
            TimeSlots4PortalTime: TimeSlots4PortalTimeEnd,
            TimeSlots4PortalTimeEnd: FlexEvents4PortalTimeEndTimeOnly,
            TimeSlots4PortalTimePassingPeriod: FlexEvents4PortalPassingPeriod,
            TimeSlots4PortalTimestamp: TimeSlots4PortalTimestampEnd,
            TimeSlots4PortalTimestampEnd: FlexEvents4PortalTimeEnd,
            TimeSlots4PortalVirtualRoomState: '',
          };

          schedule.push(itemToadd);
        }
      }
    });
  };
  // 3 means use urls
  // virtualize meetings is set to 0 hide buttons all together
  // 2 should be using the main zoom meeting for everythin

  const determineLocalTime = (localTime, timeOfUpdate, currentServerTime) => {
    const rightNow = new Date(currentServerTime);
    const localTimeAsDateObject = new Date(localTime);
    const milisecondDifference = rightNow.getTime() - timeOfUpdate.getTime();
    localTimeAsDateObject.setTime(localTimeAsDateObject.getTime() + milisecondDifference);
    return localTimeAsDateObject;
  };

  const getData = (scheduledTimes, oldAlarms) => {
    let scheduleWithoutAdjustment;
    let schedule;
    let currentAlarms = oldAlarms;
    fetchDataAgnosticWithoutCredentials('schedule/candidateId', {
      id: clientID,
      dev: isDev(),
    })
      .then(async (res) => {
        const data = res.data.schedule;
        const { Departments4PortalName, Candidates4Portal_fkUUID_InterviewDate } = data;
        setInterviewDateUUID(Candidates4Portal_fkUUID_InterviewDate);
        setDepartmentName(Departments4PortalName);

        const { FlexEvents4Portal } = res.data.schedule;

        const { currentServerTime } = res.data;
        const { Candidates4PortalDateOfInterview, InterviewDates4PortalScheduledVirtualizedMeetings } = data;

        let meetingType;
        if (InterviewDates4PortalScheduledVirtualizedMeetings === 1) {
          meetingType = MEETING_STATUS.Standard;
        }
        if (InterviewDates4PortalScheduledVirtualizedMeetings === 2) {
          meetingType = MEETING_STATUS.AllJoinMeetingButtons;
        }
        if (InterviewDates4PortalScheduledVirtualizedMeetings === 3) {
          meetingType = MEETING_STATUS.UseScheduleUrl;
        }
        if (InterviewDates4PortalScheduledVirtualizedMeetings === 0) {
          meetingType = MEETING_STATUS.NoButtons;
        }

        // meetingType = MEETING_STATUS.AllJoinMeetingButtons;

        setMeetingType(meetingType);

        const timeOfUpdate = new Date(res.data.timeOfUpdate);

        props.setPartDocumentTitle(moment(new Date(Candidates4PortalDateOfInterview)).format('MMM. D, YYYY'));

        if (data.DeptSettings4PortalFirstBlockEarlyJoin && data.DeptSettings4PortalFirstBlockEarlyJoin !== '') {
          setFirstBlockEarlyJoin(data.DeptSettings4PortalFirstBlockEarlyJoin);
        }

        if (
          data.InterviewDates4PortalScheduledMainRoomDescription &&
          data.InterviewDates4PortalScheduledMainRoomDescription !== ''
        ) {
          setMainRoomDescription(data.InterviewDates4PortalScheduledMainRoomDescription);
          setDescriptionToUse(data.InterviewDates4PortalScheduledMainRoomDescription);
        }

        if (data.InterviewDates4PortalScheduledAlerts && typeof currentAlarms === 'undefined') {
          const alertsWithFile = handleSounds(data.InterviewDates4PortalScheduledAlerts);
          currentAlarms = createAlarms(alertsWithFile, res.data.InterviewDates4PortalScheduledPassingPeriod);
          setAlarms(currentAlarms);
        }
        props.setBreakUrl({
          type: meetingType,
          url: data.InterviewDates4PortalScheduledMainRoomURL,
          buttonText: data.InterviewDates4PortalScheduledMainRoomButtonText,
        });

        data.Departments4PortalLocalTimestamp = await determineLocalTime(
          data.Departments4PortalLocalTimestamp,
          timeOfUpdate,
          currentServerTime,
        );

        if (FlexEvents4Portal && FlexEvents4Portal.length > 0) {
          await addFlexEvents(data.TimeSlots4Portal, FlexEvents4Portal);
        }

        schedule = await adaptToTimeZone(
          data.TimeSlots4Portal,
          // because localtime is determined in filemaker and set in azure, need to calculate what localtime WOULD be
          // data.Departments4PortalLocalTimestamp,
          data.Departments4PortalLocalTimestamp,
          'TimeSlots4PortalTimestamp',
          'TimeSlots4PortalTimestampEnd',
          'TimeSlots4PortalTime',
          'TimeSlots4PortalTimeEnd',
          data.Candidates4PortalDateOfInterview,
          currentServerTime,
        );

        const rightNow = new Date(Date.now());

        const differenceAsDateObject = new Date(data.Departments4PortalLocalTimestamp);

        var timeZoneDifference = differenceAsDateObject.getTime() - rightNow.getTime(); // This will give difference in milliseconds
        var resultInMinutes = Math.round(timeZoneDifference / 60000);

        const flexEvents = await adaptToTimeZone(
          FlexEvents4Portal,
          data.Departments4PortalLocalTimestamp,
          'FlexEvents4PortalTimeStart',
          'FlexEvents4PortalTimeEnd',
          'FlexEvents4PortalTimeStartTimeOnly',
          'FlexEvents4PortalTimeEndTimeOnly',
          data.Candidates4PortalDateOfInterview,
          currentServerTime,
        );

        await FlexEvents4Portal.forEach((item) => {
          const { FlexEvents4PortalTimeEnd, FlexEvents4PortalTimeStart } = item;
          item.flexEventEndObject = new Date(FlexEvents4PortalTimeEnd);
          item.flexEventStartObject = new Date(FlexEvents4PortalTimeStart);
        });

        schedule = await createDateObjects(data.TimeSlots4Portal);
        data.TimeSlots4Portal = schedule;
        setPassingPeriod(res.data.InterviewDates4PortalScheduledPassingPeriod);
        const date = data.Candidates4PortalDateOfInterview;
        // const date = '08/23/2019';
        await setAllDayRoom(data.InterviewDates4PortalScheduledMainRoomURL);
        scheduleWithoutAdjustment = await formatSchedule(data, date);

        // TimeSlots4PortalTimeEnd: "14:58:00"
        // TimeSlots4PortalTimestampEnd: "11/09/2020 14:58:00"

        // recordId: "6374"
        // timeSlotEndObject: Tue Nov 10 2020 14:58:00 GMT-0600 (Central Standard Time) {}
        schedule = await filterRepeatItems(schedule);

        // schedule = await schedule
        //   .reverse()
        //   .filter((item, i) => {
        //     if (schedule[i - 1]) {
        //       if (item.message === schedule[i - 1].message && item.message.toUpperCase() === 'BREAK') return false;
        //     }
        //     return true;
        //   })
        //   .reverse();
        await addColors(schedule);

        // return;

        // return;

        await addPassingPeriods(schedule, data.InterviewDates4PortalScheduledPassingPeriod);

        await extractFirstAndLastDayTime(schedule, data.DeptSettings4PortalFirstBlockEarlyJoin);

        setScheduleReturned(schedule);
        const keysToCheck = {
          TimeSlots4PortalTime: true,
          TimeSlots4PortalTimeEnd: true,
          TimeSlots4PortalDuration: true,
          TimeSlots4PortalAltText: true,
          Schedules4PortalEvaluatorNamesOnly: true,
          TimeSlots4PortalAltText: true,
          Schedules4PortalAltText: true,
        };
        const arrayEqual = isArrayEqual(schedule, scheduledTimes, keysToCheck);
        if (arrayEqual !== undefined && Object.keys(arrayEqual).length > 0 && scheduledTimes !== undefined) {
          setDifferences(arrayEqual);

          Swal.fire({
            title: 'Schedule has been updated',
            text: 'changes will flash in red',
            onClose: () => {
              setShowRed(true);
              setTimeout(() => {
                setShowRed(false);
              }, 10 * 1000);
            },
          });
        }

        setScheduleReturned(schedule);
        if (!data.Candidates4PortalDateOfInterview || data.Candidates4PortalDateOfInterview === '') {
          props.history.push(`/candidate/${clientID}`);
          return;
        }
        if (
          (!data.Schedules4Portal || data.Schedules4Portal.length === 0) &&
          (!data.TimeSlots4Portal || data.TimeSlots4Portal.length === 0)
        ) {
          setNoData(true);
          setLoading(false);
          return;
        }
        const userObject = getNeededDataFromUser(data);
        if (!isValidUser(userObject)) {
          setIncorrectUUID(true);
          return;
        }
        props.setUser(userObject);
        setIsLocalTime(true);
        // setIsLocalTime(
        //   checkIfTimesMatch(new Date(date + ' ' + data.Departments4PortalLocalTime), new Date(Date.now())),
        // );

        setInterviewIsToday(sameDay(schedule, new Date(Date.now())));

        const filteredSchedule = schedule.filter((item, i) => {
          if (schedule[i - 1]) {
            if (item.message === schedule[i - 1].message && item.message.toUpperCase() === 'BREAK') return false;
          }
          return true;
        });

        // schedule is calculated dynamically so use it to tell the user their interview date
        if (filteredSchedule[0] && filteredSchedule[0].TimeSlots4PortalTimestamp) {
          const dateSplit = filteredSchedule[0].TimeSlots4PortalTimestamp.split(' ');

          setInterviewDate(dateSplit[0]);
        } else {
          // if for any reason fallback to the date provided in the json
          setInterviewDate(date);
        }
        setScheduledTimes(filteredSchedule);
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
      });
    setTimeout(() => {
      // TODO: bring this out into a useEffect
      getData(schedule, currentAlarms);
    }, 5 * 60 * 1000);
  };

  useEffect(() => {
    if (isDemo()) {
      if (!('Notification' in window)) {
      } else {
        Notification.requestPermission();
      }
    }
  }, []);

  useEffect(() => {
    if (!demo) {
      getData();
    } else {
      const nextFriday = getNextFriday();
      props.setUser(demoUserObject);
      setLoading(false);
      setIsLocalTime(true);
      setInterviewDate(nextFriday);
      setInterviewIsToday(nextFriday);
      setScheduledTimes(mockScheduledTimes);
      Swal.fire('This is a demo!', 'These are not real appointments.');
    }
  }, [clientID]);

  useEffect(() => {
    if (headerRef.current) {
      const { clientHeight } = headerRef.current;
      setTopOpacity(clientHeight);
    }
  }, [headerRef]);

  useEffect(() => {
    if (tableHeadRef.current) {
      const { clientHeight } = tableHeadRef.current;
      setHeaderOpacity(clientHeight);
    }
  }, [tableHeadRef]);

  useEffect(() => {
    if (tableRowRef.current) {
      const { clientHeight } = tableRowRef.current;
      setTableRowOpacity(clientHeight);
    }
  }, [tableRowRef]);

  const populateDates = () => {
    let dateformatTime;
    let difference;
    let color = null;
    let coverBorder = null;
    const items = scheduledTimes.map((item, i) => {
      const { TimeSlots4PortalDuration } = item;
      let last = false;
      if (demo) {
        const year = interviewDate.get;
        const stringDate = interviewDate.getFullYear();
        const numberDate = Date.parse(stringDate);
        if (scheduledTimes[i + 1]) {
          dateformatTime = item.time;
          difference = getPercentageTime(currentTime, tableRowOpacity, item.time, scheduledTimes[i + 1].time);
        } else {
          const hourLater = new Date(scheduledTimes[i].time);
          hourLater.setHours(hourLater.getHours + 1);
          difference = getPercentageTime(currentTime, tableRowOpacity, item.time, hourLater);
          last = true;
        }
      } else {
        const stringDate = interviewDate + ' ' + item.Schedules4PortalTime;
        const numberDate = Date.parse(stringDate);
        dateformatTime = new Date(numberDate);
        if (scheduledTimes[i + 1]) {
          const nextDate = scheduledTimes[i + 1].time;
          difference = getPercentageTime(currentTime, tableRowOpacity, item.time, nextDate);
        } else {
          const hourLater = new Date(numberDate);
          hourLater.setHours(item.time.getHours());
          hourLater.setSeconds(item.time.getSeconds() + 100);
          difference = getPercentageTime(currentTime, tableRowOpacity, item.time, hourLater);
          last = true;
        }
      }
      if (difference.height !== 0) {
        color = '#0C7489';
        coverBorder = '1px solid #0c7489';
      } else {
        color = '#FCDC4D';
        coverBorder = 'none';
      }
      const joinMeetingButtonVisisble = shouldJoinMeetingButtonBeVisisble(
        item,
        demo,
        passingPeriod,
        'TimeSlots4PortalTime',
        'TimeSlots4PortalTimeEnd',
        scheduledTimes[i - 1],
        i,
        firstBlockEarlyJoin,
      );
      const isCurrentTimeSlot = difference.height !== 0 && difference.height !== '100%';
      const shouldBeVisible = demo ? isCurrentTimeSlot : joinMeetingButtonVisisble;
      const timeFlashRed =
        differences['TimeSlots4PortalTime' + i] ||
        differences['TimeSlots4PortalTimeEnd' + i] ||
        differences['TimeSlots4PortalDuration' + i];

      const messageFlashRed =
        differences['TimeSlots4PortalAltText' + i] ||
        differences['Schedules4PortalEvaluatorNamesOnly' + i] ||
        differences['TimeSlots4PortalAltText' + i] ||
        differences['Schedules4PortalAltText' + i];

      return (
        <tr
          ref={tableRowRef}
          key={dateformatTime + i}
          style={{ backgroundColor: item.color }}
          className={item.isPassingPeriod ? 'dashed_top_line' : ''}
        >
          <TDPadding className="d-none d-sm-table-cell" />
          {interviewIsToday && isLocalTime && createTimeTracker(heights, i, difference, color, coverBorder)}
          <td id={'tableRow' + i} style={{ verticalAlign: 'middle' }}>
            <p className={'red_staging ' + (timeFlashRed && showRed ? 'red' : '')} style={{ margin: 0 }}>
              <Moment style={{ whiteSpace: 'nowrap' }} format="h:mm A">
                {item.time}
              </Moment>
            </p>
          </td>
          <td style={{ verticalAlign: 'middle' }}>
            <p
              className={'red_staging ' + (messageFlashRed && showRed ? 'red' : '')}
              style={{ margin: 0, textAlign: 'center' }}
            >
              {item.message}
              <br />
              {item.TimeSlots4PortalAltRoom !== '' ? item.TimeSlots4PortalAltRoom : item.Schedules4PortalRoomNumber}

              {shouldBeVisible &&
                interviewIsToday &&
                !item.isPassingPeriod &&
                showJoinMeetingButton(
                  item.message.toUpperCase(),
                  item.TimeSlots4PortalJoinManualURL,
                  item.Schedules4PortalJoinManualURL,
                  item.Schedules4Portal__pkUUID_Schedule,
                  item,
                  TimeSlots4PortalDuration,
                )}
            </p>
          </td>
          {hasRoomNumber && (
            <td>
              <p>{item.Schedules4PortalRoomNumber ? item.Schedules4PortalRoomNumber : '-'}</p>
            </td>
          )}
          <TDPadding className="d-none d-sm-table-cell" />
        </tr>
      );
    });
    return items;
  };

  if (error) {
    return <Error />;
  }

  if (loading || globalSettingsLoading) {
    return <Loading />;
  }

  if (noData) {
    return <Error header={'Schedule is not currently published.'} subHeader="Please check back later." />;
  }

  return (
    <CustomCol key={2} sm={12}>
      <form id="zoom_form" target="_blank"></form>
      {zoomPopupJSX(
        showZoomLink,
        setShowZoomLink,
        zoomLink,
        setCopied,
        copied,
        zoomInfo,
        zoomPassword,
        descriptionToUse,
        meetingId,
      )}
      <CustomCard>
        <CustomCardHeader ref={headerRef}>
          <div>
            <h4>
              Your Schedule
              <br /> <Moment format="dddd, MMMM DD, YYYY">{new Date(interviewDate)}</Moment>
            </h4>
            <div>
              <p className="no-margin">Times are displayed in your device's local time zone.</p>
            </div>
          </div>
          {demo && (
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
              <p>
                <FontAwesomeIcon icon={faInfoCircle} /> Because you're using the demo, you can change the time of day.
              </p>
              <Input
                style={{ maxWidth: '200px' }}
                type="select"
                name="select"
                id="exampleSelect"
                onChange={(e) => {
                  if (e.target.value === 'Current Time') {
                    setDemoTimeDifference(null);
                  } else {
                    const rightNow = new Date(Date.now());
                    const newTime = new Date(e.target.value);
                    var difference = newTime.getTime() - rightNow.getTime(); // This will give difference in milliseconds
                    var resultsInSeconds = Math.round(difference / 1000) + 2;
                    setDemoTimeDifference(resultsInSeconds);

                    tick();
                    // setCurrentTime(e.target.value);
                  }
                }}
              >
                <option>Current Time</option>
                {mockScheduledTimes.map((item) => {
                  return <option value={item.time}>{moment(item.time).format('h:mm a')}</option>;
                })}
              </Input>
            </div>
          )}
        </CustomCardHeader>
        {!isLocalTime && interviewIsToday && (
          <div className="alert alert-error" style={{ margin: 0, display: 'flex', justifyContent: 'center' }}>
            <div>WARNING: Your system time does not match the timezone of the interview location.</div>
          </div>
        )}

        {displayJoinMeetingButonInfo && meetingType !== 0 && (
          <div style={{ backgroundColor: '#ffe900', textAlign: 'center' }}>
            <h5>
              You're early. Join meeting buttons will appear {firstBlockEarlyJoin} minutes before your first meeting.
            </h5>
          </div>
        )}
        <CustomTable>
          <thead ref={tableHeadRef}>
            <tr>
              <th id="padding-header" className="d-none d-sm-table-cell"></th>
              {interviewIsToday && isLocalTime && (
                <th style={{ whiteSpace: 'nowrap', paddingRight: 0 }}>
                  <Moment format="h:mm:ss A">{currentTime}</Moment>
                </th>
              )}
              <th>Time</th>
              <th style={{ textAlign: 'center' }}>Event</th>
              {hasRoomNumber && <th style={{ textAlign: 'center' }}>Room</th>}
              <th className="d-none d-sm-table-cell"></th>
            </tr>
          </thead>
          <tbody>{populateDates()}</tbody>
        </CustomTable>
        {/* {timeWrappers()} */}
      </CustomCard>
    </CustomCol>
  );
};

export default InterviewSchedule;

// const timeWrappers = () => {
//   if (headerRef.current) {
//     return scheduleTimes.map((item, i) => {
//       let difference;
//       if (scheduleTimes[i + 1]) {
//         difference = getPercentageTime(item.time, scheduleTimes[i + 1].time);
//       } else {
//         const hourLater = new Date(item.time.getTime());
//         hourLater.setHours(item.time.getHours() + 1);
//         difference = getPercentageTime(item.time, hourLater);
//       }
//       if (difference.borderBottom && i === 0) {
//         difference.top = topOpacity + headerOpacity + 'px';
//         return (
//           <div>
//             <div>
//               <Moment format="h:mm A">{currentTime}</Moment>
//             </div>
//             <TimeWrapper className="yoooo" style={difference} />
//           </div>
//         );
//       }
//       if (difference.borderBottom) {
//         difference.top = topOpacity + headerOpacity + tableRowOpacity * i + 'px';
//         return (
//           <div>
//             <TimeWrapper className="yoooo" style={difference}>
//               <Moment format="h:mm:s A">{currentTime}</Moment>
//             </TimeWrapper>
//           </div>
//         );
//       }
//       if (i === 0) {
//         difference.top = topOpacity + headerOpacity + 'px';
//         return <TimeWrapper className="yoooo" style={difference} />;
//       } else {
//         difference.top = topOpacity + headerOpacity + tableRowOpacity * i + 'px';
//         return <TimeWrapper className="yoooo" style={difference} />;
//       }
//     });
//   }
// };
