import { Col } from 'reactstrap';
import styled from 'styled-components';

const HeaderStyle = {
  ColumnWrapper: styled(Col)`
    display: flex;
    justify-content: center;
    maxwidth: 100%;
    padding: 0;
  `,
};

export default HeaderStyle;
