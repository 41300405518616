import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const renderAvatar = (smallPhoto, largePhoto, Candidates4DateExternalPhotoURL) => {
  if (Candidates4DateExternalPhotoURL && Candidates4DateExternalPhotoURL !== '') {
    return (
      <div style={{ minHeight: '78px' }}>
        <img style={{ width: '78px', height: 'auto' }} src={Candidates4DateExternalPhotoURL}></img>
      </div>
    );
  }

  if (smallPhoto && smallPhoto !== '') {
    return (
      <div style={{ minHeight: '78px' }}>
        <img style={{ width: '78px', height: 'auto' }} src={smallPhoto}></img>
      </div>
    );
  }

  if (largePhoto && largePhoto !== '') {
    return (
      <div style={{ minHeight: '78px' }}>
        <img style={{ width: '78px', height: 'auto' }} src={largePhoto}></img>
      </div>
    );
  }

  return (
    <div
      style={{
        width: '78px',
        height: '78px',
        border: '1px solid grey',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <FontAwesomeIcon icon={faUser} size="4x" />
    </div>
  );
};

export { renderAvatar };
