import React, { useEffect, useState } from 'react';

const Test = () => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const testApi = localStorage.getItem('testApi');
    if (testApi) {
      setIsChecked(true);
    }
  }, []);

  const addTestVariable = () => {
    localStorage.setItem('testApi', true);
  };

  const removeTestVariable = () => {
    localStorage.removeItem('testApi');
  };

  const toggleTestBoolean = (event) => {
    if (isChecked) {
      removeTestVariable();
    } else {
      addTestVariable();
    }
    setIsChecked((prevState) => !prevState);
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      {isChecked ? <div>it's on</div> : <div>it's off</div>}
      <button onClick={toggleTestBoolean}>toggle</button>
    </div>
  );
};

export default Test;
