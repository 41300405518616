import {
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFileImage,
  faFilePdf,
  faFileWord,
  faInfoCircle,
  faSpinner,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactTooltip from 'react-tooltip';
import { Button, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { fetchDataAgnosticWithoutCredentials, postDataAgnosticWithoutCredentials } from '../../Services/dataApi';

const ROOT_LOGO_URL = 'https://rezratefiles.blob.core.windows.net/logos/';
const WIDTH_DIFFERENCE = 10;

const viewWidth = `calc(100vw - ${WIDTH_DIFFERENCE + 22}px)`;
const BOX_WIDTH = 'calc(100% - 20px)';

// function contactFilemaker() {
//   /*eslint-disable no-undef*/
//   FileMaker.PerformScript('FMDocumentView', file);
//   /*eslint-enable no-undef*/
// }

const iconStyle = {
  height: '100%',
  width: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const SingleCandidateDragAndDropItem = ({
  item,
  setFiles,
  setSelectedDocumentType,
  urlObject,
  initiateFileUpload,
  setDocumentTypes,
  setCandidateFiles,
  avatarUpload,
  uploadLogo,
  uploadAvatar,
  currentLogo,
  documentTypes,
  candidateFiles,
  currentAvatar,
  getCandidateInformation,
}) => {
  const [file, setFile] = useState(null);
  const [preventClick, setPreventClick] = useState(false);
  const [validDepartment, setValidDepartment] = useState(null);
  const [confirmReplace, setConfirmReplace] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [pendingFile, setPendingFile] = useState(null);
  useEffect(() => {
    if (uploadLogo || uploadAvatar) {
      const validateDepartment = async () => {
        const { pk_UUID_FM, department, organization, sessionID, tempToken } = urlObject;
        const validDepartment = fetchDataAgnosticWithoutCredentials('organization/department', urlObject)
          .then((res) => {
            if (res && res.data && res.data.pk_Department) {
              setValidDepartment(true);
            } else {
              setValidDepartment(false);
            }
          })
          .catch((err) => {});
      };
      validateDepartment();
    }
  }, [uploadLogo, uploadAvatar]);

  const BOX_STYLE = {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    alignItems: 'center',
    width: window.location.href.includes('localhost:000/') ? '215px' : viewWidth,
  };

  const BOX_STYLE_NO_POINTER = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: window.location.href.includes('localhost:000/') ? '215px' : viewWidth,
  };

  const sendFile = (acceptedFiles) => {
    let filesToUse = null;
    filesToUse = acceptedFiles;
    if (!filesToUse) {
      filesToUse = pendingFile;
    }

    setPreventClick(true);
    setSelectedDocumentType(item.pk_DocumentType);
    setFiles([filesToUse]);
    return initiateFileUpload([filesToUse], item.pk_DocumentType);
  };

  const initiateSending = async (acceptedFiles) => {
    const fileSent = await sendFile(acceptedFiles);
    Promise.all([fileSent]).then((values) => {
      setPreventClick(false);
    });
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 1) {
        Swal.fire('Oops', 'Please limit 1 file per category', 'error');
      } else if (uploadLogo) {
        if (file && file.deptLogoExists === true) {
          setPendingFile(acceptedFiles);
          setConfirmReplace(true);
        } else {
          initiateSending(acceptedFiles);
        }
      } else if (uploadAvatar) {
        if (file && file.deptLogoExists === true) {
          setPendingFile(acceptedFiles);
          setConfirmReplace(true);
        } else {
          initiateSending(acceptedFiles);
        }
      } else {
        if (file) {
          setPendingFile(acceptedFiles);
          setConfirmReplace(true);
        } else {
          initiateSending(acceptedFiles);
        }
      }
    },
    [file],
  );

  const determineIcon = () => {
    if (file.MimeType.includes('pdf')) {
      return faFilePdf;
    }
    if (file.MimeType.includes('image')) {
      return faFileImage;
    }
    if (file.MimeType.includes('image')) {
      return faFileWord;
    }
    return faFile;
  };

  const deleteFile = () => {
    if (file) {
      return postDataAgnosticWithoutCredentials('files/delete', null, { pk_File: file.pk_File })
        .then((res) => {
          const candidateFilesCopy = [...candidateFiles];
          candidateFilesCopy.forEach((item, i) => {
            if (item.pk_File === file.pk_File) {
              candidateFilesCopy.splice(i, 1);
            }
          });
          setFile(null);
          setCandidateFiles(candidateFilesCopy);
          getCandidateInformation();
          setConfirmDelete(false);
          return true;
        })
        .catch((err) => {
          setConfirmDelete(false);
          return false;
        });
    } else {
      setConfirmDelete(false);
      return true;
    }
  };

  const documentContent = () => {
    if (preventClick) {
      return (
        <div style={{ margin: '10px', border: '1px dashed #000', width: BOX_WIDTH }}>
          <div
            className="hover_blue"
            style={{
              ...BOX_STYLE,
              width: '100%',
              height: `calc(${window.location.href.includes('localhost:000/') ? '215px' : viewWidth}`,
              flexDirection: 'column',
              padding: '5px',
            }}
          >
            <FontAwesomeIcon icon={faSpinner} spin={true} />
          </div>
        </div>
      );
    }

    if (confirmReplace) {
      const message = uploadAvatar
        ? 'Are you sure you want to replace this avatar?'
        : `Are you sure you want to replace the current file ${item.DocumentTypeName}?`;
      return (
        <div style={{ margin: '10px', border: '1px dashed #000', width: BOX_WIDTH }}>
          <div
            style={{
              ...BOX_STYLE_NO_POINTER,
              height: `calc(${window.location.href.includes('localhost:000/') ? '215px' : viewWidth}`,
              flexDirection: 'column',
              padding: '5px',
              width: '100%',
            }}
          >
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <h6 style={{ paddingRight: '5px' }}>
                <FontAwesomeIcon icon={faExclamationTriangle} color="#d11a2a" />
              </h6>
              <h6>Replace</h6>
            </div>
            <div>
              <p style={{ textAlign: 'center' }}>
                Are you sure you want to replace the current file ({item.DocumentTypeName})?
              </p>
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
              <div>
                <Button
                  onClick={async (e) => {
                    e.preventDefault();
                    setPreventClick(true);
                    const deleted = await deleteFile();
                    const fileSent = await sendFile();
                    Promise.all([deleted, fileSent]).then((values) => {
                      setPreventClick(false);
                      setConfirmReplace(false);
                    });
                  }}
                  style={{ backgroundColor: '#d11a2a' }}
                >
                  Yes
                </Button>
              </div>
              <div>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmReplace(false);
                    setPendingFile(null);
                  }}
                  style={{ backgroundColor: '#32CD32' }}
                >
                  No
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (confirmDelete) {
      const message = uploadAvatar
        ? 'Are you sure you want to delete this avatar?'
        : `Are you sure you want to delete ${item.DocumentTypeName}?`;
      return (
        <div style={{ margin: '10px', border: '1px dashed #000', width: BOX_WIDTH }}>
          <div
            style={{
              ...BOX_STYLE_NO_POINTER,
              height: `calc(${window.location.href.includes('localhost:000/') ? '215px' : viewWidth}`,
              flexDirection: 'column',
              padding: '5px',
              width: '100%',
            }}
          >
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <h6 style={{ paddingRight: '5px' }}>
                <FontAwesomeIcon icon={faExclamationTriangle} color="#d11a2a" />
              </h6>
              <h6>Delete?</h6>
            </div>
            <div>
              <p style={{ textAlign: 'center' }}>{message}</p>
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
              <div>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setPreventClick(true);
                    deleteFile();
                    setPreventClick(false);
                  }}
                  style={{ backgroundColor: '#d11a2a' }}
                >
                  Yes
                </Button>
              </div>
              <div>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmDelete(false);
                  }}
                  style={{ backgroundColor: '#32CD32' }}
                >
                  No
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (file)
      return (
        <div style={{ margin: '10px', border: '1px dashed #000', width: BOX_WIDTH }}>
          <div
            style={{
              zIndex: 100,
              top: '1px',
              height: '40px',
              borderBottom: '1px solid black',
              // width: window.location.href.includes('localhost:000/') ? '215px' : viewWidth,
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
            onClick={() => {}}
          >
            <div
              className="hover_blue"
              style={{ ...iconStyle, cursor: 'pointer' }}
              onClick={() => {
                setConfirmDelete(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ color: '#d11a2a' }} size="lg" />
            </div>
            <div style={iconStyle}>
              {/* <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#0D47A1' }} size="lg" /> */}
              <p data-tip={file.Name.split('-UUID-')[1]} style={{ margin: 0 }}>
                <FontAwesomeIcon icon={faInfoCircle} style={{ color: '#0D47A1' }} size="lg" />
              </p>
            </div>
            <div
              className="hover_blue"
              style={{ ...iconStyle, cursor: 'pointer' }}
              onClick={() => {
                const { pk_UUID_FM, department, organization, sessionID, tempToken } = urlObject;
                // contactFilemaker();
                window.open(
                  `/viewFile/${organization}/${department}/${sessionID}/${pk_UUID_FM}/${tempToken}/${file.pk_File}/${item.pk_DocumentType}/${file.Name}`,
                  '_blank',
                );
              }}
            >
              <FontAwesomeIcon icon={faEye} style={{ color: '#2E8B57' }} size="lg" />
            </div>
          </div>
          <div
            className="hover_blue"
            {...getRootProps()}
            style={{
              ...BOX_STYLE,
              height:
                uploadLogo || uploadAvatar
                  ? 'auto'
                  : `calc(${window.location.href.includes('localhost:000/') ? '215px' : viewWidth} - ${
                      file ? '40px' : '0px'
                    })`,
            }}
          >
            <input {...getInputProps()} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
                height: '100%',
              }}
            >
              <div style={{ paddingBottom: '25px' }}>
                <div>
                  <FontAwesomeIcon icon={determineIcon()} size="4x" />
                </div>
                <div style={{ fontWeight: '700' }}>{item.DocumentTypeName}</div>
                {item.Hide && (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: '10px' }}>
                    <div style={{ paddingRight: '5px' }}>
                      <FontAwesomeIcon icon={faEyeSlash} />
                    </div>
                    <h6 style={{ margin: 0 }}>Hidden</h6>
                  </div>
                )}
              </div>
            </div>
          </div>
          <ReactTooltip place="bottom" />
        </div>
      );
    return (
      <div style={{ margin: '5px', border: '1px dashed #000', width: BOX_WIDTH }}>
        <div
          className="hover_blue"
          {...getRootProps()}
          style={{
            ...BOX_STYLE,
            height: `calc(${window.location.href.includes('localhost:000/') ? '215px' : viewWidth} - ${
              file ? '40px' : '0px'
            })`,
          }}
        >
          <input {...getInputProps()} />
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <p>Click or Drag</p>
            <p style={{ fontWeight: '700' }}>{item.DocumentTypeName}</p>
            {item.Hide && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ paddingRight: '5px' }}>
                  <FontAwesomeIcon icon={faEyeSlash} />
                </div>
                <h6 style={{ margin: 0 }}>Hidden</h6>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const specificContent = (type) => {
    const isLogo = type === 'logo';
    const isAvatar = type === 'avatar';

    if (validDepartment === null) {
      return <div />;
    }
    if (!validDepartment) {
      return (
        <div style={{ margin: '5px', border: '1px dashed #000', width: BOX_WIDTH }}>
          <div
            className="hover_blue"
            style={{
              ...BOX_STYLE,
              height: uploadLogo || uploadAvatar ? '90vh' : 'auto',
            }}
          >
            <div
              style={{
                display: 'flex',
                overflow: 'hidden',
                alignContent: 'center',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <p style={{ color: 'rgb(255, 0, 0)' }}>Invalid Department</p>
              <p style={{ fontWeight: '700' }}>{item.DocumentTypeName}</p>
              {item.Hide && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ paddingRight: '5px' }}>
                    <FontAwesomeIcon icon={faEyeSlash} />
                  </div>
                  <h6 style={{ margin: 0 }}>Hidden</h6>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
    if (confirmReplace) {
      const message = uploadAvatar
        ? 'Are you sure you want to replace this avatar?'
        : `Are you sure you want to replace the current file ${item.DocumentTypeName}?`;
      return (
        <div style={{ margin: '10px', border: '1px dashed #000', width: BOX_WIDTH }}>
          <div
            style={{
              ...BOX_STYLE_NO_POINTER,
              height: `calc(${window.location.href.includes('localhost:000/') ? '215px' : viewWidth}`,
              flexDirection: 'column',
              padding: '5px',
              width: '100%',
            }}
          >
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <h6 style={{ paddingRight: '5px' }}>
                <FontAwesomeIcon icon={faExclamationTriangle} color="#d11a2a" />
              </h6>
              <h6>Replace</h6>
            </div>
            <div>
              <p style={{ textAlign: 'center' }}>{message}</p>
            </div>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}>
              <div>
                <Button
                  onClick={async (e) => {
                    e.preventDefault();
                    if (uploadAvatar) {
                      const fileSent = await sendFile();

                      setPreventClick(false);
                      setConfirmReplace(false);
                      initiateSending(pendingFile);
                    } else {
                      setPreventClick(true);
                      const deleted = deleteFile();
                      const fileSent = sendFile();
                      Promise.all([deleted, fileSent]).then((values) => {
                        setPreventClick(false);
                        setConfirmReplace(false);
                      });
                    }
                  }}
                  style={{ backgroundColor: '#d11a2a' }}
                >
                  Yes
                </Button>
              </div>
              <div>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmReplace(false);
                    setPendingFile(null);
                  }}
                  style={{ backgroundColor: '#32CD32' }}
                >
                  No
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (currentLogo.deptLogoExists || currentAvatar.PhotoUrl) {
      let fullLogoUrl = '';
      if (isLogo) {
        fullLogoUrl = currentLogo.logoUrl;
      } else if (isAvatar) {
        fullLogoUrl = currentAvatar.avatarUrl;
      }

      return (
        <div style={{ margin: '5px', border: '1px dashed #000', width: BOX_WIDTH }}>
          <div
            className="hover_blue"
            {...getRootProps()}
            style={{
              ...BOX_STYLE,
              height: uploadLogo || uploadAvatar ? '90vh' : 'auto',
            }}
          >
            <input {...getInputProps()} />
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                overflow: 'hidden',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <img style={{ maxWidth: '100%', maxHeight: '100%' }} src={fullLogoUrl} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div style={{ margin: '5px', border: '1px dashed #000', width: BOX_WIDTH }}>
        <div
          className="hover_blue"
          {...getRootProps()}
          style={{
            ...BOX_STYLE,
            height: uploadLogo || uploadAvatar ? '90vh' : 'auto',
          }}
        >
          <input {...getInputProps()} />
          <div
            style={{
              display: 'flex',
              overflow: 'hidden',
              alignContent: 'center',
              flexDirection: 'column',
              textAlign: 'center',
            }}
          >
            <p>Click or Drag</p>
            <p style={{ fontWeight: '700' }}>{item.DocumentTypeName}</p>
            {item.Hide && (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ paddingRight: '5px' }}>
                  <FontAwesomeIcon icon={faEyeSlash} />
                </div>
                <h6 style={{ margin: 0 }}>Hidden</h6>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const determineContent = () => {
    if (uploadLogo) {
      return specificContent('logo');
    } else if (uploadAvatar) {
      return specificContent('avatar');
    } else {
      return documentContent();
    }
  };

  useEffect(() => {
    let filePlaceHolder = null;
    candidateFiles.forEach((file) => {
      if (file.fk_DocumentType === item.pk_DocumentType || uploadLogo) {
        filePlaceHolder = file;
      }
    });
    setFile(filePlaceHolder);
  }, [candidateFiles]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Row
      style={{
        width: uploadLogo || uploadAvatar ? '100%' : `calc(100vw - ${WIDTH_DIFFERENCE}px)`,
        overflowX: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
      }}
      key={item.pk_DocumentType}
    >
      {determineContent()}
    </Row>
  );
};

export default SingleCandidateDragAndDropItem;
