import React, { useEffect, useState } from 'react';
import { isDev, isValidUser } from '../../Common.functions';
import { fetchData } from '../../Services/dataApi';
import setTitle from '../../TitleHandler';
import Error from '../Statuses/Error';
import Loading from '../Statuses/Loading';
import { SubscriptionForkWrapper } from './SubscriptionFork.styles';
import Resubscribe from './UserResubscribe';
import Unsubscribe from './UserUnsubscribe';

const UNSUBSCRIBE_DEPARTMENT = 'unsubscribeDepartment';
const UNSUBSCRIBE_ALL = 'UNSUBSCRIBE_ALL';

// determins whether or not to show the user subscribe or unsubscribe component based off of their status
const SubscriptionFork = ({ setUser, setOrder, setDateList, location, match, history }) => {
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState('');
  const [error, setError] = useState(false);
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [department, setDepartment] = useState('');
  const [incorrectUUID, setIncorrectUUID] = useState(false);
  const clientID = match.params.id;
  const typeOfUnsubscription = selectedOption === UNSUBSCRIBE_ALL ? 'RezRATE' : { department };

  useEffect(
    (props) => {
      fetchData('https://tools.rezrate.com/rezrate/api/candidateportal', { unsubscribe: 1, id: clientID, dev: isDev() })
        .then(({ data }) => {
          const userObject = (({
            Candidates4PortalNameFirst,
            Candidates4PortalNameLast,
            Candidates4PortalPhotoSM,
            Departments4PortalAddressLine1,
            Departments4PortalAddressLine2,
            Departments4PortalEmail,
            Departments4PortalLogo,
            Departments4PortalName,
            Departments4PortalOrganization,
            Departments4PortalPhone,
            Departments4PortalState,
            Departments4PortalWebsite,
            Departments4PortalZip,
          }) => ({
            Candidates4PortalNameFirst,
            Candidates4PortalNameLast,
            Candidates4PortalPhotoSM,
            Departments4PortalAddressLine1,
            Departments4PortalAddressLine2,
            Departments4PortalEmail,
            Departments4PortalLogo,
            Departments4PortalName,
            Departments4PortalOrganization,
            Departments4PortalPhone,
            Departments4PortalState,
            Departments4PortalWebsite,
            Departments4PortalZip,
          }))(data);
          if (!data || data.length === 0) {
            setError(true);
          }
          if (!isValidUser(userObject)) {
            setIncorrectUUID(true);
          }
          setLoading(false);
          setUnsubscribed(data.Candidates4PortalUnsubscribed);
          setOrganization(data.Departments4PortalOrganization);
          setDepartment(data.Departments4PortalName);
          setUser(userObject);
          setTitle({
            pageTitle: 'Registration',
            departmentName: data.Departments4PortalName,
            organizationName: data.Departments4PortalOrganization,
          });
        })
        .catch(() => {
          setError(true);
        });
    },
    [clientID],
  );

  if (error) {
    return <Error />;
  }

  if (incorrectUUID) {
    return (
      <Error
        key={1}
        header="The ID provided is either invalid or expired"
        subHeader="Please verify the address and try again"
      />
    );
  }
  // unsubscribe is set when data is returned
  if (loading) {
    return <Loading header="Please wait while we load the form" subHeader=" " />;
  }
  return (
    <SubscriptionForkWrapper>
      <div key={loading.toString()}>
        {unsubscribed === 1 && (
          <Resubscribe
            department={department}
            clientID={clientID}
            organization={organization}
            history={history}
            selectedOption={selectedOption}
            UNSUBSCRIBE_DEPARTMENT={UNSUBSCRIBE_DEPARTMENT}
            UNSUBSCRIBE_ALL={UNSUBSCRIBE_ALL}
            typeOfUnsubscription={typeOfUnsubscription}
          />
        )}
        {unsubscribed === 0 && (
          <Unsubscribe
            clientID={clientID}
            selectedOption={selectedOption}
            organization={organization}
            history={history}
            UNSUBSCRIBE_ALL={UNSUBSCRIBE_ALL}
            UNSUBSCRIBE_DEPARTMENT={UNSUBSCRIBE_DEPARTMENT}
            setSelectedOption={setSelectedOption}
            typeOfUnsubscription={typeOfUnsubscription}
            department={department}
          />
        )}
      </div>
    </SubscriptionForkWrapper>
  );
};

export default SubscriptionFork;
