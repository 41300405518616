import styled from 'styled-components';

const DateDisplayStyle = {
  Wrapper: styled.div`
    color: #fff;
    display: flex;
    align-items: center;
    & p {
      margin: 0;
    }
  `,
};

export default DateDisplayStyle;
