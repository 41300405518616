import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';

const standInArray = [
  [0, 1],
  [2, 3],
  [4, 5],
  [6, 7],
  [8, 9],
];

const Circle = styled.div`
  height: 40px;
  width: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
  display: inline-block;
`;

const InnerCircle = styled.div`
  height: 30px;
  width: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props.borderColor};
  border-radius: 50%;
  display: inline-block;
  font-weight: 700;
`;

const LikertQuestion1Through10 = ({ question, Answers4QuestionsAnswerNumberScore, updateEval, i, includeNote }) => {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    // Update the answer locally immediately while waiting for the database to update
    if (Answers4QuestionsAnswerNumberScore !== 0) setSelected(Answers4QuestionsAnswerNumberScore - 1);
  }, [Answers4QuestionsAnswerNumberScore]);

  const postAnswer = (value) => {
    updateEval(question.Answers4Questions__pkUUID_Answer, value);
  };

  const constructCircleClassName = (num) => {
    return 'blue-hover ' + (selected === num ? 'selectedButton' : '');
  };
  return (
    <Row>
      <Col style={{ padding: 0 }} sm="12">
        <p style={{ fontWeight: 700 }}>
          <span style={{ fontWeight: 700 }}>{i + 1}. </span>
          {question.QuestionsVLRequiredOptional === 'Required' && <span style={{ color: '#ff0000' }}>*</span>}
          {question.QuestionsVLQuestionText}
        </p>
        {includeNote && includeNote}
      </Col>
      <Col sm="12" style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
          {standInArray.map((item, i) => {
            return (
              <Col>
                <Row style={{ paddingTop: '5px', paddingBottom: '5px' }}>
                  <Col sm="12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="vl" />
                    <p style={{ margin: 0, fontWeight: 700 }}>Example Text</p>
                    <div className="vl" />
                  </Col>
                </Row>
                <Row>
                  <Col sm="6" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Circle
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelected(item[0]);
                        postAnswer(item[0] + 1);
                      }}
                      className={constructCircleClassName(item[0])}
                    >
                      {item[0] + 1}
                    </Circle>
                  </Col>
                  <Col sm="6" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Circle
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setSelected(item[1]);
                        postAnswer(item[1] + 1);
                      }}
                      className={constructCircleClassName(item[1])}
                    >
                      {item[1] + 1}
                    </Circle>
                  </Col>
                </Row>
                <Row style={{ paddingTop: '5px' }}>
                  <Col sm="12" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {/* <div className="vl" /> */}
                    <p style={{ margin: 0, padding: '2px' }}></p>
                    {/* <div className="vl" /> */}
                  </Col>
                </Row>
              </Col>
            );
          })}
        </div>
      </Col>
    </Row>
  );
};

export default LikertQuestion1Through10;
