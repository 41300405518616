import {
  faCheck,
  faFileAlt,
  faFileImage,
  faFilePdf,
  faPencilAlt,
  faPencilRuler,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import queryString from 'query-string';
import React, { useEffect, useRef } from 'react';
import Popup from 'reactjs-popup';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';

const PopupWrapper = styled.div`
  .popup-overlay {
    .popup-content {
      width: 90vw !important;
      max-width: 800px !important;
    }
  }
`;

const lightOrDark = (color) => {
  // Variables for red, green, blue values
  var r, g, b, hsp;

  // Check the format of the color, HEX or RGB?
  if (color.match(/^rgb/)) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    return 'light';
  } else {
    return 'dark';
  }
};

const customStartsWith = (stringToExamine, phrase) => {
  if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position) {
      position = position || 0;
      return this.indexOf(searchString, position) === position;
    };
  } else {
    stringToExamine.startsWith(phrase);
  }
};

const useEventListener = (eventName, handler, element = global) => {
  // Create a ref that stores handler
  const savedHandler = useRef();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element], // Re-run if eventName or element changes
  );
};

function isDev() {
  const { hostname, search } = window.location;
  const values = queryString.parse(search);

  if (values.dev === '1' || values.dev === '0') {
    return Number(values.dev);
  }
  if (hostname === 'rezrate.azurewebsites.net' || hostname === 'localhost') {
    return 1;
  }
  return 0;
}

const informUserEvalLocked = () => {
  Swal.fire('', 'Evaluations have been locked by your administrator.', 'warning');
};

function determineCredentials() {
  if (isDev()) {
    return false;
  }

  return true;
}

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

const getSelectedDate = (arrayOfDates, dateChosen) => {
  let selectedDate;
  arrayOfDates.forEach((item) => {
    if (item.InterviewDates4Portal__pkUUID_InterviewDate === dateChosen) {
      selectedDate = item.InterviewDates4PortalDate;
    }
  });

  return selectedDate;
};

const isValidUser = (userObject) => {
  const portalNameInvalid = !userObject.Departments4PortalName || userObject.Departments4PortalName === '';
  const firstNameInvalid = !userObject.Candidates4PortalNameFirst || userObject.Candidates4PortalNameFirst === '';
  const lastNameInvalid = !userObject.Candidates4PortalNameLast || userObject.Candidates4PortalNameLast === '';
  const address1Invalid =
    !userObject.Departments4PortalAddressLine1 || userObject.Departments4PortalAddressLine1 === '';
  const externalUUIDInvalid =
    !userObject.Candidates4PortalExternalUUID || userObject.Candidates4PortalExternalUUID === '';
  if (
    !userObject ||
    typeof userObject !== 'object' ||
    (portalNameInvalid && firstNameInvalid && lastNameInvalid && address1Invalid && externalUUIDInvalid)
  ) {
    return false;
  }
  return true;
};

const joinArrays = (array1, key1, array2, key2) => {
  return array1.map((x) =>
    Object.assign(
      x,
      array2.find((y) => y[key2] == x[key1]),
    ),
  );
};

const getNeededDataFromUser = (data) => {
  const userObject = (({
    Candidates4PortalDatePreferenceArray,
    Candidates4Portal_fkUUID_InterviewDate,
    Candidates4PortalDatePreference,
    Candidates4PortalCandDeclined,
    Candidates4PortalCandResponseTS,
    Candidates4PortalNameFirst,
    Candidates4PortalCandComment,
    Candidates4PortalUnsubscribed,
    Candidates4PortalWaitlist,
    Candidates4PortalDateOfInterview,
    Candidates4PortalNameLast,
    Candidates4PortalPhotoSM,
    Departments4PortalAddressLine1,
    Departments4PortalAddressLine2,
    Departments4PortalEmail,
    Departments4PortalLogoURL,
    Departments4PortalLogo,
    Departments4PortalName,
    Departments4PortalOrganization,
    Departments4PortalPhone,
    Departments4PortalState,
    Departments4PortalWebsite,
    Departments4PortalZip,
  }) => ({
    Candidates4PortalDatePreferenceArray,
    Candidates4Portal_fkUUID_InterviewDate,
    Candidates4PortalDatePreference,
    Candidates4PortalCandDeclined,
    Candidates4PortalDateOfInterview,
    Candidates4PortalCandComment,
    Candidates4PortalUnsubscribed,
    Candidates4PortalCandResponseTS,
    Candidates4PortalNameFirst,
    Candidates4PortalNameLast,
    Candidates4PortalPhotoSM,
    Candidates4PortalWaitlist,
    Departments4PortalAddressLine1,
    Departments4PortalAddressLine2,
    Departments4PortalEmail,
    Departments4PortalLogoURL,
    Departments4PortalLogo,
    Departments4PortalName,
    Departments4PortalOrganization,
    Departments4PortalPhone,
    Departments4PortalState,
    Departments4PortalWebsite,
    Departments4PortalZip,
  }))(data);
  return userObject;
};

function isArrayEqual(arr1, arr2, keysToCheck = {}) {
  if (arr1 === null || arr2 === null) {
    return false;
  }

  let differentPoints = {};
  for (var i = arr1.length; i--; ) {
    Object.keys(arr1[i]).map((key, index) => {
      if (
        keysToCheck[key] &&
        arr1 &&
        arr2 &&
        arr1[i] &&
        arr2[i] &&
        arr1[i][key] &&
        arr2[i][key] &&
        arr1[i][key].toString() !== arr2[i][key].toString()
      ) {
        differentPoints[key + i] = true;
      }
    });
  }
  return differentPoints;
}

const getCorrectEvalToken = (isAdmin) => {
  if (isAdmin) {
    return localStorage.getItem('adminEvalToken');
  } else {
    return localStorage.getItem('evalToken');
  }
};

const setCorrectEvalToken = (isAdmin, token) => {
  if (isAdmin) {
    localStorage.setItem('adminEvalToken', token);
  } else {
    localStorage.setItem('evalToken', token);
  }
};

const removeCorrectEvalTOken = (isAdmin, user, logout) => {
  if (isAdmin) {
    localStorage.removeItem('adminEvalToken');
  } else {
    if (user) {
      logout({
        returnTo: window.location.origin,
      });
    }
    localStorage.removeItem('evalToken');
  }
};

const smoothScrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

const getPercentageTime = (currentTime, tableRowOpacity, beginning, end) => {
  if (currentTime > end) {
    return { height: '100%', border: '1px solid #0C7489', width: '1px' };
  }
  if (beginning > currentTime) {
    return { height: 0, width: '1px' };
  }
  const timeElapsed = currentTime - beginning;
  const totalTime = end - beginning;
  const percentageElapsed = timeElapsed / totalTime;
  const pixelHeight = percentageElapsed * tableRowOpacity;
  return {
    height: `${percentageElapsed * 100}%`,
    borderBottom: '1px solid #0C7489',
    border: '1px solid #0C7489',
    width: '1px',
  };
};

const getURLParameter = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const determineProgress = (
  Candidates4DateEvaluationStatus,
  Candidates4DateUnableToEvaluate,
  hideCandidateEvaluations,
) => {
  let color;
  let icon = faPencilAlt;
  let text;

  if (typeof Candidates4DateEvaluationStatus === 'undefined' || hideCandidateEvaluations) return <div />;

  if (Candidates4DateEvaluationStatus === '') {
    // color = '#FD9A00';
    color = '#0080ff';
    icon = faPencilRuler;
    text = 'Evaluation Pending';
  }

  if (Candidates4DateEvaluationStatus === 'In progress') {
    // color = '#FD9A00';
    color = '#FF8C00';
    icon = faPencilAlt;
    text = 'Evaluation In Progress';
  }
  if (Candidates4DateEvaluationStatus === 'Complete') {
    // color = '#66B131';
    color = '#00b300';
    icon = faCheck;
    text = 'Evaluation Complete';
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', color: color }}>
        <FontAwesomeIcon icon={icon} />
        <p style={{ paddingLeft: '10px', margin: 0 }}>{text}</p>
      </div>
      <div>
        {Candidates4DateUnableToEvaluate === 1 && <p style={{ margin: 0, fontSize: '.7em' }}>(Unable To Evaluate)</p>}
      </div>
    </div>
  );
};

const determineIcon = (MimeType) => {
  if (MimeType.toUpperCase().includes('PDF')) {
    return faFilePdf;
  }
  if (MimeType.toUpperCase().includes('DOC')) {
    return faFileAlt;
  }
  return faFileImage;
};

const adaptToTimeZone = (
  TimeSlots4Portal,
  serverTime,
  startKey,
  endKey,
  startKeyTimeOnly,
  endKeyTimeOnly,
  dayOfInterview,
) => {
  const rightNow = new Date(Date.now());

  const differenceAsDateObject = new Date(serverTime);

  var timeZoneDifference = differenceAsDateObject.getTime() - rightNow.getTime(); // This will give difference in milliseconds
  var resultInMinutes = Math.round(timeZoneDifference / 60000);

  TimeSlots4Portal.forEach((item, i) => {
    const start = new Date(item[startKey]);
    const end = new Date(item[endKey]);

    start.setMinutes(start.getMinutes() - resultInMinutes);
    end.setMinutes(end.getMinutes() - resultInMinutes);

    const startTimeFormatBackToString = moment(start).format('HH:mm:ss');
    const endTimeFormatBackToString = moment(end).format('HH:mm:ss');
    const startDateFormatBackToString = moment(start).format('MM/DD/yyyy');
    const endDateFormatBackToString = moment(end).format('MM/DD/yyyy');

    TimeSlots4Portal[i][startKey] = `${startDateFormatBackToString} ${startTimeFormatBackToString}`;
    TimeSlots4Portal[i][endKey] = `${endDateFormatBackToString} ${endTimeFormatBackToString}`;
    TimeSlots4Portal[i][startKeyTimeOnly] = startTimeFormatBackToString;
    TimeSlots4Portal[i][endKeyTimeOnly] = endTimeFormatBackToString;
  });
  return TimeSlots4Portal;
};

const shouldJoinMeetingButtonBeVisisble = (
  item,
  demo,
  passingPeriod,
  keyOne,
  keyTwo,
  prevItem,
  i,
  firstBlockEarlyJoin,
) => {
  if (item.isPassingPeriod) {
    return false;
  }
  const rightNow = new Date(Date.now());
  if (demo) {
    return true;
  }

  let startTimeStringSplit;
  let endTimeStringSplit;
  let prevStartTimeStringSplit;
  let prevEndTimeStringSplit;
  let prevStart;
  let prevEnd;

  if (prevItem && prevItem.isPassingPeriod) {
    prevStartTimeStringSplit = prevItem[keyOne].split(':');
    prevEndTimeStringSplit = prevItem[keyTwo].split(':');
    prevStart = new Date(new Date());
    prevEnd = new Date(new Date());
    prevStart.setHours(prevStartTimeStringSplit[0], prevStartTimeStringSplit[1], prevStartTimeStringSplit[2]);
    prevEnd.setHours(prevEndTimeStringSplit[0], prevEndTimeStringSplit[1], prevEndTimeStringSplit[2]);
  }

  startTimeStringSplit = item[keyOne].split(':');
  endTimeStringSplit = item[keyTwo].split(':');
  const start = new Date(new Date());
  const end = new Date(new Date());
  start.setHours(startTimeStringSplit[0], startTimeStringSplit[1], startTimeStringSplit[2]);
  end.setHours(endTimeStringSplit[0], endTimeStringSplit[1], endTimeStringSplit[2]);

  // if it's the first meeting show the join meeting button 15 minutes before starting time
  if (i === 0) {
    start.setMinutes(start.getMinutes() - firstBlockEarlyJoin || 0);
  }

  const withinCurrent = rightNow > start && rightNow < end;
  const withinPrevious = rightNow > prevStart && rightNow < prevEnd;

  return withinCurrent || withinPrevious;
};

const getMeetingIdFromUrl = (url) => {
  const urlSplit = url.split('/');
  let meetingId;

  urlSplit.forEach((item, i) => {
    if (item === 'j') {
      meetingId = urlSplit[i + 1];
    }
  });
  if (meetingId) {
    const meetingIdWIthoutPassword = meetingId.split('?')[0];
    return meetingIdWIthoutPassword;
  }
};

const msieVersion = () => {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf('MSIE ');

  if (msie > -1 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    // If Internet Explorer, return version number
    return true;
  } // If another browser, return 0
  else {
    return false;
  }

  return false;
};

const zoomPopupJSX = (
  showZoomLink,
  setShowZoomLink,
  zoomLink,
  setCopied,
  copied,
  zoomInfo,
  zoomPassword,
  mainRoomDescription,
  meetingId,
) => {
  return (
    <PopupWrapper style={{ color: '#000' }}>
      <Popup open={showZoomLink} closeOnDocumentClick={false} style={{ width: '100vw' }}>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: '15px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <div
              onClick={() => {
                setShowZoomLink(false);
              }}
              style={{ padding: '10px', cursor: 'pointer' }}
            >
              <FontAwesomeIcon color="#FF0000" size="lg" icon={faTimes} />
            </div>
          </div>
          <h2 style={{ textAlign: 'center' }} className="h2_mobile">
            Your meeting should open in a new tab.
          </h2>
          <h5 className="h5_mobile" style={{ paddingBottom: '20px', textAlign: 'center' }}>
            If the tab does not open,{' '}
            <a href={zoomLink} target="_blank">
              click here
            </a>{' '}
            or copy and paste the link into a new tab.
          </h5>
          {mainRoomDescription && <p>{mainRoomDescription}</p>}
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <a href={zoomLink} target="_blank" id="myInput">
              {zoomLink}
            </a>
            <div>
              {meetingId && (
                <p style={{ marginTop: '1rem', marginBottom: 'none !important' }}>
                  <span className="bold">Meeting ID: </span>
                  {meetingId}
                </p>
              )}
              {zoomPassword && zoomPassword.length > 0 && (
                <p style={{ margin: 0, padding: 0 }}>
                  <span style={{ fontWeight: '700' }}>Password:</span> {zoomPassword}
                </p>
              )}
            </div>
            <div style={{ paddingTop: '15px' }}>
              <Button
                size="sm"
                color={copied ? 'success' : 'warning'}
                onClick={(e) => {
                  e.preventDefault();

                  const el = document.createElement('textarea');
                  el.value = zoomLink;
                  document.body.appendChild(el);
                  el.select();
                  document.execCommand('copy');
                  document.body.removeChild(el);
                  setCopied(true);
                  setTimeout(() => {
                    setCopied(false);
                  }, 3000);
                }}
              >
                {copied ? 'Copied!' : 'Copy Link'}
              </Button>
            </div>
            <div>
              {zoomInfo.length > 0 && <h5 style={{ textAlign: 'center', paddingTop: '15px' }}>Call-In Numbers</h5>}
              {zoomInfo.map((item) => {
                return (
                  <div style={{ display: 'flex' }}>
                    <p style={{ fontWeight: '700', margin: 0 }}>Country: &nbsp;</p>
                    <p style={{ margin: 0 }}>{item.country} &nbsp; </p>{' '}
                    <p style={{ fontWeight: '700', margin: 0 }}>Number: &nbsp;</p>
                    <a href={`tel:+${item.number}`}>{item.number} </a>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </Popup>
    </PopupWrapper>
  );
};

const addColors = (arrayToAdd, color1 = '#FFFFFF', color2 = '#f2f2f2') => {
  arrayToAdd.forEach((item, i) => {
    const color = i % 2 === 0 ? color1 : color2;
    item.color = color;
  });
  return arrayToAdd;
};

const constructFilemakerBaseUrl = () => {
  const testApi = localStorage.getItem('testApi');
  if (testApi) {
    return 'https://tools.rezrate.com/api/v2b';
  }

  return 'https://tools.rezrate.com/api/v2';
};

const MEETING_STATUS = {
  // InterviewDates4PortalScheduledVirtualizedMeetings
  NoButtons: 0,
  Standard: 1,
  AllJoinMeetingButtons: 2,
  UseScheduleUrl: 3,
};

export {
  smoothScrollTop,
  addColors,
  MEETING_STATUS,
  determineProgress,
  useEventListener,
  adaptToTimeZone,
  emailIsValid,
  isDev,
  usePrevious,
  determineIcon,
  getPercentageTime,
  msieVersion,
  constructFilemakerBaseUrl,
  getSelectedDate,
  zoomPopupJSX,
  getMeetingIdFromUrl,
  removeCorrectEvalTOken,
  isValidUser,
  getNeededDataFromUser,
  lightOrDark,
  isArrayEqual,
  shouldJoinMeetingButtonBeVisisble,
  joinArrays,
  customStartsWith,
  determineCredentials,
  getURLParameter,
  getCorrectEvalToken,
  setCorrectEvalToken,
  informUserEvalLocked,
};
