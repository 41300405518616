import React, { useEffect, useState } from 'react';
import CoordinatorScheduleRowItem from './CoordinatorScheduleRowItem';

// const border = '4px solid #007c00'
// const boxShadow = '0 0 0 5px Black'
// const outline = 'dashed 6px firebrick';

// const boxShadow = '0 0 0 5px firebrick inset';
// const border = 'dashed 5px 007c00';

const boxShadow = '0 0 0 5px firebrick inset';
const border = 'dashed 6px darkturquoise';
const antiBorder = '4px solid black';

// constbox-shadow: 0 0 0 5px firebrick inset;
// border: dashed 5px darkturquoise;
// const boxShadow = '0 2px 8px rgba(0,0,0,0.2)';

// SchedulesCandidateBG: "FD6F9B14-7CAE-9040-896C-0173996F52B6#ffffff"
// SchedulesCandidateColor: "FD6F9B14-7CAE-9040-896C-0173996F52B6#000000"
// SchedulesCandidateFullName: "Shinagawa, Austin"
// SchedulesCandidateIsAttending: 0
// SchedulesDisplay: "Shinagawa, Austin"
// SchedulesEvaluatorIsAttending: 0
// SchedulesEvaluatorNames: "Fowler & Hsiao"
// SchedulesModified: "12/3/2020 10:18:53"
// Schedules__pkUUID_Schedule: "6E998172-F779-5045-B266-F451D0CDEE38"
// Schedules_fkUUID_Candidate: "FD6F9B14-7CAE-9040-896C-0173996F52B6"

const CoordinatorScheduleRow = ({ scheduleRow, isHeader, setSelectedBlock, setShowSelectedBlock, rightNow }) => {
  const [timeslotIsActive, setTimeslotIsActive] = useState(false);
  useEffect(() => {
    if (scheduleRow && rightNow) {
      const { MomentTimeslotsTimestampStartUTC, MomentTimeslotsTimestampEndUTC } = scheduleRow;
      const isActive = rightNow.isBetween(MomentTimeslotsTimestampStartUTC, MomentTimeslotsTimestampEndUTC);
      setTimeslotIsActive(isActive);
    }
  }, [rightNow, scheduleRow]);

  if (scheduleRow && scheduleRow.TimeslotsTimeDisplay) {
    return (
      <tr
        style={{
          overflowX: 'scroll',
          // border: timeslotIsActive ? border : antiBorder,
          // boxShadow: timeslotIsActive ? boxShadow : 'none',
          // outline: timeslotIsActive ? outline : 'none',
          top: 0,
        }}
        className={timeslotIsActive ? 'activeTableRow' : ''}
      >
        <CoordinatorScheduleRowItem
          scheduleElement={{ SchedulesDisplay: scheduleRow.TimeslotsTimeDisplay }}
          isTime={true}
          TimeslotsActive={timeslotIsActive}
          rightNow={rightNow}
          TimeslotsCustomDuration={scheduleRow.TimeslotsCustomDuration}
        />
        {scheduleRow.Schedules.map((scheduleElement, i) => {
          return (
            <CoordinatorScheduleRowItem
              setSelectedBlock={setSelectedBlock}
              setShowSelectedBlock={setShowSelectedBlock}
              rightNow={rightNow}
              TimeslotsActive={timeslotIsActive}
              i={i}
              isHeader={isHeader}
              key={scheduleElement.Schedules__pkUUID_Schedule}
              scheduleElement={scheduleElement}
            />
          );
        })}
      </tr>
    );
  }

  return <div />;
};

export default CoordinatorScheduleRow;
