// sets current logged in user so that any component can potentially access this data
export const setUser = (payload) => {
  return {
    type: 'SET_USER',
    payload,
  };
};

export const setName = (payload) => ({
  type: 'SET_NAME',
  payload,
});

export const isDemo = (payload) => ({
  type: 'SET_IS_DEMO',
  payload,
});
