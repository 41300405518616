import { faCoffee, faHandshake, faSpinner, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Table } from 'reactstrap';
import styled from 'styled-components';
import { fetchDataAgnosticWithoutCredentials, postDataAgnosticWithoutCredentials } from '../../../../Services/dataApi';
import Swal from 'sweetalert2';
import { determineProgress, getPercentageTime } from '../../../../Common.functions.js';
import { renderAvatar } from './Common.js';
import { checkForSound } from './ScheduleAlerts.js';
import ScheduleItem from './ScheduleItem.js';

let tableRowOpacity = 0;

const diff_minutes = (dt2, dt1) => {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};

const replaceWithNumber = '|###|';

const Circle = styled.div`
  position: absolute;
  bottom: 10%;
  right: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid rgb(51, 51, 51);
  background-color: #cc0000;
  width: 30px;
  height: 30px;
`;

const Schedule = ({
  date,
  getCandidate,
  alarms,
  setAlarms,
  schedule,
  interviewDateUUID,
  postRequest,
  informUserMeetingIsStartingLocally,
  Schedules4EvalJoinManualURL,
  setSchedules4EvalJoinManualURL,
  selectedUser,
  Schedules4Eval__pkUUID_Schedule,
  firstBlockEarlyJoin,
  disableJoinMeetingButton,
  setPopupDescription,
  setDisableJoinMeetingButton,
  userInformation,
  globalSettings,
  hideCandidateEvaluations,
  differences,
  setSchedules4Eval__pkUUID_Schedule,
  isMobile,
  meetingType,
  setDuration,
  duration,
  changeToCandidateTab,
  selectedCandidateIdLocal,
  setShowRed,
  showRed,
  match,
  interviewIsToday,
  setUserIdOnCurrentInterview,
  userIdOnCurrentInterview,
  requestZoomMeeting,
  handleZoomClick,
}) => {
  const [heights, setHeights] = useState([]);
  const [displayLocalNotification, setDisplayLocalNotification] = useState(true);
  const [scheduledTimes, setScheduledTimes] = useState(null);
  const [allDayRoom, setAllDayRoom] = useState(null);
  const [zoomLink, setZoomLink] = useState(null);
  const [preventJoinMeetingClick, setPreventJoinMeetingClick] = useState(false);
  const [showZoomLink, setShowZoomLink] = useState(false);
  const [chosenCandidate, setChosenCandidate] = useState(null);
  const [currentTime, setCurrentTime] = useState(new Date(Date.now()));

  useEffect(() => {
    var timerID = setInterval(() => tick(), 500);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  const determineEvent = (item, Candidates4DateEvaluationStatus, Candidates4DateUnableToEvaluate) => {
    if (item.isPassingPeriod) {
      return <h6 style={{ margin: 0 }}>Passing Period</h6>;
    }
    if (item.Candidates4Date__pkUUID_Candidate)
      return (
        <div>
          <h4 style={{ fontWeight: 700, margin: 0 }}>
            {item.Candidates4DateNameLast}, {item.Candidates4DateNameFirst}
          </h4>
          <p style={{ margin: 0, fontWeight: 700 }}>{item.Candidates4DateMedSchool}</p>
          <div>
            {determineProgress(
              Candidates4DateEvaluationStatus,
              Candidates4DateUnableToEvaluate,
              hideCandidateEvaluations,
            )}
          </div>
        </div>
      );

    if (item.Schedules4EvalAltText && item.Schedules4EvalAltText.length > 0)
      return <h5>{item.Schedules4EvalAltText}</h5>;

    return <h5>Break</h5>;
  };

  const formattedMessage = (message, minutes) => {
    return message.replace(replaceWithNumber, Math.abs(minutes));
  };

  const showJoinMeetingButton = (
    url,
    priorityUrl,
    Schedules4Portal__pkUUID_Schedule,
    message = '',
    Schedules4EvalJoinManualDescription,
    duration,
  ) => {
    const demo = false;
    // if (message === 'BREAK')
    //   return (
    //     <Fragment>
    //       <br />
    //       <Button
    //         color="warning"
    //         size="sm"
    //         onClick={(e) => {
    //           e.preventDefault();
    //           if (!demo) {
    //             handleZoomClick(allDayRoom);
    //           } else {
    //             Swal.fire('This is a demo!', 'This would normally join your meeting.');
    //           }
    //         }}
    //       >
    //         Visit Break Room
    //       </Button>
    //     </Fragment>
    //   );
    return (
      <Fragment>
        <br />
        {meetingType !== 0 && (
          <Button
            color="success"
            size="sm"
            disabled={disableJoinMeetingButton || preventJoinMeetingClick}
            style={{ minWidth: '95px' }}
            onClick={(e) => {
              e.preventDefault();
              if (!demo) {
                const { AzureZoom } = globalSettings;
                setPopupDescription(Schedules4EvalJoinManualDescription || '');
                if (typeof priorityUrl !== 'undefined' && priorityUrl === '') {
                  if (AzureZoom) {
                    if (!Schedules4Portal__pkUUID_Schedule) {
                      handleZoomClick(allDayRoom);
                      return;
                    }
                    setDisableJoinMeetingButton(true);
                    const { Users4AccessTokenDeptName, AccessTokens_fkUUID_User } = userInformation;
                    fetchDataAgnosticWithoutCredentials('schedule/season/evaluator/interviewDate/virtualMeeting', {
                      scheduleId: Schedules4Portal__pkUUID_Schedule,
                      evaluatorId: AccessTokens_fkUUID_User,
                      duration,
                      departmentName: Users4AccessTokenDeptName,
                    })
                      .then((result) => {
                        const { JoinURL, DialInNumbers, Password } = result.data;
                        handleZoomClick(JoinURL, DialInNumbers, Password);
                      })
                      .catch((err) => {
                        console.log('message', err);
                        let message = '';
                        if (err && err.message) {
                          message = err.message;
                        } else {
                          message = err;
                        }
                        postDataAgnosticWithoutCredentials('department/errorLog', {
                          message,
                          userId: localStorage.getItem('evalToken') || '',
                        });
                        Swal.fire('Error', 'Something went wrong... please try again or contact support.', 'error');
                      })
                      .finally(() => {
                        setDisableJoinMeetingButton(false);
                      });
                    return;
                  }
                  requestZoomMeeting(Schedules4Portal__pkUUID_Schedule);
                } else if (typeof priorityUrl !== 'undefined' && priorityUrl !== '') {
                  handleZoomClick(priorityUrl);
                } else if (typeof url !== 'undefined' && url === '') {
                  handleZoomClick(allDayRoom);
                } else if (typeof url !== 'undefined' && url !== '') {
                  handleZoomClick(url);
                } else {
                  handleZoomClick(allDayRoom);
                }
              } else {
                Swal.fire('This is a demo!', 'This would normally join your meeting.');
              }
            }}
          >
            {disableJoinMeetingButton || preventJoinMeetingClick ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : message.toUpperCase() === 'BREAK' && meetingType !== 2 ? (
              'Visit Break Room'
            ) : (
              'Join Meeting'
            )}
          </Button>
        )}
      </Fragment>
    );
  };

  const tick = () => {
    const rightNow = new Date();
    if (schedule) {
      schedule.forEach((element, i) => {
        if (diff_minutes(rightNow, element.time) === 0)
          if (
            diff_minutes(rightNow, element.time) === 0 &&
            displayLocalNotification &&
            element.Candidates4DateNameFull &&
            element.Candidates4DateNameFull !== ''
          ) {
            informUserMeetingIsStartingLocally(setDisplayLocalNotification, element);
          }
        checkForSound(
          element.timeSlotEndObject,
          rightNow,
          null,
          alarms,
          formattedMessage,
          setAlarms,
          date,
          element.isPassingPeriod,
          element.hasPassingPeriod,
          interviewIsToday,
        );
      });
    }

    if (schedule && document.getElementById('tableRow0')) {
      let heights = [];
      schedule.forEach((element, i) => {
        const height = document.getElementById('tableRow' + i).clientHeight;
        heights.push(height);
      });

      setHeights(heights);
    }
    setCurrentTime(new Date());
  };

  const determineIcon = (item) => {
    if (item.Schedules4EvalAltText === 'passing period') {
      return <div />;
    }
    const returnBreak = () => {
      if (item.isPassingPeriod) return <div></div>;
      return (
        <div style={{ width: '78px', height: '78px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faCoffee} size="3x" color="rgb(51,51,51)" />
        </div>
      );
    };
    if (item.Candidates4DateExternalPhotoURL || item.Candidates4DatePhotoSM || item.Candidates4DatePhoto) {
      return (
        <div style={{ position: 'relative', top: 0 }}>
          <div style={{ minHeight: '78px', display: 'flex', alignItems: 'center' }}>
            <img
              style={{ width: '78px', height: 'auto' }}
              src={item.Candidates4DateExternalPhotoURL || item.Candidates4DatePhotoSM || item.Candidates4DatePhoto}
            />
            {item.Candidates4DateDNRStatus === 1 && (
              <div style={{ position: 'absolute', bottom: 0, left: '87px' }}>
                <Circle>
                  <p style={{ padding: 0, margin: 0, fontSize: '.7em' }}>DNR</p>
                </Circle>
              </div>
            )}
          </div>
        </div>
      );
    }

    if (item.Schedules4EvalAltText && item.Schedules4EvalAltText.toUpperCase() === 'WELCOME RECEPTION')
      return (
        <div style={{ width: '78px', height: '78px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faHandshake} size="3x" color="rgb(51,51,51)" />
        </div>
      );

    if (
      item.Schedules4EvalAltText &&
      (item.Schedules4EvalAltText.toUpperCase() === 'LUNCH' || item.Schedules4EvalAltText.toUpperCase() === 'DINNER')
    )
      return (
        <div style={{ width: '78px', height: '78px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faUtensils} size="3x" color="rgb(51,51,51)" />
        </div>
      );

    if (item.Schedules4EvalAltText && item.Schedules4EvalAltText.toUpperCase() === 'BREAK') return returnBreak();

    if (item.Schedules4EvalAltText && item.Schedules4EvalAltText.length > 0) {
      const firstLetter = item.Schedules4EvalAltText.charAt(0).toUpperCase();
      return (
        <div style={{ width: '78px', height: '78px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <h2 style={{ fontWeight: '700' }}>{firstLetter}</h2>
        </div>
      );
    }

    if (item.Candidates4Date__pkUUID_Candidate) {
      return (
        <div style={{ position: 'relative', top: 0 }}>
          {renderAvatar()}
          {item.Candidates4DateDNRStatus === 1 && (
            <div style={{ position: 'absolute', bottom: 0, left: '87px' }}>
              <Circle>
                <p style={{ padding: 0, margin: 0, fontSize: '.7em' }}>DNR</p>
              </Circle>
            </div>
          )}
        </div>
      );
    }

    return returnBreak();
  };

  const joinMeetingButton = (joinMeetingButtonVisible, interviewIsToday, item) => {
    const { Schedules4EvalTimeDuration } = item;
    if (joinMeetingButtonVisible && interviewIsToday)
      return (
        <span>
          {showJoinMeetingButton(
            '',
            item.Schedules4EvalJoinManualURL,
            item.Schedules4Eval__pkUUID_Schedule,
            item.message,
            item.Schedules4EvalJoinManualDescription || '',
            Schedules4EvalTimeDuration,
          )}
        </span>
      );
  };

  const handleIterativeVariables = (item) => {
    //Variables are being set this way because CandidateAlphabetical needs to know
    // about which schedule item is currently active.  This is being determined
    // by this Schedule component so in order to communicate this to CandidateAlphabetical
    // I'm setting state in their parent component

    if (item.Schedules4Eval__pkUUID_Schedule !== Schedules4Eval__pkUUID_Schedule) {
      setSchedules4Eval__pkUUID_Schedule(item.Schedules4Eval__pkUUID_Schedule);
    }

    if (Schedules4EvalJoinManualURL !== item.Schedules4EvalJoinManualURL) {
      setSchedules4EvalJoinManualURL(item.Schedules4EvalJoinManualURL);
    }

    if (typeof item.Candidates4Date__pkUUID_Candidate === 'undefined' && userIdOnCurrentInterview !== null) {
      setUserIdOnCurrentInterview(null);
    }

    if (item.Candidates4Date__pkUUID_Candidate && item.Candidates4Date__pkUUID_Candidate !== userIdOnCurrentInterview) {
      setUserIdOnCurrentInterview(item.Candidates4Date__pkUUID_Candidate);
    }
  };

  return (
    <div id="evaluation-table-wrapper" style={{ width: '100%' }}>
      <Table responsive={true} style={{ marginBottom: 0 }}>
        <tbody>
          {schedule.map((item, i) => {
            return (
              <ScheduleItem
                item={item}
                key={`${item.Schedules4EvalTimestamp} ${item.Schedules4EvalTimestampEnd} ${i}`}
                prevItem={schedule[i - 1]}
                i={i}
                differences={differences}
                handleIterativeVariables={handleIterativeVariables}
                setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
                userIdOnCurrentInterview={userIdOnCurrentInterview}
                chosenCandidate={chosenCandidate}
                date={date}
                item={item}
                isPassingPeriod={item.Schedules4EvalAltText === 'passing period'}
                getCandidate={getCandidate}
                isMobile={isMobile}
                getPercentageTime={getPercentageTime}
                showRed={showRed}
                firstBlockEarlyJoin={firstBlockEarlyJoin}
                heights={heights}
                selectedCandidateIdLocal={selectedCandidateIdLocal}
                setChosenCandidate={setChosenCandidate}
                determineEvent={determineEvent}
                currentTime={currentTime}
                setDuration={setDuration}
                duration={duration}
                determineIcon={determineIcon}
                joinMeetingButton={joinMeetingButton}
                tableRowOpacity={tableRowOpacity}
                interviewIsToday={interviewIsToday}
              />
            );
          })}
          <tr></tr>
        </tbody>
      </Table>
    </div>
  );

  return <div></div>;
};
export default Schedule;
