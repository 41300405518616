import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import styled from 'styled-components';
import Demo from './Body/Demo/Demo';
import UserHeaderContainer from './Body/RezRATE/Header/Header.container';
import LoadingDot from './LoadingDot/LoadingDot';
import CandidateHeader from './Header/header.container';
import { useAuth0 } from './auth0/reactAuth0Spa';

import UserNotification from './UserNotification';

const HeaderWrapper = styled.div`
  width: 100%;
  background-color: rgb(255, 255, 255);
  vertical-align: middle;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
`;

const Layout = (props) => {
  const { children, location, user, embedded, partDocumentTitle } = props;
  const [padding, setPadding] = useState('0');

  const { loading } = useAuth0();

  const { pathname } = location;

  const setTitle = (conditionalText) => {
    if (!conditionalText) {
      document.title = 'RezRATE';
      return;
    }

    document.title = `RezRATE ${conditionalText}`;
  };

  useEffect(() => {
    switch (true) {
      case pathname === '/evaluate/user':
        setTitle('Evaluator Portal');
        break;
      case pathname === '/':
        setTitle('Login');
        break;
      case pathname.includes('/candidate/schedule/'):
        setTitle(`Schedule ${partDocumentTitle}`);
        break;
      case pathname.includes('/candidate/'):
        setTitle('Registration');
        break;
      default:
        setTitle();
    }
  }, [pathname, partDocumentTitle]);

  useEffect(() => {
    if (window.mobilecheck()) {
      setPadding('0px');
    } else {
      setPadding('15px');
    }
  }, []);

  if (!String.prototype.startsWith) {
    String.prototype.startsWith = function(searchString, position) {
      position = position || 0;
      return this.indexOf(searchString, position) === position;
    };
  }

  // different headers for different views
  const isCandidatePage = location.pathname.startsWith('/candidate') || location.pathname.startsWith('/unsubscribe');

  const noHeader =
    location.pathname.startsWith('/files') ||
    location.pathname.startsWith('/viewFile') ||
    location.pathname.startsWith('/logo') ||
    location.pathname.startsWith('/coordinator/schedule') ||
    location.pathname.startsWith('/avatar');

  if (noHeader) {
    document.body.style.backgroundColor = '#fff';
    return children;
  }

  if (isCandidatePage) {
    // document.body.style.backgroundColor = 'rgb(238, 238, 238)';
  } else {
    document.body.style.backgroundColor = '#fff';
  }

  const conditionalHeader = () => {
    if (isCandidatePage) {
      return (
        <HeaderWrapper>
          <CandidateHeader />
        </HeaderWrapper>
      );
    } else {
      return (
        <div style={{ width: '100%', backgroundColor: '#333333' }}>
          <UserHeaderContainer />
        </div>
      );
    }
  };
  return (
    <div>
      {props.userInfo && props.userInfo.user && props.userInfo.user.demo && <Demo />}
      <Container fluid={true} className="blorp">
        <div id="header_notification_wrapper">
          {!embedded && <Row>{conditionalHeader()}</Row>}
          <UserNotification />
        </div>
        <Row style={{ paddingLeft: padding, paddingRight: padding, paddingTop: padding }}>
          {loading && <LoadingDot />}
          {!loading && <Col>{children}</Col>}
        </Row>
      </Container>
    </div>
  );
};

export default withRouter(Layout);
