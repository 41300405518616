import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import BellSound from '../../assets/sounds/shipbell.wav';
import { CustomCarousel } from '../InterviewDates/InterviewDatesWrapper.styles';

const notificationMessages = [
  'Hello there!',
  'Over here!',
  'Greetings, from RezRate!',
  `Looks like they're working!`,
  `You're all set here!`,
];

const title = 'Welcome to RezRATE!';
const message = `RezRATE can be used to give you notifications of different things such as when your interview meetings are starting and ending.  On this page we'll test your browser to make sure you don't miss these.`;
const messageTwo = 'blorp';
const firstSlideMessage = `First, let's make sure you're allowing notifications from RezRATE (we promise we'll only use this to help you keep track of your schedule).`;
const secondSlideMessage = `Now, let's make sure you're getting visual notifications.  Press the button below to send yourself a notification.`;

const notificationRequest = {
  Requesting: `We've sent you a request to send you notifications.  This can appear at different places on your device but genually appears in the top left or right of the screen.`,
  Approved: `Great! Looks like your device is allowing us to send you notifications.  On the next slide we'll test a few things to make sure you're properly getting them.`,
  Denied: `Uh oh, looks like your device has denied us access to notifications.  Click ${htmlInfo} to get some troubleshooting help.`,
};

const NotificationTroubleshootUrl = 'https://www.rezrate.com/support/getting-started/notifications/';

const htmlInfo = (
  <a href={NotificationTroubleshootUrl} target="_blank">
    here
  </a>
);

const titleStyle = {
  color: 'RGB(0, 89, 120)',
};

const buttonWrapper = {
  display: 'flex',
  justifyContent: 'space-evenly',
};

const wrapperStyle = {
  height: '100%',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

const NotificationTest = () => {
  const [canRecieveNotifications, setCanRecieveNotifications] = useState(true);
  const [isGranted, setIsGranted] = useState(false);
  const [isDenied, setIsDenied] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);
  const [currentVisualMessage, setCurrentVisualMessage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);

  const checkIfUserCanRecieveNotifications = () => {
    if (!('Notification' in window)) {
      setCanRecieveNotifications(false);
    } else if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      setIsGranted(true);
    } else if (Notification.permission !== 'denied') {
      setIsRequesting(true);
      Notification.requestPermission().then(function(permission) {
        // If the user accepts, let's create a notification
        setIsRequesting(false);
        if (permission === 'granted') {
          setIsGranted(true);
        } else if (permission === 'denied') {
          setIsDenied(true);
        }
        setIsRequesting(false);
      });
    } else if (Notification.permission === 'denied') {
      setIsDenied(true);
    }
  };

  const determineMessage = () => {
    const messageToSend = notificationMessages[currentVisualMessage];
    if (currentVisualMessage < notificationMessages.length - 1) {
      setCurrentVisualMessage((prevState) => {
        return prevState + 1;
      });
    }
    return messageToSend;
  };

  const visualTest = () => {
    new Notification(determineMessage());
  };

  const audioTest = () => {
    var audio = new Audio(BellSound);
    audio.volume = 0.5;
    audio.play();
  };

  useEffect(() => {
    checkIfUserCanRecieveNotifications();
    setIsLoading(false);
  }, []);
  if (isLoading) {
    return <div></div>;
  }

  const determineWidth = () => {
    if (currentSlide === 0 && !isGranted) {
      return 0;
    } else if (currentSlide === 0) {
      return '33.3%';
    } else if (currentSlide === 1) {
      return '66.6%';
    } else if (currentSlide === 2) {
      return '100%';
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div style={{ textAlign: 'center' }}>
        <h3>{title}</h3>
        <p>{message}</p>
      </div>
      <CustomCarousel
        className="carousel-notifications"
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        swipeable={false}
        selectedItem={currentSlide}
      >
        <div className="check-notifications" style={wrapperStyle}>
          <h5 style={titleStyle}>{firstSlideMessage}</h5>
          {!canRecieveNotifications && (
            <div>
              <h5 style={{ color: '#FF0000' }}>
                <span className="bold">Status: </span>Incompatible
              </h5>
              <p>
                Uh oh, looks like your device doesn't have the ability to recieve notifications. You can switch devices
                and try again or use RezRATE without notifications.
              </p>
            </div>
          )}
          {isRequesting && (
            <div>
              <h5 style={{ color: '#ffa500' }}>
                <span className="bold">Status: </span>Requesting
              </h5>
              <p>{notificationRequest.Requesting}</p>
            </div>
          )}
          {isGranted && (
            <div>
              <h5 style={{ color: '#189C5B' }}>
                <span className="bold">Status: </span>Approved
              </h5>
              <p>{notificationRequest.Approved}</p>
            </div>
          )}
          {isDenied && (
            <div>
              <h5 style={{ color: '#FF0000' }}>
                <span className="bold">Status: </span>Denied
              </h5>
              <p>
                Uh oh, looks like your device has denied us access to notifications. Click {htmlInfo} to get some
                troubleshooting help.
              </p>
            </div>
          )}
          <div>
            {isGranted && (
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentSlide(1);
                }}
                color="primary"
              >
                Next
              </Button>
            )}
          </div>
        </div>
        <div style={wrapperStyle}>
          <h5 style={titleStyle}>{secondSlideMessage}</h5>
          <div>
            <Button
              disabled={!isGranted}
              onClick={(e) => {
                e.preventDefault();
                visualTest();
              }}
              color="info"
            >
              Send Notfication!
            </Button>
          </div>
          <div>
            if you're recieving notifications, great! We can move to the next step. If not, we can try to help you{' '}
            <a href={NotificationTroubleshootUrl} target="_blank" className="bold">
              troubleshoot.
            </a>
          </div>
          <div style={buttonWrapper}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setCurrentSlide(0);
              }}
            >
              Previous Step
            </Button>

            <Button
              onClick={(e) => {
                e.preventDefault();
                setCurrentSlide(2);
              }}
              color="primary"
            >
              Next
            </Button>
          </div>
        </div>
        <div style={wrapperStyle}>
          <h5 style={titleStyle}>
            Now, let's test your audio settings. RezRATE can you send you audio notifications when you're not looking
            directly at your browser.
          </h5>
          <p>
            click the button below to send yourself an audio notification (remember to have the volume turned up on your
            device).
          </p>
          <div>
            <Button
              disabled={!isGranted}
              style={{ marginBottom: '15px' }}
              onClick={(e) => {
                e.preventDefault();
                audioTest();
              }}
              color="info"
            >
              Send Audio Notfication!
            </Button>
          </div>
          <p>
            You should hear the sound of a ship bell. We'll use this sound along with visual notifications to help keep
            you informed.
          </p>
          <p>
            If you heard the sound, congratulations! You're all finished. If not, we can help you troubleshoot this.
          </p>
          <div style={buttonWrapper}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setCurrentSlide(1);
              }}
            >
              Previous Step
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.open(NotificationTroubleshootUrl, '_blank');
              }}
              color="warning"
            >
              Troubleshoot
            </Button>
          </div>
        </div>
      </CustomCarousel>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingTop: '10px' }}>
        <p style={{ padding: 0, margin: 0 }}>Progress</p>
        <div
          className="progress_bar"
          style={{
            width: '95vw',
            maxWidth: '400px',
            height: '10px',
            border: '1px solid #000',
          }}
        >
          <div style={{ height: '10px', width: determineWidth(), backgroundColor: '#292FE9' }}></div>
        </div>
      </div>
    </div>
  );
};

export default NotificationTest;
