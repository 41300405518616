import { faEnvelope, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useState } from 'react';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { determineCredentials, emailIsValid, isDev } from '../../Common.functions';

require('velocity-animate');
require('velocity-animate/velocity.ui');

const Wrapper = styled.div`
  padding: 30px;
`;

const ForgotPassword = (props) => {
  const [email, setEmail] = useState(props.email);
  const [requestSent, setRequestSent] = useState(false);
  const [requestMessage, setRequestMessage] = useState('');
  const [disabledButton, setDisabledButton] = useState(false);

  const validateForm = (e) => {
    e.preventDefault();
    if (!emailIsValid(email)) {
      Swal.fire('Oops...', 'Please include a valid email', 'error');
      return;
    }
    async function postData() {
      setDisabledButton(true);
      const bodyFormData = new FormData();
      bodyFormData.append('email', email);
      bodyFormData.append('reset', 1);

      const url = 'https://tools.rezrate.com/rezrate/api/login/';

      const response = await axios({
        method: 'post',
        url,
        withCredentials: determineCredentials(),
        params: { dev: isDev() },
        data: bodyFormData,
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((res) => {
          if (res.data.result === '200') {
            setRequestSent(true);
            setRequestMessage(res.data.message);
          } else {
            Swal.fire('Something went wrong', 'Check the email and make sure it is correct', 'error');
          }
        })
        .catch((err) => {})
        .finally(() => {
          setDisabledButton(false);
        });
    }
    postData();
  };

  if (requestSent) {
    return (
      <Col className="alert alert-success" style={{ paddingTop: '30px' }}>
        <Row style={{ padingTop: '10vh' }}>
          <Col style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <h3>
              Your request has been received.
              <br />
              Please check your email for further instructions.
            </h3>
          </Col>
        </Row>
      </Col>
    );
  }

  return (
    <Col>
      {props.expiredToken && (
        <Row
          className="alert alert-error"
          style={{ height: '35px', width: '100vw', display: 'flex', alignItems: 'center', padding: 0 }}
        >
          <Col lg={{ size: 4, offset: 4 }}>
            <h6 style={{ margin: 0 }}>Your token is invalid or has expired.</h6>
          </Col>
        </Row>
      )}
      <Row width="100%" style={{ textAlign: 'center', paddingTop: '30px' }}>
        <Col lg={{ size: 4, offset: 4 }}>
          <h2>Forgot Password?</h2>
          <p>You can reset your password here.</p>
          <Form
            id="reset-form"
            role="form"
            autoComplete="on"
            className="form"
            // method="post"
            // action="https://tools.rezrate.com/login/"
          >
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText style={{ height: '100%' }}>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  autoFocus=""
                  id="emailForgot"
                  name="email"
                  placeholder="email address"
                  className="form-control"
                  type="email"
                  data-error="Invalid email address."
                  required=""
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input id="userForgot" name="user" type="hidden" />
              </InputGroup>
              <div className="help-block with-errors"></div>
            </FormGroup>
            <FormGroup style={{ textAlign: 'center' }}>
              <Button
                disabled={disabledButton}
                onClick={validateForm}
                type="submit"
                name="recover-submit"
                value="Reset Password"
                className="btn-lg btn-primary"
                style={{ backgroundColor: '#337ab7', minWidth: '200px' }}
              >
                {disabledButton ? <FontAwesomeIcon spin icon={faSpinner} /> : 'Reset Password'}
              </Button>
            </FormGroup>
          </Form>
        </Col>
      </Row>
    </Col>
  );
};

export default ForgotPassword;
