import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { Button, Table } from 'reactstrap';
import CandidateEvaluatorDetails from './CandidateEvaluatorDetails';
import CoordinatorScheduleRow from './CoordinatorScheduleRow';

const CoordinatorSchedule = ({ match }) => {
  const [schedule, setSchedule] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [showSelectedBlock, setShowSelectedBlock] = useState(false);
  const [evaluators, setEvaluators] = useState([]);

  const getInfo = (id) => {
    Axios({
      method: 'get',
      url: `https://tools.rezrate.com/api/v2/department/schedule/?id=${id}`,
      withCredentials: true,
    })
      .then(async (results) => {
        if (results && results.data && results.data.Timeslots) {
          const newEvaluators = {};

          await results.data.Timeslots.forEach((timeslot) => {
            timeslot.Schedules.forEach((schedule) => {
              const { SchedulesCandidate, SchedulesEvaluators } = schedule;
              if (SchedulesCandidate && Object.keys(SchedulesCandidate).length > 0) {
                schedule.hasCandidate = true;
                // since these are all represented on one table, it's easier just to concat them all initially
                // and note which one(s) are a candidate
                schedule.SchedulesCandidatesAndEvaluators = [{ ...SchedulesCandidate, isCandidate: true }].concat(
                  SchedulesEvaluators,
                );
              }
            });
          });

          const evalSchedule = results.data.Timeslots[0].Schedules.map((item) => {
            return {
              SchedulesDisplay: item.SchedulesEvaluatorNames,
              SchedulesCandidateColor: '#000000',
              Schedules__pkUUID_Schedule: item.SchedulesEvaluatorNames,
            };
          });

          newEvaluators.TimeslotsTimeDisplay = 'Time';
          newEvaluators.SchedulesCandidateBG = '#FFFFFF';
          newEvaluators.Schedules = evalSchedule;
          setEvaluators(newEvaluators);
          setSchedule(results.data.Timeslots);
          setLoading(false);
        } else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  useEffect(() => {
    const { id } = match.params;
    getInfo(id);

    const interval = setInterval(() => {
      getInfo(id);
    }, 30 * 1000);
    return () => clearInterval(interval);
  }, []);
  if (error) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h4> Schedule is not available</h4>
        <Button
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            window.location.reload();
          }}
        >
          Refresh
        </Button>
      </div>
    );
  }
  if (loading) {
    return (
      <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <FontAwesomeIcon icon={faSpinner} size="3x" spin />
      </div>
    );
  }

  return (
    <Fragment>
      <div>
        <Popup
          open={showSelectedBlock}
          onClose={() => {
            setShowSelectedBlock(false);
            setSelectedBlock(null);
          }}
          style={{ width: '100vw' }}
        >
          <CandidateEvaluatorDetails selectedBlock={selectedBlock} setShowSelectedBlock={setShowSelectedBlock} />
        </Popup>
      </div>
      <Table style={{ position: 'relative', top: 0, maxWidth: '100vw' }}>
        <thead>
          <CoordinatorScheduleRow scheduleRow={evaluators} isHeader />
        </thead>
        <tbody>
          {schedule.map((scheduleRow) => {
            return (
              <CoordinatorScheduleRow
                setSelectedBlock={setSelectedBlock}
                setShowSelectedBlock={setShowSelectedBlock}
                key={`${scheduleRow.TimeslotsTimestamp}${scheduleRow.TimeslotsTimestampEnd}`}
                scheduleRow={scheduleRow}
              />
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default CoordinatorSchedule;
