import React from 'react';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Generic loading screen.  Can either have variables passed in or will take the values of the header and subheader below
const Info = ({ header, subHeader }) => {
  const headerMessage = header ? header : '';
  const subHeaderMessage = subHeader ? subHeader : '';
  return (
    <div className="alert alert-success" style={{ margin: 0 }}>
      <div className="midwrapper">
        <FontAwesomeIcon icon={faInfoCircle} size="4x" style={{ marginRight: '50px' }} />
        <div className="midblock">
          <h2>{headerMessage}</h2>
          {subHeaderMessage}
        </div>
      </div>
    </div>
  );
};

export default Info;
