export const setDateList = (payload) => ({
  type: 'SET_DATE_LIST',
  payload,
});

// order is necessary as drag-and-drop uses numerical values for their data array (only use numbered arrays in this. i.e. [0, 1, 2, 3])
export const setOrder = (payload) => ({
  type: 'SET_ORDER',
  payload,
});
