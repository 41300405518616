import styled from 'styled-components';
import { cssSizes } from '../Common.style';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  height: 100%;
  overflow-y: hidden;
  justify-content: center;
  align-items: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  -webkit-align-items: center;
  & * {
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
  }
`;

const List = styled.div`
  margin: 25px 0 25px 0;
  width: 50vw;
  max-width: 400px;
  height: ${({ height }) => height}px;
  ${cssSizes.phoneLandscape`
    width: 95vw;
  `}
`;
export { Wrapper, List };
