import 'bootstrap/dist/css/bootstrap.css';
import React, { useEffect, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import './App.css';
// import CacheHandler from './CacheHandler';
import DocumentViewer from './Body/DocumentViewer/DocumentViewer';
import CandidatePhoto from './Body/DragAndDropFiles/CandidatePhoto';
import DragAndDropFiles from './Body/DragAndDropFiles/DragAndDropFiles';
import FileViewer from './Body/DragAndDropFiles/FileViewer';
import Footer from './Body/Footer';
import FourOhFour from './Body/FourOhFour';
import InterviewDatesRouterContianer from './Body/InterviewDates/InterviewDateRouter.container';
import InterviewScheduleContainer from './Body/InterviewDates/InterviewDatesSteps/InterviewSchedule.container';
import { CustomCard, CustomCol } from './Body/InterviewDates/InterviewDatesWrapper.styles';
import ZoomRoom from './Body/InterviewDates/ZoomRoom';
import EvalTokenSetterContainer from './Body/Login/EvalTokenSetter.container';
import ForgotPasswordContainer from './Body/Login/ForgotPassword.container';
import LoginContainer from './Body/Login/Login.container';
import ResetContainer from './Body/Login/Reset.container';
import NotificationTest from './Body/Notifications/NotificationTest';
import CoordinatorSchedule from './Body/RezRATE/Coordinator/CoordinatorSchedule';
import CoordinatorScheduleNew from './Body/RezRATE/Coordinator/CoordinatorScheduleNew';

import AgreementDocumentContainer from './Body/RezRATE/Header/MobileForkEvaluator/AgreementDocumentContainer';
import RezRATE from './Body/RezRATE/RezRATE';
import Wrapper from './Body/RezRATE/Wrapper';
import SubscriptionForkContainer from './Body/Subscription/SubscriptionFork.container';
import Test from './Body/Test/Test';
import LayoutContainer from './Layout.container';

const OutterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100%;
`;

const fakeAuth = {
  isAthenticated: true,
};
// const checkAuth = () => {
//   const token = localStorage.getItem('token');
//   const refreshToken = localStorage.getItem('refreshToken');

//   if (!token || !refreshToken) {
//     return false;
//   }

//   try {
//     // { exp: 12903819203 }
//     const { exp } = decode(refreshToken);

//     if (exp < new Date().getTime() / 1000) {
//       return false;
//     }
//   } catch (e) {
//     return false;
//   }

//   return true;
// };

// const AuthRoute = ({ component: Component, ...rest }) => {
//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         checkAuth() ? (
//           <Component {...props} />
//         ) : (
//           <Redirect
//             to={{
//               pathanme: './login',
//               state: { from: props.location },
//             }}
//           />
//         )
//       }
//     />
//   );
// };

const App = () => {
  const [embedded, setEmbedded] = useState(null);

  const toggleEmbedded = () => {
    const { href } = window.location;
    if (href.toUpperCase().includes('EMBEDDED=1')) {
      setEmbedded(true);
    } else {
      setEmbedded(false);
    }
  };

  useEffect(() => {
    toggleEmbedded();
    window.addEventListener(
      'hashchange',
      function() {
        toggleEmbedded();
      },
      false,
    );
  }, []);

  if (embedded === null) return <div />;

  return (
    <OutterWrapper>
      {/* <CacheHandler> */}
      <div className="two">
        <Router className=" three">
          <LayoutContainer embedded={embedded}>
            <CustomCol key={2} sm={12} className="">
              <CustomCard style={{ border: 'none' }} className="">
                <Switch>
                  <Route exact path="/" component={LoginContainer} />
                  <Route exact path="/ssologin" render={(props) => <LoginContainer {...props} ssoLogin={true} />} />
                  <Route
                    path="/viewFile/:organization/:department/:seasonID/:pk_UUID_FM/:tempToken/:pk_File/:pk_DocumentType/:fileName"
                    component={FileViewer}
                  />
                  <Route path="/login/:id" component={EvalTokenSetterContainer} />
                  <Route path="/zoom" component={ZoomRoom} />
                  <Route path="/reset/:id" component={ResetContainer} />
                  <Route path="/reset" component={ForgotPasswordContainer} />
                  <Route path="/coordinator/schedule/:id" component={CoordinatorSchedule} />
                  <Route path="/coordinator/schedulev2/:id" component={CoordinatorScheduleNew} />
                  <Route path="/candidate/photo/:id/:token" component={CandidatePhoto} />
                  <Route path="/candidate/photo/:id" component={CandidatePhoto} />
                  <Route path="/candidate/schedule/:id" component={InterviewScheduleContainer} />
                  <Route path="/candidate/:id/" component={InterviewDatesRouterContianer} />
                  <Route path="/unsubscribe/:id" component={SubscriptionForkContainer} />
                  {/* `/viewFile/${organization}/${department}/${pk_UUID_FM}/${sessionID}/${tempToken}` */}
                  <Route path="/agreement" component={AgreementDocumentContainer} />
                  <Route exact path="/evaluate" component={Wrapper}></Route>
                  <Route path="/notifications/test" component={NotificationTest} />
                  {/* <Route path="/testingUpload" component={TestingUpload} /> */}
                  <Route path="/evaluate/user" component={RezRATE} />
                  <Route path="/e0b6c3c4-c758-4747-8c1f-1248597713f2" component={Test} />
                  <Route
                    path="/files/view/:organization/:department/:seasonID/:pk_UUID_FM/:tempToken"
                    render={(props) => <DocumentViewer {...props} embedded={embedded} />}
                  />
                  <Route
                    path="/files/:organization/:department/:seasonID/:pk_UUID_FM/:tempToken"
                    render={(props) => <DragAndDropFiles {...props} embedded={embedded} />}
                  />
                  <Route
                    path="/logo/:organization/:department/:tempToken"
                    render={(props) => <DragAndDropFiles {...props} uploadLogo={true} embedded={embedded} />}
                  />
                  <Route
                    path="/avatar/:organization/:department/:candidate/:tempToken"
                    render={(props) => <DragAndDropFiles {...props} uploadAvatar={true} embedded={embedded} />}
                  />
                  {/* <Route component={Test} /> */}
                  <Route component={FourOhFour} />
                </Switch>
              </CustomCard>
            </CustomCol>
          </LayoutContainer>
        </Router>
      </div>
      <Footer />
      {/* </CacheHandler> */}
    </OutterWrapper>
  );
};

export default App;

// :authority: tools.rezrate.com
// :method: GET
// :path: /Streaming_SSL/Additional_1/99600DCAB3C04EB2CAEF37451B8449DD482521A9AA2FB81471CE976385DFCF76.jpg?RCType=EmbeddedRCFileProcessor
// :scheme: https
// accept: text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9
// accept-encoding: gzip, deflate, br
// accept-language: en-US,en;q=0.9
// cache-control: no-cache
// cookie: X-FMS-Session-Key=4FFC1084F8A5FF568B376DAFFA458012A21E404F6DF409BEB5E3E7B854E7F316
// pragma: no-cache
// sec-fetch-dest: document
// sec-fetch-mode: navigate
// sec-fetch-site: none
// sec-fetch-user: ?1
// upgrade-insecure-requests: 1
// user-agent: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/80.0.3987.132 Safari/537.36
