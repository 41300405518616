import React from 'react';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// generic success screen.  Can pass in variables or use generic message
const Warning = ({ header, subHeader, topAndBottomPadding }) => {
  const headerMessage = header ? header : 'Warning!';
  const subHeaderMessage = subHeader ? subHeader : '';
  return (
    <div
      className="alert alert-warning"
      style={{
        margin: 0,
        paddingTop: topAndBottomPadding ? topAndBottomPadding : 'auto',
        paddingBottom: topAndBottomPadding ? topAndBottomPadding : 'auto',
      }}
    >
      <div className="midwrapper">
        <FontAwesomeIcon icon={faExclamationTriangle} size="4x" style={{ marginRight: '50px' }} />
        <div className="midblock">
          <h3>{headerMessage}</h3>
          {subHeaderMessage}
        </div>
      </div>
    </div>
  );
};

export default Warning;
