import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import Swal from 'sweetalert2';
import { determineCredentials, isDev } from '../../Common.functions';
import { CustomButton, EvenRow } from '../InterviewDates/InterviewDatesSteps/InterviewDateSelection.styles';
import { CustomCard, CustomCardHeader, CustomCol } from '../InterviewDates/InterviewDatesWrapper.styles';
import { HeaderWrapper } from './Resubscribe.styles';

const Unsubscribe = ({
  setSubscribed,
  setSelectedOption,
  selectedOption,
  department,
  UNSUBSCRIBE_DEPARTMENT,
  UNSUBSCRIBE_ALL,
  clientID,
  history,
}) => {
  const [comment, setComment] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const sendRequest = useCallback(async () => {
    setDisableButton(true);
    const bodyFormData = new FormData();
    bodyFormData.append('unsubscribe', '1');
    if (comment !== '') {
      bodyFormData.append('comments', comment);
    }
    const url = 'https://tools.rezrate.com/rezrate/api/candidateportal/';
    const postData = await axios({
      method: 'post',
      url,
      withCredentials: determineCredentials(),
      params: { id: clientID, dev: isDev() },
      data: bodyFormData,
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => {
        setDisableButton(false);
        if (res.data === 'unsubscribed') {
          window.location.reload();
        } else {
          Swal.fire('Something went wrong...', 'Your submission was not recieved.<br>please try again later.', 'error');
        }
      })
      .catch(() => {
        setDisableButton(false);
        Swal.fire('Something went wrong...', 'Your submission was not recieved.<br>please try again later.', 'error');
      });
  });

  return (
    <CustomCol key={2} sm={12}>
      <CustomCard>
        <CustomCardHeader>
          <h4>Are you sure you want to unsubscribe?</h4>
        </CustomCardHeader>
        <div style={{ padding: '0 10vw 0 10vw' }}>
          <HeaderWrapper>
            <h4>
              Unsubscribing from RezRATE notifications will prevent you from receiving future invitations and
              communications from any residency programs utilizing our service.
            </h4>
          </HeaderWrapper>
          <EvenRow style={{ margin: 0 }}>
            <Form style={{ width: '100%', paddingTop: '20px' }}>
              <FormGroup>
                <Input
                  type="textarea"
                  name="text"
                  value={comment}
                  id="exampleText"
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Enter optional comments here..."
                />
              </FormGroup>
            </Form>
            <CustomButton
              color="danger"
              disabled={disableButton}
              onClick={(e) => {
                e.preventDefault();
                sendRequest();
              }}
            >
              {disableButton ? <FontAwesomeIcon spin icon={faSpinner} /> : 'Unsubscribe'}
            </CustomButton>
            <CustomButton
              disabled={disableButton}
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/candidate/${clientID}`);
              }}
            >
              Cancel
            </CustomButton>
          </EvenRow>
        </div>
      </CustomCard>
    </CustomCol>
  );
};

export default Unsubscribe;
