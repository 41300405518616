import styled from 'styled-components';

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justifycontent: center;
  alignitems: center;
  padding: 15px;
  flexdirection: column;
`;

export { HeaderWrapper };
