import styled from 'styled-components';
import { Row, Button, ListGroupItem } from 'reactstrap';
import { cssSizes } from '../../Common.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EvenRow = styled(Row)`
  display: flex;
  justify-content: space-evenly;
`;

const CustomButton = styled(Button)`
  color: rgb(255, 255, 255);
  width: 40vw;
  max-width: 300px;
  ${cssSizes.tabletPortrait`
    padding: 15px 0 15px 0
  `}
  margin: 15px 0 20px 0;
`;

export { EvenRow, CustomButton };
