import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import styled from 'styled-components';
import { getMeetingIdFromUrl, removeCorrectEvalTOken, zoomPopupJSX } from '../../../../Common.functions';
import Style from './UserInformation.style';

const CustomDropdown = styled(Dropdown)`
  button {
    background-color: transparent !important;
    border: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    text-align: left !important;
  }
`;

const UserInformation = ({ userInfo, setUser, isAdmin, zoomBreakRoom, breakUrl }) => {
  const [isMobile, setIsMobile] = useState(window.mobilecheck());
  const [zoomLink, setZoomLink] = useState('');
  const [password, setPassword] = useState(null);
  const [copied, setCopied] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showZoomLink, setShowZoomLink] = useState(false);
  const [meetingId, setMeetingId] = useState(null);
  const [logOut, setLogout] = useState(false);

  const { loading, user, isAuthenticated, loginWithRedirect, getTokenSilently, logout } = useAuth0();

  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const getPasswordFromUrl = (zoomUrl) => {
    const passwordSplit = zoomUrl.split('?');
    if (passwordSplit[1]) {
      const withoutKey = passwordSplit[1].split('=');
      if (withoutKey[1]) {
        setPassword(withoutKey[1]);
      }
    }
  };

  if (!userInfo) return <div></div>;

  const hasImage = userInfo.Users4AccessTokenPhotoSM && userInfo.Users4AccessTokenPhotoSM !== '';

  if (logOut) {
    return <Redirect to="/" />;
  }

  return (
    <Style.Wrapper>
      <Style.TitleWrapper>
        <h6>{userInfo.Users4AccessTokenFormalName}</h6>
        <h6>{userInfo.Users4AccessTokenDeptName}</h6>
      </Style.TitleWrapper>
      <CustomDropdown className="custom_dropdown" isOpen={dropdownOpen} direction={'left'} toggle={toggle}>
        <DropdownToggle style={{ paddingRight: 0 }}>
          <div style={{ position: 'relative', top: 0 }}>
            {hasImage && <img src={userInfo.Users4AccessTokenPhotoSM} />}
            {!hasImage && <img />}
            <Style.CogWrapper>
              <FontAwesomeIcon className="icon-outline" icon={faCog} size="lg" />
            </Style.CogWrapper>
          </div>
        </DropdownToggle>
        <DropdownMenu>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <p style={{ fontWeight: 700, borderBottom: '1px solid #000000' }}>{userInfo.Users4AccessTokenDeptName}</p>
          </div>
          <DropdownItem
            style={{ cursor: 'pointer' }}
            className="blue-hover"
            onClick={() => {
              removeCorrectEvalTOken(isAdmin, user, logout);
              setUser(null);
              setLogout(true);
            }}
          >
            Logout
          </DropdownItem>
          {zoomBreakRoom && breakUrl.type !== 0 && (
            <a href={zoomBreakRoom.zoomUrl} target="_blank">
              <DropdownItem
                style={{ cursor: 'pointer' }}
                className="blue-hover"
                onClick={() => {
                  const password = getPasswordFromUrl(zoomBreakRoom.zoomUrl);
                  const meetingId = getMeetingIdFromUrl(zoomBreakRoom.zoomUrl);
                  setMeetingId(meetingId);
                  // const zoomForm = document.getElementById('zoom_form_header');
                  localStorage.setItem('zoomUrl', zoomBreakRoom.zoomUrl);
                  setShowZoomLink(true);
                  setZoomLink(zoomBreakRoom.zoomUrl);
                  setTimeout(() => {
                    setShowZoomLink(false);
                  }, 3 * 60 * 1000);
                  // zoomForm.action = zoomBreakRoom.zoomUrl;
                  // zoomForm.submit();
                }}
              >
                {breakUrl.type === 2 ? 'Join Meeting' : 'Break Room'}
              </DropdownItem>
            </a>
          )}
        </DropdownMenu>
      </CustomDropdown>
      {/* <form id="zoom_form_header" target="_blank"></form> */}
      {zoomBreakRoom &&
        zoomPopupJSX(
          showZoomLink,
          setShowZoomLink,
          zoomBreakRoom.zoomUrl,
          setCopied,
          copied,
          [],
          password,
          zoomBreakRoom.description,
          meetingId,
        )}
    </Style.Wrapper>
  );
};

export default UserInformation;
