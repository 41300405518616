import React, { Component } from 'react';

class EmbededPdf extends Component {
  shouldComponentUpdate(nextProps, nextState) {
    // Pdfs have weird behavior, need this to make sure it doesn't keep rerendering
    if (
      nextProps.thumbnail.size === this.props.thumbnail.size &&
      nextProps.thumbnail.name === this.props.thumbnail.name
    ) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <embed
        width="150"
        height="100"
        src={`${this.props.thumbnail.preview}#toolbar=0&navpanes=0&scrollbar=0`}
        type="application/pdf"
      />
    );
  }
}

export default EmbededPdf;
