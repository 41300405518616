import { connect } from 'react-redux';
import ForgotPassword from './ForgotPassword';
import { setEvaluatorToken } from './Login.action';

const mapStateToProps = (state) => ({
  email: state.login.email,
  expiredToken: state.login.expiredToken,
});

const mapDispatchToProps = (dispatch) => ({
  setEvaluatorToken: (token) => dispatch(setEvaluatorToken(token)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
