import { combineReducers } from 'redux';
import AnimationReducer from './Body/Animation/ListAnimation.reducer';
import InterviewDatesRouterReducer from './Body/InterviewDates/InterviewDatesRouter.reducer';
import LoginReducer from './Body/Login/Login.reducer';
import HeaderReducer from './Body/RezRATE/Header/Header.reducer';
import UserInformationReducer from './Body/RezRATE/Header/UserInformation/UserInformation.reducer';

export default combineReducers({
  userInfo: InterviewDatesRouterReducer,
  animation: AnimationReducer,
  login: LoginReducer,
  header: HeaderReducer,
  adminUser: UserInformationReducer,
});
