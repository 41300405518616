import { connect } from 'react-redux';
import { setDateList } from '../../Animation/ListAnimation.action';
import { setPartDocumentTitle } from '../../RezRATE/Header/Header.action';
import { setUser } from '../InterviewDatesRouter.action';
import { setBreakUrl } from './../../RezRATE/Header/UserInformation/UserInformation.action';
import InterviewSchedule from './InterviewSchedule';

const mapStateToProps = (state) => {
  return {
    user: state.userInfo.user,
    order: state.animation.order,
    listArray: state.animation.listArray,
    breakUrl: state.adminUser.breakUrl,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUser: (userObject) => dispatch(setUser(userObject)),
  setDateList: (listArray) => {
    return dispatch(setDateList(listArray));
  },
  setBreakUrl: (url) => dispatch(setBreakUrl(url)),
  setPartDocumentTitle: (date) => dispatch(setPartDocumentTitle(date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InterviewSchedule);
