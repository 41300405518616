import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import LikertQuestionNumber from './LikertQuestionNumber';
import sanitizeHtml from 'sanitize-html';
import HtmlToReactParser from 'html-to-react';

const Circle = styled.div`
  height: 40px;
  width: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props.borderColor};
  background-color: ${(props) => props.backgroundColor};
  border-radius: 50%;
  display: inline-block;
`;

const InnerCircle = styled.div`
  height: 30px;
  width: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => props.borderColor};
  border-radius: 50%;
  display: inline-block;
  font-weight: 700;
`;

const wrapperStyle = {
  width: '20%',
  width: '20%',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

const LikertQuestion = ({
  question,
  Answers4QuestionsAnswerNumberScore,
  updateEval,
  includeNote,
  i,
  numberOfSelections = 5,
  date,
}) => {
  const [selected, setSelected] = useState(null);
  const [likertLengthAlphabet, setLikertLengthAlphabet] = useState([]);
  const [gridTemplateColumns, setGridTemplateColumns] = useState('');
  const [questionText, setQuestionText] = useState('');

  const { QuestionsVLQuestionText } = question;

  useEffect(() => {
    const textSanitized = sanitizeHtml(QuestionsVLQuestionText);
    const newParser = new HtmlToReactParser.Parser();
    const reactElement = newParser.parse(textSanitized);
    setQuestionText(reactElement);
  }, [QuestionsVLQuestionText]);

  useEffect(() => {
    // set the grid width based on the number of choices
    if (numberOfSelections === 5) {
      setGridTemplateColumns('20% 20% 20% 20% 20%');
    }

    if (numberOfSelections === 4) {
      setGridTemplateColumns('25% 25% 25% 25%');
    }

    if (numberOfSelections === 3) {
      setGridTemplateColumns('33.33% 33.33% 33.33%');
    }
    const alphabetArray = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm'];

    //
    const restrictedArray = alphabetArray.splice(0, numberOfSelections);
    setLikertLengthAlphabet(restrictedArray);
  }, [numberOfSelections]);

  useEffect(() => {
    // Update the answer locally immediately while waiting for the database to update
    if (Answers4QuestionsAnswerNumberScore !== 0) setSelected(Answers4QuestionsAnswerNumberScore - 1);
  }, [Answers4QuestionsAnswerNumberScore]);

  const postAnswer = (value) => {
    updateEval(question.Answers4Questions__pkUUID_Answer, value);
  };

  const constructCircleClassName = (num, disableSelection) => {
    return 'no-select light-blue-border-hover ' + (selected === num ? 'selectedButton' : '');
  };
  return (
    <Row>
      <Col style={{ padding: 0 }} lg="12" md="12">
        <p style={{ fontWeight: 700 }}>
          <span style={{ fontWeight: 700 }}>{i + 1}. </span>
          {question.QuestionsVLRequiredOptional === 'Required' && <span style={{ color: '#ff0000' }}>*</span>}
          {questionText}
        </p>
        {includeNote && includeNote}
      </Col>
      <Col lg="12" md="12" style={{ display: 'flex', alignItems: 'center' }}>
        <div className="like-wrapper" style={{ gridTemplateColumns }}>
          {likertLengthAlphabet.map((item, index) => {
            return (
              <LikertQuestionNumber
                item={item}
                Circle={Circle}
                index={index}
                selected={selected}
                postAnswer={postAnswer}
                setSelected={setSelected}
                constructCircleClassName={constructCircleClassName}
              />
            );
          })}
          {likertLengthAlphabet.map((item, index) => {
            return <div className="box bold">{question[`QuestionsVLLabel${index + 1}`]}</div>;
          })}
          {likertLengthAlphabet.map((item, index) => {
            return <div className={`box ${item}`}>{question[`QuestionsVLDescription${index + 1}`]}</div>;
          })}
        </div>
      </Col>
    </Row>
  );
};

export default LikertQuestion;
