import arrayMove from 'array-move';
import React from 'react';
import { Col, Row } from 'reactstrap';
import SelectionFork from '../../Animation/HTMLComponents/SelectableElements/SelectionFork';
// import SweetAlert from 'sweetalert2-react';
import ListAnimationContainer from '../../Animation/ListAnimation.container';
import { CustomButton, EvenRow } from './InterviewDateSelection.styles';

const InterviewDateSelection = (props) => {
  const { listArray, setListArray, preferenceItems, hasPreferenceOrderWithNoInterviewDate } = props;

  // TODO: vet fucntionality once final decision is made in UI
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newArray = arrayMove(this.props.selectedDates, oldIndex, newIndex);
    this.props.setSelectedDates(newArray);
    // find the new position of the selected item (this is necessary if a non-selected item is being moved)
    for (let i in newArray) {
      if (newArray[i].id === this.props.selectedItem) {
        this.props.setSelectedItemIndex(Number(i));
        return;
      }
    }
  };

  // TODO: Vet these once final decision on UI is made
  const {
    changeStep,
    decrementStep,
    selectedableDates,
    setSelectedableDates,
    selectedDates,
    setShowAlert,
    dateOfInterview,
    moveSelectedItemUp,
    moveSelectedItemDown,
    selectedItemIndex,
    setSelectedItemIndex,
    toggleOrSetSelectedItem,
    selectedItem,
    showAlert,
    setSelectedDates,
    addToSelectedDates,
    handleClick,
    currentSlide,
    setCurrentSlide,
    retractFromSelectedDates,
    validateDateSelection,
  } = props;
  const combined = selectedDates.concat(selectedableDates);

  // TODO: Vet these once final decision on UI is made
  const checkIfCurrentSlide = (num) => {
    if (currentSlide !== num) {
      setCurrentSlide(num);
    }
  };
  return (
    <Col stlye={{ padding: '15px' }}>
      <Row>
        <ListAnimationContainer
          handleClick={handleClick}
          clickable={true}
          dateOfInterview={dateOfInterview}
          preferenceItems={preferenceItems}
          hasPreferenceOrderWithNoInterviewDate={hasPreferenceOrderWithNoInterviewDate}
          HTMLComponent={SelectionFork}
          isOrdinal={true}
        />
      </Row>
      <EvenRow>
        <CustomButton color="danger" onClick={(e) => changeStep(e, 0)}>
          Decline
        </CustomButton>
        <CustomButton color="success" onClick={(e) => validateDateSelection(e, 2)}>
          Next
        </CustomButton>
      </EvenRow>
    </Col>
  );
};

export default InterviewDateSelection;

// class InterviewDateSelection extends Component {
//   onSortEnd = ({ oldIndex, newIndex }) => {
//     const newArray = arrayMove(this.props.selectedDates, oldIndex, newIndex);
//     this.props.setSelectedDates(newArray);
//     // find the new position of the selected item (this is necessary if a non-selected item is being moved)
//     for (let i in newArray) {
//       if (newArray[i].id === this.props.selectedItem) {
//         this.props.setSelectedItemIndex(Number(i));
//         return;
//       }
//     }
//   };
//   render() {
//     const {
//       changeStep,
//       decrementStep,
//       selectedableDates,
//       setSelectedableDates,
//       selectedDates,
//       setShowAlert,
//       moveSelectedItemUp,
//       moveSelectedItemDown,
//       selectedItemIndex,
//       setSelectedItemIndex,
//       toggleOrSetSelectedItem,
//       selectedItem,
//       showAlert,
//       setSelectedDates,
//       addToSelectedDates,
//       retractFromSelectedDates,
//     } = this.props;
//     // const [selectableItemDivHeight, setSelectableItemDivHeight] = useState('auto');
//     // const disableUpArrow = selectedItem === null || selectedItemIndex === 0;
//     // const disableDownArrow = selectedItem === null || selectedItemIndex === selectedDates.length - 1;
//     return (
//       <Col>
//         <CustomRow id="selectable-items-container">
//           <SetHeightColumn sm={{ offset: 1, size: 5 }}>
//             <h6>Available interview dates (click to select dates):</h6>
//             <ListGroup>
//               <FlipMove>
//                 {selectedDates.map((item, i) => {
//                   return (
//                     <ListGroupItem
//                       className="transition"
//                       key={item.id}
//                       tag="a"
//                       href="#"
//                       action
//                       onClick={(e) => {
//                         addToSelectedDates(i);
//                         e.preventDefault();
//                       }}
//                     >
//                       <Moment format="dddd, MMMM DD, YYYY">{item.date}</Moment>
//                     </ListGroupItem>
//                   );
//                 })}
//                 {/* <SortableList
//               items={selectedDates}
//               useDragHandle={true}
//               onSortEnd={onSortEnd}
//               toggleOrSetSelectedItem={toggleOrSetSelectedItem}
//               selectedItem={selectedItem}
//             /> */}
//                 {selectedableDates.map((item, i) => {
//                   return (
//                     <ListGroupItem
//                       className="transition"
//                       key={item.id}
//                       tag="a"
//                       href="#"
//                       action
// onClick={(e) => {
//   addToSelectedDates(i);
//   e.preventDefault();
// }}
//                     >
//                       <Moment format="dddd, MMMM DD, YYYY">{item.date}</Moment>
//                     </ListGroupItem>
//                   );
//                 })}
//               </FlipMove>
//             </ListGroup>
//           </SetHeightColumn>
//           <Col sm={{ size: 5 }} style={{ padding: '15px' }}>
//             <Row>
//               <Col sm={6}>
//                 <h6>Selected Interview Dates:</h6>
//               </Col>
//               <Col sm={6}>
//                 <Label color="primary">{selectedDates.length} selected</Label>
//               </Col>
//             </Row>
//             <ListGroup>
//               {/* <SortableList
//               items={selectedDates}
//               useDragHandle={true}
//               onSortEnd={onSortEnd}
//               toggleOrSetSelectedItem={toggleOrSetSelectedItem}
//               selectedItem={selectedItem}
//             /> */}
//             </ListGroup>
//           </Col>
//           {/* <CustomCol sm={{ size: 1 }}>
//             <div>
//               <CustomFontAwesomeIcon
//                 className={disableUpArrow ? 'disabled' : ''}
//                 icon={faArrowAltCircleUp}
//                 onClick={(e) => {
//                   moveSelectedItemUp(disableUpArrow);
//                   e.preventDefault();
//                 }}
//                 color={'#337ab7'}
//                 size="3x"
//               />
//             </div>
//             <div>
//               <CustomFontAwesomeIcon
//                 className={!selectedItem ? 'disabled' : ''}
//                 icon={faTimesCircle}
//                 onClick={(e) => {
//                   retractFromSelectedDates(!selectedItem);
//                   e.preventDefault();
//                 }}
//                 color={'rgb(195, 80, 74)'}
//                 size="3x"
//               />
//             </div>
//             <div>
//               <CustomFontAwesomeIcon
//                 className={disableDownArrow ? 'disabled' : ''}
//                 icon={faArrowAltCircleDown}
//                 onClick={(e) => {
//                   moveSelectedItemDown(disableDownArrow);
//                   e.preventDefault();
//                 }}
//                 color={'#337ab7'}
//                 size="3x"
//               />
//             </div>
//           </CustomCol> */}
//         </CustomRow>
//         <EvenRow>
//           <CustomButton color="warning">decline</CustomButton>
//           <CustomButton color="success" onClick={changeStep}>
//             next
//           </CustomButton>
//           <SweetAlert
//             show={showAlert}
//             title="Oops..."
//             text="Please select at least one interview date"
//             onConfirm={() => setShowAlert(false)}
//           />
//         </EvenRow>
//       </Col>
//     );
//   }
// }

// export default InterviewDateSelection;

// import React, { SetStateAction, Dispatch, useState, useEffect } from 'react';
// import Moment from 'react-moment';
// import SweetAlert from 'sweetalert2-react';
// import { SortableItem, SortableList, ordinal_suffix_of } from '../../../SortableList';
// import { ListGroup, ListGroupItem, Label, Button } from 'reactstrap';
// import styled from 'styled-components';
// import { EvenRow, CustomFontAwesomeIcon, CustomButton } from './InterviewDateSelection.styles';
// import { Row, Col } from 'reactstrap';
// import { faTimesCircle, faArrowAltCircleDown, faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';
// import { temporaryCall } from '../../../JSONSstandardizing';
// import arrayMove from 'array-move';

// const CustomCol = styled(Col)`
//   margin: 30px 0 30px 0;

//   div {
//     margin: 5px;
//   }

//   @media (max-width: 600px) {
//     display: flex;
//     justify-content: center;
//   }
// `;

// const SetHeightColumn = styled(Col)`
//   padding: 15px;
// `;

// const CustomRow = styled(Row)`
//   min-height: ${(props) => props.minHeight}px;
// `;

// const InterviewDateSelection = ({
//   changeStep,
//   decrementStep,
//   selectedableDates,
//   setSelectedableDates,
//   selectedDates,
//   setShowAlert,
//   moveSelectedItemUp,
//   moveSelectedItemDown,
//   selectedItemIndex,
//   setSelectedItemIndex,
//   toggleOrSetSelectedItem,
//   selectedItem,
//   showAlert,
//   setSelectedDates,
//   addToSelectedDates,
//   retractFromSelectedDates,
// }) => {
//   temporaryCall();
//   useEffect(() => {
//     const dateHeight = document.getElementById('selectable-items-container').clientHeight;
//     setSelectableItemDivHeight(dateHeight);
//   }, []);
//   const handleClick = (e) => {
//     e.preventDefault();
//   };
//   const onSortEnd = ({ oldIndex, newIndex }) => {
//     const newArray = arrayMove(selectedDates, oldIndex, newIndex);
//     setSelectedDates(newArray);
//     // find the new position of the selected item (this is necessary if a non-selected item is being moved)
//     for (let i in newArray) {
//       if (newArray[i].id === selectedItem) {
//         setSelectedItemIndex(Number(i));
//         return;
//       }
//     }
//   };
//   const [selectableItemDivHeight, setSelectableItemDivHeight] = useState('auto');
//   const disableUpArrow = selectedItem === null || selectedItemIndex === 0;
//   const disableDownArrow = selectedItem === null || selectedItemIndex === selectedDates.length - 1;
//   return (
//     <Col>
//       <CustomRow minHeight={selectableItemDivHeight} id="selectable-items-container">
//         <SetHeightColumn sm={{ offset: 1, size: 5 }}>
//           <h6>Available interview dates (click to select dates):</h6>
//           <ListGroup>
//             {selectedableDates.map((item, i) => {
//               if (item.selected) {
//                 return (
//                   <ListGroupItem disabled key={item.id}>
//                     <Moment format="dddd, MMMM DD, YYYY">{item.date}</Moment>
//                   </ListGroupItem>
//                 );
//               }
//               return (
//                 <ListGroupItem
//                   key={item.id}
//                   tag="a"
//                   href="#"
//                   action
//                   onClick={(e) => {
//                     addToSelectedDates(i);
//                     e.preventDefault();
//                   }}
//                 >
//                   <Moment format="dddd, MMMM DD, YYYY">{item.date}</Moment>
//                 </ListGroupItem>
//               );
//             })}
//           </ListGroup>
//         </SetHeightColumn>
//         <Col sm={{ size: 5 }} style={{ padding: '15px' }}>
//           <Row>
//             <Col sm={6}>
//               <h6>Selected Interview Dates:</h6>
//             </Col>
//             <Col sm={6}>
//               <Label color="primary">{selectedDates.length} selected</Label>
//             </Col>
//           </Row>
//           <ListGroup>
//             <SortableList
//               items={selectedDates}
//               useDragHandle={true}
//               onSortEnd={onSortEnd}
//               toggleOrSetSelectedItem={toggleOrSetSelectedItem}
//               selectedItem={selectedItem}
//             />
//           </ListGroup>
//         </Col>
//         <CustomCol sm={{ size: 1 }}>
//           <div>
//             <CustomFontAwesomeIcon
//               className={disableUpArrow ? 'disabled' : ''}
//               icon={faArrowAltCircleUp}
//               onClick={(e) => {
//                 moveSelectedItemUp(disableUpArrow);
//                 e.preventDefault();
//               }}
//               color={'#337ab7'}
//               size="3x"
//             />
//           </div>
//           <div>
//             <CustomFontAwesomeIcon
//               className={!selectedItem ? 'disabled' : ''}
//               icon={faTimesCircle}
//               onClick={(e) => {
//                 retractFromSelectedDates(!selectedItem);
//                 e.preventDefault();
//               }}
//               color={'rgb(195, 80, 74)'}
//               size="3x"
//             />
//           </div>
//           <div>
//             <CustomFontAwesomeIcon
//               className={disableDownArrow ? 'disabled' : ''}
//               icon={faArrowAltCircleDown}
//               onClick={(e) => {
//                 moveSelectedItemDown(disableDownArrow);
//                 e.preventDefault();
//               }}
//               color={'#337ab7'}
//               size="3x"
//             />
//           </div>
//         </CustomCol>
//       </CustomRow>
//       <EvenRow>
//         <CustomButton color="warning">decline</CustomButton>
//         <CustomButton color="success" onClick={changeStep}>
//           next
//         </CustomButton>
//         <SweetAlert
//           show={showAlert}
//           title="Oops..."
//           text="Please select at least one interview date"
//           onConfirm={() => setShowAlert(false)}
//         />
//       </EvenRow>
//     </Col>
//   );
// };

// export default InterviewDateSelection;
