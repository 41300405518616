import React, { useEffect } from 'react';

const ZoomRoom = () => {
  useEffect(() => {
    const zoomRoom = localStorage.getItem('zoomUrl');
    window.open(zoomRoom, '_self');
  }, []);
  return <div></div>;
};

export default ZoomRoom;
