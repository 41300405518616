import styled from 'styled-components';

const Date = styled.p`
  color: #ffffff;
  padding-left: 20px;
  pointer-events: none;
  font-size: 0.7em;
  @media (max-width: 480px) {
    font-size: 0.65em;
  }
`;

const ClickTransfer = styled.div`
  position: absolute;
  top: 0px;
  width: calc(100% - 70px);
  height: 100%;
`;

const DraggableDiv = styled.div`
  position: absolute;
  width: 80px;
  font-size: 0.7em;
  right: 0;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: ${(props) => (props.active ? 'grabbing' : 'grab')};
  cursor: -moz-grab;
  cursor: -webkit-grab;
  z-index: 10;
  &:active {
    cursor: grabbing;
  }
  & img {
    position: relative;
    width: auto !important;
    vertical-align: middle !important;
    left: 28px;
    pointer-events: none;
  }
  @media (max-width: 480px) {
    font-size: 0.65em;
  }
`;

export { Date, DraggableDiv, ClickTransfer };
