import { connect } from 'react-redux';
import Login from './Login';
import { setEmail } from './Login.action';

const mapStateToProps = (state) => ({
  email: state.login.email,
});

const mapDispatchToProps = (dispatch) => ({
  setEmail: (emailObject) => dispatch(setEmail(emailObject)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Login);
