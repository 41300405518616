import React, { useState } from 'react';

const LikertQuestionNumber = ({ item, Circle, index, selected, postAnswer, setSelected, constructCircleClassName }) => {
  const [disableSelection, setDisableSelection] = useState(false);

  const toggleDisableButtons = () => {
    setDisableSelection(true);
    setTimeout(() => {
      setDisableSelection(false);
    }, 1000);
  };
  return (
    <div className={`box ${item}`} key={item}>
      <Circle
        style={{ cursor: 'pointer' }}
        onClick={() => {
          if (disableSelection) {
            return;
          }
          toggleDisableButtons();
          // if clicking on the same answer, deselect answer by passing in an empty string
          if (index === selected) {
            postAnswer('');
            // if resetting an answer prevent clicks for a few seconds
          } else {
            postAnswer(index + 1);
          }
          setSelected(index);
        }}
        className={constructCircleClassName(index, disableSelection)}
      >
        {index + 1}
      </Circle>
    </div>
  );
};

export default LikertQuestionNumber;
