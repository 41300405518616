import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { post } from 'axios';
import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Redirect } from 'react-router-dom';
import { Button } from 'reactstrap';
import Swal from 'sweetalert2';
import { constructFilemakerBaseUrl, getCorrectEvalToken } from '../../../../Common.functions';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

var resizeTimer;

const AgreementDocument = ({ user, isAdmin }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [screenWidth, setScreenWidth] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const [screenHeight, setScreenHeight] = useState(0);
  const [userReachedBottom, setUserReachedBottom] = useState(false);
  const [agreementSuccessful, setAgreementSuccessful] = useState(false);
  const [arrayLengthOfPages, setArrayLengthOfPages] = useState([]);
  const [isMobile, setIsMobile] = useState(window.mobilecheck());

  const getPDFHeight = () => {
    const headerHeight = isMobile ? 54 : 104;
    return window.innerHeight - headerHeight - 150;
  };
  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setUserReachedBottom(true);
    }
  };

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setScreenHeight(getPDFHeight);
      setScreenWidth(window.innerWidth);
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);

    // need this to map and render all pages
    const arrayOfPageNumbers = [];
    for (let i = 0; i < numPages; i++) {
      arrayOfPageNumbers.push(i + 1);
    }
    setArrayLengthOfPages(arrayOfPageNumbers);
  }

  const onDocumentError = (err) => {};

  const fireError = () => {
    Swal.fire('Error', 'Something went wrong... please try again later', 'error');
  };

  const submitAgreement = () => {
    setDisableButton(true);
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const evalToken = getCorrectEvalToken(isAdmin);
    const url = `${constructFilemakerBaseUrl()}/evaluator/agreement/?token=${evalToken}`;
    const formData = new FormData();
    formData.append('agree', true);
    post(url, formData, config)
      .then((res) => {
        if (res.data && res.data.signed) {
          Swal.fire(
            'Agreement accepted.',
            'Thank you, you will be redirected back to the evaluation portal.',
            'success',
          );
          setAgreementSuccessful(true);
        } else {
          fireError();
        }
      })
      .catch((err) => {
        fireError();
      })
      .finally(() => {
        setDisableButton(false);
      });
  };

  const onSourceError = (err) => {};
  if (!user || agreementSuccessful) {
    return <Redirect to="/evaluate/user" />;
  }

  return (
    <div>
      <div
        id="pdfWrapper"
        onScroll={handleScroll}
        style={{
          height: screenHeight,
          overflow: 'scroll',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'black',
        }}
      >
        <Document
          file={user.AgreementDocsDocument}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentError}
          onSourceError={onSourceError}
        >
          {arrayLengthOfPages.map((page) => {
            return <Page pageNumber={page} width={screenWidth * 0.9} />;
          })}
        </Document>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', paddingTop: '20px' }}>
        <h6 style={{ fontWeight: 700 }}>Please accept the agreement to continue</h6>
        <div>
          <Button
            color={'success'}
            style={{
              minWidth: '52px',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              e.preventDefault();

              submitAgreement();
            }}
          >
            {disableButton ? <FontAwesomeIcon icon={faSpinner} spin /> : 'I Agree'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AgreementDocument;
