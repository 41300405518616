// sets current logged in user so that any component can potentially access this data
export const setEmail = (payload) => {
  return {
    type: 'SET_EMAIL',
    payload,
  };
};

export const setExpiredToken = (payload) => {
  return {
    type: 'EXPIRED_TOKEN',
    payload,
  };
};

export const setEvaluatorToken = (payload) => {
  return {
    type: 'SET_EVALUATOR_TOKEN',
    payload,
  };
};

export const setIsAdmin = (payload) => {
  return {
    type: 'SET_IS_ADMIN',
    payload,
  };
};
