import styled from 'styled-components';

const Date = styled.p`
  color: black;
  padding-left: 20px;
  font-size: 0.7em;
  @media (max-width: 480px) {
    font-size: 0.65em;
  }
`;

export { Date };
