// sets current logged in user so that any component can potentially access this data
export const setShowName = (payload) => {
  return {
    type: 'SET_SHOW_NAME',
    payload,
  };
};

export const setDate = (payload) => {
  return {
    type: 'SET_DATE',
    payload,
  };
};

export const setDateArray = (payload) => {
  return {
    type: 'SET_DATE_ARRAY',
    payload,
  };
};

export const setPartDocumentTitle = (payload) => {
  return {
    type: 'SET_PART_DOCUMENT_TITLE',
    payload,
  };
};

export const setZoomBreakRoom = (payload) => {
  return {
    type: 'SET_ZOOM_BREAK_ROOM',
    payload,
  };
};

export const setSeasonType = (payload) => {
  return {
    type: 'SET_SEASON_TYPE',
    payload,
  };
};

export const setBreakRoomUrl = (payload) => {
  return {
    type: 'SET_BREAK_ROOM_URL',
    payload,
  };
};

export const setHeaderHeight = (payload) => {
  return {
    type: 'SET_HEADER_HEIGHT',
    payload,
  };
};
