import axios from 'axios';
import React, { useState } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import Swal from 'sweetalert2';
import { determineCredentials, isDev } from '../../../Common.functions';
import { CustomButton, EvenRow } from './InterviewDateSelection.styles';

const InterviewDateDecline = ({ changeStep, clientID, location, demo, globalSettings }) => {
  const [comment, setComment] = useState('');

  const simulateDecline = () => {
    Swal.fire(
      'The invitaiton has been declined (not really)',
      'If this were a real entry this would inform the corresponding department you did not want to interview.',
    );
  };

  const postDecline = (e) => {
    e.preventDefault();

    async function postData() {
      const bodyFormData = new FormData();
      if (comment !== '') {
        bodyFormData.append('comments', comment);
      }
      bodyFormData.append('decline', 1);

      let url = 'https://tools.rezrate.com/rezrate/api/candidateportal/';
      const params = { id: clientID, dev: isDev() };
      if (globalSettings.EnableRelay) {
        url = 'https://tools2.rezrate.com/api/v2/relay/';
        params.endpoint = 'rezrate/api/candidateportal';
      }

      const response = await axios({
        method: 'post',
        url,
        withCredentials: determineCredentials(),
        params,
        data: bodyFormData,
        headers: {
          'content-type': 'application/json',
        },
      }).then((res) => {
        window.location.reload();
      });
    }
    postData();
    //validate post happend
  };

  return (
    <div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '15px' }}>
        <Form style={{ width: '80%' }}>
          <FormGroup>
            <Input
              type="textarea"
              name="text"
              id="exampleText"
              placeholder="Enter optional comments here..."
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </FormGroup>
        </Form>
      </div>
      <EvenRow>
        {!demo && (
          <CustomButton color="danger" onClick={postDecline}>
            Decline Invitation
          </CustomButton>
        )}
        {demo && (
          <CustomButton color="danger" onClick={simulateDecline}>
            Decline Invitation
          </CustomButton>
        )}
        <CustomButton color="warning" onClick={(e) => changeStep(e, 1)}>
          Go Back
        </CustomButton>
      </EvenRow>
    </div>
  );
};

export default InterviewDateDecline;
