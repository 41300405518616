import { isDev } from '../../../../Common.functions';
import { determineMeetingSound } from '../../../../Sound/MeetingStatusSounds';

const createAlarms = (alarms, passingPeriod) => {
  const alarm = [];
  alarms.forEach((item, i) => {
    let minutesAdjusted;
    if (item.type === 'meetingEnd') {
      minutesAdjusted = item.minutes;
    } else {
      minutesAdjusted = item.minutes;
    }
    const name = `soundAlarm`;
    alarm.push({
      [name]: true,
      minutes: minutesAdjusted,
      sound: item.soundAsFile,
      message: item.message,
      type: item.type,
      alertCandidate: item.alertCandidate,
      alertEvaluator: item.alertEvaluator,
    });
  });
  return alarm;
};

const notify = (formattedMessage, setAlarms, item, i) => {
  var audio = new Audio(item.sound);
  audio.volume = 0.5;
  audio.play();
  //send notificaiotn here
  new Notification(formattedMessage(item.message, item.minutes));
  setAlarms((alarms) => {
    let copyArrayOfObjects = JSON.parse(JSON.stringify(alarms));
    copyArrayOfObjects[i].soundAlarm = false;
    return copyArrayOfObjects;
  });
  setTimeout(() => {
    setAlarms((alarms) => {
      let copyArrayOfObjects = JSON.parse(JSON.stringify(alarms));
      copyArrayOfObjects[i].soundAlarm = true;
      return copyArrayOfObjects;
    });
  }, 5 * 60 * 1000);
};

const checkAlarms = (
  resultInMinutes,
  eventAlarmsAsJson,
  formattedMessage,
  setAlarms,
  alarms,
  isPassingPeriod,
  hasPassingPeriod,
) => {
  const alarmsToUse = eventAlarmsAsJson || alarms;

  alarmsToUse.forEach((item, i) => {
    const isMeetingEnd = item.type === 'meetingEnd';
    const isBlockEnd = item.type === 'blockEnd';
    if (resultInMinutes === item.minutes && item.soundAlarm && !isPassingPeriod && isMeetingEnd) {
      notify(formattedMessage, setAlarms, item, i);
    } else if (resultInMinutes === item.minutes && item.soundAlarm && isPassingPeriod && isBlockEnd) {
      notify(formattedMessage, setAlarms, item, i);
    }
  });
};

const checkForSound = (
  TimeSlots4PortalTimeEnd,
  rightNow,
  alertAsJson,
  alarms,
  formattedMessage,
  setAlarms,
  interviewDate,
  isPassingPeriod,
  hasPassingPeriod,
  interviewIsToday,
) => {
  if (TimeSlots4PortalTimeEnd && interviewDate && interviewIsToday) {
    var difference = rightNow.getTime() - TimeSlots4PortalTimeEnd.getTime(); // This will give difference in milliseconds
    var resultInMinutes = Math.round(difference / 60000);
    if (isDev()) {
    }
    if (alarms) {
      checkAlarms(resultInMinutes, alertAsJson, formattedMessage, setAlarms, alarms, isPassingPeriod, hasPassingPeriod);
    }
  }
};

const handleSounds = (alertsJson) => {
  alertsJson.forEach((alert) => {
    alert['soundAsFile'] = determineMeetingSound(alert.sound);
  });
  return alertsJson;
};

export { createAlarms, checkForSound, handleSounds };
