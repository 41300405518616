import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex-shrink: 0;
`;

//Wrapper keeps footer at bottom of page
const Footer = () => {
  const noHeader =
    window.location.pathname.startsWith('/files') ||
    window.location.pathname.startsWith('/viewFile') ||
    window.location.pathname.startsWith('/logo') ||
    window.location.pathname.startsWith('/coordinator/schedule') ||
    window.location.pathname.startsWith('/avatar');

  if (noHeader) {
    document.body.style.backgroundColor = '#fff';
    return <div />;
  }

  return (
    <Wrapper
      style={{
        // position: 'absolute',
        right: 0,
        left: 0,
        width: '100vw',
        maxWidth: '100%',
        height: '50px',
        backgroundColor: '#fff',
        zIndex: 10,
        padding: '15px',
        textAlign: 'center',
      }}
    >
      <p style={{ margin: '0' }}>
        ©2018-2021 <a href="https://druksys.com/">Druksys, LLC</a> • <a href="https://www.rezrate.com/">RezRATE</a>®
      </p>
    </Wrapper>
  );
};

export default Footer;

// 80
