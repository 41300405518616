import React from 'react';
import InterviewDateConfirm from './InterviewDatesSteps/InterviewDateConfirm';

const InterviewConfirmRouter = ({
  changeStep,
  listArray,
  clientID,
  location,
  demo,
  order,
  globalSettings,
  showButtons,
  currentSlide,
  headerText,
  dateIsLocked,
  setCurrentSlide,
  setHasPreferenceOrderWithNoInterviewDate,
  setError,
  setDateOfInterview,
  decrementStep,
  hasPreferenceOrderWithNoInterviewDate,
  scheduledLabel,
  setDisplayOverlay,
  dateOfInterview,
  selectedableDates,
  setSelectedableDates,
  setDateList,
  selectedDates,
  setSelectedDates,
  addToSelectedDates,
}) => {
  return (
    <div>
      <InterviewDateConfirm
        changeStep={changeStep}
        listArray={listArray}
        clientID={clientID}
        dateOfInterview={dateOfInterview}
        scheduledLabel={scheduledLabel}
        headerText={headerText}
        globalSettings={globalSettings}
        dateIsLocked={dateIsLocked}
        setDateList={setDateList}
        location={location}
        hasPreferenceOrderWithNoInterviewDate={hasPreferenceOrderWithNoInterviewDate}
        setError={setError}
        demo={demo}
        setHasPreferenceOrderWithNoInterviewDate={setHasPreferenceOrderWithNoInterviewDate}
        setDateOfInterview={setDateOfInterview}
        order={order}
        showButtons
        currentSlide={currentSlide}
        setDisplayOverlay={setDisplayOverlay}
        setCurrentSlide={setCurrentSlide}
        decrementStep={decrementStep}
        selectedableDates={selectedableDates}
        setSelectedableDates={setSelectedableDates}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        addToSelectedDates={addToSelectedDates}
      />
    </div>
  );
};

export default InterviewConfirmRouter;
