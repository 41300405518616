import React, { useEffect, useRef } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import RezRateImg from '../../../assets/images/header/rezRate.gif';
import { isDev } from '../../../Common.functions';
import DateDisplay from './DateDisplay/DateDisplay';
import Style from './Header.style';
import UserInformationContainer from './UserInformation/UserInformation.container';

const UserWrapper = styled.div`
  display: flex
  background-color: #585858;
  color: white;
  border-radius: 500px;
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;

`;

const LogoWrapper = styled.div`
  padding: 14px 14px 14px 14px;
  margin-left: 14px;
  background-color: #ffffff;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #0077aa;
  -webkit-transition: background-color 200ms linear;
  -ms-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
  border-radius: 50px 50px 50px 50px;
  &:hover {
    background-color: rgb(205, 232, 253);
  }
`;

const ImgWrapper = styled.div`
  width: 66px;
  & > img {
    border-radius: 500px;
    -webkit-border-radius: 500px;
    -moz-border-radius: 500px;
    height: 66px;
    width: 66px !important;
  }
`;

const NameWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GearWrapper = styled.div`
  background-image: -webkit-linear-gradient(270deg, #34a0f4 0%, #1167a5 100%);
  border-top-color: #1167a5;
  border-right-color: #1167a5;
  border-bottom-color: #1167a5;
  border-left-color: #1167a5;
  padding: 15px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

const Header = (props) => {
  const location = useLocation();
  const wrapperEl = useRef(null);
  //TODO: Find a more elegant way to do this.  Need to listen to clientHeight of the wrapperEl div and set it in redux

  useEffect(() => {
    if (props.header.headerHeight !== wrapperEl.current.clientHeight) {
      props.setHeaderHeight(wrapperEl.current.clientHeight);
    }
  });

  const conditionalLogo = () => {
    const isEvaluator = window.location.pathname === '/evaluate/user';
    return (
      <Row style={{ display: 'flex' }} className={isEvaluator ? 'browser_display' : ''}>
        <Col>
          <div style={{ paddingLeft: '23px', display: 'flex' }}>
            {/* <a href="https://rezrate.com"> */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <LogoWrapper className="logowrapper">
                <img style={{ height: '50px', width: '50px' }} src={RezRateImg} />
              </LogoWrapper>
            </div>
            {/* </a> */}
            <HeaderWrapper
              style={{
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              {/* <a href="https://rezrate.com" style={{ textDecoration: 'none' }}> */}
              <h4 style={{ color: '#ffffff', cursor: 'default' }} id="logo_title">
                RezRATE <span style={{ color: 'red' }}>{isDev() === 1 ? 'DEV' : ''}</span>
              </h4>
              {/* </a> */}
              {props.header.showName && props.user && props.user.name && <h5>{props.user.name}</h5>}
              {/* <h6 style={{ color: 'white' }}>{user.school}</h6> */}
            </HeaderWrapper>
          </div>
        </Col>
      </Row>
    );
  };

  const dateArrayExists =
    !props || !props.header || !props.header.dateArray || !props.header.dateArray.InterviewDatesVL;
  //   return <div></div>;
  return (
    <div ref={wrapperEl} style={{ minHeight: '136px' }} id="header_wrapper">
      <Row
        style={{
          alignItems: 'center',
          height: '100%',
          maxWidth: '100%',
          margin: 0,
          minHeight: '136px',
          paddingLeft: '5px',
          paddingRight: '5px',
        }}
      >
        <Col sm={4} xs={4} style={{ height: '100%', display: 'flex' }}>
          {conditionalLogo()}
        </Col>
        <Style.ColumnWrapper sm={4} xs={4}></Style.ColumnWrapper>
        <Style.ColumnWrapper lg={4} md={12}>
          <div>
            <div>
              <Switch>
                <Route path="/evaluate/:id">
                  <UserInformationContainer setUser={props.setUser} zoomBreakRoom={props.header.zoomBreakRoom}/>
                </Route>
              </Switch>
            </div>
            <div>
              <Switch>
                {!dateArrayExists && (
                  <Route path="/evaluate/:id">
                    {props.header &&
                      props.header.dateArray &&
                      props.header.dateArray.SeasonSelectedMode &&
                      props.header.dateArray.SeasonSelectedMode === 'Evaluation' && (
                        <DateDisplay
                          seasonType={props.header.seasonType}
                          dateArray={props.header.dateArray.InterviewDatesVL}
                          setDate={props.setDate}
                        />
                      )}
                  </Route>
                )}
              </Switch>
            </div>
          </div>
        </Style.ColumnWrapper>
      </Row>
    </div>
  );
};

export default Header;
