import { connect } from 'react-redux';
import UserInformation from './UserInformation';

const mapStateToProps = (state) => {
  const { userInfo } = state.adminUser;
  const { isAdmin } = state.login;
  const { breakUrl } = state.adminUser;
  return { userInfo, isAdmin, breakUrl };
};

export default connect(mapStateToProps)(UserInformation);
