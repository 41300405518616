import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Table } from 'reactstrap';
import { determineProgress } from '../../../../Common.functions';
import { sortByLastName } from '../../../../SortableList';
import { candidates } from '../../mockData';
import { renderAvatar } from './Common';

const url =
  'https://tools.druksys.com/rezrate/api/evalportal/?token=kv78czgx78yb6gr0un1rg896y34645126262063170788677958307489916498670013146389725866310kthb05bde';

// Candidates4DateEvaluationStatus

const CandidatesAlphabetical = ({
  setSelectedUser,
  getCandidate,
  Schedules4Eval__pkUUID_Schedule,
  setCarouselIndex,
  candidateInfo,
  Schedules4EvalJoinManualURL,
  setDNRStatus,
  handleZoomClick,
  hideCandidateEvaluations,
  isMobile,
  hideShowMeetingButton,
  changeToCandidateTab,
  selectedCandidateIdLocal,
  disableJoinMeetingButton,
  setDisableJoinMeetingButton,
  interviewIsToday,
  requestZoomMeeting,
  userIdOnCurrentInterview,
  postRequest,
  selectedUser,
}) => {
  const [alphabetizedCandidates, setAlphabetizedCandidates] = useState(sortByLastName(candidates.slice()));
  const [selectedId, setSelectedId] = useState(null);
  if (!candidateInfo) return <div></div>;

  return (
    <div id="evaluation-table-wrapper" style={{ width: '100%' }}>
      <Table responsive={true} style={{ marginBottom: 0 }}>
        <tbody>
          {candidateInfo.map((item) => {
            let chosenCandidate = false;

            if (item && selectedUser) {
              chosenCandidate =
                item.Candidates4Date__pkUUID_Candidate === selectedUser.CandidateSelected__pkUUID_Candidate;
            }

            const showMeetingButton = item.Candidates4Date__pkUUID_Candidate === userIdOnCurrentInterview;

            return (
              <tr
                key={item.Candidates4Date__pkUUID_Candidate}
                className={'hover_blue ' + (chosenCandidate ? 'chosen-candidate' : '')}
                onClick={() => {
                  const { Candidates4DateDNRStatus } = item;
                  if (selectedUser === 'fetching') return;

                  if (isMobile && item.Candidates4Date__pkUUID_Candidate === selectedCandidateIdLocal) {
                    changeToCandidateTab();
                  }

                  if (setCarouselIndex) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                  }

                  if (Candidates4DateDNRStatus === 1) {
                    setDNRStatus(true);
                  } else {
                    setDNRStatus(false);
                  }

                  setSelectedId(item.Candidates4Date__pkUUID_Candidate);
                  getCandidate(item.Candidates4Date__pkUUID_Candidate, item);
                }}
                style={{ cursor: 'pointer' }}
              >
                <td style={{ width: '78px' }}>
                  {renderAvatar(
                    item.Candidates4DatePhotoSM,
                    item.Candidates4DatePhoto,
                    item.Candidates4DateExternalPhotoURL,
                  )}
                </td>
                <td style={{ height: '100%' }}>
                  <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                    <div>
                      <h4 style={{ margin: 0, fontWeight: 700 }}>
                        {item.Candidates4DateNameLast}, {item.Candidates4DateNameFirst}
                      </h4>
                      <p style={{ margin: 0, fontWeight: 700 }}>{item.Candidates4DateMedSchool}</p>
                      {determineProgress(
                        item.Candidates4DateEvaluationStatus,
                        item.Candidates4DateUnableToEvaluate,
                        hideCandidateEvaluations,
                      )}
                    </div>
                  </div>
                </td>
                <td>
                  {showMeetingButton && !hideShowMeetingButton && interviewIsToday && (
                    <Button
                      color="success"
                      disabled={disableJoinMeetingButton}
                      style={{ marginTop: '20px', minWidth: '95px' }}
                      onClick={(e) => {
                        e.preventDefault();
                        if (
                          typeof Schedules4EvalJoinManualURL !== 'undefined' &&
                          Schedules4EvalJoinManualURL !== '' &&
                          Schedules4EvalJoinManualURL !== null
                        ) {
                          handleZoomClick(Schedules4EvalJoinManualURL);
                        } else {
                          requestZoomMeeting(Schedules4Eval__pkUUID_Schedule);
                        }
                      }}
                    >
                      {disableJoinMeetingButton ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Join Meeting'}
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
          <tr></tr>
        </tbody>
      </Table>
    </div>
  );
};
export default CandidatesAlphabetical;
