import { post } from 'axios';
import { cloneDeep } from 'lodash';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { constructFilemakerBaseUrl } from '../../../../Common.functions';
import BinaryQuestion from './BinaryQuestion';
import axios from 'axios';
import LikertQuestion from './LikertQuestion';
import { postDataAgnosticWithoutCredentials } from '../../../../Services/dataApi';
import LikertQuestion1Through10 from './LikertQuestion1Through10';
import TextQuestion from './TextQuestion';

let timeoutTime = 10000;

var timeoutHandle;

var MOUSE_MOVE_THRESHOLD = 1000,
  lastMouseMoveTime = -100;

const determineUrl = (token) => {
  return `${constructFilemakerBaseUrl()}/evaluator/evaluation/?token=${token}`;
};

const TimeoutOverlay = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  button {
    position: fixed;
  }
`;

const Evaluation = ({
  date,
  candidateEvaluation,
  candidateInfo,
  timeoutDurationInMinutes,
  typeOfEvent,
  selectedCandidateId,
  setSelectedUser,
  activeTab,
  toggleToSchedule,
  currentCandidateSortType,
  setActiveTab,
  token,
  setEvalStatus,
  schedule,
  userInformation,
  selectedUser,
  evalStatus,
  setDisableScreen,
  DNRStatus,
  candidateSortTypes,
  setDNRStatus,
  evalTab,
  setCandidateEvaluation,
  Status4SelectedDNR,
  getInfo,
  getCandidateEvaluation,
  currentlyGettingEval,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const [AnswersAnswerNumberScore, setAnswersAnswerNumberScore] = useState(null);
  const [questionsNeedingAnswers, setQuestionsNeedingAnswers] = useState({});
  const [count, setCount] = useState(0);
  const [isTyping, setIsTyping] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const [submitQuestions, setSubmitQuestions] = useState(false);
  const [promisesToResolve, setPromisesToResolve] = useState({});
  const [promisesResolving, setPromisesResolving] = useState(false);
  const activeTabRef = useRef(activeTab);

  // statechange (overriding) orange
  // modification blue
  // creation green
  // destruction red

  // continue evaluation - blue
  // begin - blue
  // modify evlaution - orange
  // zoom - green
  // submit - green
  // unable to evaluate - orange

  // fluid container - darker color

  // no bottom border
  // no schedule or candidates returned - "No Items available for this date"
  // spelled out in date picker

  // change style of document viewer - change icon

  const updateUser = () => {
    setSelectedUser((prevState) => {
      // eslint-disable-next-line no-undef
      const clone = structuredClone(prevState);
      clone.CandidateSelectedEvaluationStatus = 'Complete';
      return clone;
    });
  };

  const submitCompleteToAzure = () => {
    const { AccessTokens_fkUUID_User, gUUID_ExtUserToken } = userInformation;
    return postDataAgnosticWithoutCredentials(
      'department/candidate/evaluation/submit',
      {
        mode: date === 'Prescreen' ? 'Prescreen' : 'Evaluation',
        CandidateUUID: selectedCandidateId,
        EvaluatorUUID: AccessTokens_fkUUID_User,
      },
      null,
    )
      .then((result) => {
        // const url = 'https://tools2.rezrate.com/api/v2/relay/';
        const url = 'https://tools.rezrate.com/api/v2/evaluator/evalsubmit/';
        const formData = new FormData();
        // formData.append('AnswerJson', JSON.stringify(result.data));
        // formData.append('CandidateUUID', selectedCandidateId);
        // formData.append('EvaluationMode', date === 'Prescreen' ? 'Prescreen' : 'Evaluation');
        const payload = {
          AnswerJson: result.data,
          CandidateUUID: selectedCandidateId,
          EvaluationMode: date === 'Prescreen' ? 'Prescreen' : 'Evaluation',
        };
        formData.append('data', JSON.stringify(payload));
        // formData.append('data', {
        //   AnswerJson: result.data,
        //   CandidateUUID: selectedCandidateId,
        //   EvaluationMode: date === 'Prescreen' ? 'Prescreen' : 'Evaluation',
        // });
        const data = {
          AnswerJson: result.data,
          CandidateUUID: selectedCandidateId,
          EvaluationMode: date === 'Prescreen' ? 'Prescreen' : 'Evaluation',
        };
        axios({
          method: 'post',
          url,
          data: formData,
          params: { token: gUUID_ExtUserToken },
        })
          .then((result) => {
            updateUser();
            Swal.fire('Submitted!', `${selectedUser.CandidateSelectedNameFirstLast}'s evaluation has been submitted`);
            toggleToSchedule();
            setDisableScreen(false);
            setSubmitQuestions(false);
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  };

  useEffect(() => {
    setDNRStatus(Status4SelectedDNR);
  }, [Status4SelectedDNR]);

  useEffect(() => {
    let readyToSubmit = true;
    for (const property in promisesToResolve) {
      if (promisesToResolve[property] == true) readyToSubmit = false;
    }
    if (readyToSubmit == false && promisesResolving === false) {
      setPromisesResolving(true);
    }

    if (readyToSubmit == true && promisesResolving === true) {
      setPromisesResolving(false);
    }
  }, [promisesToResolve]);

  const submitForm = () => {
    const allRequiredQuestionsAnswered = checkForRequiredAnswers(candidateEvaluation.EvalPortal);
    if (!allRequiredQuestionsAnswered) {
      Swal.fire(
        'Error',
        `<div>Please answer all required questions (<span class="color_red">*</span>).</div>`,
        'error',
      );
      setDisableScreen(false);
    } else {
      if (true) {
        submitCompleteToAzure();
        return;
      }
      changeEvalStatus('Complete')
        .then((res) => {
          const { Status4SelectedStatus } = res;

          if (Status4SelectedStatus === 'Complete') {
            Swal.fire('Submitted!', `${selectedUser.CandidateSelectedNameFirstLast}'s evaluation has been submitted`);
            updateUser();
            toggleToSchedule();
          }
        })
        .catch((err) => {})
        .finally(() => {
          setDisableScreen(false);
          setSubmitQuestions(false);
        });
    }
  };

  const toggle = () => setShowOverlay((prevState) => !prevState);

  const updateEval = (answerid, answervalue) => {
    setPromisesToResolve((prevState) => {
      const promisesToResolveCopy = cloneDeep(prevState);
      promisesToResolveCopy[answerid] = true;
      return promisesToResolveCopy;
    });

    const { AccessTokens_fkUUID_User } = userInformation;
    return postDataAgnosticWithoutCredentials('department/candidate/evaluation', {
      answerid,
      answervalue,
      evaluator: AccessTokens_fkUUID_User,
      mode: date === 'Prescreen' ? 'Prescreen' : 'Evaluation',
      selectedCandidateId: selectedCandidateId,
      evaluatorUUID: AccessTokens_fkUUID_User,
    })
      .then((result) => {
        setCandidateEvaluation((prevState) => {
          let copy = JSON.parse(JSON.stringify(prevState));

          // need to stitch the answer back into the json
          copy.EvalPortal.forEach((item) => {
            if (result && result.data && result.data.answerid === item.Answers4Questions__pkUUID_Answer) {
              if (result.data.type === 'number') {
                item.Answers4QuestionsAnswerNumberScore = result.data.answervalue;
              } else {
                item.Answers4QuestionsAnswerText = result.data.answervalue;
              }
            }
          });
          return copy;
        });
        setIsTyping(false);
        setPromisesToResolve((prevState) => {
          const promisesToResolveCopy = cloneDeep(prevState);
          promisesToResolveCopy[answerid] = false;
          return promisesToResolveCopy;
        });
      })
      .catch((err) => {
        Swal.fire('Error', 'Something went wrong... please contact support', 'error');
      });

    setPromisesToResolve((prevState) => {
      const promisesToResolveCopy = cloneDeep(prevState);
      promisesToResolveCopy[answerid] = true;
      return promisesToResolveCopy;
    });
    // return;
    const formData = new FormData();
    formData.append('evaluation', 1);
    formData.append('candidateid', selectedCandidateId);
    // If user is evaluating candidates through the prescreen make sure
    // to mark the mode as prescreen when they change answers
    formData.append('mode', date === 'Prescreen' ? 'Prescreen' : 'Evaluation');
    formData.append('answervalue', answervalue);
    formData.append('answerid', answerid);

    const url = determineUrl(token);

    post(url, formData)
      .then((res) => {
        if (res.data) setCandidateEvaluation(res.data);
      })
      .catch((err) => {})
      .finally(() => {
        setIsTyping(false);
        setPromisesToResolve((prevState) => {
          const promisesToResolveCopy = cloneDeep(prevState);
          promisesToResolveCopy[answerid] = false;
          return promisesToResolveCopy;
        });
      });
  };

  const changeEvalStatus = async (status) => {
    if (!selectedCandidateId) return;

    if (status === 'UnableToEvaluate') {
      const { AccessTokens_fkUUID_User } = userInformation;
      await postDataAgnosticWithoutCredentials(
        'department/candidate/answers/deleteAll',
        {
          CandidateUUID: selectedCandidateId,
          EvaluatorUUID: AccessTokens_fkUUID_User,
          mode: date === 'Prescreen' ? 'Prescreen' : 'Evaluation',
        },
        null,
      )
        .then((result) => {})
        .catch((err) => {});
    }

    if (status !== 'DoNotRank' && status !== 'Rank' && status !== 'UnableToEvaluate') return {};

    const formData = new FormData();
    formData.append('evaluation', 1);
    formData.append('candidateid', selectedCandidateId);
    // If user is evaluating candidates through the prescreen make sure
    // to mark the mode as prescreen when they change answers
    formData.append('mode', date === 'Prescreen' ? 'Prescreen' : 'Evaluation');
    formData.append('status', status);

    const url = determineUrl(token);

    return post(url, formData)
      .then((res) => {
        setDisableButton(false);
        setEvalStatus(res.data);
        if (res.data.Status4SelectedDNR === 'Do Not Rank') {
          setDNRStatus('Do Not Rank');
        } else if (res.data.Status4SelectedDNR === '') {
          setDNRStatus('');
        }
        return res.data;
      })
      .catch((err) => {});
  };

  const getCurrentEvalStatus = (e) => {
    e.preventDefault();

    setShowOverlay(false);
    getCandidateEvaluation(selectedCandidateId);
    timeoutHandle = window.setTimeout(function() {
      setShowOverlay(true);
    }, timeoutDurationInMinutes * 60000);
  };

  const handleMouseMove = useCallback((event) => {
    if (activeTabRef.current === evalTab) {
      var now = +new Date();
      if (now - lastMouseMoveTime < MOUSE_MOVE_THRESHOLD) return;
      lastMouseMoveTime = now;

      window.clearTimeout(timeoutHandle);
      timeoutHandle = window.setTimeout(function() {
        setShowOverlay(true);
      }, timeoutDurationInMinutes * 60000);
    }
  }, []);

  const checkForRequiredAnswers = (EvalPortal) => {
    setQuestionsNeedingAnswers({});
    let allRequiredAnswered = true;
    const questionsNeedToAnswer = {};
    EvalPortal.forEach((item, i) => {
      const {
        Answers4Questions__pkUUID_Answer,
        QuestionsVLRequiredOptional,
        Answers4QuestionsAnswerNumberScore,
        Answers4QuestionsAnswerText,
        Answers4QuestionsPrescreenAnswerNumber,
        Answers4QuestionsPrescreenAnswerText,
      } = item;
      // check to see if question has any answer in them for all types
      const hasValue =
        Answers4QuestionsAnswerNumberScore !== '' ||
        Answers4QuestionsAnswerText !== '' ||
        Answers4QuestionsPrescreenAnswerNumber !== '' ||
        Answers4QuestionsPrescreenAnswerText !== '';
      if (!hasValue && QuestionsVLRequiredOptional === 'Required') {
        questionsNeedToAnswer[Answers4Questions__pkUUID_Answer] = true;
        allRequiredAnswered = false;
      }
    });
    setQuestionsNeedingAnswers(questionsNeedToAnswer);
    return allRequiredAnswered;
  };

  const checkForAnyAnswer = (EvalPortal) => {
    let hasAnswers = false;
    EvalPortal.forEach((item) => {
      const {
        Answers4QuestionsAnswerNumberScore,
        Answers4QuestionsAnswerText,
        Answers4QuestionsPrescreenAnswerNumber,
        Answers4QuestionsPrescreenAnswerText,
      } = item;
      // check to see if question has any answer in them for all types
      const hasValue =
        Answers4QuestionsAnswerNumberScore !== '' ||
        Answers4QuestionsAnswerText !== '' ||
        Answers4QuestionsPrescreenAnswerNumber !== '' ||
        Answers4QuestionsPrescreenAnswerText !== '';
      if (hasValue) {
        hasAnswers = true;
      }
    });
    return hasAnswers;
  };

  const handleTouchStart = useCallback((event) => {
    if (activeTabRef.current === evalTab) {
      window.clearTimeout(timeoutHandle);
      timeoutHandle = window.setTimeout(function() {
        setShowOverlay(true);
      }, timeoutDurationInMinutes * 60000);
    }
  }, []);

  useEffect(() => {
    activeTabRef.current = activeTab;
    // if user is on eval tab start timeout
    if (activeTab === evalTab) {
      window.clearTimeout(timeoutHandle);
      timeoutHandle = window.setTimeout(function() {
        setShowOverlay(true);
      }, timeoutTime);
      return;
    }
    window.clearTimeout(timeoutHandle);
  }, [activeTab]);

  const updateAnswersInDatabase = (index) => {
    //candidateEvaluation
  };

  useEffect(() => {
    if (typeOfEvent === 'mousemove') {
      window.addEventListener(typeOfEvent, handleMouseMove);

      return () => {
        window.removeEventListener(typeOfEvent, handleMouseMove);
      };
    }
    if (typeOfEvent === 'touchstart') {
      window.addEventListener(typeOfEvent, handleTouchStart);

      return () => {
        window.removeEventListener(typeOfEvent, handleTouchStart);
      };
    }
  }, [handleMouseMove, handleTouchStart]);

  const handleEvalChange = () => {
    changeEvalStatus('UnableToEvaluate').then((res) => {
      const { Status4SelectedStatus } = res;
      if (Status4SelectedStatus === 'Complete') {
        Swal.fire('Submitted!', `${selectedUser.CandidateSelectedNameFirstLast}'s evaluation has been submitted`);
        updateUser();
        setDisableScreen(false);
        toggleToSchedule();
      }
    });
  };

  const includeWarningAboutPrescreen = (question) => {
    const { Answers4QuestionsPrescreenAnswerNumber, Answers4QuestionsPrescreenAnswerText } = question;
    if (date !== 'Prescreen') {
      if (Answers4QuestionsPrescreenAnswerNumber !== '') {
        return <p>{`Prescreen response: ${Answers4QuestionsPrescreenAnswerNumber}`}</p>;
      }
      if (Answers4QuestionsPrescreenAnswerText !== '') {
        return <p>{`Prescreen response: ${Answers4QuestionsPrescreenAnswerText}`}</p>;
      }
    }
    return false;
  };

  const determineQuestion = (question, i) => {
    const {
      Answers4Questions__pkUUID_Answer,
      Answers4QuestionsAnswerNumberScore,
      Answers4QuestionsAnswerText,
    } = question;

    const flagAsUnanswered = questionsNeedingAnswers[Answers4Questions__pkUUID_Answer];
    const alternatingColor = i % 2 ? '#f2f2f2' : '#fff';
    const backgroundColor = flagAsUnanswered ? '#ffe5e5' : alternatingColor;

    const includeNote = includeWarningAboutPrescreen(question);

    const key = `${Answers4Questions__pkUUID_Answer}`;

    if (question.QuestionsVLAnswerType === 'Pass/Fail (1-3)') {
      return (
        <div key={key} style={{ backgroundColor: backgroundColor, padding: '20px' }}>
          <LikertQuestion
            numberOfSelections={3}
            includeNote={includeNote}
            question={question}
            i={i}
            CandidateSelected__pkUUID_Candidate={selectedUser.CandidateSelected__pkUUID_Candidate}
            updateEval={updateEval}
            updateAnswersInDatabase={updateAnswersInDatabase}
            Answers4QuestionsAnswerNumberScore={question.Answers4QuestionsAnswerNumberScore}
          />
        </div>
      );
    }

    if (question.QuestionsVLAnswerType === 'ABNS (1-4)') {
      return (
        <div key={key} style={{ backgroundColor: backgroundColor, padding: '20px' }}>
          <LikertQuestion
            numberOfSelections={4}
            question={question}
            date={date}
            includeNote={includeNote}
            i={i}
            CandidateSelected__pkUUID_Candidate={selectedUser.CandidateSelected__pkUUID_Candidate}
            updateEval={updateEval}
            updateAnswersInDatabase={updateAnswersInDatabase}
            Answers4QuestionsAnswerNumberScore={question.Answers4QuestionsAnswerNumberScore}
          />
        </div>
      );
    }

    if (question.QuestionsVLAnswerType === '1-10') {
      return (
        <div key={key} style={{ backgroundColor: backgroundColor, padding: '20px' }}>
          <LikertQuestion1Through10
            question={question}
            date={date}
            includeNote={includeNote}
            i={i}
            CandidateSelected__pkUUID_Candidate={selectedUser.CandidateSelected__pkUUID_Candidate}
            updateEval={updateEval}
            updateAnswersInDatabase={updateAnswersInDatabase}
            Answers4QuestionsAnswerNumberScore={question.Answers4QuestionsAnswerNumberScore}
          />
        </div>
      );
    }

    if (question.QuestionsVLAnswerType === '1-5') {
      return (
        <div key={key} style={{ backgroundColor: backgroundColor, padding: '20px' }}>
          <LikertQuestion
            question={question}
            includeNote={includeNote}
            date={date}
            i={i}
            CandidateSelected__pkUUID_Candidate={selectedUser.CandidateSelected__pkUUID_Candidate}
            updateEval={updateEval}
            updateAnswersInDatabase={updateAnswersInDatabase}
            Answers4QuestionsAnswerNumberScore={question.Answers4QuestionsAnswerNumberScore}
          />
        </div>
      );
    }

    if (question.QuestionsVLAnswerType === 'Yes/No') {
      return (
        <div key={key} style={{ backgroundColor: backgroundColor, padding: '20px' }}>
          <BinaryQuestion
            includeNote={includeNote}
            question={question}
            CandidateSelected__pkUUID_Candidate={selectedUser.CandidateSelected__pkUUID_Candidate}
            i={i}
            updateAnswersInDatabase={updateAnswersInDatabase}
            updateEval={updateEval}
          />
        </div>
      );
    }

    if (question.QuestionsVLAnswerType === 'Text') {
      return (
        <div key={key} style={{ backgroundColor: backgroundColor, padding: '20px' }}>
          <TextQuestion
            includeNote={includeNote}
            question={question}
            date={date}
            updateAnswersInDatabase={updateAnswersInDatabase}
            setIsTyping={setIsTyping}
            CandidateSelected__pkUUID_Candidate={selectedUser.CandidateSelected__pkUUID_Candidate}
            i={i}
            updateEval={updateEval}
            answerText={question.Answers4QuestionsAnswerText}
          />
        </div>
      );
    }

    return <div></div>;
  };

  if (!candidateEvaluation) return <div />;

  if (candidateEvaluation === 'error' || !candidateEvaluation.EvalPortal) return <div />;

  return (
    <Fragment>
      {/* {showOverlay && (
        <TimeoutOverlay className="overlay">
          <div
            style={{ position: 'relative', width: '100%', height: '100%', justifyContent: 'center', display: 'flex' }}
          >
            <div style={{ position: 'relative', left: '-48px', top: '200px' }}>
              <Button disabled={currentlyGettingEval} onClick={getCurrentEvalStatus} style={{ minWidth: '98px' }}>
                {currentlyGettingEval ? <FontAwesomeIcon spin icon={faSpinner} /> : 'Still there?'}
              </Button>
            </div>
          </div>
        </TimeoutOverlay>

      )} */}
      <div style={{ padding: '10px', width: '100%' }}>
        {candidateEvaluation.EvalPortal.map((question, i) => {
          return determineQuestion(question, i);
        })}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <label className="switch">
            <input
              type="checkbox"
              disabled={disableButton}
              checked={DNRStatus === 'Do Not Rank'}
              onClick={() => {
                setDisableButton(true);
                if (DNRStatus === 'Do Not Rank') {
                  setDNRStatus('');
                  changeEvalStatus('Rank');
                } else if (DNRStatus === '') {
                  setDNRStatus('Do Not Rank');
                  changeEvalStatus('DoNotRank');
                }
              }}
            />
            <span className="slider round"></span>
          </label>
          Do Not Rank
        </div>
        <div>
          <Button
            onClick={(e) => {
              const hasAnswers = checkForAnyAnswer(candidateEvaluation.EvalPortal);
              e.preventDefault();
              if (hasAnswers) {
                return Swal.fire({
                  title: 'Warning',
                  text: `This will delete any feedback you have already entered for ${selectedUser.CandidateSelectedNameFirstLast}.`,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Discard Evaluation',
                }).then((result) => {
                  if (result.value === true) {
                    setDisableScreen(true);
                    handleEvalChange();
                  }
                });
              } else {
                setDisableScreen(true);
                handleEvalChange();
              }
            }}
          >
            Unable To Evaluate
          </Button>
        </div>
        <div>
          <p style={{ margin: 0, padding: 0 }}>
            Questions marked with "<span style={{ color: '#ff0000' }}>*</span>" are required
          </p>
        </div>
        <div>
          <Button
            disabled={promisesResolving || isTyping}
            onClick={(e) => {
              e.preventDefault();
              setDisableScreen(true);
              submitForm();
            }}
          >
            {promisesResolving || isTyping ? 'Saving...' : 'Submit'}
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default Evaluation;
