import styled from 'styled-components';
import { ListGroupItem, Table } from 'reactstrap';
import { cssSizes } from '../../Common.style';
import { Row } from 'reactstrap';

const CustomListGroupItem = styled(ListGroupItem)`
  border: none !important;
`;

const CenterRow = styled(Row)`
  justify-content: center;
`;

const CustomTable = styled(Table)`
  * {
    text-align: center;
  }
`;

const TDPadding = styled.td`
  width: 30%;
  ${cssSizes.phoneLandscape`
    width: 0;
  `}
  ${cssSizes.tabletLandscape`
    width: 10vw;
  `}
`;

export { CustomListGroupItem, CenterRow, CustomTable, TDPadding };
