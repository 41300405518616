import arrayMove from 'array-move';
import React, { useEffect, useState } from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import { Button } from 'reactstrap';
import { faCloudUploadAlt, faSpinner, faPulse } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { getNeededDataFromUser, isDev, isValidUser } from '../../Common.functions';
import {
  fetchDataWithoutCredentials,
  fetchDataAgnosticWithoutCredentials,
  postDataAgnosticWithoutCredentials,
} from '../../Services/dataApi';
import { insertDate } from '../../SortableList';
import setTitle from '../../TitleHandler';
import moment from 'moment';
import Error from '../Statuses/Error';
import Info from '../Statuses/Info';
import Warning from '../Statuses/Warning';
import Loading from '../Statuses/Loading';
import { demoInterviewDates4Portal, demoUserObject } from './demo';
import InterviewConfirmRouter from './InterviewConfirmRouter';
import InterviewDateDecline from './InterviewDatesSteps/InterviewDateDecline';
import InterviewDateSelection from './InterviewDatesSteps/InterviewDateSelection';
import { CustomCard, CustomCardHeader, CustomCarousel, CustomCol } from './InterviewDatesWrapper.styles';

const headers = [
  'Please confirm your choice to decline',
  'Select one or multiple available interview dates:',
  'Please confirm your selection:',
  `Congratulations, you've been scheduled for the following date`,
  'You have been waitlisted for the dates below',
  'You have been waitlisted for the date below',
  '-',
];
const InterviewDatesRouter = (props) => {
  const { listArray, order, user } = props;
  const { setUser, setDateList, setOrder, location, history } = props;
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [incorrectUUID, setIncorrectUUID] = useState(false);
  const [dateIsLocked, setDateIsLocked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [ignoreDecline, setIgnoreDecline] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [getError, setGetError] = useState(false);
  const [hasPreferenceOrderWithNoInterviewDate, setHasPreferenceOrderWithNoInterviewDate] = useState(false);
  const clientID = props.match.params.id;
  const [needUserInfo, setNeedUserInfo] = useState(true);
  const [preferenceItems, setPreferenceItems] = useState([]);
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [demo, setDemo] = useState(clientID.toUpperCase() === 'DEMO' ? true : false);
  const [data, setData] = useState(null);
  const [step, setStep] = useState(1);
  const [globalSettings, setGlobalSettings] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [dateOfInterview, setDateOfInterview] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [globalSettingsLoading, setGlobalSettingsLoading] = useState(true);
  const [scheduledLabel, setScheduledLabel] = useState(null);
  const [selectedableDates, setSelectedableDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [checkIntervalID, setCheckIntervalID] = useState(null);
  const [attendancedLogged, setAttendancedLogged] = useState(false);
  const [queueInterviewID, setQueueInterviewID] = useState(null);
  const [checkQueue, setCheckQueue] = useState(true);
  const [fetchSchedule, setFetchSchedule] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [headerText, setHeaderText] = useState(['Select one or multiple available interview dates:']);
  const [status, setStatus] = useState(null);

  const logAttendance = () => {
    if (demo) {
      setAttendancedLogged(true);
      return;
    }

    const url = 'candidate/attendance';
    postDataAgnosticWithoutCredentials(url, { id: clientID })
      .then((result) => {})
      .catch((err) => {})
      .finally(() => {
        setAttendancedLogged(true);
      });
  };

  const getGlobalSettings = () => {
    const url = 'groupInfo/settings/global';
    fetchDataAgnosticWithoutCredentials(url, null)
      .then((result) => {
        setGlobalSettings(result.data);
      })
      .catch((err) => {})
      .finally(() => {
        setGlobalSettingsLoading(false);
      });
  };

  useEffect(() => {
    getGlobalSettings();
    logAttendance();
  }, []);

  const queue = () => {
    const url = 'candidate/queue';
    fetchDataAgnosticWithoutCredentials(url, { id: clientID })
      .then((result) => {
        let placeInLineAsString = null;
        let timeAsMoment = null;
        if (result.data && result.data.greenLit && result.data.greenLit === true) {
          setCheckQueue(false);
          setFetchSchedule(true);
          setStatus(false);
          return;
        }
        if (result.data && result.data.placeInLine) {
          placeInLineAsString = result.data.placeInLine.toString();
        }
        if (result.data && result.data.time) {
          timeAsMoment = moment(new Date(result.data.time));
        }
        setStatus({ ...result.data, placeInLineAsString, timeAsMoment });
      })
      .catch((err) => {})
      .finally(() => {});
  };

  const check = (status, checkQueue) => {
    const rightNow = moment(new Date());
    if (status && status.greenLit && status.greenLit === true) {
      setCheckQueue(false);
      setFetchSchedule(true);
      setStatus(false);
      return;
    }
    if (status && status.timeAsMoment.isBefore(rightNow)) {
      setCheckQueue(false);
      setFetchSchedule(true);
      setStatus(false);
      return;
    }
  };
  useEffect(() => {
    if (!checkQueue) {
      clearInterval(checkIntervalID);
      return;
    }

    check(status, checkQueue);
    const interval = setInterval(() => {
      check(status, checkQueue);
    }, 1000);
    setCheckIntervalID(interval);
    return () => clearInterval(interval);
  }, [status, checkQueue]);

  useEffect(() => {
    if (!attendancedLogged) {
      return;
    }
    if (!checkQueue) {
      clearInterval(queueInterviewID);
      return;
    }
    queue();
    const interval = setInterval(() => {
      queue();
    }, 14 * 1000);
    setQueueInterviewID(interval);
    return () => clearInterval(interval);
  }, [checkQueue, attendancedLogged]);

  useEffect(() => {
    if (!isLoading || globalSettingsLoading) {
      return;
    }

    if (fetchSchedule === false) {
      return;
    }

    setStatus(null);
    if (!demo) {
      let url = 'https://tools.rezrate.com/rezrate/api/candidateportal/';
      const params = {
        id: clientID,
        dev: isDev(),
      };

      if (globalSettings.EnableRelay) {
        url = 'https://tools2.rezrate.com/api/v2/relay/';
        params.endpoint = 'rezrate/api/candidateportal';
      }

      fetchDataWithoutCredentials(url, params)
        .then(({ data }) => {
          if (data.error && data.error === 429) {
            setShowWarning(true);
            setWarningMessage({
              header: data.message,
              subHeader: '',
            });
          }
          const userObject = getNeededDataFromUser(data);
          if (
            !hasPreferenceOrderWithNoInterviewDate &&
            data.Candidates4PortalDatePreferenceArray !== '[]' &&
            data.Candidates4PortalDateOfInterview === ''
          ) {
            setHasPreferenceOrderWithNoInterviewDate(true);
            if (currentSlide !== 2) {
              setCurrentSlide(2);
            }
          }

          if (
            data &&
            data.Candidates4PortalDatePreferenceArray &&
            data.Candidates4PortalDatePreferenceArray !== '[]' &&
            data.Candidates4PortalDateOfInterview === ''
          ) {
            const selectedArray = data.Candidates4PortalDatePreferenceArray.replace(/\[/g, '')
              .replace(/\n/gi, ',')
              .replace(/]/g, '')
              .split(',');
            setPreferenceItems(selectedArray);

            if (selectedArray.length > 1) {
              setHeaderText(headers[4]);
            } else {
              setHeaderText(headers[5]);
            }
          }

          if (data.Candidates4PortalDateOfInterview && data.Candidates4PortalDateOfInterview !== '') {
            if (data.Candidates4PortalDateOfInterviewLabel && data.Candidates4PortalDateOfInterviewLabel !== '') {
              setScheduledLabel(data.Candidates4PortalDateOfInterviewLabel);
            }
            if (data.Candidates4PortalDateOfInterviewLocked === 1) {
              setDateIsLocked(true);
            }
            setHeaderText(headers[3]);
            setDateOfInterview(data.Candidates4PortalDateOfInterview);
            // if the date has been selected show the user that information
            if (isLoading) {
              setCurrentSlide(2);
              // setHeaderText(headers[6]);
            }
          }

          if (data.Candidates4PortalUnsubscribed === 1) {
            history.push(`/unsubscribe/${clientID}`);
            return;
          }

          if (
            ((data && data.InterviewDates4Portal === null) || data.InterviewDates4Portal === undefined) &&
            data.Departments4PortalName
          ) {
            // setError(true);
            setShowInfo(true);
            setInfoMessage({
              header: `The ${data.Departments4PortalName} does not currently have any dates.`,
              subHeader: 'Please contact the coordinator for additional details.',
            });
          }

          // only get future dates
          const futureDates = data.InterviewDates4Portal;

          if (!isValidUser(userObject)) {
            setIncorrectUUID(true);
          }
          if (
            data &&
            data.InterviewDates4Portal &&
            (data.InterviewDates4Portal.length === 0 || futureDates.length === 0)
          ) {
            setShowInfo(true);
            setInfoMessage({
              header: `${data.Departments4PortalName || 'The department'} does not currently have any dates.`,
              subHeader: 'Please contact the coordinator for additional details.',
            });
          }
          setUser(userObject);
          if (isLoading) {
            setOrder(futureDates);
            setDateList(futureDates);
            setTitle({
              pageTitle: 'Registration',
              departmentName: data.Departments4PortalName,
              organizationName: data.Departments4PortalOrganization,
            });
          }
        })
        .catch((error) => {
          setError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      Swal.fire('This is a demo!', 'Changes will not be recorded. <br> Feel free to click around.');
      setUser(demoUserObject);
      setOrder(demoInterviewDates4Portal);
      setDateList(demoInterviewDates4Portal);
      setIsLoading(false);
      // setTitle({
      //   pageTitle: 'Registration',
      //   departmentName: data.Departments4PortalName,
      //   organizationName: data.Departments4PortalOrganization,
      // });
    }
  }, [clientID, isLoading, globalSettingsLoading, fetchSchedule]);

  // Does not let the user move to the next screen until they've picked at least one date
  const validateDateSelection = (e, num) => {
    e.preventDefault();
    if (listArray.filter((item) => item.selected).length === 0) {
      Swal.fire('Oops...', 'Please select at least one interview date.', 'error');
    } else {
      changeStep(e, num);
    }
  };

  // changes step.  Currently this moves it back and forth based on the num that's passed in
  const changeStep = (e, num) => {
    // if (e) e.preventDefault();

    const { listArray } = props;

    setHeaderText(headers[step]);
    setCurrentSlide(num);
    setHeaderText(headers[num]);
    // this scrollTo helps with very long lists
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // toggles item selection if selected item is already clicked
  // otherwise, set selected item as item clicked
  const toggleOrSetSelectedItem = (id, index) => {
    if (id === selectedItem) {
      setSelectedItem(null);
      setSelectedItemIndex(null);
    } else {
      setSelectedItem(id);
      setSelectedItemIndex(index);
    }
  };

  // not needed as increment step handles both
  //TODO deprecate this and rename increment step
  const decrementStep = () => {
    setHeaderText(headers[step - 2]);
    setStep(step - 1);
  };

  // uses the index of selectableDates
  const addToSelectedDates = (index) => {
    const newItem = selectedableDates[index];
    newItem.selected = true;
    setSelectedDates([...selectedDates, newItem]);
    setSelectedableDates(selectedableDates.filter((date, i) => i !== index));
  };

  // TODO: Deprecate if browser uses drag and drop functionality
  const resetSelection = () => {
    setSelectedItem(null);
    setSelectedItemIndex(null);
  };

  // TODO: Deprecate if browser uses drag and drop functionality
  const moveSelectedItemUp = (buttonIsDisabled) => {
    if (!buttonIsDisabled) {
      const newArray = arrayMove(selectedDates, selectedItemIndex, selectedItemIndex - 1);
      setSelectedDates(newArray);
      setSelectedItemIndex(selectedItemIndex - 1);
    }
  };

  // TODO: Deprecate if browser uses drag and drop functionality
  const moveSelectedItemDown = (buttonIsDisabled) => {
    if (!buttonIsDisabled) {
      const newArray = arrayMove(selectedDates, selectedItemIndex, selectedItemIndex + 1);
      setSelectedDates(newArray);
      setSelectedItemIndex(selectedItemIndex + 1);
    }
  };

  // TODO: Deprecate if browser uses drag and drop functionality
  const retractFromSelectedDates = (buttonIsDisabled) => {
    if (!buttonIsDisabled) {
      setSelectedableDates(insertDate(selectedableDates, selectedDates[selectedItemIndex]));
      setSelectedDates(selectedDates.filter((date, i) => i !== selectedItemIndex));
      resetSelection();
    }
  };

  const determineFontSize = (numberLength) => {
    if (numberLength === 1) {
      return '74px';
    } else if (numberLength === 2) {
      return '58px';
    } else if (numberLength === 3) {
      return '46px';
    } else {
      return '34px';
    }
  };

  const determineTop = (numberLength) => {
    if (numberLength === 1) {
      return '34px';
    } else if (numberLength === 2) {
      return '45px';
    } else if (numberLength === 3) {
      return '52px';
    } else {
      return '59px';
    }
  };

  const ignoreDeclineFlag = (e) => {
    e.preventDefault();
    setCurrentSlide(1);
    setHasPreferenceOrderWithNoInterviewDate(false);
    setDateOfInterview(false);
    setIgnoreDecline(true);
  };

  const loadContent = () => {
    const { listArray, order, user } = props;

    if (status && status.placeInLine) {
      return (
        <div className="box queue-wrapper">
          <CustomCol sm={{ size: 4, offset: 4 }} style={{ backgroundColor: '#c36a56' }}>
            <div style={{ margin: '20px' }}>
              <div>
                <h5>Hello,</h5>
                <br />
                <h6>Thank you for your patience. Many individuals are choosing dates at this time.</h6>
                <h6>You have the following place in line...</h6>
              </div>
              <div>
                <div className="midblock queue-dual-ring">
                  <h1
                    className="bold"
                    style={{
                      height: '0px',
                      position: 'relative',
                      fontSize: determineFontSize(status.placeInLineAsString.length),
                      top: determineTop(status.placeInLineAsString.length),
                      color: '#804e83',
                    }}
                  >
                    {status.placeInLine}
                  </h1>
                </div>
              </div>
              <div>
                <h6>
                  WARNING: Refreshing the page or opening in a new tab could negatively impact your place in line.
                </h6>
                <h6>When it's your turn, we'll automatically direct you to the date selection page.</h6>
              </div>
            </div>
          </CustomCol>
        </div>
      );
    }

    if (showWarning) {
      return <Warning header={warningMessage.header} subHeader={warningMessage.subHeader} />;
    }

    if (showInfo) {
      return <Info header={infoMessage.header} subHeader={infoMessage.subHeader} />;
    }

    if (error) {
      const header = errorMessage.header ? errorMessage.header : 'service is temporarily unavailable';
      const subHeader = errorMessage.subHeader ? errorMessage.subHeader : 'please try again later';
      return <Error key={1} header={header} subHeader={subHeader} />;
    }
    if (incorrectUUID) {
      return (
        <Error
          key={1}
          header="The ID provided is either invalid or expired"
          subHeader="Please verify the address and try again"
        />
      );
    }
    if (!user || !listArray || listArray.length === 0) {
      return <Loading key={1} header="Loading invitation..." />;
    }
    if (user.Candidates4PortalCandDeclined === 1 && !ignoreDecline) {
      return (
        <CustomCol key={2} sm={12}>
          <CustomCard>
            <div
              className="alert alert-error"
              style={{
                textAlign: 'center',
                flexDirection: 'column',
                height: '40vh',
                margin: '0',
                display: 'flex',
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}
            >
              <h3 style={{ paddingBottom: '20px' }}>You have declined your invitation</h3>
              <Button onClick={(e) => ignoreDeclineFlag(e)} color="primary">
                I changed my mind...
              </Button>
            </div>
          </CustomCard>
        </CustomCol>
      );
      // // will display schedule date (not needed for now)
      // } else if (user.Candidates4Portal_fkUUID_InterviewDate && user.Candidates4Portal_fkUUID_InterviewDate !== '') {
      //   const dateScheduled = getSelectedDate(listArray, user.Candidates4Portal_fkUUID_InterviewDate);
      //   if (dateScheduled === undefined) {
      //     setError(true);
      //     setErrorMessage({ header: 'No Interview date was found.', subHeader: ' ' });
      //     return;
      //   }
      //   const message =
      //     `Congratulations, you're scheduled to interview on ` + Moment(dateScheduled).format('dddd, MMMM DD, YYYY');
      //   return <Success header={message} subHeader=" " topAndBottomPadding="60px" />;
      // } else if (needUserInfo) {
      //   return <GetUserInfo />;
    } else if (
      user &&
      user.Candidates4PortalDatePreferenceArray &&
      user.Candidates4PortalDatePreferenceArray !== '[]'
    ) {
      // const selectedArray = user.Candidates4PortalDatePreferenceArray.replace(/\[/g, '')
      //   .replace(/\n/gi, ',')
      //   .replace(/]/g, '')
      //   .split(',');

      // selectedArray.forEach((selectedUUID, i) => {
      //   listArray.forEach((choice) => {
      //     if (selectedUUID === choice.InterviewDates4Portal__pkUUID_InterviewDate) {
      //       choice['selected'] = true;
      //       choice['preference'] = i;
      //     }
      //   });
      // });
      // return (
      //   <CustomCol key={2} sm={12}>
      //     <CustomCard>
      //       <CustomCardHeader>
      //         <h4>Thank you for your submission.</h4>
      //         <h6>We will email your results once all submissions have been processed.</h6>
      //       </CustomCardHeader>
      //       <InterviewDateConfirm
      //         listArray={listArray}
      //         showButton={false}
      //         hasPreferenceOrderWithNoInterviewDate={hasPreferenceOrderWithNoInterviewDate}
      //         setDateOfInterview={setDateOfInterview}
      //         changeStep={changeStep}
      //       />
      //     </CustomCard>
      //   </CustomCol>
      // );
      return (
        <CustomCol key={2} sm={12}>
          <CustomCard>
            <CustomCardHeader>
              <h4>{headerText}</h4>
            </CustomCardHeader>
            {renderSwitch()}
          </CustomCard>
        </CustomCol>
      );
    } else if (listArray.length !== 0) {
      return (
        <CustomCol key={2} sm={12}>
          <CustomCard>
            <CustomCardHeader>
              <h4>{headerText}</h4>
            </CustomCardHeader>
            {renderSwitch()}
          </CustomCard>
        </CustomCol>
      );
    }
  };

  const renderSwitch = () => {
    return (
      <CustomCarousel
        className="carousel"
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        swipeable={false}
        selectedItem={currentSlide}
      >
        <div id="slide1">
          <InterviewDateDecline
            changeStep={changeStep}
            globalSettings={globalSettings}
            clientID={clientID}
            location={location}
            demo={demo}
          />
        </div>
        <div id="slide2">
          {/* vet these once final decisions are made on UI */}
          <InterviewDateSelection
            changeStep={changeStep}
            decrementStep={decrementStep}
            selectedableDates={selectedableDates}
            toggleOrSetSelectedItem={toggleOrSetSelectedItem}
            selectedItem={selectedItem}
            globalSettings={globalSettings}
            setSelectedItemIndex={setSelectedItemIndex}
            hasPreferenceOrderWithNoInterviewDate={hasPreferenceOrderWithNoInterviewDate}
            selectedItemIndex={selectedItemIndex}
            setSelectedableDates={setSelectedableDates}
            showAlert={showAlert}
            preferenceItems={preferenceItems}
            currentSlide={currentSlide}
            setShowAlert={setShowAlert}
            selectedDates={selectedDates}
            dateOfInterview={dateOfInterview}
            setCurrentSlide={setCurrentSlide}
            validateDateSelection={validateDateSelection}
            moveSelectedItemUp={moveSelectedItemUp}
            moveSelectedItemDown={moveSelectedItemDown}
            setSelectedDates={setSelectedDates}
            addToSelectedDates={addToSelectedDates}
            retractFromSelectedDates={retractFromSelectedDates}
          />
        </div>

        <div id="slide3" style={{ height: '100%', position: 'relative' }}>
          {/* {displayOverlay ||
            (true && <div style={{ height: '100%', backgroundColor: '#000', position: 'absolute' }}></div>)} */}
          <InterviewConfirmRouter
            changeStep={changeStep}
            listArray={listArray}
            clientID={clientID}
            location={location}
            headerText={headerText}
            globalSettings={globalSettings}
            displayOverlay={displayOverlay}
            dateIsLocked={dateIsLocked}
            demo={demo}
            setDateList={setDateList}
            scheduledLabel={scheduledLabel}
            setDisplayOverlay={setDisplayOverlay}
            order={order}
            // hasPreferenceOrderWithNoInterviewDate={hasPreferenceOrderWithNoInterviewDate}
            setDateOfInterview={setDateOfInterview}
            setError={setError}
            dateOfInterview={dateOfInterview}
            hasPreferenceOrderWithNoInterviewDate={hasPreferenceOrderWithNoInterviewDate}
            setHasPreferenceOrderWithNoInterviewDate={setHasPreferenceOrderWithNoInterviewDate}
            showButtons
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
            decrementStep={decrementStep}
            selectedableDates={selectedableDates}
            setSelectedableDates={setSelectedableDates}
            hasPreferenceOrderWithNoInterviewDate={hasPreferenceOrderWithNoInterviewDate}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            addToSelectedDates={addToSelectedDates}
          />
        </div>
      </CustomCarousel>
    );
  };

  if (isLoading && !status) {
    return <Loading key={1} header="Loading invitation..." />;
  }

  return (
    <ReactCSSTransitionReplace transitionName="cross-fade" transitionEnterTimeout={1000} transitionLeaveTimeout={1000}>
      <div key="7">{loadContent()}</div>
    </ReactCSSTransitionReplace>
  );
};

export default InterviewDatesRouter;
