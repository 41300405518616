import ShipBell from '../assets/sounds/shipbell.wav';

const determineMeetingSound = () => {
  return ShipBell;
};

export { determineMeetingSound };

// // It was decided that we would always use a shipbell.
// // Leaving this code just in case 09/01/2020. If it's been more
// // Than a few months feel free to delete this and always return
// // the shipbell sound
// switch (sound) {
//   case 'Meeting_Ends_in_2_Minutes':
//     return Meeting_Ends_in_2_Minutes;
//   case 'Meeting_is_Ending':
//     return Meeting_is_Ending;
//   case 'One_Minute_Remaining':
//     return One_Minute_Remaining;
//   case 'Optimus_Prime_Meeting_Ends_in_2_Minutes':
//     return Optimus_Prime_Meeting_Ends_in_2_Minutes;
//   case 'Please_Wrap_Up':
//     return Please_Wrap_Up;
//   case 'The_Meeting_Ends_in_One_Minute':
//     return The_Meeting_Ends_in_One_Minute;
//   case 'The_Meeting_Ends_Soon':
//     return The_Meeting_Ends_Soon;
//   case 'The_Meeting_is_Ending':
//     return The_Meeting_is_Ending;
//   case 'Times_Up':
//     return Times_Up;
//   case 'Two_Minutes_Remaining':
//     return Two_Minutes_Remaining;
//   case 'Your_Meeting_is_About_to_Start':
//     return Your_Meeting_is_About_to_Start;
//   case 'bellsound':
//     return Your_Meeting_is_About_to_Start;
//   case 'Fancy_Sound.mp3':
//     return Times_Up;
//   default:
//     return null;
// }
