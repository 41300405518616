import React, { useEffect } from 'react';
import BlueDot from '../../../assets/images/blueDot.png';
import Smile from '../../../assets/images/smile.png';

const wrapper = {
  // width: '200px',
  // height: '50px',
  // padding: '5px',
  // border: '1px solid black',
  // display: 'flex',
  // justifyContent: 'center',
  // alignItems: 'center',
};

// const determineColor = (color) => {
//   if (!color) {
//     return '#000000';
//   }
//   const shade = lightOrDark(color);

//   if (shade === 'dark') {
//     return '#FFFFFF';
//   }

//   return '#000000';
// };

const determineBackgroundColor = (
  TimeslotsCustomDuration,
  isHeader,
  SchedulesCandidateBG,
  message,
  TimeslotsActive,
) => {
  if (TimeslotsActive) {
    return '#007c00';
  }
  if (message.toUpperCase() === 'UNAVAILABLE') {
    return '#FF0000';
  }

  if (TimeslotsCustomDuration) {
    return '#ffc04d';
  }

  if (isHeader) {
    return '#333333';
  }

  return SchedulesCandidateBG;
};

const CoordinatorScheduleRowItem = ({
  scheduleElement,
  isHeader,
  timeslotIsActivem,
  isTime,
  TimeslotsCustomDuration,
  TimeslotsActive,
  setSelectedBlock,
  setShowSelectedBlock,
}) => {
  const { hasCandidate } = scheduleElement;

  const isTimeSlot = !scheduleElement.Schedules__pkUUID_Schedule && !isHeader;
  const isHeaderOrTime = scheduleElement.SchedulesDisplay === 'Time' || isHeader;

  const determineClassName = () => {
    const stickyClass = isHeaderOrTime ? 'sticky' : '';
    const hoverClass = hasCandidate ? 'hover_blue' : '';
    const activeslot = TimeslotsActive ? 'activeSlot' : '';
    return `${hoverClass} ${stickyClass} ${activeslot}`;
  };

  return (
    <td
      onClick={() => {
        if (hasCandidate) {
          setSelectedBlock(scheduleElement);
          setShowSelectedBlock(true);
        }
      }}
      className={determineClassName()}
      style={{
        paddingLeft: isTimeSlot ? '3px' : 'auto',
        paddingRight: isTimeSlot ? '3px' : 'auto',
        width: isTimeSlot ? '66px' : 'auto',
        paddingTop: '5px',
        paddingBottom: '5px',
        verticalAlign: 'middle',
        top: '-2px',
        whiteSpace: isTimeSlot ? 'nowrap' : '',
        color: isHeaderOrTime || (TimeslotsActive && isTime) ? '#FFFFFF' : scheduleElement.SchedulesCandidateColor,
        backgroundColor: determineBackgroundColor(
          TimeslotsCustomDuration,
          isHeaderOrTime,
          scheduleElement.SchedulesCandidateBG,
          scheduleElement.SchedulesDisplay,
          TimeslotsActive && isTime,
        ),
        fontWeight: isHeaderOrTime ? 700 : 500,
        textAlign: 'center',
        border: '1px solid #000000',
      }}
    >
      {scheduleElement.SchedulesCandidateIsAttending === 1 && (
        <span className="fontawesome_shadow" style={{ padding: '3px' }}>
          <img style={{ width: '20px', height: 'auto' }} src={Smile} />
        </span>
      )}
      {scheduleElement.SchedulesEvaluatorIsAttending === 1 && (
        <span className="fontawesome_shadow" style={{ padding: '3px' }}>
          <img style={{ width: '20px', height: 'auto' }} src={BlueDot} />
        </span>
      )}
      {scheduleElement.SchedulesDisplay}
    </td>
  );
};

export default CoordinatorScheduleRowItem;
