import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { Col } from 'reactstrap';
import Swal from 'sweetalert2';
import { determineCredentials, isDev } from '../../Common.functions';
import { CustomButton, EvenRow } from '../InterviewDates/InterviewDatesSteps/InterviewDateSelection.styles';
import { CustomCard, CustomCardHeader, CustomCol } from '../InterviewDates/InterviewDatesWrapper.styles';
import { HeaderWrapper } from './Resubscribe.styles';

const Unsubscribe = ({
  setSubscribed,
  organization,
  department,
  UNSUBSCRIBE_ALL,
  selectedOption,
  clientID,
  history,
}) => {
  const [disableButton, setDisableButton] = useState(false);
  const sendRequest = useCallback(async () => {
    setDisableButton(true);
    const bodyFormData = new FormData();
    bodyFormData.append('resubscribe', '1');
    const url = 'https://tools.rezrate.com/rezrate/api/candidateportal/';
    const postData = await axios({
      method: 'post',
      url,
      withCredentials: determineCredentials(),
      params: { id: clientID, dev: isDev() },
      data: bodyFormData,
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => {
        setDisableButton(false);
        if (res.data === 'resubscribed') {
          history.push(`/candidate/${clientID}`);
        } else {
          Swal.fire('Something went wrong...', 'Your submission was not recieved.<br>please try again later.', 'error');
        }
      })
      .catch(() => {
        setDisableButton(false);
        Swal.fire('Something went wrong...', 'Your submission was not recieved.<br>please try again later.', 'error');
      });
  });

  return (
    <CustomCol className="column" key={2} sm={12}>
      <CustomCard>
        <CustomCardHeader>
          <h4>
            You are currently unsubscribed from {organization} and {department}
          </h4>
        </CustomCardHeader>
        <div>
          <HeaderWrapper style={{ display: 'flex', justifyContent: 'center' }}>
            <h4>
              You are unable to receive invitations and communications from
              {selectedOption === UNSUBSCRIBE_ALL ? ' any residency programs utilizing RezRATE.' : ` ${department}`}
            </h4>
          </HeaderWrapper>
          <Col>
            <EvenRow>
              <CustomButton
                color="success"
                disabled={disableButton}
                onClick={(e) => {
                  e.preventDefault();
                  sendRequest();
                }}
              >
                {disableButton ? <FontAwesomeIcon spin icon={faSpinner} /> : 'Subscribe'}
              </CustomButton>
            </EvenRow>
          </Col>
        </div>
      </CustomCard>
    </CustomCol>
  );
};

export default Unsubscribe;
