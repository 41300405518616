import { faEnvelope, faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { default as axios, default as Axios } from 'axios';
import React, { useEffect, useRef, useState, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import LoadingDot from '../../LoadingDot/LoadingDot';
import { fetchDataAgnosticWithoutCredentials } from '../../Services/dataApi';
import { Link } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { useAuth0 } from '../../auth0/reactAuth0Spa';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import { VelocityComponent } from 'velocity-react';
import { constructFilemakerBaseUrl, determineCredentials, emailIsValid } from '../../Common.functions';

// Revoke - revokes all access tokens for the selected user.
// Revoke:ACCESSTOKENID - revokes a specific access token.
// RevokeAndForceNew - Revokes all access tokens and issues a new access token.
// ForceNew - Grants a new access token leaving any existing tokens unmodified.
// Refresh - Refreshes existing access token or issues a new one.

const overlayStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  backgroundColor: '#000',
  left: '0px',
  top: '0px',
  opacity: '.3',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

// filter out any permissions where the user is not an evaluator
const filterOutDepartmentPermissionsWhereIsEvaluatorIsFalse = (data) => {
  return data.filter((item) => {
    if (item.IsEvaluator) return true;

    return false;
  });
};

const filterOutDepartmentsWithoutPermissions = (data) => {
  return data.filter((item) => {
    if (!item.Permissions) return false;

    if (item.Permissions && item.Permissions.length === 0) return false;

    return true;
  });
};

const filterOutDepartmentPermissionsDuplicates = (data) => {
  const newArray = [];
  data.forEach((item) => {
    const filtered = item.Permissions.filter((permission, index) => {
      if (permission.selectionAction === 0) {
        return false;
      }

      return (
        index ===
        item.Permissions.findIndex((obj) => {
          return obj.selectionDisplay === permission.selectionDisplay;
        })
      );
    });
    item.Permissions = filtered;
  });

  return data;
};

const CancelToken = axios.CancelToken;

let cancel;

const isSpecificDate = (dateParameter, dateToCompare) => {
  const today = new Date(dateToCompare);
  return (
    dateParameter.getDate() === today.getDate() &&
    dateParameter.getMonth() === today.getMonth() &&
    dateParameter.getFullYear() === today.getFullYear()
  );
};

require('velocity-animate');
require('velocity-animate/velocity.ui');

const ForgotPasswordWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  padding: 30px;
  height: 100%;
`;

const WhiteWrapper = styled.div`
  border-radius: 25px;
`;

const Login = (props) => {
  const formData = useRef(null);
  const loginInput = useRef(null);
  const passwordInput = useRef(null);
  const incorrectPasswordDiv = useRef(null);
  const [showDecisions, setShowDecisions] = useState(false);
  const [showError, setShowError] = useState(false);
  const [departmentChosenPhase, setDepartmentChosenPhase] = useState(1);
  const [emailCheckedInAuth, setEmailCheckedInAuth] = useState(null);
  const [checkingEmailOrigin, setCheckingEmailOrigin] = useState(false);
  const [emailNeedsAuth0Check, setEmailNeedsAuth0Check] = useState(true);
  const [chosenDepartment, setChosenDepartment] = useState(null);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [candidateWarningMessage, setCandidateWarningMessage] = useState(false);
  const [allowCancelRequest, setAllowCancelRequest] = useState(true);
  const [animateError, setAnimateError] = useState(false);
  const [departmentChoices, setDepartmentChoices] = useState([]);
  const [userForgotPassword, setUserForgetPassword] = useState(false);
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);
  const [redirectToEvaluate, setRedirectToEvaluate] = useState(false);
  const [password, setPassword] = useState('');
  // const [axiosRequest, setAxiosRequest]
  const [username, setUsername] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  const { loading, user, isAuthenticated, loginWithRedirect, getTokenSilently, logout, setLoginHint } = useAuth0();

  const { ssoLogin, match } = props;

  // useEffect(() => {
  //   setTimeout(() => {
  //     props.setEmail('jeff.hernandez@druksys.com');
  //   }, 1000);
  // }, []);

  useEffect(() => {
    if (loading === false && ssoLogin) {
      loginWithRedirect();
    }
  }, [loading]);

  useEffect(() => {
    if (document.getElementById('emailLogin')) {
      document.getElementById('emailLogin').focus();
    }
  }, [document.getElementById('emailLogin')]);

  useEffect(() => {
    if (typeof isAuthenticated !== 'undefined') {
      if (isAuthenticated) {
        getTokenSilently()
          .then((token) => {
            const postUrl = 'https://tools.rezrate.com/api/v2/user/auth0/options/';

            const formData = new FormData();
            formData.append('jwt', token);
            Axios.post(postUrl, formData)
              .then((res) => {
                const { data } = res;
                localStorage.setItem('evalToken', res.data.token);
                const filteredData = filterOutDepartmentPermissionsWhereIsEvaluatorIsFalse(data);
                const filterOutDuplicates = filterOutDepartmentPermissionsDuplicates(filteredData);

                // filterOutDuplicates.forEach((item) => {
                //   item.logo = item.AdminConsoleURL;
                // });

                setDepartmentChoices(filterOutDuplicates);
                setShowDecisions(true);
                setIsLoadingAuth(false);
                // props.history.push(`/evaluate/user`);
              })
              .catch((err) => {
                setIsLoadingAuth(false);
              });
          })
          .catch(() => {
            setIsLoadingAuth(false);
          });
        if (user) {
          // setRedirectToEvaluate(true);
        }
      } else {
        setIsLoadingAuth(false);
      }
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (props.email !== emailCheckedInAuth && !emailNeedsAuth0Check) {
      setEmailNeedsAuth0Check(true);
    }
  }, [props.email, emailNeedsAuth0Check]);

  //comment

  const getErrorMessage = (passwordBlank, emailBLank, invalidEmail) => {
    if (error) {
      return 'Service temporarily unavailable';
    }
    if (passwordBlank && emailBLank) {
      return 'Fields must not be empty';
    }

    if (passwordBlank && invalidEmail) {
      return 'Must have valid email and password must not be empty';
    }

    if (invalidEmail) {
      return 'Must have valid email';
    }

    if (passwordBlank) {
      return 'Password must not be empty';
    }

    if (emailBLank) {
      return 'Email must not be empty';
    }
  };

  const validateForm = (e, email, pwd) => {
    const pwdInput = document.getElementById('pwd');
    const loginInput = document.getElementById('emailLogin');
    const incorrectEmailOrPassword = {};

    if (password.trim() !== '') {
      pwdInput.style.border = 'auto';
    }

    if (emailIsValid(email)) {
      loginInput.style.border = 'auto';
    }

    if (password.trim() === '') {
      pwdInput.focus();
      pwdInput.style.border = '1px solid red';
      incorrectEmailOrPassword.passwordBlank = true;
    }

    if (email.trim() === '') {
      loginInput.focus();
      loginInput.style.border = '1px solid red';
      incorrectEmailOrPassword.emailBlank = true;
      // loginInput.select();
    }

    if (!emailIsValid(email)) {
      loginInput.focus();
      loginInput.style.border = '1px solid red';
      incorrectEmailOrPassword.emailNotValid = true;
      // loginInput.select();
    }

    const errorMessage = getErrorMessage(
      incorrectEmailOrPassword.passwordBlank,
      incorrectEmailOrPassword.emailBlank,
      incorrectEmailOrPassword.emailNotValid,
    );

    if (password.trim() !== '' && emailIsValid(email)) {
      postData(email, pwd);
      return;
    }

    setErrorText(errorMessage);
    setShowError(true);
    incorrectPasswordDiv.current.runAnimation(true);
  };

  useEffect(() => {
    // if the user changes the username or passowrd cancel the login request
    if (allowCancelRequest && cancel) {
      cancel();
      setAllowCancelRequest(false);
      setDisableButton(false);
      setShowError(false);
    }

    // clear show error if user starts entering new information
    if (showError === true) setShowError(false);
  }, [props.email, password]);

  const incorrectPasswordAnimation = () => {
    incorrectPasswordDiv.current.velocity('callout.shake');
    // Velocity(incorrectPasswordDiv.current, [{ left: '5px' }], 500).then((e) => {});
  };

  const determineAction = (data) => {
    let isEvaluatorOnly = true;
    let isEvaluator = false;
    let hasMultipleDepartments = data.length > 1;
    data.forEach((department) => {
      const { EvaluatorOnly, IsEvaluator } = department;

      if (IsEvaluator) {
        isEvaluator = true;
      }
    });

    if (!isEvaluator) {
      submitToFileMaker();
      //send them through to fileamker
      return;
    }

    const filteredData = filterOutDepartmentsWithoutPermissions(data);
    const filterOutDuplicates = filterOutDepartmentPermissionsDuplicates(filteredData);

    setDepartmentChoices(filterOutDuplicates);
    setShowDecisions(true);
    return;

    // have them pick department then role
    // use Permissions names
  };

  const setDepartmentInFilemaker = (chosenDepartment, permissions) => {
    const formData = new FormData();
    const url = `${constructFilemakerBaseUrl()}/login/selection/`;
    formData.append('username', username);
    formData.append('password', password);
    formData.append('departmentID', chosenDepartment.fkUUID_Dept);
    formData.append('permission', permissions.pkUUID_Permission);
    return Axios.post(url, formData)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    if (emailNeedsAuth0Check === false) {
      const passwordInput = document.getElementById('pwd');
      passwordInput.focus();
    }
  }, [emailNeedsAuth0Check]);

  const handleAuth0Workflow = (chosenDepartment) => {
    setLoadingOverlay(true);

    // in this workflow the user will always be an evaluator, so no need to check
    getUserTokenAuth(chosenDepartment);
    setLoadingOverlay(false);
    return;
  };

  const handleLegacyWorkflow = (chosenDepartment) => {
    setLoadingOverlay(true);

    const { EvaluatorOnly, IsEvaluator, NotEvaluator } = chosenDepartment;
    if (EvaluatorOnly) {
      getUserToken(chosenDepartment);
      setLoadingOverlay(false);
      return;
      // send them to new portal
    }
    if (!IsEvaluator) {
      if (chosenDepartment && chosenDepartment.Permissions && chosenDepartment.Permissions.length === 1) {
        setDepartmentInFilemaker(chosenDepartment, chosenDepartment.Permissions[0])
          .then(() => {})
          .catch((err) => {})
          .finally(() => {
            submitToFileMaker();
          });
      } else {
        setDepartmentChosenPhase(2);
        setLoadingOverlay(false);
      }
      return;
      // send to old
    }
    setDepartmentChosenPhase(2);
  };

  useEffect(() => {
    if (chosenDepartment) {
      if (isAuthenticated) {
        handleAuth0Workflow(chosenDepartment);
        return;
      } else {
        handleLegacyWorkflow(chosenDepartment);
        return;
      }
    }
  }, [chosenDepartment]);

  const submitToFileMaker = () => {
    let url = 'https://tools.rezrate.com/fmi/webd/RezRATE?homeurl=https://portal.rezrate.com';

    // if the chosen department has a custom url, use it instead of the default
    if (chosenDepartment && chosenDepartment.AdminConsoleURL && chosenDepartment.AdminConsoleURL !== '') {
      url = chosenDepartment.AdminConsoleURL;
    }
    const loginForm = document.getElementById('login-form');
    loginForm.action = url;
    loginForm.submit();
  };

  const checkEmailOrigin = () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    const preventMigration = params.legacy === 'true' ? true : false;

    setCheckingEmailOrigin(true);
    const postUrl = 'https://tools.rezrate.com/api/v2/user/authtype/';
    const formData = new FormData();
    formData.append('email', props.email);
    setEmailCheckedInAuth(props.email);
    Axios.post(postUrl, formData)
      .then((res) => {
        if (res.data.authType === 'Auth0') {
          loginWithRedirect();
        } else if (res.data.authType === 'Migrated' && !preventMigration) {
          const newLogin = `https://app.rezrate.com/user/login/${props.email}`;
          window.location.href = newLogin;
          return;
        } else {
          setEmailNeedsAuth0Check(false);
        }
      })

      .finally(() => {
        setCheckingEmailOrigin(false);
      });
  };

  const getUserToken = (department) => {
    const { fkUUID_Dept, pkUUID_User } = department;
    const postUrl = `${constructFilemakerBaseUrl()}/login/access/`;
    const formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('userid', pkUUID_User);
    formData.append('departmentid', fkUUID_Dept);
    formData.append('action', 'Refresh');
    Axios.post(postUrl, formData)
      .then((res) => {
        localStorage.setItem('evalToken', res.data.token);
        props.history.push(`/evaluate/user`);
      })
      .catch((err) => {
        setLoadingOverlay(false);
      });
  };

  // if they're loged in through auth0, we need to get their token using the jwt token
  const getUserTokenAuth = (department) => {
    const { fkUUID_Dept } = department;

    const postUrl = 'https://tools.rezrate.com/api/v2/user/auth0/department/';

    getTokenSilently()
      .then((token) => {
        const formData = new FormData();

        formData.append('deptID', fkUUID_Dept);
        formData.append('jwt', token);
        Axios.post(postUrl, formData)
          .then((res) => {
            localStorage.setItem('evalToken', res.data.token);
            props.history.push(`/evaluate/user`);
          })
          .catch((err) => {
            setLoadingOverlay(false);
          });
      })
      .catch((err) => {
        setLoadingOverlay(false);
      });
  };

  useEffect(() => {
    if (username !== '') {
      const postUrl = `${constructFilemakerBaseUrl()}/login/options/`;
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);
      Axios.post(postUrl, formData)
        .then((res) => {
          if (res.data === 'Error.') {
            return;
          }
          determineAction(res.data);
        })
        .catch((err) => {});
    }
  }, [username]);

  useEffect(() => {
    document.getElementById('emailLogin').focus();
  }, []);

  async function postData(email, pwd) {
    setAllowCancelRequest(true);
    setShowError(false);
    setDisableButton(true);
    const disableTimeout = setTimeout(function() {
      setDisableButton(false);
    }, 20000);
    const bodyFormData = new FormData();
    bodyFormData.append('email', email);
    bodyFormData.append('pwd', pwd);

    const url = `${constructFilemakerBaseUrl()}/login/`;
    const getUsername = () => {
      axios({
        method: 'post',
        url,
        withCredentials: determineCredentials(),
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
        data: bodyFormData,
        headers: {
          'content-type': 'application/json',
        },
      })
        .then((res) => {
          if (res.data && res.data.result === '200') {
            setUsername(res.data.username);
          } else {
            setErrorText(res.data.message);
            if (res.data.message === 'Invalid username or password.') {
              setCandidateWarningMessage(true);
            }

            clearTimeout(disableTimeout);
            setDisableButton(false);
            setShowError(true);
            const passwordInput = document.getElementById('pwd');
            passwordInput.focus();
            incorrectPasswordDiv.current.runAnimation(true);
          }
        })
        .catch((err) => {
          // if user cancels request don't set error to true, jsut disable and let them try again
          if (axios.isCancel(err)) {
            clearTimeout(disableTimeout);
            setDisableButton(false);
            setShowError(false);
          } else {
            setError(true);
          }
        });
    };
    getUsername();
  }

  const determineContents = () => {
    if (departmentChosenPhase === 1) {
      return (
        <div style={{ padding: '15px' }}>
          {false && (
            <div style={overlayStyle}>
              <FontAwesomeIcon color="#FFF" size="2x" icon={faSpinner} spin />
            </div>
          )}
          <div style={{ textAlign: 'center' }}>
            <h5>Which department are you acting under?</h5>
          </div>
          {departmentChoices.map((department, i) => {
            const alternatingColor = i % 2 ? '#f2f2f2' : '#fff';
            const { departmentName } = department;
            return (
              <div
                className="hover_blue"
                key={departmentName}
                style={{ padding: '20px', cursor: 'pointer', backgroundColor: alternatingColor }}
                onClick={() => {
                  setChosenDepartment(department);
                }}
              >
                <div style={{ display: 'flex' }}>
                  <div style={{ maxHeight: '70px', maxWidth: '70px', paddingRight: '15px' }}>
                    <img src={department.logo} alt="logo" style={{ width: '100%', height: '100%' }} />
                  </div>
                  <div>
                    <h6 className="no-margin bold">{department.organizationName}</h6>
                    <h6 className="no-margin bold">{departmentName}</h6>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return (
        <div style={{ padding: '15px' }}>
          {false && (
            <div style={overlayStyle}>
              <FontAwesomeIcon color="#FFF" size="2x" icon={faSpinner} spin />
            </div>
          )}
          <div style={{ textAlign: 'center' }}>
            <h5>Which role would you like to perform under?</h5>
          </div>
          <div>
            {chosenDepartment && chosenDepartment.Permissions && (
              <div>
                {chosenDepartment.Permissions.map((permission, i) => {
                  return (
                    <div
                      className="hover_blue"
                      style={{ padding: '20px', cursor: 'pointer' }}
                      onClick={() => {
                        if (permission.selectionAction === 2) {
                          getUserToken(chosenDepartment);
                        } else {
                          setDepartmentInFilemaker(chosenDepartment, permission)
                            .then((res) => {})
                            .catch(() => {})
                            .finally(() => {
                              submitToFileMaker();
                            });
                        }
                      }}
                    >
                      <h6 className="bold">
                        {i + 1}: {permission.selectionDisplay}
                      </h6>
                    </div>
                  );
                })}
              </div>
            )}
            {/* <div
              style={{ padding: '20px', cursor: 'pointer' }}
              onClick={() => {
                getUserToken(chosenDepartment);
              }}
            >
              Evaluator
            </div>
            <div
              style={{ padding: '20px', cursor: 'pointer' }}
              onClick={() => {
                submitToFileMaker();
              }}
            >
              Other
            </div> */}
          </div>
        </div>
      );
    }
  };

  const getGlobalSettings = () => {
    const url = 'groupInfo/settings/global';
    return fetchDataAgnosticWithoutCredentials(url, null)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return { error: true };
      });
  };

  const animation = 'callout.shake';
  const trueOrFalse = animateError ? 1 : 0;

  const loadContent = () => {
    if (error) {
      Swal.fire(
        'Service Temporarily Unavailable',
        'RezRATE is experiencing technical issues.<br>Please try again later',
        'error',
      );
    }
    return (
      <Row width="100%">
        <Popup open={showDecisions} closeOnDocumentClick={false} onClose={() => setShowDecisions(false)}>
          {determineContents()}
        </Popup>
        <Col lg={{ size: 4, offset: 4 }}>
          {isSpecificDate(new Date(), '03-06-2020') && (
            <p style={{ textAlign: 'center', color: 'red' }}>
              RezRATE will be unavailable (March 6, 2020) from 8 PM until 11 PM CST
            </p>
          )}
          <Form ref={formData} id="login-form" role="form" autoComplete="on" className="form" method="post">
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText style={{ height: '100%' }}>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  autoFocus=""
                  id="emailLogin"
                  name="email"
                  placeholder="email address"
                  className="form-control"
                  type="email"
                  autoComplete="on"
                  data-error="Invalid email address."
                  required=""
                  value={props.email}
                  ref={loginInput}
                  onChange={(e) => {
                    setLoginHint(e.target.value);
                    props.setEmail(e.target.value);
                  }}
                />
              </InputGroup>
              <div className="help-block with-errors"></div>
            </FormGroup>
            <FormGroup className="form-group" style={{ visibility: emailNeedsAuth0Check ? 'hidden' : 'visible' }}>
              <InputGroup className="input-group">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText style={{ height: '100%' }}>
                    <FontAwesomeIcon icon={faLock} />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  id="pwd"
                  name="pwd"
                  placeholder="password"
                  className="form-control input_for_login"
                  type="password"
                  autoComplete="off"
                  data-error="Password is required."
                  value={password}
                  ref={passwordInput}
                  onChange={(e) => setPassword(e.target.value)}
                  required=""
                />
              </InputGroup>
            </FormGroup>
            <VelocityComponent animation={'callout.shake'} ref={incorrectPasswordDiv} key={'callout.shake'}>
              <div
                className="form-group alert alert-danger"
                style={{ textAlign: 'center', display: showError ? '' : 'none' }}
              >
                <strong>Error:</strong> <em>{errorText}</em>
              </div>
            </VelocityComponent>
            <FormGroup style={{ textAlign: 'center' }}>
              <Button
                style={{ backgroundColor: '#2e6da4', minWidth: '150px' }}
                type="submit"
                onClick={async (e) => {
                  e.preventDefault();

                  const settings = await getGlobalSettings();

                  if (settings && settings.PreventLogin) {
                    Swal.fire('Error', 'Login service is currently unavailable.', 'error');
                    return;
                  }

                  if (emailNeedsAuth0Check) {
                    checkEmailOrigin();
                  } else {
                    validateForm(e, props.email, password);
                  }
                }}
                name="loginSubmit"
                id="loginSubmit"
                value="login"
                disabled={disableButton || checkingEmailOrigin}
                className="btn-lg btn-primary"
              >
                {disableButton ? <FontAwesomeIcon spin icon={faSpinner} /> : emailNeedsAuth0Check ? 'Submit' : 'Log In'}
              </Button>
            </FormGroup>
            <FormGroup className="form-group" style={{ height: '0px', visibility: 'hidden', margin: 0 }}>
              {username !== '' && (
                <InputGroup className="input-group" style={{ height: '0px' }}>
                  <Input
                    type="hidden"
                    id="user"
                    name="user"
                    placeholder="user"
                    className="form-control username"
                    type="text"
                    readOnly="readOnly"
                    value={username}
                  />
                </InputGroup>
              )}
              <div className="help-block with-errors">
                <span id="errormessage"></span>
              </div>
            </FormGroup>
          </Form>
          <ForgotPasswordWrapper>
            <Link
              to="/reset"
              style={{
                textAlign: 'center',
                cursor: 'pointer',
                color: '#337ab7',
                visibility: emailNeedsAuth0Check ? 'hidden' : 'visible',
              }}
            >
              Forgot password?
            </Link>
          </ForgotPasswordWrapper>
          {candidateWarningMessage && (
            <div style={{ textAlign: 'center', paddingTop: '15px' }}>
              <h5>Are you a candidate?</h5>
              <p className="no-margin">Applicants are not required to log in to RezRATE.</p>
              <p className="no-margin">
                Check your inbox and spam folders for a direct link to your schedule. Contact your coordinator with any
                questions.
              </p>
            </div>
          )}
        </Col>
      </Row>
    );
  };

  // const { loading, user } = useAuth0();

  // if ((loading, !user)) {
  //   return <div />;
  // }

  if (redirectToEvaluate) {
    return <Redirect to="/evaluate/user" />;
  }

  return (
    <Fragment>
      {(isLoadingAuth || ssoLogin) && <LoadingDot />}
      <Wrapper style={{ visibility: isLoadingAuth || ssoLogin ? 'hidden' : 'visible' }}>{loadContent()}</Wrapper>
    </Fragment>
  );
};

export default Login;
