import React from 'react';
import { Col } from 'reactstrap';
import SingleCandidateDragAndDropItem from './SingleCandidateDragAndDropItem';

const SingleCandidateDragAndDrop = ({
  documentTypes,
  singleCandidateObject,
  getCandidateInformation,
  fileUpload,
  setDocumentTypes,
  setFiles,
  uploadLogo,
  uploadAvatar,
  currentLogo,
  avatarUpload,
  setCandidateFiles,
  currentAvatar,
  setSelectedDocumentType,
  initiateFileUpload,
  urlObject,
  candidateFiles,
}) => {
  const determineRender = () => {
    if (uploadLogo || uploadAvatar) {
      return (
        <SingleCandidateDragAndDropItem
          setFiles={setFiles}
          item={{}}
          setDocumentTypes={setDocumentTypes}
          avatarUpload={avatarUpload}
          documentTypes={documentTypes}
          uploadLogo={uploadLogo}
          uploadAvatar={uploadAvatar}
          initiateFileUpload={initiateFileUpload}
          currentAvatar={currentAvatar}
          urlObject={urlObject}
          currentLogo={currentLogo}
          getCandidateInformation={getCandidateInformation}
          candidateFiles={candidateFiles}
          setSelectedDocumentType={setSelectedDocumentType}
        />
      );
    }
    return documentTypes.map((item) => {
      return (
        <SingleCandidateDragAndDropItem
          key={item.pk_UUID_FM}
          item={item}
          setFiles={setFiles}
          setDocumentTypes={setDocumentTypes}
          documentTypes={documentTypes}
          initiateFileUpload={initiateFileUpload}
          urlObject={urlObject}
          getCandidateInformation={getCandidateInformation}
          candidateFiles={candidateFiles}
          setSelectedDocumentType={setSelectedDocumentType}
        />
      );
    });
  };
  return (
    <Col style={{ padding: 0, width: '100vw', maxWidth: '100%' }}>
      <div style={{ height: '100vh', overflowY: 'scroll', overflowX: 'hidden', width: '100%' }}>
        <div style={{ width: '100%' }}>{determineRender()}</div>
      </div>
    </Col>
  );
};

export default SingleCandidateDragAndDrop;
