import React from 'react';

// generic error screen.  Can pass in variables or use generic message
const Error = ({ header, subHeader }) => {
  const headerMessage = header ? header : 'Unable to load content.';
  const subHeaderMessage = subHeader ? subHeader : 'Please try again later';
  return (
    <div className="alert alert-error" style={{ margin: 0 }}>
      <div className="midwrapper">
        <div className="midblock">
          <h2>{headerMessage}</h2>
          {subHeaderMessage}
        </div>
      </div>
    </div>
  );
};

export default Error;
