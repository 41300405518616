import { connect } from 'react-redux';
import { setDate, setDateArray, setSeasonType, setZoomBreakRoom } from '../Header.action';
import { setBreakUrl, setUser } from '../UserInformation/UserInformation.action';
import MobileFork from './MobileFork';

const mapDispatchToProps = (dispatch) => {
  return {
    setDateArray: (dateArray) => dispatch(setDateArray(dateArray)),
    setDate: (date) => dispatch(setDate(date)),
    setUser: (userObject) => dispatch(setUser(userObject)),
    setSeasonType: (seasonType) => dispatch(setSeasonType(seasonType)),
    setZoomBreakRoom: (zoomLink) => dispatch(setZoomBreakRoom(zoomLink)),
    setBreakUrl: (url) => dispatch(setBreakUrl(url)),
  };
};

const mapStateToProps = (state) => {
  const { date, headerHeight } = state.header;
  const { isAdmin } = state.login;
  const { breakUrl } = state.adminUser;
  return { date, headerHeight, isAdmin, breakUrl };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileFork);
