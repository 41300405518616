import React, { useState } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - ${(props) => props.navbarHeight}px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckMobile = () => {
  if (window.mobilecheck()) {
    return 74;
  } else {
    return 200;
  }
};

const LoadingDot = () => {
  const [navbarHeight, setNavbarHeight] = useState(CheckMobile());

  return (
    <Wrapper navbarHeight={navbarHeight}>
      <BounceLoader size={60} />
    </Wrapper>
  );
};

export default LoadingDot;
