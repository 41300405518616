import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Redirect } from 'react-router-dom';
import { Col } from 'reactstrap';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import Swal from 'sweetalert2';
import {
  adaptToTimeZone,
  addColors,
  constructFilemakerBaseUrl,
  determineCredentials,
  getCorrectEvalToken,
  getMeetingIdFromUrl,
  isArrayEqual,
  isDev,
  joinArrays,
  MEETING_STATUS,
  removeCorrectEvalTOken,
  usePrevious,
  zoomPopupJSX,
} from '../../../../Common.functions';
import LoadingDot from '../../../../LoadingDot/LoadingDot';
import {
  fetchDataWithoutCredentials,
  postDataAgnosticWithoutCredentials,
  fetchDataAgnosticWithoutCredentials,
} from '../../../../Services/dataApi';
import { determineMeetingSound } from '../../../../Sound/MeetingStatusSounds';
import { createAlarms, handleSounds } from '../../Body/Evaluate/ScheduleAlerts';

const candidateSortTypes = {
  Alphabetical: 'Alphabetical',
  EvalStatus: 'EvalStatus',
  University: 'University',
};

const urlV2 = `${constructFilemakerBaseUrl()}/evaluator/portal/`;
const scheduleBaseUrl = `${constructFilemakerBaseUrl()}/evaluator/candidates/`;
// const url =
// 'https://tools.rezrate.com/rezrate/api/evalportal/?token=kv78czgx78yb6gr0un1rg896y34645126262063170788677958307489916498670013146389725866310kthb05bde';

// handles mobile vs browser components.  Will reevaluate on resize

const WIDTH_BREAK_POINT = 767;

const REDACTED = 'Redacted';

const getWidth = () => {
  return window.innerWidth < WIDTH_BREAK_POINT;
};

const filterCandidateInfo = (candidates) => {
  return candidates.filter((item) => {
    return item.Candidates4DateEvaluationStatus === 'In progress' || item.Candidates4DateEvaluationStatus === '';
  });
};

const MobileFork = ({
  MobileComponent,
  BrowserComponent,
  headerHeight,
  setZoomBreakRoom,
  setUser,
  setBreakUrl,
  setDate,
  breakUrl,
  setDateArray,
  isAdmin,
  date,
  history,
  setSeasonType,
  match,
  setShowLargePhoto,
  setLargePhoto,
}) => {
  const [isMobile, setIsMobile] = useState(window.mobilecheck() || getWidth());
  const [userInformation, setUserInformation] = useState(null);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [heightOfHeader, setHeightOfHeader] = useState(201);
  const [beginningTimeMinus30Minutes, setBeginningTimeMinus30Minutes] = useState(null);
  const [endingTimePlus30Minutes, setEndingTimePlus30Minutes] = useState(null);
  const [candidateInfo, setCandidateInfo] = useState(null);
  const [copied, setCopied] = useState(false);
  const [currentCandidateSortType, setCurrentCandidateSortType] = useState(candidateSortTypes.Alphabetical);
  const [sortCandAscending, setSortCandAscending] = useState(true);
  const [sortCandIncompleteAscending, setSortIncompleteCandAscending] = useState(true);
  const [incompleteCandidates, setIncompleteCandidates] = useState([]);
  const [zoomPassword, setZoomPassword] = useState('');
  const [Schedules4EvalJoinManualURL, setSchedules4EvalJoinManualURL] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [hideNames, setHideNames] = useState(false);
  const [hidePhotos, setHidePhotos] = useState(false);
  const [hideLanguages, setHideLanguages] = useState(false);
  const [hideSchool, setHideSchool] = useState(false);
  const [hideHobbies, setHideHobbies] = useState(false);
  const [hideDocuments, setHideDocuments] = useState(false);
  const [hideScoresAndAwards, setHideScoresAndAwards] = useState(false);
  const [hideCandidateEvaluations, setHideCandidateEvaluations] = useState(false);
  const [firstBlockEarlyJoin, setFirstBlockEarlyJoin] = useState(0);
  const [userIdOnCurrentInterview, setUserIdOnCurrentInterview] = useState(null);
  const [DNRStatus, setDNRStatus] = useState(null);
  const [differences, setDifferences] = useState([]);
  const [seasonUUID, setSeasonUUID] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [disableJoinMeetingButton, setDisableJoinMeetingButton] = useState(false);
  const [isEvaluatorMode, setIsEvaluatorMode] = useState(null);
  const [meetingId, setMeetingId] = useState(null);
  const [mainRoomDescription, setMainRoomDescription] = useState(null);
  const [schedule, setSchedule] = useState(null);
  const [evaluationsLocked, setEvaluationsLocked] = useState(false);
  const [interviewIsToday, setInterviewIsToday] = useState(false);
  const [interviewDateUUID, setInterviewDateUUID] = useState('');
  const [allDayRoom, setAllDayRoom] = useState(null);
  const [preventJoinMeetingClick, setPreventJoinMeetingClick] = useState(false);
  const [popupDescription, setPopupDescription] = useState('');
  const [meetingType, setMeetingType] = useState(null);
  const [globalSettings, setGlobalSettings] = useState({});
  const [globalSettingsLoading, setGlobalSettingsLoading] = useState(true);
  const [Schedules4Eval__pkUUID_Schedule, setSchedules4Eval__pkUUID_Schedule] = useState(null);
  const [evalStatus, setEvalStatus] = useState(null);
  const [duration, setDuration] = useState(20);
  const [alarms, setAlarms] = useState(null);
  const [demo, setDemo] = useState(false);
  const [scheduleEndTimePlus10Minutes, setScheduleEndTimePlus10Minutes] = useState(null);
  const [error, setError] = useState(false);
  const [showRed, setShowRed] = useState(false);
  const [zoomInfo, setZoomInfo] = useState([]);
  const [userNeedsToSignAgreement, setUserNeedsToSignAgreement] = useState(false);
  const [currentlyGettingEval, setCurrentlGettingEval] = useState(false);
  const [selectedCandidateId, setSelectedCandidateId] = useState(null);
  const [candidateEvaluation, setCandidateEvaluation] = useState(null);
  const [scheduleUrl, setScheduleUrl] = useState(`${scheduleBaseUrl}?token=${getCorrectEvalToken(isAdmin)}`);
  const [zoomLink, setZoomLink] = useState(null);
  const [interviewHasPassed, setInterviewHasPassed] = useState(true);
  const [documentKey, setDocumentKey] = useState('');
  const [showZoomLink, setShowZoomLink] = useState(false);
  const [token, setToken] = useState(getCorrectEvalToken(isAdmin));
  const [scheduleTimeStamp, setScheduleTimeStamp] = useState('');
  const [expiredToken, setExpiredToken] = useState(false);
  const prevAmount = usePrevious({ date });
  const [fullUrlV2, setFullUrlV2] = useState(`${urlV2}?token=${getCorrectEvalToken(isAdmin)}`);
  const zoomForm = useRef(null);

  const { loading, user, isAuthenticated, loginWithRedirect, getTokenSilently, logout } = useAuth0();
  const candidateLength = candidateInfo ? candidateInfo.length : 0;

  useEffect(() => {
    if (incompleteCandidates && selectedUser) {
      setIncompleteCandidates((prevState) => {
        let needsUpdate = false;
        // eslint-disable-next-line no-undef
        let clone = structuredClone(prevState);
        clone.forEach((item) => {
          if (item.Candidates4Date__pkUUID_Candidate === selectedUser.CandidateSelected__pkUUID_Candidate) {
            if (item.Candidates4DateEvaluationStatus !== selectedUser.CandidateSelectedEvaluationStatus) {
              needsUpdate = true;
              item.Candidates4DateEvaluationStatus = selectedUser.CandidateSelectedEvaluationStatus;
            }

            if (item.Candidates4DateDNRStatus !== selectedUser.CandidateSelectedDNRStatus) {
              needsUpdate = true;
              item.Candidates4DateDNRStatus = selectedUser.CandidateSelectedDNRStatus;
            }
          }
        });
        if (needsUpdate) {
          clone = clone.filter((item) => item.Candidates4DateEvaluationStatus !== 'Complete');
          return clone;
        } else {
          return prevState;
        }
      });
    }

    if (schedule && selectedUser) {
      setSchedule((prevState) => {
        let needsUpdate = false;
        // eslint-disable-next-line no-undef
        const clone = structuredClone(prevState);
        clone.forEach((item) => {
          if (item.Candidates4Date__pkUUID_Candidate === selectedUser.CandidateSelected__pkUUID_Candidate) {
            if (item.Candidates4DateEvaluationStatus !== selectedUser.CandidateSelectedEvaluationStatus) {
              needsUpdate = true;
              item.Candidates4DateEvaluationStatus = selectedUser.CandidateSelectedEvaluationStatus;
            }

            if (item.Candidates4DateDNRStatus !== selectedUser.CandidateSelectedDNRStatus) {
              needsUpdate = true;
              item.Candidates4DateDNRStatus = selectedUser.CandidateSelectedDNRStatus;
            }
          }
        });
        if (needsUpdate) {
          return clone;
        } else {
          return prevState;
        }
      });
    }
    if (selectedUser && candidateInfo) {
      setCandidateInfo((prevState) => {
        let needsUpdate = false;
        // eslint-disable-next-line no-undef
        const clone = structuredClone(prevState);

        clone.forEach((item, i) => {
          if (item.Candidates4Date__pkUUID_Candidate === selectedUser.CandidateSelected__pkUUID_Candidate) {
            // if candidate in array does not have the same status as the selected user, update them
            if (item.Candidates4DateEvaluationStatus !== selectedUser.CandidateSelectedEvaluationStatus) {
              needsUpdate = true;
              item.Candidates4DateEvaluationStatus = selectedUser.CandidateSelectedEvaluationStatus;
            }

            if (item.Candidates4DateDNRStatus !== selectedUser.CandidateSelectedDNRStatus) {
              needsUpdate = true;
              item.Candidates4DateDNRStatus = selectedUser.CandidateSelectedDNRStatus;
            }
          }
        });

        // only return the new reference variables if an actually change has occurred
        if (needsUpdate) {
          return clone;
        } else {
          return prevState;
        }
      });
    }
  }, [selectedUser]);

  useEffect(() => {
    if (candidateLength > 0) sortCandidates(null, null, true);
  }, [candidateLength]);

  useEffect(() => {
    const url = 'groupInfo/settings/global';
    fetchDataAgnosticWithoutCredentials(url, null)
      .then((result) => {
        setGlobalSettings(result.data);
      })
      .catch((err) => {})
      .finally(() => {
        setGlobalSettingsLoading(false);
      });
  }, []);

  const handleOnIdle = (event) => {
    const rightNow = new Date(Date.now());
    if (interviewIsToday && rightNow > beginningTimeMinus30Minutes && rightNow < endingTimePlus30Minutes) {
      reset();
    } else {
      let redirect;
      Swal.fire({
        title: 'Are you still there?',
        text: `Your session will time out in 30 seconds.`,
        icon: 'warning',
        confirmButtonText: 'Stay logged in',
      }).then((result) => {
        clearTimeout(redirect);
      });
      redirect = setTimeout(function() {
        setRedirectToLogin(true);
        Swal.close();
      }, 30 * 1000);
    }
  };

  const handleOnActive = (event) => {};

  const handleOnAction = (e) => {};

  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: 30 * 60 * 1000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });

  useEffect(() => {
    //TODO: Find a better way to wait for notifications to mount rather than just setting a timeout
    setTimeout(() => {
      const heightOfHeader = document.getElementById('header_notification_wrapper').offsetHeight;
      setHeightOfHeader(heightOfHeader);
    }, 1000);
  }, []);

  // when this was written filemaker changes table key values based on the route.  In order to make them uniform, we have to change
  // them on the frontend

  const changePrescreenKeys = (candidates) => {
    return candidates.map((item) => {
      return {
        Candidates4DateDNRStatus: item.CandidatesDNRStatus,
        Candidates4DateDateOfInterviewLabel: item.CandidatesDateOfInterviewLabel,
        Candidates4DateEmail: item.CandidatesEmail,
        Candidates4DateEvaluationStatus: item.CandidatesEvaluationStatus,
        Candidates4DateExternalPhotoURL: item.CandidatesExternalPhotoURL,
        Candidates4DateHobbies: item.CandidatesHobbies,
        Candidates4DateLanguages: item.CandidatesLanguages,
        Candidates4DateMedSchool: item.CandidatesMedSchool,
        Candidates4DateMedicalDegree: item.CandidatesMedicalDegree,
        Candidates4DateNameFirst: item.CandidatesNameFirst,
        Candidates4DateNameFirstLast: item.CandidatesNameFirstLast,
        Candidates4DateNameFull: item.CandidatesNameFull,
        Candidates4DateNameLast: item.CandidatesNameLast,
        Candidates4DateNameMiddle: item.CandidatesNameMiddle,
        Candidates4DateNamePreferred: item.CandidatesNamePreferred,
        Candidates4DatePhotoSM: item.CandidatesPhotoSM,
        Candidates4DatePreferredPhone: item.CandidatesPreferredPhone,
        Candidates4DateTags: item.CandidatesTags,
        Candidates4DateTagsArray: item.CandidatesTagsArray,
        Candidates4DateUnableToEvaluate: item.CandidatesUnableToEvaluate,
        Candidates4Date__pkUUID_Candidate: item.Candidates__pkUUID_Candidate,
        Candidates4Date_fkUUID_InterviewDate: item.Candidates_fkUUID_InterviewDate,
        Candidates4DatejsonScoresAwards: item.CandidatesjsonScoresAwards,
        CandidatesUnableToEvaluate: item.CandidatesUnableToEvaluate,
        Status4CandidatesPrescreenAssignmentType: item.Status4CandidatesPrescreenAssignmentType,
      };
    });
  };
  const changeKeys = (Candidates4PendingIncomplete) => {
    return Candidates4PendingIncomplete.map((item) => {
      return {
        Candidates4DateDNRStatus: item.Candidates4PendingIncompleteDNRStatus,
        Candidates4DateDateOfInterviewLabel: item.Candidates4PendingIncompleteDateOfInterviewLabel,
        Candidates4DateEmail: item.Candidates4PendingIncompleteEmail,
        Candidates4DateEvaluationStatus: item.Candidates4PendingIncompleteEvaluationStatus,
        Candidates4DateExternalPhotoURL: item.Candidates4PendingIncompleteExternalPhotoURL,
        Candidates4DateHobbies: item.Candidates4PendingIncompleteHobbies,
        Candidates4DateLanguages: item.Candidates4PendingIncompleteLanguages,
        Candidates4DateMedSchool: item.Candidates4PendingIncompleteMedSchool,
        Candidates4DateMedicalDegree: item.Candidates4PendingIncompleteMedicalDegree,
        Candidates4DateNameFirst: item.Candidates4PendingIncompleteNameFirst,
        Candidates4DateNameFirstLast: item.Candidates4PendingIncompleteNameFirstLast,
        Candidates4DateNameFull: item.Candidates4PendingIncompleteNameFull,
        Candidates4DateNameLast: item.Candidates4PendingIncompleteNameLast,
        Candidates4DateNameMiddle: item.Candidates4PendingIncompleteNameMiddle,
        Candidates4DateNamePreferred: item.Candidates4PendingIncompleteNamePreferred,
        Candidates4DatePhotoSM: item.Candidates4PendingIncompletePhotoSM,
        Candidates4DatePreferredPhone: item.Candidates4PendingIncompletePreferredPhone,
        Candidates4DateTags: item.Candidates4PendingIncompleteTags,
        Candidates4DateTagsArray: item.Candidates4PendingIncompleteTagsArray,
        Candidates4DateUnableToEvaluate: item.Candidates4PendingIncompleteUnableToEvaluate,
        Candidates4Date__pkUUID_Candidate: item.Candidates4PendingIncomplete__pkUUID_Candidate,
        Candidates4Date_fkUUID_InterviewDate: item.Candidates4PendingIncomplete_fkUUID_InterviewDate,
        Candidates4DatejsonScoresAwards: item.Candidates4PendingIncompletejsonScoresAwards,
      };
    });
  };

  const getScheduleTimestamp = () => {
    const { AccessTokens_fkUUID_Dept } = userInformation;
    const { InterviewDatesVLDate } = date;
    return fetchDataAgnosticWithoutCredentials('department/season/schedule/updateTimeStamp', {
      departmentUUID: AccessTokens_fkUUID_Dept,
      seasonUUID,
      interviewDateDay: InterviewDatesVLDate,
    })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        return { error: true };
      });
  };

  useEffect(() => {
    const rightNow = moment(new Date());
    const minutes = 4 * 60 * 1000;
    if (date === 'Prescreen' || !date || !scheduleEndTimePlus10Minutes) {
      return;
    }

    // only check the schedule if it's the day of the schedule and before 10 minutes after the last schedule ends
    // if (rightNow.isAfter(scheduleEndTimePlus10Minutes) || !interviewIsToday) {
    //   return;
    // }

    // get schedule every N minutes[']

    const interval = setInterval(async () => {
      const { InterviewDatesVL__pkUUID_InterviewDate } = date;

      const timeStampObject = await getScheduleTimestamp();

      if (timeStampObject.hasTimeLogged) {
        // if ScheduleLastUpdatedMiliseconds has changed that means the schedule has updated, so get the schedule by calling
        // getInfo
        setScheduleTimeStamp((prevState) => {
          // first time called if null,
          if (prevState === null) {
            return timeStampObject.ScheduleLastUpdatedMiliseconds;
          }

          if (prevState !== timeStampObject.ScheduleLastUpdatedMiliseconds) {
            getInfo(true, true, false, currentCandidateSortType, sortCandAscending);
            return timeStampObject.ScheduleLastUpdatedMiliseconds;
          }
          return prevState;
        });
      } else {
        getInfo(true, true, false, currentCandidateSortType, sortCandAscending);
      }
    }, minutes);
    return () => clearInterval(interval);
  }, [
    date,
    currentCandidateSortType,
    sortCandAscending,
    scheduleTimeStamp,
    scheduleEndTimePlus10Minutes,
    interviewIsToday,
  ]);

  useEffect(() => {
    const resizeListener = (e) => {
      // mobile view should be viewable on browser after a certain
      const { innerWidth } = window;
      if (innerWidth < WIDTH_BREAK_POINT && !isMobile) {
        setIsMobile(true);
      } else if (innerWidth >= WIDTH_BREAK_POINT && isMobile) {
        setIsMobile(false);
      }
    };
    // set resize listener
    window.addEventListener('resize', resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener('resize', resizeListener);
    };
  }, [isMobile]);

  // const handler = () => {
  //   setIsMobile(window.mobilecheck());
  // };
  // useEventListener('resize', handler);

  const getDefaultScheduleTimestamp = async (InterviewDatesVL__pkUUID_InterviewDate) => {
    const timeStampObject = await getScheduleTimestamp();
    if (timeStampObject.hasTimeLogged) {
      setScheduleTimeStamp(timeStampObject.ScheduleLastUpdatedMiliseconds);
    } else {
      setScheduleTimeStamp('');
    }
  };

  useEffect(() => {
    if (date && userInformation && seasonUUID) getDefaultScheduleTimestamp();
  }, [seasonUUID, date, userInformation]);

  useEffect(() => {
    setSchedule(null);
    setSelectedUser(null);
    if (((date && date.InterviewDatesVLDate) || (date && date.toUpperCase() === 'PRESCREEN')) && userInformation) {
      const rightNow = new Date(Date.now());
      const day = moment(rightNow).format('MM/DD/YYYY');
      if (day === date.InterviewDatesVLDate) {
        setInterviewIsToday(true);
      } else {
        setInterviewIsToday(false);
      }
      const isInPast = moment(new Date(date.InterviewDatesVLDate)).diff(moment(new Date(day)));
      if (isInPast < 0) {
        setInterviewHasPassed(true);
      } else {
        setInterviewHasPassed(false);
      }

      getInfo(false, false, true, null, null, true);
    }

    // const interval = setInterval(() => {
    //   // getInfo(true);
    //   // forceUpdate();
    // }, 60 * 1000);
    // return () => clearInterval(interval);
  }, [date, userInformation]);

  const getPrescreen = async () => {
    let continueWhileLoop = true;
    let batch = 0;
    let stopper = 0;
    let url = `${constructFilemakerBaseUrl()}/evaluator/prescreen/?token=${getCorrectEvalToken(isAdmin)}`;

    try {
      while (continueWhileLoop) {
        const urlWithBatch = url + `&batch=${batch}`;

        const result = await fetchDataWithoutCredentials(urlWithBatch, null);

        const {
          CandidateDocumentsKey,
          SeasonSelected__pkUUID_Season,
          BatchesRemaining,
          BatchesTotal,
          TotalCandidates,
        } = result.data;

        setSeasonUUID(SeasonSelected__pkUUID_Season);
        setDocumentKey(CandidateDocumentsKey);

        setCandidateInfo((prevState) => {
          // to avoid doublig up array or setting it to null too early (jarring to the user), overwrite the array on the first batch
          if (batch === 0) {
            return changePrescreenKeys(result.data.Candidates);
          }

          // candidate info starts as null so set it to an empty array if it's null
          let clone = [];
          if (prevState != null) {
            // eslint-disable-next-line no-undef
            clone = structuredClone(prevState);
          }
          const formattedPayload = changePrescreenKeys(result.data.Candidates);

          // combine batch with current results
          const combined = [...clone, ...formattedPayload];
          return combined;
        });
        setIsLoading((prevState) => {
          if (prevState === true) {
            return false;
          }

          return prevState;
        });

        // if no more batches are needed break out of while loop
        if (BatchesRemaining <= 0) {
          continueWhileLoop = false;
        }
        batch += 1;
      }
    } catch (err) {
      setIsLoading(false);
      setError(true);
    }
  };
  const returnWithToken = (url, optionalToken) => {
    const token = optionalToken || getCorrectEvalToken(isAdmin);
    return `${url}?token=${token}`;
  };

  const handleZoomClick = (url, mobileNumbers, password) => {
    const meetingId = getMeetingIdFromUrl(url);
    setMeetingId(meetingId);
    // if there are mobile numbers set them.  If not,
    // make sure zoom info resets so it doesn't display
    // info from previous meeting
    if (password && password !== '') {
      setZoomPassword(password);
    } else {
      setZoomPassword('');
    }

    if (mobileNumbers && mobileNumbers.length > 0) {
      setZoomInfo(mobileNumbers);
    } else {
      setZoomInfo([]);
    }

    localStorage.setItem('zoomUrl', url);
    if (zoomForm && zoomForm.current) {
      zoomForm.current.action = '/zoom';
    }

    setZoomLink(url);
    setShowZoomLink(true);
    setTimeout(() => {
      setShowZoomLink(false);
    }, 3 * 60 * 1000);

    try {
      if (zoomForm && zoomForm.current) {
        zoomForm.current.submit();
      }
    } catch (err) {
      if (err.name !== 'NS_ERROR_FAILURE') {
        Swal.fire('Error', 'Something went wrong... please try again or contact support.', 'error');
      }
    }
  };

  const createDateObjects = (Schedules4Eval) => {
    Schedules4Eval.forEach((item) => {
      const start = new Date(item.Schedules4EvalTimestamp);
      const end = new Date(item.Schedules4EvalTimestampEnd);
      item.timeSlotStartObject = start;
      item.timeSlotEndObject = end;
    });
    return Schedules4Eval;
  };

  const informUserMeetingIsStartingLocally = (setDisplayLocalNotification, scheduleElement) => {
    const { Schedules4Eval__pkUUID_Schedule, Schedules4EvalTimeDuration } = scheduleElement;

    const { Users4AccessTokenDeptName, AccessTokens_fkUUID_User } = userInformation;

    setDisplayLocalNotification(false);
    setTimeout(() => {
      setDisplayLocalNotification(true);
    }, 3 * 60 * 1000);
    if (meetingType !== 0) {
      NotificationManager.info('Click here to join!', 'Time for next meeting', 10 * 1000, () => {
        fetchDataAgnosticWithoutCredentials('schedule/season/evaluator/interviewDate/virtualMeeting', {
          scheduleId: Schedules4Eval__pkUUID_Schedule,
          evaluatorId: AccessTokens_fkUUID_User,
          duration: Schedules4EvalTimeDuration,
          departmentName: Users4AccessTokenDeptName,
        })
          .then((result) => {
            const { JoinURL, DialInNumbers, Password } = result.data;
            handleZoomClick(JoinURL, DialInNumbers, Password);
          })
          .catch((err) => {
            Swal.fire('Error', 'Something went wrong... please try again or contact support.', 'error');
          })
          .finally(() => {
            setDisableJoinMeetingButton(false);
          });
      });
    } else {
      NotificationManager.info('Time for your next meeting');
    }
  };

  const sortCandidatesAlphabetically = (incomingCandidates, isIncompleteCandidates, preventFlipSort) => {
    //if changing sort type always sort by descending instead of toggling
    const sortTypeIsChanging = currentCandidateSortType !== candidateSortTypes.Alphabetical;
    const sortAscending = sortTypeIsChanging ? true : incomingCandidates ? sortCandAscending : !sortCandAscending;

    setCandidateInfo((prevState) => {
      const candidates = incomingCandidates ?? prevState;
      return candidates.sort(function(a, b) {
        if (a.Candidates4DateNameLast < b.Candidates4DateNameLast) {
          if (sortAscending) {
            return preventFlipSort ? 1 : -1;
          } else {
            return preventFlipSort ? -1 : 1;
          }
        }
        if (a.Candidates4DateNameLast > b.Candidates4DateNameLast) {
          if (sortAscending) {
            return preventFlipSort ? -1 : 1;
          } else {
            return preventFlipSort ? 1 : -1;
          }
        }
        return 0;
      });
    });

    if (preventFlipSort) return;

    // if we're just updating the candidate list don't change the sort direction

    if (!incomingCandidates) {
      if (sortTypeIsChanging) {
        setSortCandAscending(true);
      } else {
        setSortCandAscending((prevState) => !prevState);
      }
    }
    setCurrentCandidateSortType(candidateSortTypes.Alphabetical);
  };

  const sortByUniversity = (incomingCandidates, isIncompleteCandidates, preventFlipSort) => {
    //if changing sort type always sort by descending instead of toggling
    const sortTypeIsChanging = currentCandidateSortType !== candidateSortTypes.University;
    const sortAscending = sortTypeIsChanging ? true : incomingCandidates ? sortCandAscending : !sortCandAscending;

    setCandidateInfo((prevState) => {
      const candidates = incomingCandidates ?? prevState;
      const sortedByAlphabet = sortAlphabetically(candidates);

      return sortedByAlphabet.sort(function(a, b) {
        if (a.Candidates4DateMedSchool < b.Candidates4DateMedSchool) {
          if (sortAscending) {
            return preventFlipSort ? 1 : -1;
          } else {
            return preventFlipSort ? -1 : 1;
          }
        }
        if (a.Candidates4DateMedSchool > b.Candidates4DateMedSchool) {
          if (sortAscending) {
            return preventFlipSort ? -1 : 1;
          } else {
            return preventFlipSort ? 1 : -1;
          }
        }
        return 0;
      });
    });

    if (preventFlipSort) return;

    // if we're just updating the candidate list don't change the sort direction
    if (!incomingCandidates) {
      if (sortTypeIsChanging) {
        setSortCandAscending(true);
      } else {
        setSortCandAscending((prevState) => !prevState);
      }
    }
    setCurrentCandidateSortType(candidateSortTypes.University);
  };

  const sortAlphabetically = (candidates, isIncompleteCandidates) => {
    return candidates.sort(function(a, b) {
      if (a.Candidates4DateNameLast < b.Candidates4DateNameLast) {
        return -1;
      } else if (a.Candidates4DateNameLast > b.Candidates4DateNameLast) {
        return 1;
      }
      return 0;
    });
  };

  const sortByEvalStatus = (incomingCandidates, isIncompleteCandidates, preventFlipSort) => {
    //if changing sort type always sort by descending instead of toggling
    const sortTypeIsChanging = currentCandidateSortType !== candidateSortTypes.EvalStatus;
    const sortAscending = sortTypeIsChanging ? true : incomingCandidates ? sortCandAscending : !sortCandAscending;

    setCandidateInfo((prevState) => {
      const candidates = incomingCandidates ?? prevState;

      const sortedByAlphabet = sortAlphabetically(candidates);

      return sortedByAlphabet.sort(function(a, b) {
        if (
          a.Candidates4DateEvaluationStatus.split(' ')[0].length <
          b.Candidates4DateEvaluationStatus.split(' ')[0].length
        ) {
          if (sortAscending) {
            return preventFlipSort ? 1 : -1;
          } else {
            return preventFlipSort ? -1 : 1;
          }
        }
        if (
          a.Candidates4DateEvaluationStatus.split(' ')[0].length >
          b.Candidates4DateEvaluationStatus.split(' ')[0].length
        ) {
          if (sortAscending) {
            return preventFlipSort ? -1 : 1;
          } else {
            return preventFlipSort ? 1 : -1;
          }
        }
        return 0;
      });
    });

    if (preventFlipSort) return;

    // if we're just updating the candidate list don't change the sort direction
    if (!incomingCandidates) {
      if (sortTypeIsChanging) {
        setSortCandAscending(true);
      } else {
        setSortCandAscending((prevState) => !prevState);
      }
    }

    setCurrentCandidateSortType(candidateSortTypes.EvalStatus);
  };

  const sortCandidates = (type, isIncompleteCandidates = false, preventFlipSort) => {
    let typeToUse = type || currentCandidateSortType;
    if (typeToUse === candidateSortTypes.Alphabetical) {
      sortCandidatesAlphabetically(null, isIncompleteCandidates, preventFlipSort);
    } else if (typeToUse === candidateSortTypes.EvalStatus) {
      sortByEvalStatus(null, isIncompleteCandidates, preventFlipSort);
    } else if (typeToUse === candidateSortTypes.University) {
      sortByUniversity(null, isIncompleteCandidates, preventFlipSort);
    }
  };

  const determineMessage = (eventObject) => {
    if (eventObject.Candidates4DateNameFull && eventObject.Candidates4DateNameFull !== '') {
      return eventObject.Candidates4DateNameFull;
    }
    if (eventObject.Schedules4EvalAltText && eventObject.Schedules4EvalAltText !== '') {
      return eventObject.Schedules4PortalAltText;
    }
    if (eventObject.TimeSlots4PortalAltText && eventObject.TimeSlots4PortalAltText !== '') {
      return eventObject.TimeSlots4PortalAltText;
    }
    return 'Break';
  };

  const addPassingPeriods = (result) => {
    for (var i = result.length - 1; i >= 0; i--) {
      const item = result[i];
      const passingPeriod = item.Schedules4EvalTimePassingPeriod;
      if (passingPeriod > 0) {
        const end = new Date(item.timeSlotEndObject);
        const endBeforePassing = new Date(end);
        end.setMinutes(end.getMinutes() - passingPeriod);
        const dateAsString = moment(end).format();
        const dateSplit = dateAsString.split('T');
        const time = dateSplit[1].split('-')[0];
        const dateSplitToReform = dateSplit[0].split('-');
        const dateReformed = `${dateSplitToReform[1]}/${dateSplitToReform[2]}/${dateSplitToReform[0]}`;
        item.Schedules4EvalTimeEnd = time;
        item.Schedules4EvalTimestampEnd = `${dateReformed} ${time}`;
        item.timeSlotEndObject = end;
        item.endTime = end;
        item.hasPassingPeriod = true;

        const passingDateAsString = moment(endBeforePassing).format();
        const passingDateSplit = passingDateAsString.split('T');
        const passingTime = passingDateSplit[1].split('-')[0];
        const passingDateSplitToReform = passingDateSplit[0].split('-');
        const passingDateReformed = `${passingDateSplitToReform[1]}/${passingDateSplitToReform[2]}/${passingDateSplitToReform[0]}`;
        const passingPeriodItem = {
          Schedules4EvalRoomNumber: '',
          Schedules4EvalAltText: '',
          Schedules4EvalTimeDuration: passingPeriod,
          isPassingPeriod: true,
          message: 'Passing Period',
          color: item.color,
          Schedules4EvalTime: time,
          Schedules4EvalTimePassingPeriod: passingPeriod,
          Schedules4EvalTimeEnd: passingTime,
          Schedules4EvalTimestamp: `${dateReformed} ${time}`,
          Schedules4EvalTimestampEnd: `${passingDateReformed} ${passingTime}`,
          time: end,
          endTime: endBeforePassing,
          timeSlotEndObject: endBeforePassing,
          timeSlotStartObject: end,
          TimeSlots4PortalVirtualRoomState: '',
          TimeSlots4Portal__pkUUID_Timeslot: '',
        };
        result.splice(i + 1, 0, passingPeriodItem);
      }
    }
    return result;
  };

  const extractFirstAndLastDayTime = (array) => {
    array.forEach((item, i) => {
      if (i === 0) {
        let beginning = new Date(item.timeSlotStartObject);
        beginning.setMinutes(beginning.getMinutes() - 30);
        setBeginningTimeMinus30Minutes(item.timeSlotStartObject);
      }
      if (i === array.length - 1) {
        let end = new Date(item.timeSlotEndObject);
        end.setMinutes(end.getMinutes + 30);
        setEndingTimePlus30Minutes(item.timeSlotEndObject);
      }
    });
  };

  const getInfo = (
    quietly,
    includeCandidateDetails = true,
    alarms,
    passedCurrentCandidateSortType = currentCandidateSortType,
    passedSortCandAscending = sortCandAscending,
    changeSortDirection = false,
  ) => {
    if (!quietly) setIsLoading(true);
    // setSelectedUser(null);
    if (date === 'Prescreen') {
      getPrescreen();
      return;
    }
    if (date && date.InterviewDatesVL__pkUUID_InterviewDate) {
      setInterviewDateUUID(date.InterviewDatesVL__pkUUID_InterviewDate);
      const bodyFormData = new FormData();
      bodyFormData.append('dateid', date.InterviewDatesVL__pkUUID_InterviewDate);
      postRequest(scheduleUrl, bodyFormData)
        .then(async (res) => {
          const {
            DeptSettingsVirtualizedMeetings,
            DeptSettingsHideCandidateHobbies,
            DeptSettingsHideCandidateLanguages,
            SeasonSelected__pkUUID_Season,
            DeptSettingsHideCandidateNames,
            DeptSettingsHideCandidatePhotos,
            CandidateDocumentsKey,
            DeptSettingsHideCandidateSchool,
            Candidates4Date,
            Candidates4PendingIncomplete,
          } = res.data;

          setDocumentKey(CandidateDocumentsKey);
          setSeasonUUID(SeasonSelected__pkUUID_Season);

          Candidates4Date.forEach((item) => {
            if (DeptSettingsHideCandidateNames === 1) {
              item.Candidates4DateNameFirst = 'Redacted';
              item.Candidates4DateNameFirstLast = 'Redacted Redacted';
              item.Candidates4DateNameFull = 'Redacted, Redacted';
              item.Candidates4DateNameLast = 'Redacted';
              item.Candidates4DateNameMiddle = 'Redacted';
              item.Candidates4DateNamePreferred = 'Redacted Redacted';
            }
            if (DeptSettingsHideCandidateSchool === 1) {
              item.Candidates4DateMedSchool = 'Redacted';
            }
            if (DeptSettingsHideCandidatePhotos === 1) {
              item.Candidates4DateExternalPhotoURL = null;
              item.Candidates4DatePhotoSM = null;
            }
          });

          Candidates4PendingIncomplete.forEach((item) => {
            if (DeptSettingsHideCandidateNames === 1) {
              item.Candidates4PendingIncompleteNameFirst = 'Redacted';
              item.Candidates4PendingIncompleteNameFirstLast = 'Redacted Redacted';
              item.Candidates4PendingIncompleteNameFull = 'Redacted, Redacted';
              item.Candidates4PendingIncompleteNameLast = 'Redacted';
              item.Candidates4PendingIncompleteNameMiddle = 'Redacted';
              item.Candidates4PendingIncompleteNamePreferred = 'Redacted Redacted';
            }
            if (DeptSettingsHideCandidateSchool === 1) {
              item.Candidates4PendingIncompleteMedSchool = 'Redacted';
            }
            if (DeptSettingsHideCandidatePhotos === 1) {
              item.Candidates4PendingIncompleteExternalPhotoURL = null;
              item.Candidates4PendingIncompletePhotoSM = null;
            }
          });

          if (DeptSettingsHideCandidateNames === 1) {
            setHideNames(true);
          }

          if (DeptSettingsHideCandidatePhotos === 1) {
            setHidePhotos(true);
          }

          if (DeptSettingsHideCandidateLanguages === 1) {
            setHideLanguages(true);
          }

          if (DeptSettingsHideCandidateSchool === 1) {
            setHideSchool(true);
          }
          if (DeptSettingsHideCandidateHobbies === 1) {
            setHideHobbies(true);
          }

          let meetingType;
          if (DeptSettingsVirtualizedMeetings === 1) {
            meetingType = MEETING_STATUS.Standard;
          }
          if (DeptSettingsVirtualizedMeetings === 2) {
            meetingType = MEETING_STATUS.AllJoinMeetingButtons;
          }
          if (DeptSettingsVirtualizedMeetings === 3) {
            meetingType = MEETING_STATUS.UseScheduleUrl;
          }
          if (DeptSettingsVirtualizedMeetings === 0) {
            meetingType = MEETING_STATUS.NoButtons;
          }

          // meetingType = MEETING_STATUS;

          setMeetingType(meetingType);

          setBreakUrl({ ...breakUrl, type: meetingType });

          setEvaluationsLocked(res.data.SeasonSelectedLockEvaluations === 1 ? true : false);

          if (res.data && res.data.DeptSettingsFirstBlockEarlyJoin) {
            setFirstBlockEarlyJoin(res.data.DeptSettingsFirstBlockEarlyJoin);
          }
          // Update the selected user info
          if (res.data && res.data.Candidates4Date && selectedUser) {
            setSelectedUser((prevState) => {
              for (let i = 0; i < res.data.Candidates4Date.length; i++) {
                const item = res.data.Candidates4Date[i];
                if (
                  prevState &&
                  item.Candidates4Date__pkUUID_Candidate === prevState.CandidateSelected__pkUUID_Candidate
                ) {
                  let prevStateCopy = {
                    ...prevState,
                  };

                  prevStateCopy.CandidateSelectedDNRStatus = item.Candidates4DateDNRStatus;
                  prevStateCopy.CandidateSelectedEvaluationStatus = item.Candidates4DateEvaluationStatus;
                  return prevStateCopy;
                }
              }
              return prevState;
            });
          }
          if (res.data.InterviewDateSelectedMainRoomURL && res.data.InterviewDateSelectedMainRoomURL !== '') {
            setZoomBreakRoom({
              Description: res.data.InterviewDateSelectedMainRoomDescription,
              zoomUrl: res.data.InterviewDateSelectedMainRoomURL,
            });
            // setZoomBreakRoom(res.data.InterviewDateSelectedMainRoomURL)
          }
          if (res.data.InterviewDateSelectedMainRoomDescription) {
            setMainRoomDescription(res.data.InterviewDateSelectedMainRoomDescription);
          }
          let adaptedTimeZone;
          let alertsWithFile = null;

          // wrap in a try catch so that if json is ever formatted incorrectly it doesn't break everything
          try {
            if (res && res.data && res.data.DeptSettingsAlerts) {
              alertsWithFile = handleSounds(JSON.parse(res.data.DeptSettingsAlerts));
            }
          } catch (err) {}

          if (alarms && alertsWithFile) {
            const currentAlarms = createAlarms(alertsWithFile, res.data.InterviewDateSelectedPassingPeriod);
            setAlarms(currentAlarms);
          }

          setIsLoading(false);
          const { Schedules4Eval, DepartmentsLocalTimestamp } = res.data;
          if (Candidates4PendingIncomplete) {
            setIncompleteCandidates(changeKeys(Candidates4PendingIncomplete));
          }
          if (Candidates4Date) {
            if (currentCandidateSortType === candidateSortTypes.Alphabetical) {
              sortCandidatesAlphabetically(Candidates4Date, changeSortDirection);
            } else if (currentCandidateSortType === candidateSortTypes.EvalStatus) {
              sortByEvalStatus(Candidates4Date);
            } else if (currentCandidateSortType === candidateSortTypes.University) {
              sortByUniversity(Candidates4Date);
            } else {
              setCandidateInfo(Candidates4Date);
            }
          }

          if (!Candidates4Date || !Schedules4Eval) return;

          res.data.Schedules4Eval = await filterRepeatItems(Schedules4Eval);
          await addColors(Schedules4Eval);
          adaptedTimeZone = await adaptToTimeZone(
            Schedules4Eval,
            DepartmentsLocalTimestamp,
            'Schedules4EvalTimestamp',
            'Schedules4EvalTimestampEnd',
            'Schedules4EvalTime',
            'Schedules4EvalTimeEnd',
            date.InterviewDatesVLDate,
          );

          adaptedTimeZone = await createDateObjects(res.data.Schedules4Eval);
          const result = await joinArrays(
            adaptedTimeZone,
            'Schedules4Eval_fkUUID_Candidate',
            Candidates4Date,
            'Candidates4Date__pkUUID_Candidate',
          );

          await result.forEach((item) => {
            if (item.timeSlotStartObject) {
              item.time = item.timeSlotStartObject;
              item.endTime = item.timeSlotEndObject;
            }
            if (item.Schedules4PortalTime) {
              item.time = new Date(date + ' ' + item.Schedules4PortalTime);
            }
            if (item.TimeSlots4PortalCustomAlerts && item.TimeSlots4PortalCustomAlerts !== '') {
              const alertAsJson = JSON.parse(item.TimeSlots4PortalCustomAlerts);
              alertAsJson.forEach((alert) => {
                alert['soundAsFile'] = determineMeetingSound(alert.sound);
              });
              item['alertsAsJson'] = alertAsJson;
              // item.alertsAsJson.forEach(alert => {
              // })
            }
            item.message = determineMessage(item);
          });

          await addColors(result);

          await addPassingPeriods(result, res.data.InterviewDateSelectedPassingPeriod);

          extractFirstAndLastDayTime(result);

          const keysToCheck = {
            Candidates4DateNameFirst: true,
            Schedules4EvalTime: true,
            Schedules4EvalTimeDuration: true,
            Schedules4EvalTimeEnd: true,
            Candidates4DateNameFull: true,
          };
          setSchedule((prevState) => {
            const arrayEqual = isArrayEqual(result, prevState, keysToCheck);

            // if there are differencesin the schedule inform the user
            const thereAreDifferences = Object.keys(arrayEqual).length > 0;
            if (thereAreDifferences) {
              Swal.fire({
                title: 'Schedule has been updated',
                text: 'changes will flash in red',
                onClose: () => {
                  setShowRed(true);
                  setTimeout(() => {
                    setShowRed(false);
                  }, 10 * 1000);
                },
              });
            }
            if (result && result.length && result[result.length - 1] && result[result.length - 1].endTime) {
              const endTime = result[result.length - 1].endTime;
              setScheduleEndTimePlus10Minutes(moment(endTime));
            }
            setDifferences(arrayEqual);
            return result;
          });
        })
        .catch((err) => {
          setError(true);
        });
    }
  };

  const requestZoomMeeting = (Schedules4Portal__pkUUID_Schedule) => {
    setDisableJoinMeetingButton(true);
    const url = `${constructFilemakerBaseUrl()}/evaluator/joinmeeting/?token=${getCorrectEvalToken(
      isAdmin,
    )}&scheduleblockid=${Schedules4Portal__pkUUID_Schedule}`;
    setPreventJoinMeetingClick(true);

    fetchDataWithoutCredentials(url)
      .then((res) => {
        if (res && res.data && res.data.errorDetail && res.data.errorDetail === 'No assigned Zoom account!') {
          alert('No room assigned');
        }
        if (res && res.data && res.data.joinURL && res.data.dialInNumbers) {
          handleZoomClick(res.data.joinURL, JSON.parse(res.data.dialInNumbers), res.data.password);
        }

        setPreventJoinMeetingClick(false);
      })
      .catch((err) => {
        setPreventJoinMeetingClick(false);
      })
      .finally(() => {
        setDisableJoinMeetingButton(false);
      });
  };

  const showJoinMeetingButton = (message, url, priorityUrl, Schedules4Portal__pkUUID_Schedule, item) => {
    // if (message === 'BREAK')
    //   return (
    //     <Fragment>
    //       <br />
    //       <Button
    //         color={meetingType === 2 ? 'success' : 'warning'}
    //         size="sm"
    //         onClick={(e) => {
    //           e.preventDefault();
    //           if (!demo) {
    //             handleZoomClick(allDayRoom);
    //           } else {
    //             Swal.fire('This is a demo!', 'This would normally join your meeting.');
    //           }
    //         }}
    //       >
    //         {meetingType === 2 ? 'Join Meeting' : 'Visit Break Room'}
    //       </Button>
    //     </Fragment>
    //   );
    // return (
    //   <Fragment>
    //     <br />
    //     <Button
    //       color="success"
    //       size="sm"
    //       disabled={preventJoinMeetingClick}
    //       style={{ minWidth: '95px' }}
    //       onClick={(e) => {
    //         e.preventDefault();
    //         if (!demo) {
    //           if (typeof priorityUrl !== 'undefined' && priorityUrl === '') {
    //             requestZoomMeeting(Schedules4Portal__pkUUID_Schedule);
    //           } else if (typeof priorityUrl !== 'undefined' && priorityUrl !== '') {
    //             handleZoomClick(priorityUrl);
    //           } else if (typeof url !== 'undefined' && url === '') {
    //             handleZoomClick(allDayRoom);
    //           } else if (typeof url !== 'undefined' && url !== '') {
    //             handleZoomClick(url);
    //           } else {
    //             handleZoomClick(allDayRoom);
    //           }
    //         } else {
    //           Swal.fire('This is a demo!', 'This would normally join your meeting.');
    //         }
    //       }}
    //     >
    //       {preventJoinMeetingClick ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Join Meeting'}
    //     </Button>
    //   </Fragment>
    // );
  };

  const getRequest = async (url) => {
    const result = await axios({
      url,
      method: 'GET',
      withCredentials: determineCredentials(),
      params: { dev: isDev() },
    });
    return result;
  };

  const postPrescreen = async (url) => {
    const bodyFormData = new FormData();
    bodyFormData.append('prescreenlist', 1);
    const result = await axios({
      url,
      method: 'POST',
      withCredentials: determineCredentials(),
      data: bodyFormData,
    });
    return result;
  };

  const postRequest = async (url, bodyFormData) => {
    const instance = axios.create({
      withCredentials: true,
    });
    const result = await instance({
      url,
      method: 'POST',
      withCredentials: determineCredentials(),
      params: { dev: isDev() },
      data: bodyFormData,
    });
    return result;
  };

  const getCandidateDetails = (id, quietly) => {
    const url = `${constructFilemakerBaseUrl()}/evaluator/candidate/`;
    if (!quietly) {
      setSelectedUser('fetching');
    }
    const bodyFormData = new FormData();
    bodyFormData.append('candidatedetails', 1);
    bodyFormData.append('candidateid', id);
    bodyFormData.append('mode', date === 'Prescreen' ? 'Prescreen' : 'Evaluation');
    postRequest(returnWithToken(url), bodyFormData)
      .then((res) => {
        if (hidePhotos) {
          res.data.CandidateSelectedExternalPhotoURL = null;
          res.data.CandidateSelectedPhoto = null;
          res.data.CandidateSelectedPhotoSM = null;
        }

        if (hideNames) {
          res.data.CandidateSelectedNameFirst = 'FirstName';
          res.data.CandidateSelectedNameFirstLast = 'FirstName LastName';
          res.data.CandidateSelectedNameFull = 'LastName, FirstName';
          res.data.CandidateSelectedNameLast = 'LastName';
          res.data.CandidateSelectedNameMiddle = 'MiddleName';
          res.data.CandidateSelectedNamePreferred = 'FirstName, LastName';
        }
        setSelectedUser(res.data);
      })
      .catch((err) => {});
  };

  const getCandidateEvaluation = (id) => {
    setCandidateEvaluation({});
    const url = `${constructFilemakerBaseUrl()}/evaluator/evaluation/?token=${getCorrectEvalToken(isAdmin)}`;
    setCurrentlGettingEval(true);
    const bodyFormData = new FormData();
    bodyFormData.append('evaluation', 1);
    bodyFormData.append('candidateid', id);
    bodyFormData.append('mode', date === 'Prescreen' ? 'Prescreen' : 'Evaluation');

    // if (candidateEvaluation && selectedCandidateId === candidateEvaluation.candidateUUID) {
    //   setCurrentlGettingEval(false);
    //   return;
    // }

    return postRequest(fullUrlV2, bodyFormData)
      .then((res) => {
        const { EvalPortal } = res.data;
        const payload = {};
        EvalPortal.forEach((item) => {
          const {
            Answers4Questions__pkUUID_Answer,
            Answers4QuestionsAnswerNumberScore,
            Answers4QuestionsAnswerText,
          } = item;
          let value = '';
          if (Answers4QuestionsAnswerText && Answers4QuestionsAnswerText !== '') {
            value = Answers4QuestionsAnswerText;
          }

          if (Answers4QuestionsAnswerNumberScore && Answers4QuestionsAnswerNumberScore !== '') {
            value = Answers4QuestionsAnswerNumberScore;
          }
          payload[Answers4Questions__pkUUID_Answer] = value;
        });

        const { AccessTokens_fkUUID_User } = userInformation;
        postDataAgnosticWithoutCredentials(
          'department/candidate/evaluation/initiation',
          {
            CandidateUUID: selectedCandidateId,
            EvaluatorUUID: AccessTokens_fkUUID_User,
            mode: date === 'Prescreen' ? 'Prescreen' : 'Evaluation',
          },
          { payload },
        )
          .then((results) => {
            const { AnswerJson } = results.data;

            res.data.EvalPortal.forEach((item) => {
              const { Answers4Questions__pkUUID_Answer } = item;

              if (AnswerJson[Answers4Questions__pkUUID_Answer]) {
                if (typeof AnswerJson[Answers4Questions__pkUUID_Answer] === 'string') {
                  item.Answers4QuestionsAnswerText = AnswerJson[Answers4Questions__pkUUID_Answer];
                } else {
                  item.Answers4QuestionsAnswerNumberScore = AnswerJson[Answers4Questions__pkUUID_Answer];
                }
              }
            });

            setCandidateEvaluation({ ...res.data, candidateUUID: selectedCandidateId });
            setCurrentlGettingEval(false);
          })
          .catch((err) => {
            return { error: true };
          });
        return res.data;
      })
      .catch((err) => {
        return { error: true };
      });
  };

  const filterRepeatItems = (schedule) => {
    let tempTimeEnd;
    let tempTimeStampEnd;
    let tempRecordId;
    let tempSchedules4EvalTimePassingPeriod;
    let tempTimeSlotEnd;
    for (var i = schedule.length - 1; i >= 0; i--) {
      const item = schedule[i];
      if (item.Schedules4Eval_fkUUID_Candidate === '') {
        // Check to see if previous item exists

        const previousItem = schedule[i - 1];
        if (previousItem && previousItem.Schedules4Eval_fkUUID_Candidate === '') {
          // if the previous item has these same attributes we can combine them into one

          const sameMessageAsPrevious = previousItem.Schedules4EvalAltText === item.Schedules4EvalAltText;
          const sameZoomLinkAsPrevious = previousItem.Schedules4EvalJoinManualURL === item.Schedules4EvalJoinManualURL;

          // if previous item matches with current item

          if (sameMessageAsPrevious && sameZoomLinkAsPrevious) {
            const nextItem = schedule[i + 1];
            if (nextItem) {
              //Prepare items to be compared

              const differentMessageAsNext = nextItem.Schedules4EvalAltText !== item.Schedules4EvalAltText;

              const differentZoomLinkAsNext = nextItem.Schedules4EvalJoinManualURL !== item.Schedules4EvalJoinManualURL;

              // if next item has a uuid for the candidate we can assume it's not in the list of items to be combined
              const nextItemHasCandidate = nextItem.Schedules4Eval_fkUUID_Candidate !== '';

              //if any items are different set current item to the temporary items

              if (differentMessageAsNext || differentZoomLinkAsNext || nextItemHasCandidate) {
                tempTimeEnd = item.Schedules4EvalTimeEnd;
                tempTimeStampEnd = item.Schedules4EvalTimestampEnd;
                tempRecordId = item.recordId;
                tempTimeSlotEnd = item.timeSlotEndObject;
                tempSchedules4EvalTimePassingPeriod = item.Schedules4EvalTimePassingPeriod;
              }

              // if this is the very last item then set them as the temporary item
            } else {
              tempTimeEnd = item.Schedules4EvalTimeEnd;
              tempTimeStampEnd = item.Schedules4EvalTimestampEnd;
              tempRecordId = item.recordId;
              tempTimeSlotEnd = item.timeSlotEndObject;
              tempSchedules4EvalTimePassingPeriod = item.Schedules4EvalTimePassingPeriod;
            }
            // use this later to filter out items (see below).  Can't do this now because each item must be aware of the previous and next item
            item.removeItem = true;

            // if current item does NOT match with current item
          } else {
            const nextItem = schedule[i + 1];
            if (nextItem && nextItem.Schedules4Eval_fkUUID_Candidate === '') {
              const sameMessageAsNext = nextItem.Schedules4EvalAltText === item.Schedules4EvalAltText;

              const sameZoomLinkAsNext = nextItem.Schedules4EvalJoinManualURL === item.Schedules4EvalJoinManualURL;

              if (sameMessageAsNext && sameZoomLinkAsNext) {
                item.Schedules4EvalTimeEnd = tempTimeEnd;
                item.Schedules4EvalTimestampEnd = tempTimeStampEnd;
                item.recordId = tempRecordId;
                item.timeSlotEndObject = tempTimeSlotEnd;
                item.Schedules4EvalTimePassingPeriod = tempSchedules4EvalTimePassingPeriod;
              }
            }
          }
        }
      }
    }
    schedule = schedule.filter((item) => {
      if (item.removeItem) {
        return false;
      }
      return true;
    });
    return schedule;
  };

  const getCandidateDocuments = (id) => {
    const url = 'department/season/candidate/documents';
    const { AccessTokens_fkUUID_Dept } = userInformation;

    fetchDataAgnosticWithoutCredentials(url, {
      candidateUUID: id,
      departmentUUID: AccessTokens_fkUUID_Dept,
      documentKey,
    })
      .then((res) => {
        setSelectedUser((prevState) => {
          if (typeof prevState === 'string' && prevState.toUpperCase() === 'FETCHING') return prevState;

          let copy = JSON.parse(JSON.stringify(prevState));
          const resultStringified = JSON.stringify(res.data);
          copy.CandidateSelectedDocumentJSON = resultStringified;

          return copy;
        });
      })
      .catch((err) => {});
  };

  const getCandidate = (id, chosenCandidate) => {
    // don't get the user if it's already the one selected
    if (selectedUser && selectedUser.CandidateSelected__pkUUID_Candidate === id) return;

    // to reduce server load candidate objects are being set from candidate array instead of making a second api
    // call to get more detailed candidate object
    const scoresAndAwardsParsed = JSON.parse(chosenCandidate.Candidates4DatejsonScoresAwards || '[]');
    const SAWKeyChange = scoresAndAwardsParsed.map((item) => {
      const { Title, Type, score, Visible, SortOrder } = item;
      return {
        SortOrder,
        recordId: null,
        ScoresAwardsCandTitle: Title,
        ScoresAwardsCandScore: score,
        modId: null,
        Visible,
      };
    });

    const selectedCandidateObject = {
      CandidateSelectedDNRStatus: chosenCandidate.Candidates4DateDNRStatus,
      CandidateSelectedEmail: chosenCandidate.Candidates4DateEmail,
      CandidateSelectedEvaluationStatus: chosenCandidate.Candidates4DateEvaluationStatus,
      CandidateSelectedExternalPhotoURL: chosenCandidate.Candidates4DateExternalPhotoURL,
      CandidateSelectedHobbies: chosenCandidate.Candidates4DateHobbies,
      CandidateSelectedLanguages: chosenCandidate.Candidates4DateLanguages,
      CandidateSelectedMedSchool: chosenCandidate.Candidates4DateMedSchool,
      CandidateSelectedMedicalDegree: chosenCandidate.Candidates4DateMedicalDegree,
      CandidateSelectedNameFirst: chosenCandidate.Candidates4DateNameFirst,
      CandidateSelectedNameFirstLast: chosenCandidate.Candidates4DateNameFirstLast,
      CandidateSelectedNameFull: chosenCandidate.Candidates4DateNameFull,
      CandidateSelectedNameLast: chosenCandidate.Candidates4DateNameLast,
      CandidateSelectedNameMiddle: chosenCandidate.Candidates4DateNameMiddle,
      CandidateSelectedNamePreferred: chosenCandidate.Candidates4DateNamePreferred,
      CandidateSelectedPhoto: null,
      CandidateSelectedPhotoSM: chosenCandidate.Candidates4DatePhotoSM,
      CandidateSelectedDateTags: chosenCandidate.Candidates4DateTags,
      CandidateSelectedDateTagsArray: chosenCandidate.Candidates4DateTagsArray,
      CandidateSelected__pkUUID_Candidate: chosenCandidate.Candidates4Date__pkUUID_Candidate,
      CandidateSelected_fkUUID_InterviewDate: chosenCandidate.Candidates4Date_fkUUID_InterviewDate,
      // CandidateSelected_gEvaluationMode: chosenCandidate.Candidates4Date_fkUUID_InterviewDate,
      SAPortal: SAWKeyChange,
    };

    setDNRStatus(chosenCandidate.Candidates4DateDNRStatus === 1 ? true : false);
    setSelectedUser(selectedCandidateObject);
    getCandidateDocuments(id);
    setSelectedCandidateId(id);

    // getCandidateDetails(id);
    // getCandidateEvaluation(id);
  };

  const determineDefaultDate = (dates) => {
    if (dates && dates.InterviewDatesVL) {
      // look for the marked default date
      for (let i = 0; i < dates.InterviewDatesVL.length; i++) {
        const item = dates.InterviewDatesVL[i];
        if (item.InterviewDatesVLDefault === 1 && item.InterviewDatesVLCandidateCount4Date !== 0) {
          return item;
        }
      }

      // if the default date can't be used find the first date in the past (or current day) that has candidates
      const rightNow = new Date(Date.now());
      for (var i = dates.InterviewDatesVL.length - 1; i >= 0; i--) {
        const item = dates.InterviewDatesVL[i];
        const interviewDate = new Date(new Date(item.InterviewDatesVLDate).setHours('00', '00', '00', '00'));
        if (rightNow > interviewDate && dates.InterviewDatesVL[i].InterviewDatesVLCandidateCount4Date !== 0) {
          return item;
        }
      }

      // if none of the above conditions are met just take the last item to not have zero candidates
      for (var i = dates.InterviewDatesVL.length - 1; i >= 0; i--) {
        const item = dates.InterviewDatesVL[i];
        if (dates.InterviewDatesVL[i].InterviewDatesVLCandidateCount4Date !== 0) {
          return item;
        }
      }
    }
  };

  useEffect(() => {
    getRequest(fullUrlV2).then((res) => {
      if (res && res.data) {
        const {
          Users4AccessTokenHideCandidateDocuments,
          Users4AccessTokenHideCandidateScores,
          Users4AccessTokenDisableCandidateEvaluations,
        } = res.data;
        if (Users4AccessTokenHideCandidateScores === 1) {
          setHideScoresAndAwards(true);
        }
        if (Users4AccessTokenHideCandidateDocuments === 1) {
          setHideDocuments(true);
        }

        if (Users4AccessTokenDisableCandidateEvaluations === 1) {
          setHideCandidateEvaluations(true);
        }
        setUser(res.data);
        setUserInformation(res.data);
      }
      if (res.data && res.data.RequireAgreement && res.data.RequireAgreement === 1) {
        setUserNeedsToSignAgreement(true);
      }
    });

    const bodyFormData = new FormData();
    bodyFormData.append('datelist', 1);
    postRequest(fullUrlV2, bodyFormData).then(async (res) => {
      if (res.data === 'expired') {
        removeCorrectEvalTOken(isAdmin, user, logout);
        setExpiredToken(true);
        return;
      }

      const defaultDate = determineDefaultDate(res.data);
      if (defaultDate && defaultDate.InterviewDatesVL__pkUUID_InterviewDate) {
        if (res.data && res.data.InterviewDatesVL) {
          const filteredDates = res.data.InterviewDatesVL.filter((item, i) => {
            if (item.InterviewDatesVLCandidateCount4Date && item.InterviewDatesVLCandidateCount4Date > 0) {
              return true;
            }

            return false;
          });

          res.data.InterviewDatesVL = filteredDates;
        }
        setDateArray(res.data);
        // setIsLoading(false);
      }

      if (res.data && res.data.SeasonSelectedMode) {
        if (res.data.SeasonSelectedMode === 'Prescreen') {
          setSeasonType('Prescreen');
          setDate('Prescreen');
        } else {
          setDateArray(res.data);
          setDate(defaultDate);
        }
      }
    });
    return;
  }, []);

  if (redirectToLogin) {
    return <Redirect to="/" />;
  }

  // TODO: create component to inform user their token is expired
  if (expiredToken) {
    // return <ExpiredToken />
    return <Redirect to="/" />;
  }

  // if user is an admin don't send them the agreement as they're probably acting under someone else
  if (userNeedsToSignAgreement && !isAdmin) {
    return <Redirect to="/agreement" />;
  }

  if (!token || expiredToken) {
    logout({
      returnTo: window.location.origin,
    });
    return <Redirect to="/" />;
  }
  if (!candidateInfo || !userInformation || isLoading || globalSettingsLoading) {
    return <LoadingDot />;
  }

  const determineContent = () => {
    if (isMobile) {
      return (
        <MobileComponent
          getCandidate={getCandidate}
          userInformation={userInformation}
          candidateEvaluation={candidateEvaluation}
          candidateInfo={candidateInfo}
          currentlyGettingEval={currentlyGettingEval}
          getCandidateEvaluation={getCandidateEvaluation}
          selectedCandidateId={selectedCandidateId}
          hideHobbies={hideHobbies}
          hideLanguages={hideLanguages}
          hideDocuments={hideDocuments}
          hideCandidateEvaluations={hideCandidateEvaluations}
          hideScoresAndAwards={hideScoresAndAwards}
          interviewIsToday={interviewIsToday}
          setShowLargePhoto={setShowLargePhoto}
          evaluationsLocked={evaluationsLocked}
          globalSettings={globalSettings}
          interviewDateUUID={interviewDateUUID}
          globalSettingsLoading={globalSettingsLoading}
          differences={differences}
          setShowRed={setShowRed}
          currentCandidateSortType={currentCandidateSortType}
          setDuration={setDuration}
          duration={duration}
          sortCandAscending={sortCandAscending}
          setPopupDescription={setPopupDescription}
          allDayRoom={allDayRoom}
          showRed={showRed}
          meetingType={meetingType}
          seasonUUID={seasonUUID}
          firstBlockEarlyJoin={firstBlockEarlyJoin}
          alarms={alarms}
          disableJoinMeetingButton={disableJoinMeetingButton}
          setDisableJoinMeetingButton={setDisableJoinMeetingButton}
          setAlarms={setAlarms}
          headerHeight={headerHeight}
          setSelectedUser={setSelectedUser}
          heightOfHeader={heightOfHeader}
          setLargePhoto={setLargePhoto}
          setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
          Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
          setSchedules4Eval__pkUUID_Schedule={setSchedules4Eval__pkUUID_Schedule}
          userIdOnCurrentInterview={userIdOnCurrentInterview}
          setCandidateEvaluation={setCandidateEvaluation}
          DNRStatus={DNRStatus}
          Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
          incompleteCandidates={incompleteCandidates}
          setSchedules4EvalJoinManualURL={setSchedules4EvalJoinManualURL}
          requestZoomMeeting={requestZoomMeeting}
          informUserMeetingIsStartingLocally={informUserMeetingIsStartingLocally}
          interviewHasPassed={interviewHasPassed}
          handleZoomClick={handleZoomClick}
          filterCandidateInfo={filterCandidateInfo}
          setEvalStatus={setEvalStatus}
          getInfo={getInfo}
          evalStatus={evalStatus}
          setDNRStatus={setDNRStatus}
          schedule={schedule}
          token={token}
          date={date}
          timeoutDurationInMinutes={userInformation.DeptSettingsEvalTimeout}
          selectedUser={selectedUser}
        />
      );
    } else {
      return (
        <BrowserComponent
          getCandidate={getCandidate}
          userInformation={userInformation}
          hideHobbies={hideHobbies}
          hideLanguages={hideLanguages}
          candidateInfo={candidateInfo}
          hideDocuments={hideDocuments}
          hideCandidateEvaluations={hideCandidateEvaluations}
          hideScoresAndAwards={hideScoresAndAwards}
          setShowRed={setShowRed}
          heightOfHeader={heightOfHeader}
          globalSettings={globalSettings}
          globalSettingsLoading={globalSettingsLoading}
          headerHeight={headerHeight}
          seasonUUID={seasonUUID}
          interviewDateUUID={interviewDateUUID}
          setDuration={setDuration}
          setSelectedUser={setSelectedUser}
          duration={duration}
          allDayRoom={allDayRoom}
          candidateSortTypes={candidateSortTypes}
          showRed={showRed}
          getInfo={getInfo}
          differences={differences}
          firstBlockEarlyJoin={firstBlockEarlyJoin}
          sortCandidates={sortCandidates}
          disableJoinMeetingButton={disableJoinMeetingButton}
          setPopupDescription={setPopupDescription}
          setDisableJoinMeetingButton={setDisableJoinMeetingButton}
          currentCandidateSortType={currentCandidateSortType}
          sortCandAscending={sortCandAscending}
          token={token}
          meetingType={meetingType}
          filterCandidateInfo={filterCandidateInfo}
          evaluationsLocked={evaluationsLocked}
          alarms={alarms}
          setAlarms={setAlarms}
          informUserMeetingIsStartingLocally={informUserMeetingIsStartingLocally}
          interviewIsToday={interviewIsToday}
          date={date}
          setEvalStatus={setEvalStatus}
          Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
          setSchedules4EvalJoinManualURL={setSchedules4EvalJoinManualURL}
          evalStatus={evalStatus}
          requestZoomMeeting={requestZoomMeeting}
          incompleteCandidates={incompleteCandidates}
          interviewHasPassed={interviewHasPassed}
          Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
          setSchedules4Eval__pkUUID_Schedule={setSchedules4Eval__pkUUID_Schedule}
          setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
          userIdOnCurrentInterview={userIdOnCurrentInterview}
          handleZoomClick={handleZoomClick}
          DNRStatus={DNRStatus}
          setDNRStatus={setDNRStatus}
          setEvalStatus={setEvalStatus}
          setCandidateEvaluation={setCandidateEvaluation}
          evalStatus={evalStatus}
          selectedCandidateId={selectedCandidateId}
          setShowLargePhoto={setShowLargePhoto}
          setLargePhoto={setLargePhoto}
          candidateEvaluation={candidateEvaluation}
          currentlyGettingEval={currentlyGettingEval}
          schedule={schedule}
          timeoutDurationInMinutes={userInformation.DeptSettingsEvalTimeout}
          getCandidateEvaluation={getCandidateEvaluation}
          selectedUser={selectedUser}
        />
      );
    }
  };
  return (
    <Col>
      <NotificationContainer />
      <form ref={zoomForm} id="zoom_form_eval" target="_blank"></form>
      {zoomPopupJSX(
        showZoomLink,
        setShowZoomLink,
        zoomLink,
        setCopied,
        copied,
        zoomInfo,
        zoomPassword,
        popupDescription,
        meetingId,
      )}
      {determineContent()}
    </Col>
  );
};

export default MobileFork;
