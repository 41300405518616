import React from 'react';
import Moment from 'react-moment';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ListGroupItem } from 'reactstrap';
import styled from 'styled-components';
import blueHamburger from './assets/images/icons/blueHamburger.png';

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DragableListGroupItem = styled(ListGroupItem)`
  display: flex;
  & * {
    white-space: nowrap;
  }
`;

const DragableListGroupItemWithBackground = styled(ListGroupItem)`
  background-color: rgb(195, 80, 74) !important;
  color: white !important;
  & * {
    white-space: nowrap;
  }
`;

const CustomUL = styled.ul`
  padding: 0;
`;

const Hamburger = styled.img`
  cursor: move;
`;

const DragHandle = SortableHandle(() => <Hamburger src={blueHamburger} />);

// sorts an array of dates
const sortDates = (arrayOfDates) => {
  return arrayOfDates.sort((a, b) => {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return new Date(a.date) - new Date(b.date);
  });
};

const sortByLastName = (arrayOfObjects) => {
  const alphabetical = arrayOfObjects.sort((a, b) => {
    if (a.lastName < b.lastName) {
      return -1;
    }
    if (a.lastName > b.lastName) {
      return 1;
    }
    return 0;
  });
  return alphabetical;
};

// inserts new date into EXISTING sorted array in the correct place from earliest date to latest
const insertDate = (array, itemToBeInserted) => {
  const newArray = array.slice();
  for (let i in newArray) {
    if (new Date(newArray[i].date).getTime() > new Date(itemToBeInserted.date).getTime()) {
      newArray.splice(i, 0, itemToBeInserted);
      return newArray;
    }
  }
  newArray.splice(newArray.length, 0, itemToBeInserted);
  return newArray;
};

const insertNumber = (array, numToBeInserted, indexToStartAt = 0) => {
  const newArray = array.slice();
  for (let i = indexToStartAt; i < array.length; i++) {
    if (newArray[i] > numToBeInserted) {
      newArray.splice(i, 0, numToBeInserted);
      return newArray;
    }
  }
  newArray.splice(newArray.length, 0, numToBeInserted);
  return newArray;
};

// adds the correct suffix to a number (i.e. 1st, 2nd, 3rd, 4th)
const ordinal_suffix_of = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return `${i}st`;
  }
  if (j == 2 && k != 12) {
    return `${i}nd`;
  }
  if (j == 3 && k != 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};
const SortableItem = SortableElement(({ value, index, toggleOrSetSelectedItem }) => (
  <DragableListGroupItem
    tag="a"
    className="transition"
    id={value.id}
    style={{ backgroundColor: '#404040', color: 'white' }}
    onClick={() => toggleOrSetSelectedItem(value.id, index)}
  >
    {ordinal_suffix_of(index + 1)} &nbsp; &nbsp; &nbsp;
    <Moment format="ddd, MMM DD, YYYY">{value.date}</Moment>
    &nbsp; &nbsp; &nbsp;
    <DragHandle />
  </DragableListGroupItem>
));

const SortableItemWithBackground = SortableElement(({ value, index, toggleOrSetSelectedItem }) => (
  // <DragableListGroupItemWithBackground tag="a" id={value.id} onClick={() => toggleOrSetSelectedItem(value.id, index)}>
  //   <DragHandle />
  //   &nbsp; &nbsp; &nbsp;
  //   <Moment format="ddd, MMM DD, YYYY">{value.date}</Moment>
  // </DragableListGroupItemWithBackground>
  <DragableListGroupItem
    tag="a"
    id={value.id}
    style={{ backgroundColor: '#404040', color: 'white' }}
    onClick={() => toggleOrSetSelectedItem(value.id, index)}
  >
    {ordinal_suffix_of(index + 1)} &nbsp; &nbsp; &nbsp;
    <Moment format="ddd, MMM DD, YYYY">{value.date}</Moment>
    &nbsp; &nbsp; &nbsp;
    <DragHandle />
  </DragableListGroupItem>
));

const SortableList = SortableContainer(({ items, toggleOrSetSelectedItem, selectedItem }) => {
  return (
    <CustomUL>
      {items.map((value, index) => {
        if (value.id != selectedItem) {
          return (
            <SortableItem
              key={`item-${index}`}
              id={value.id}
              index={index}
              value={value}
              toggleOrSetSelectedItem={toggleOrSetSelectedItem}
            />
          );
        } else {
          return (
            <SortableItem
              key={`item-${index}`}
              id={value.id}
              index={index}
              value={value}
              toggleOrSetSelectedItem={toggleOrSetSelectedItem}
            />
          );
        }
      })}
    </CustomUL>
  );
});

export { SortableItem, SortableList, ordinal_suffix_of, insertDate, sortDates, insertNumber, sortByLastName };
