import { faCaretDown, faCaretUp, faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, TabContent, TabPane } from 'reactstrap';
import Swal from 'sweetalert2';
import { informUserEvalLocked } from '../../../../Common.functions';
import CandidatesAlphabetical from './CandidatesAlphabetical';
import Evaluation from './Evaluation';
import Portfolio from './Portfolio';
import Schedule from './Schedule';

const OFFSET = 65;
const OFFSET_OLD = 201;

const verticalLine = { height: '100%', borderRight: '1px solid #000000', width: '1px' };

const EvaluateBrowser = ({
  userInformation,
  getCandidate,
  selectedUser,
  candidateInfo,
  differences,
  headerHeight,
  token,
  disableJoinMeetingButton,
  setDisableJoinMeetingButton,
  currentlyGettingEval,
  informUserMeetingIsStartingLocally,
  DNRStatus,
  setCandidateEvaluation,
  setDuration,
  duration,
  alarms,
  setAlarms,
  globalSettings,
  globalSettingsLoading,
  setSelectedUser,
  setDNRStatus,
  schedule,
  seasonUUID,
  candidateSortTypes,
  hideDocuments,
  hideCandidateEvaluations,
  hideScoresAndAwards,
  interviewDateUUID,
  setShowRed,
  meetingType,
  showRed,
  currentCandidateSortType,
  postRequest,
  filterCandidateInfo,
  requestZoomMeeting,
  sortCandAscending,
  hideHobbies,
  hideLanguages,
  Schedules4Eval__pkUUID_Schedule,
  Schedules4EvalJoinManualURL,
  interviewIsToday,
  setSchedules4EvalJoinManualURL,
  setSchedules4Eval__pkUUID_Schedule,
  incompleteCandidates,
  handleZoomClick,
  allDayRoom,
  evaluationsLocked,
  setShowLargePhoto,
  setEvalStatus,
  setUserIdOnCurrentInterview,
  sortCandidates,
  firstBlockEarlyJoin,
  setPopupDescription,
  interviewHasPassed,
  userIdOnCurrentInterview,
  evalStatus,
  getInfo,
  setLargePhoto,
  timeoutDurationInMinutes,
  date,
  getCandidateEvaluation,
  selectedCandidateId,
  heightOfHeader,
  candidateEvaluation,
}) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [hasSchedule, setHasSchedule] = useState(null);
  const [chosenCandidate, setChosenCandidate] = useState({ uniqueId: null, candidate: null });
  const [error, setError] = useState(false);
  const [candidatesAttentionNeeded, setCandidatesAttentionNeeded] = useState([]);
  const [disableScreen, setDisableScreen] = useState(false);
  const [previousStatus, setPreviousStatus] = useState(null);
  const [renderEval, setRenderEval] = useState(false);
  const [activeTab, setActiveTab] = useState();
  const [disableEvaluation, setDisableEvaluation] = useState(true);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    if (candidateInfo) setCandidatesAttentionNeeded(filterCandidateInfo(candidateInfo));
  }, [candidateInfo]);

  const getDefaultTab = () => {
    if (date === 'Prescreen') {
      toggle('2');
      return;
    }
    if (hasSchedule) {
      toggle('1');
    } else {
      toggle('2');
    }
  };

  useEffect(() => {
    if (hasSchedule !== null) getDefaultTab();
  }, [date, hasSchedule]);

  useEffect(() => {
    if ((!selectedUser && disableEvaluation === false) || selectedUser === 'fetching') {
      setDisableEvaluation(true);
    } else if (selectedUser && selectedUser !== 'fetching' && disableEvaluation === true) {
      setDisableEvaluation(false);
    }
    return;
  }, [selectedUser]);

  useEffect(() => {
    if (schedule && schedule.length > 0) {
      setHasSchedule(true);
    } else {
      setHasSchedule(false);
    }
  }, [schedule]);

  const changeEvalTab = () => {
    setRenderEval(false);
    setDisableScreen(true);
    toggle('3');
    return getCandidateEvaluation(selectedUser.CandidateSelected__pkUUID_Candidate)
      .then((res) => {
        if (!res.error) {
          return true;
        } else {
          setError(true);
          return false;
        }
      })
      .finally(() => {
        setDisableScreen(false);
        setRenderEval(true);
      });
  };

  const renderSort = (isIncompleteCandidates = false) => {
    const isAlphabet = currentCandidateSortType === candidateSortTypes.Alphabetical;
    const isEval = currentCandidateSortType === candidateSortTypes.EvalStatus;
    const isUniversity = currentCandidateSortType === candidateSortTypes.University;

    return (
      <div style={{ display: 'flex', justifyContent: 'space-evenly', paddingTop: '5px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <h6 style={{ margin: 0 }}>Sort By:</h6>
        </div>
        <div id="candidate_sort">
          <div
            className={`${isAlphabet ? 'candidate_sort_selected' : ''} hover_blue`}
            onClick={() => {
              sortCandidates(candidateSortTypes.Alphabetical, isIncompleteCandidates);
            }}
          >
            <h6>
              {isAlphabet && (
                <span style={{ paddingRight: '5px' }}>
                  <FontAwesomeIcon icon={sortCandAscending ? faCaretDown : faCaretUp} />
                </span>
              )}
              Alphabetical
            </h6>
          </div>
          <div
            className={`${isEval ? 'candidate_sort_selected' : ''} hover_blue`}
            onClick={() => {
              sortCandidates(candidateSortTypes.EvalStatus, isIncompleteCandidates);
            }}
          >
            <h6>
              {isEval && (
                <span style={{ paddingRight: '5px' }}>
                  <FontAwesomeIcon icon={sortCandAscending ? faCaretDown : faCaretUp} />
                </span>
              )}
              Evaluation Status
            </h6>
          </div>
          <div
            className={`${isUniversity ? 'candidate_sort_selected' : ''} hover_blue`}
            onClick={() => {
              sortCandidates(candidateSortTypes.University, isIncompleteCandidates);
            }}
          >
            <h6>
              {isUniversity && (
                <span style={{ paddingRight: '5px' }}>
                  <FontAwesomeIcon icon={sortCandAscending ? faCaretDown : faCaretUp} />
                </span>
              )}
              University
            </h6>
          </div>
        </div>
      </div>
    );
  };

  const getEvalAndChangeTab = () => {
    if (selectedUser.CandidateSelectedEvaluationStatus === 'Complete') {
      return Swal.fire({
        title: 'Do you want to modify this evaluation?',
        text: `Status will be set to "In progress."`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Modify',
      }).then((result) => {
        if (result.value === true) {
          // need to put current user to in progress
          setSelectedUser((prevState) => {
            // eslint-disable-next-line no-undef
            const clone = structuredClone(prevState);
            clone.CandidateSelectedEvaluationStatus = 'In progress';
            return clone;
          });
          return changeEvalTab();
        }
      });
    } else {
      return changeEvalTab();
    }
  };
  return (
    <Row>
      <Col sm="8" className="frame tab_wrapper">
        <div
          className="overlay"
          style={{
            position: 'absolute',
            visibility: disableScreen ? 'visible' : 'hidden',
            height: '100%',
            width: '100%',
            top: 0,
            backgroundColor: 'rgba(5, 5, 5, 0.5)',
            zIndex: 15,
          }}
        >
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            color="#fff"
            size={'3x'}
            style={{ position: 'relative', left: '50%', top: '50%' }}
          />
        </div>
        <div
          style={{
            maxHeight: `calc(100vh - ${heightOfHeader + OFFSET}px)`,
            overflowY: 'scroll',
            border: '1px solid #000000',
          }}
        >
          <div>
            <Row style={{ width: '100%', top: '0', zIndex: 10, margin: 0 }} className="sticky">
              <Col md="12" style={{ padding: '0' }}>
                <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'space-evenly' }}>
                  {hasSchedule && date !== 'Prescreen' && (
                    <Fragment>
                      <div
                        className={activeTab === '1' ? 'selectedTab' : 'unselectedTab'}
                        style={{
                          // borderRight: '1px solid black',
                          width: '100%',
                          padding: '17px',
                          textAlign: 'center',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          if (activeTab !== '1') {
                            toggle('1');
                          }
                        }}
                      >
                        <h5 style={{ margin: 0 }}>My Schedule</h5>
                      </div>
                      <div style={verticalLine} />
                    </Fragment>
                  )}
                  <div
                    className={activeTab === '2' ? 'selectedTab' : 'unselectedTab'}
                    style={{
                      // borderRight: '1px solid black',
                      width: '100%',
                      padding: '17px',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (activeTab !== '2') {
                        toggle('2');
                      }
                    }}
                  >
                    <h5 style={{ margin: 0 }}>{candidateInfo.length} Candidates</h5>
                  </div>
                  <div style={verticalLine} />
                  <div
                    className={
                      (activeTab === '3' ? 'selectedTab ' : 'unselectedTab ') +
                      (disableEvaluation || evaluationsLocked || hideCandidateEvaluations ? 'disable-tab' : '')
                    }
                    style={{
                      // borderRight: '1px solid black',
                      width: '100%',
                      padding: '17px',
                      textAlign: 'center',
                      cursor: evaluationsLocked ? 'default' : 'pointer',
                    }}
                    onClick={() => {
                      if (evaluationsLocked) {
                        informUserEvalLocked();
                        return;
                      }

                      if (disableEvaluation || hideCandidateEvaluations) return;

                      getEvalAndChangeTab();
                    }}
                  >
                    <h5 style={{ margin: 0 }}>
                      {evaluationsLocked && (
                        <span>
                          <FontAwesomeIcon icon={faLock} />{' '}
                        </span>
                      )}
                      Evaluation
                    </h5>
                  </div>
                  {date !== 'Prescreen' &&
                    incompleteCandidates &&
                    incompleteCandidates.length > 0 &&
                    !evaluationsLocked && (
                      <Fragment>
                        <div style={verticalLine} />
                        <div
                          className={activeTab === '4' ? 'selectedTab' : 'unselectedTab'}
                          style={{
                            // borderRight: '1px solid black',
                            width: '100%',
                            padding: '17px',
                            textAlign: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (activeTab !== '4') {
                              toggle('4');
                            }
                          }}
                        >
                          <h5 style={{ margin: 0 }}>{incompleteCandidates.length} Attention Needed</h5>
                        </div>
                      </Fragment>
                    )}
                </div>
              </Col>
            </Row>
            <Row
              style={{
                width: '100%',
                margin: 0,
              }}
            >
              <Col md="12" style={{ padding: 0 }}>
                <TabContent activeTab={activeTab} style={{ width: '100%' }}>
                  {schedule && (
                    <TabPane tabId="1">
                      <Schedule
                        date={date}
                        alarms={alarms}
                        informUserMeetingIsStartingLocally={informUserMeetingIsStartingLocally}
                        setAlarms={setAlarms}
                        userInformation={userInformation}
                        interviewDateUUID={interviewDateUUID}
                        firstBlockEarlyJoin={firstBlockEarlyJoin}
                        getCandidate={getCandidate}
                        disableJoinMeetingButton={disableJoinMeetingButton}
                        setDisableJoinMeetingButton={setDisableJoinMeetingButton}
                        schedule={schedule}
                        setSelectedUser={setSelectedUser}
                        setChosenCandidate={setChosenCandidate}
                        globalSettings={globalSettings}
                        hideCandidateEvaluations={hideCandidateEvaluations}
                        setDuration={setDuration}
                        duration={duration}
                        globalSettingsLoading={globalSettingsLoading}
                        setPopupDescription={setPopupDescription}
                        setSchedules4EvalJoinManualURL={setSchedules4EvalJoinManualURL}
                        chosenCandidate={chosenCandidate}
                        setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
                        userIdOnCurrentInterview={userIdOnCurrentInterview}
                        disableJoinMeetingButton={disableJoinMeetingButton}
                        setDisableJoinMeetingButton={setDisableJoinMeetingButton}
                        Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
                        setSchedules4Eval__pkUUID_Schedule={setSchedules4Eval__pkUUID_Schedule}
                        meetingType={meetingType}
                        postRequest={postRequest}
                        requestZoomMeeting={requestZoomMeeting}
                        setShowRed={setShowRed}
                        Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
                        showRed={showRed}
                        interviewIsToday={interviewIsToday}
                        handleZoomClick={handleZoomClick}
                        differences={differences}
                        selectedUser={selectedUser}
                      />
                    </TabPane>
                  )}
                  <TabPane tabId="2">
                    {renderSort()}
                    <CandidatesAlphabetical
                      selectedUser={selectedUser}
                      getCandidate={getCandidate}
                      interviewIsToday={interviewIsToday}
                      Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
                      setChosenCandidate={setChosenCandidate}
                      disableJoinMeetingButton={disableJoinMeetingButton}
                      setDisableJoinMeetingButton={setDisableJoinMeetingButton}
                      setDNRStatus={setDNRStatus}
                      hideCandidateEvaluations={hideCandidateEvaluations}
                      chosenCandidate={chosenCandidate}
                      setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
                      userIdOnCurrentInterview={userIdOnCurrentInterview}
                      Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
                      setSchedules4Eval__pkUUID_Schedule={setSchedules4Eval__pkUUID_Schedule}
                      requestZoomMeeting={requestZoomMeeting}
                      handleZoomClick={handleZoomClick}
                      candidateInfo={candidateInfo}
                      postRequest={postRequest}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    {/* renderEval helps reset Eval when a new user's eval is fetched.  This prevents a previous users answers from being displayed in the UI since
                this is remounting the component */}
                    {/* {true && (
                      <div
                        style={{
                          height: 'calc(100vh - 262px)',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesomeIcon icon={faSpinner} spin />
                      </div>
                    )} */}

                    {/* {!disableEvaluation && !renderEval && <div>yo</div>} */}
                    {!disableEvaluation && renderEval && !evaluationsLocked && !hideCandidateEvaluations && (
                      <Evaluation
                        candidateEvaluation={candidateEvaluation}
                        candidateInfo={candidateInfo}
                        selectedCandidateId={selectedCandidateId}
                        timeoutDurationInMinutes={timeoutDurationInMinutes}
                        evalTab="3"
                        setActiveTab={setActiveTab}
                        setSelectedUser={setSelectedUser}
                        candidateSortTypes={candidateSortTypes}
                        currentCandidateSortType={currentCandidateSortType}
                        DNRStatus={DNRStatus}
                        evaluationsLocked={evaluationsLocked}
                        setDNRStatus={setDNRStatus}
                        userInformation={userInformation}
                        schedule={schedule}
                        setDisableScreen={setDisableScreen}
                        date={date}
                        toggleToSchedule={() => {
                          if (schedule && schedule.length) {
                            setActiveTab('1');
                          } else {
                            setActiveTab('2');
                          }
                        }}
                        setEvalStatus={setEvalStatus}
                        getInfo={getInfo}
                        evalStatus={evalStatus}
                        selectedUser={selectedUser}
                        token={token}
                        setCandidateEvaluation={setCandidateEvaluation}
                        typeOfEvent="mousemove"
                        activeTab={activeTab}
                        currentlyGettingEval={currentlyGettingEval}
                        getCandidateEvaluation={getCandidateEvaluation}
                        Status4SelectedDNR={candidateEvaluation ? candidateEvaluation.Status4SelectedDNR : false}
                      />
                    )}
                  </TabPane>
                  {date !== 'Prescreen' && incompleteCandidates && incompleteCandidates.length > 0 && (
                    <TabPane tabId="4">
                      {renderSort(true)}
                      <Row>
                        <Col md="12">
                          <CandidatesAlphabetical
                            selectedUser={selectedUser}
                            getCandidate={getCandidate}
                            interviewIsToday={interviewIsToday}
                            Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
                            hideShowMeetingButton={true}
                            disableJoinMeetingButton={disableJoinMeetingButton}
                            setDisableJoinMeetingButton={setDisableJoinMeetingButton}
                            setDNRStatus={setDNRStatus}
                            setChosenCandidate={setChosenCandidate}
                            chosenCandidate={chosenCandidate}
                            hideCandidateEvaluations={hideCandidateEvaluations}
                            setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
                            userIdOnCurrentInterview={userIdOnCurrentInterview}
                            Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
                            Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
                            setSchedules4Eval__pkUUID_Schedule={setSchedules4Eval__pkUUID_Schedule}
                            requestZoomMeeting={requestZoomMeeting}
                            handleZoomClick={handleZoomClick}
                            candidateInfo={incompleteCandidates}
                            postRequest={postRequest}
                          />
                        </Col>
                      </Row>
                    </TabPane>
                  )}
                </TabContent>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
      <Col sm="4">
        <div
          className="frame sticky"
          style={{ top: '0px', overflowY: 'scroll', maxHeight: `calc(100vh - ${heightOfHeader + OFFSET}px)` }}
        >
          <Portfolio
            selectedUser={selectedUser}
            setCarouselIndex={setCarouselIndex}
            setShowLargePhoto={setShowLargePhoto}
            disableJoinMeetingButton={disableJoinMeetingButton}
            setDisableJoinMeetingButton={setDisableJoinMeetingButton}
            hideCandidateEvaluations={hideCandidateEvaluations}
            DNRStatus={DNRStatus}
            getEvalAndChangeTab={() => getEvalAndChangeTab()}
            hideHobbies={hideHobbies}
            hideLanguages={hideLanguages}
            activeTab={activeTab}
            seasonUUID={seasonUUID}
            setDNRStatus={setDNRStatus}
            setDuration={setDuration}
            duration={duration}
            interviewDateUUID={interviewDateUUID}
            hideDocuments={hideDocuments}
            hideScoresAndAwards={hideScoresAndAwards}
            globalSettings={globalSettings}
            globalSettingsLoading={globalSettingsLoading}
            userInformation={userInformation}
            evaluationsLocked={evaluationsLocked}
            interviewIsToday={interviewIsToday}
            allDayRoom={allDayRoom}
            requestZoomMeeting={requestZoomMeeting}
            meetingType={meetingType}
            handleZoomClick={handleZoomClick}
            userIdOnCurrentInterview={userIdOnCurrentInterview}
            Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
            Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
            setLargePhoto={setLargePhoto}
            carouselIndex={carouselIndex}
            Status4SelectedDNR={candidateEvaluation ? candidateEvaluation.Status4SelectedDNR : false}
          />
        </div>
      </Col>
    </Row>
  );
};

export default EvaluateBrowser;
