import { faAsterisk, faCheck, faEye, faEyeSlash, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { determineCredentials } from '../../Common.functions';
import Error from '../Statuses/Error';
import Loading from '../Statuses/Loading';

const doesNotContainsName = (value, name) => {
  let containsName = true;
  if (name.length > 0) {
    const nameSplit = name.split(' ');
    nameSplit.forEach((partOfName) => {
      if (value.toUpperCase().includes(partOfName.toUpperCase())) {
        containsName = false;
      }
    });
    return containsName;
  } else {
    return true;
  }
};

const Reset = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [nameLocal, setNameLocal] = useState('');
  const [password1Blurred, setPassword1Blurred] = useState(false);
  const [password2Blurred, setPassword2Blurred] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [passwordIsVisible, setPasswordIsVisible] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [hasNumber, setHasNumber] = useState(false);
  const [doesNotHaveName, setDoesNotHaveName] = useState(true);
  const [has8Characters, setHas8Characters] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const validatePassword = (value) => {
    setHasUppercase(/[A-Z]/.test(value));
    setHasLowercase(/[a-z]/.test(value));
    setHasNumber(/\d/.test(value));
    setHas8Characters(value.length >= 8);
    setDoesNotHaveName(doesNotContainsName(value, nameLocal));
  };

  const postData = () => {
    setDisableButton(true);
    const token = props.match.params.id;
    const bodyFormData = new FormData();
    bodyFormData.append('password1', password1);
    bodyFormData.append('password2', password2);
    bodyFormData.append('token', token);
    axios({
      method: 'post',
      url: 'https://tools.rezrate.com/rezrate/api/login/',
      data: bodyFormData,
      withCredentials: determineCredentials(),
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => {
        if (res.data && res.data.message && res.data.message === 'Unable to change password.') {
          Swal.fire({
            title: 'Something went wrong...',
            text: `Unable to accept password. Please make sure it meets the requirements. If you continue to experience this problem, please try a different password. If that doesn't resolve the issue, please contact support.`,
            onClose: () => {
              setPassword1Blurred(false);
              setPassword2Blurred(false);
              setPassword1('');
              setPassword2('');
              validatePassword('');
            },
          });
        } else {
          Swal.fire({
            title: 'Password has been changed',
            text: 'redirecting to login',
            onClose: () => {
              props.setShowName(false);
              props.history.push('/');
            },
          });
        }
      })
      .catch((err) => {})
      .finally(() => {
        setDisableButton(false);
      });
  };

  const validateForm = (e) => {
    e.preventDefault();
    let message = [];
    if (!hasUppercase) {
      message.push('Must contain one uppercase character');
    }
    if (!hasLowercase) {
      message.push('Must contain one lowercase character');
    }
    if (!hasNumber) {
      message.push('Must contain one number');
    }
    if (!has8Characters) {
      message.push('Must contain 8 characters');
    }
    if (!doesNotHaveName) {
      message.push('Must NOT include your name');
    }

    if (message.length > 0) {
      let htmlMessage = '';
      message.forEach((item) => {
        htmlMessage = htmlMessage + `<div>${item}</div>`;
      });
      Swal.fire('please correct errors', `<div style="list-style: none;">${htmlMessage}</div>`, 'error');
      setPassword1Blurred(true);
      setPassword2Blurred(true);
      return;
    }

    if (password1 !== password2) {
      Swal.fire('please correct errors', 'passwords do not match', 'error');
      setPassword1Blurred(true);
      setPassword2Blurred(true);
      return;
    }
    postData();
  };

  useEffect(() => {
    const token = props.match.params.id;
    const bodyFormData = new FormData();
    bodyFormData.append('token', token);
    axios({
      method: 'post',
      url: 'https://tools.rezrate.com/rezrate/api/login/',
      data: bodyFormData,
      headers: {
        'content-type': 'application/json',
      },
    })
      .then((res) => {
        if (res.data.result === '200') {
          setNameLocal(res.data.name);
          props.setName(res.data.name);
          props.setShowName(true);
          setLoading(false);
        } else {
          props.setExpiredToken(true);
          props.history.push('/reset');
        }
      })
      .catch((err) => {
        setError(true);
      });
  }, []);
  if (error) {
    return <Error />;
  }
  if (loading) {
    return <Loading header="Validating token" subHeader="One moment..." />;
  }

  return (
    <div>
      <Row
        style={{
          width: '100%',
          margin: 0,
          backgroundColor: '#cce5ff',
          borderColor: '#b8daff',
          color: '#004085',
          height: '35px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Col lg={{ size: 4, offset: 4 }}>Password Requirements:</Col>
      </Row>
      <div style={{ padding: '15px 30px 0 30px', height: '100%' }}>
        <Row>
          <Col lg={{ size: 4, offset: 4 }}>
            <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
              <li style={{ color: !hasUppercase ? 'red' : 'green' }}>
                <FontAwesomeIcon icon={!hasUppercase ? faTimes : faCheck} size={'sm'} />
                &nbsp; At least one uppercase character
              </li>
              <li style={{ color: !hasLowercase ? 'red' : 'green' }}>
                <FontAwesomeIcon icon={!hasLowercase ? faTimes : faCheck} size={'sm'} />
                &nbsp; At least one lowercase character
              </li>
              <li style={{ color: !hasNumber ? 'red' : 'green' }}>
                <FontAwesomeIcon icon={!hasNumber ? faTimes : faCheck} size={'sm'} />
                &nbsp; At least one number
              </li>
              <li style={{ color: !has8Characters ? 'red' : 'green' }}>
                <FontAwesomeIcon icon={!has8Characters ? faTimes : faCheck} size={'sm'} />
                &nbsp; Minimum of 8 characters
              </li>
              <li style={{ color: !doesNotHaveName ? 'red' : 'green' }}>
                <FontAwesomeIcon icon={!doesNotHaveName ? faTimes : faCheck} size={'sm'} />
                &nbsp; Does not include your name
              </li>
            </ul>
          </Col>
        </Row>
        <Row width="100%">
          <Col lg={{ size: 4, offset: 4 }}>
            <Form id="login-form" role="form" autoComplete="on" className="form" method="post">
              <FormGroup>
                <InputGroup style={{ padding: '15px 0 0 0' }}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ height: '100%' }}>
                      <FontAwesomeIcon icon={faAsterisk} size={'sm'} />
                      <FontAwesomeIcon icon={faAsterisk} size={'sm'} />
                      <FontAwesomeIcon icon={faAsterisk} size={'sm'} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    invalid={password1 === '' && password1Blurred ? true : false}
                    autoFocus=""
                    id="password2"
                    name="email"
                    placeholder="password"
                    className="form-control"
                    type={passwordIsVisible ? 'text' : 'password'}
                    required=""
                    value={password1}
                    onBlur={() => setPassword1Blurred(true)}
                    onChange={(e) => {
                      setPassword1(e.target.value);
                      validatePassword(e.target.value);
                    }}
                  ></Input>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText
                      style={{ height: '100%', cursor: 'pointer' }}
                      onClick={() => setPasswordIsVisible(!passwordIsVisible)}
                    >
                      <FontAwesomeIcon icon={passwordIsVisible ? faEye : faEyeSlash} size={'sm'} />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {password1 === '' && password1Blurred && (
                  <p style={{ padding: 0, textAlign: 'center', color: '#dc3545' }}>Please fill out this field.</p>
                )}
                <InputGroup style={{ paddingTop: '15px' }}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ height: '100%' }}>
                      <FontAwesomeIcon icon={faAsterisk} size={'sm'} />
                      <FontAwesomeIcon icon={faAsterisk} size={'sm'} />
                      <FontAwesomeIcon icon={faAsterisk} size={'sm'} />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    invalid={password2 === '' && password2Blurred ? true : false}
                    autoFocus=""
                    id="emailReset"
                    name="email"
                    placeholder="password"
                    className="form-control"
                    type={passwordIsVisible ? 'text' : 'password'}
                    required=""
                    value={password2}
                    onBlur={() => setPassword2Blurred(true)}
                    onChange={(e) => {
                      setPassword2(e.target.value);
                    }}
                  />
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText style={{ height: '100%', cursor: 'pointer' }}>
                      <FontAwesomeIcon icon={passwordIsVisible ? faEye : faEyeSlash} size={'sm'} />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                {password2 === '' && password2Blurred && (
                  <p style={{ padding: 0, textAlign: 'center', color: '#dc3545' }}>Please fill out this field.</p>
                )}
                <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '15px' }}>
                  <Button
                    disabled={disableButton}
                    style={{ backgroundColor: '#2e6da4', minWidth: '200px' }}
                    type="submit"
                    onClick={(e) => validateForm(e)}
                    name="loginSubmit"
                    id="loginSubmit"
                    value="login"
                    className="btn-lg btn-primary"
                  >
                    {disableButton ? <FontAwesomeIcon spin icon={faSpinner} /> : 'Reset Password'}
                  </Button>
                </div>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Reset;
