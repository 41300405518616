import React from 'react';
import Moment from 'react-moment';
import moveIcon from '../../../../assets/images/icons/moveIcon.png';
import { ordinal_suffix_of } from '../../../../SortableList';
import { DraggableItemWrapper } from '../../../Common.style';
import { ClickTransfer, Date, DraggableDiv } from './Selected.style';

const Selection = ({
  listKey,
  data,
  state,
  lastPressed,
  isPressed,
  mouseY,
  listArray,
  isOrdinal,
  handleMouseDown,
  handleTouchEnd,
  handleClickTransfer,
  handleTouchStart,
  i,
}) => {
  const numberKey = Number(listKey);
  const item = listArray[numberKey];
  const itemsNumbered = isOrdinal ? ordinal_suffix_of(item.preference + 1) : item.preferences + 1;
  const { shadow, scale, y } = state;
  const transY = lastPressed === data && isPressed ? mouseY : y;
  // define conditional css attributes
  const selected = item.selected;
  const backgroundColor = 'rgb(64, 64, 64)';
  const color = 'rgb(255, 255, 255)';
  const action = 'subtract';

  // handles UI elements for selected items
  return (
    <DraggableItemWrapper
      key={listKey}
      // need to use style instead of styled-components to keep animation smooth
      style={{
        boxShadow: `rgba(0, 0, 0, 0.4) 0px ${shadow}px ${2 * shadow}px 0px`,
        transform: `translate3d(0, ${transY}px, 0) scale(${scale})`,
        WebkitTransform: `translate3d(0, ${transY}px, 0) scale(${scale})`,
        zIndex: data === lastPressed ? 99 : data,
        backgroundColor: backgroundColor,
      }}
      className="draggable_item"
      backgroundColor={backgroundColor}
    >
      <ClickTransfer onClick={(e) => handleClickTransfer('remove', data)}>
        <Date className="date" style={{ color: color }}>
          <span style={{ fontWeight: '700' }}>{itemsNumbered}:</span>&nbsp;&nbsp;
          <Moment className="mobile_display" format="ddd, MMM DD, YYYY">
            {item.InterviewDates4PortalDate}
          </Moment>
          <Moment className="browser_display" format="dddd, MMMM DD, YYYY">
            {item.InterviewDates4PortalDate}
          </Moment>
        </Date>
        {item.InterviewDates4PortalLabel && <div style={{ color: '#fff' }}>{item.InterviewDates4PortalLabel}</div>}
      </ClickTransfer>

      <DraggableDiv
        className="movable"
        onMouseDown={(e) => handleMouseDown(data, y, e)}
        onTouchStart={(e) => handleTouchStart(data, y, e)}
        onTouchEnd={(e) => handleTouchEnd(e)}
      >
        <img className="move-icon" src={moveIcon} />
      </DraggableDiv>
    </DraggableItemWrapper>
  );
};

export default Selection;
