import React, { Component } from 'react';
import { NodeGroup } from 'react-move';
import { insertNumber } from '../../SortableList';
import { List, Wrapper } from './ListAnimation.style';

function getTime(time) {
  const result = 1 - Math.pow(2, -10 * time);
  return result;
}

function createNumberedArray(num) {
  let numberedArray = [];
  for (let i = 0; i < num; i++) {
    numberedArray.push(i);
  }
  return numberedArray;
}

function updateOrder(arr, beg, end) {
  const copy = arr.slice(0);
  const val = copy[beg];
  copy.splice(beg, 1);
  copy.splice(end, 0, val);
  return copy;
}

function clamp(n, min, max) {
  return Math.max(Math.min(n, max), min);
}

const itemHeight = 75; // set list-item height and line-height in css as well

// Much of this was impoted from a pre-existing example and tweaked for current use
class ListAnimations extends Component {
  constructor(props) {
    super(props);
    const active = props.listArray.filter((item) => item.selected);
    this.state = {
      topDeltaY: 0,
      mouseY: 0,
      isPressed: false,
      lastPressed: 0,
      selected: props.hasPreferenceOrderWithNoInterviewDate ? 0 : active.length,
      stopScrolling: false,
      order: createNumberedArray(props.listArray.length),
      listArray: props.listArray,
    };
  }

  addItemsIfUserHasPreference() {
    const { hasPreferenceOrderWithNoInterviewDate, preferenceItems } = this.props;
    if (hasPreferenceOrderWithNoInterviewDate && preferenceItems) {
      const indexesToAdd = [];
      preferenceItems.forEach((preferenceItem) => {
        this.props.listArray.forEach((item, i) => {
          if (preferenceItem === item.InterviewDates4Portal__pkUUID_InterviewDate) {
            item.preference = null;
            item.selected = false;
            indexesToAdd.push(i);
          }
        });
      });

      const customAlert = (index, callback) => {
        // code to show your custom alert
        // in this case its just a console log

        //TODO: Find a better way to update this list rather than putting
        setTimeout(() => {
          const response = this.add(index);
          this.setState({ listArray: response }, callback());
        }, 20);

        // do callback when ready
      };

      var x = 0;
      var loopArray = (arr) => {
        customAlert(arr[x], function() {
          // set x to next item
          x++;

          // any more items in array? continue loop
          if (x < arr.length) {
            loopArray(arr);
          }
        });
      };

      loopArray(indexesToAdd);
    }
  }

  // add touchmove event for iphones
  componentDidMount() {
    this.addItemsIfUserHasPreference();

    window.addEventListener('touchmove', this.handleTouchMove, {
      passive: false,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('touchmove', this.handleTouchMove, {
      passive: true,
    });
  }

  handleTouchStart = (pos, pressY, { touches: [{ pageY }] }) => {
    this.setState({
      topDeltaY: pageY - pressY,
      mouseY: pressY,
      stopScrolling: true,
      isPressed: true,
      lastPressed: pos,
    });

    window.addEventListener('touchend', this.handleTouchEnd);
  };

  handleTouchMove = (e) => {
    if (!this.state.stopScrolling) {
      return;
    }
    e.preventDefault();
    window.scroll = false;
    this.handleMouseMove(e.touches[0]);
  };

  handleMouseDown = (pos, pressY, { pageY }) => {
    this.setState({
      topDeltaY: pageY - pressY,
      mouseY: pressY,
      isPressed: true,
      lastPressed: pos,
    });

    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
  };

  handleMouseMove = ({ pageY }) => {
    const { isPressed, topDeltaY, lastPressed, selected } = this.state;
    const { setOrder, order } = this.props;
    if (isPressed) {
      const mouseY = pageY - topDeltaY;
      // change value at end to restrict list
      const currentRow = clamp(Math.round(mouseY / itemHeight), 0, selected - 1);
      let newOrder = order;

      if (currentRow !== order.indexOf(lastPressed)) {
        newOrder = updateOrder(order, order.indexOf(lastPressed), currentRow);
      }

      this.setState({ mouseY });
      setOrder(newOrder);
    }
  };

  handleMouseUp = () => {
    this.setState({ isPressed: false, topDeltaY: 0 });
    window.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('mousemove', this.handleMouseMove);
    this.updateSelectionOrder();
  };

  handleTouchEnd = () => {
    this.setState({ isPressed: false, topDeltaY: 0, stopScrolling: false });
    window.removeEventListener('touchend', this.handleTouchEnd);
    this.updateSelectionOrder();
    return true;
  };

  // removes from selected (sets item.selected = false)
  remove = (item) => {
    const { selected } = this.state;
    const { setListArray, setOrder, order, listArray } = this.props;
    const itemSelected = listArray.slice(0);
    itemSelected[item].selected = false;
    // delete itemSelected[item].recordId;
    const newOrder = order.filter((num) => item !== num);
    const newList = insertNumber(newOrder, item, selected - 1);
    // selected currently decides insert position and number of elements which can be drag-and-dropped
    this.updateSelectionOrder(newOrder, itemSelected);
    setOrder(newList);
    setListArray(itemSelected);
    this.setState({ order: newList, selected: selected - 1 });
  };

  // updates order of selection.  If variables aren't passed in just uses state variables
  updateSelectionOrder = (newOrder, itemsSelected) => {
    const { setListArray, listArray, order } = this.props;
    // grab state variables if nothing was passedin
    const orderToBeCompared = newOrder ? newOrder : order;
    const itemToBeAltered = itemsSelected ? itemsSelected : listArray.slice();
    orderToBeCompared.forEach((num) => {
      itemToBeAltered[num].preference = orderToBeCompared.indexOf(num);
    });

    setListArray(itemToBeAltered);
  };

  // adds to selected (sets item.selected = true)
  add = (item) => {
    const { selected } = this.state;
    const { setListArray, order, listArray, setOrder } = this.props;
    const itemSelected = listArray.slice(0);
    itemSelected[item].selected = true;
    const newOrder = order.filter((num) => item !== num);
    newOrder.splice(selected, 0, item);
    this.updateSelectionOrder(newOrder, itemSelected);
    setOrder(newOrder);
    setListArray(itemSelected);
    // selected currently decides insert position and number of elements which can be drag-and-dropped
    this.setState({ selected: selected + 1 });
    return itemSelected;
  };

  // handles click when items are selectable.  This should be different from handle click which will handle selection events
  handleClickTransfer = (direction, item) => {
    if (direction === 'add') {
      this.add(item);
    } else if (direction === 'remove') {
      this.remove(item);
    }
  };

  // intended to handle click events where the item is being selected
  handleClick = (item) => {};
  render() {
    const { mouseY, isPressed, lastPressed } = this.state;
    const { clickable, HTMLComponent, isOrdinal, listArray, order } = this.props;
    if (!listArray || listArray.length === 0) {
      return <div />;
    }

    return (
      <Wrapper>
        <NodeGroup
          data={createNumberedArray(listArray.length)}
          keyAccessor={(d) => `${d}`}
          start={(d) => {
            return {
              scale: 1,
              shadow: 1,
              y: order.indexOf(d) * itemHeight,
            };
          }}
          update={(d) => {
            const dragging = lastPressed === d && isPressed;
            return {
              scale: [dragging ? 1.1 : 1],
              shadow: [dragging ? 5 : 1],
              y: [order.indexOf(d) * itemHeight],
              timing: { duration: 350, ease: getTime },
            };
          }}
        >
          {(nodes) => (
            <List height={`${listArray.length * itemHeight - 5}`}>
              {nodes.map(({ key, data, state, keyAccessor }, i) => {
                return (
                  <HTMLComponent
                    listKey={key}
                    key={key}
                    data={data}
                    state={state}
                    keyAccessor={keyAccessor}
                    lastPressed={lastPressed}
                    isPressed={isPressed}
                    mouseY={mouseY}
                    clickable={clickable}
                    listArray={listArray}
                    handleClick={this.handleClick}
                    handleClickTransfer={this.handleClickTransfer}
                    handleMouseDown={this.handleMouseDown}
                    handleTouchStart={this.handleTouchStart}
                    handleTouchEnd={this.handleTouchEnd}
                    handleMouseUp={this.handleMouseUp}
                    isOrdinal={isOrdinal}
                  />
                );
              })}
            </List>
          )}
        </NodeGroup>
      </Wrapper>
    );
  }
}

export default ListAnimations;

// import React, { useState, useEffect } from 'react';
// import { render } from 'react-dom';
// import { NodeGroup } from 'react-move';
// import Moment from 'react-moment';
// import moveIcon from '../../assets/images/icons/moveIcon.png';
// import { insertNumber } from '../../SortableList';
// import { Wrapper, List } from './ListAnimation.style';
// import { range } from 'd3-array';
// import { easeExpOut } from 'd3-ease';

// function updateOrder(arr, beg, end) {
//   const copy = arr.slice(0);
//   const val = copy[beg];
//   copy.splice(beg, 1);
//   copy.splice(end, 0, val);
//   return copy;
// }

// function clamp(n, min, max) {
//   return Math.max(Math.min(n, max), min);
// }

// const itemHeight = 75; // set list-item height and line-height in css as well

// const ListAnimations = (props) => {
//   const [topDeltaY, setTopDeltaY] = useState(0);
//   const [mouseY, setMouseY] = useState(0);
//   const [isPressed, setIsPressed] = useState(false);
//   const [lastPressed, setLastPressed] = useState(0);
//   const [selected, setSelected] = useState(0);
//   const [stopScrolling, setStopScrolling] = useState(false);
//   const [order, setOrder] = useState(range(props.listArray.length));
//   const [listArray, setListArray] = useState(props.listArray);

//   useEffect(() => {
//     window.addEventListener('touchmove', handleTouchMove, {
//       passive: false,
//     });
//     return () => {
//       window.removeEventListener('touchmove', handleTouchMove, {
//         passive: true,
//       });
//     };
//   });

//   const handleTouchStart = (pos, pressY, { touches: [{ pageY }] }) => {
//     setTopDeltaY(pageY - pressY);
//     setMouseY(pressY);
//     setStopScrolling(true);
//     setIsPressed(true);
//     setLastPressed(pos);
//     // setState({
//     //   topDeltaY: pageY - pressY,
//     //   mouseY: pressY,
//     //   stopScrolling: true,
//     //   isPressed: true,
//     //   lastPressed: pos,
//     // });

//     window.addEventListener('touchend', handleTouchEnd);
//   };

//   const handleTouchMove = (e) => {
//     if (!stopScrolling) {
//       return;
//     }
//     e.preventDefault();
//     window.scroll = false;
//     handleMouseMove(e.touches[0]);
//   };

//   const handleMouseDown = (pos, pressY, { pageY }) => {
//     setTopDeltaY(pageY - pressY);
//     setMouseY(pressY);
//     setIsPressed(true);
//     setLastPressed(pos);
//     // setState({
//     //   topDeltaY: pageY - pressY,
//     //   mouseY: pressY,
//     //   isPressed: true,
//     //   lastPressed: pos,
//     // });

//     window.addEventListener('mousemove', handleMouseMove);
//     window.addEventListener('mouseup', handleMouseUp);
//   };

//   const handleMouseMove = ({ pageY }) => {
//     if (isPressed) {
//       const mouseY = pageY - topDeltaY;
//       // change value at end to restrict list
//       const currentRow = clamp(Math.round(mouseY / itemHeight), 0, selected - 1);
//       let newOrder = order;

//       if (currentRow !== order.indexOf(lastPressed)) {
//         newOrder = updateOrder(order, order.indexOf(lastPressed), currentRow);
//       }
//       setMouseY(mouseY);
//       setOrder(newOrder);
//       // setState({ mouseY, order: newOrder });
//     }
//   };

//   const handleMouseUp = () => {
//     setIsPressed(false);
//     setTopDeltaY(0);
//     // setState({ isPressed: false, topDeltaY: 0 });
//     window.removeEventListener('mouseup', handleMouseUp);
//     window.removeEventListener('mousemove', handleMouseMove);
//     updateSelectionOrder();
//   };

//   const handleTouchEnd = () => {
//     setIsPressed(true);
//     setTopDeltaY(0);
//     setStopScrolling(false);
//     // setState({ isPressed: false, topDeltaY: 0, stopScrolling: false });
//     window.removeEventListener('touchend', handleTouchEnd);
//     updateSelectionOrder();
//     return true;
//   };

//   // removes from selected (sets item.selected = false)
//   const remove = (item) => {
//     const itemSelected = listArray.slice(0);
//     itemSelected[item].selected = false;
//     const newOrder = order.filter((num) => item !== num);
//     const newList = insertNumber(newOrder, item, selected - 1);
//     // selected currently decides insert position and number of elements which can be drag-and-dropped
//     updateSelectionOrder(newOrder, itemSelected);
//     setOrder(newList);
//     setSelected(selected - 1);
//     // setState({ order: newList, selected: selected - 1 });
//   };

//   // updates order of selection.  If variables aren't passed in just uses state variables
//   const updateSelectionOrder = (newOrder, itemsSelected) => {
//     // grab state variables if nothing was passedin
//     const orderToBeCompared = newOrder ? newOrder : order;
//     const itemToBeAltered = itemsSelected ? itemsSelected : listArray.slice();
//     orderToBeCompared.forEach((num) => {
//       itemToBeAltered[num].preference = orderToBeCompared.indexOf(num);
//     });
//   };

//   // removes from selected (sets item.selected = true)
//   const add = (item) => {
//     const itemSelected = listArray.slice(0);
//     itemSelected[item].selected = true;

//     const newOrder = order.filter((num) => item !== num);
//     newOrder.splice(selected, 0, item);
//     updateSelectionOrder(newOrder, itemSelected);
//     // selected currently decides insert position and number of elements which can be drag-and-dropped
//     setOrder(newOrder);
//     setSelected(selected + 1);
//     // setState({ order: newOrder, selected: selected + 1 });
//   };

//   // handles click when items are selectable.  This should be different from handle click which will handle selection events
//   const handleClickTransfer = (direction, item) => {
//     if (direction === 'add') {
//       add(item);
//     } else if (direction === 'remove') {
//       remove(item);
//     }
//   };

//   // intended to handle click events where the item is being selected
//   const handleClick = (item) => {};
//   const { clickable, HTMLComponent, isOrdinal } = props;
//   return (
//     <Wrapper>
//       <NodeGroup
//         data={range(listArray.length)}
//         keyAccessor={(d) => `${d}`}
//         start={(d) => ({
//           scale: 1,
//           shadow: 1,
//           y: order.indexOf(d) * itemHeight,
//         })}
//         update={(d) => {
//           const dragging = lastPressed === d && isPressed;
//           return {
//             scale: [dragging ? 1.1 : 1],
//             shadow: [dragging ? 5 : 1],
//             y: [order.indexOf(d) * itemHeight],
//             timing: { duration: 350, ease: easeExpOut },
//           };
//         }}
//       >
//         {(nodes) => (
//           <List height={`${listArray.length * itemHeight - 5}`}>
//             {nodes.map(({ key, data, state, keyAccessor }, i) => {
//               return (
//                 <HTMLComponent
//                   listKey={key}
//                   key={key}
//                   data={data}
//                   state={state}
//                   keyAccessor={keyAccessor}
//                   lastPressed={lastPressed}
//                   isPressed={isPressed}
//                   mouseY={mouseY}
//                   clickable={clickable}
//                   listArray={listArray}
//                   handleClick={handleClick}
//                   handleClickTransfer={handleClickTransfer}
//                   handleMouseDown={handleMouseDown}
//                   handleTouchStart={handleTouchStart}
//                   handleTouchEnd={handleTouchEnd}
//                   handleMouseUp={handleMouseUp}
//                   isOrdinal={isOrdinal}
//                 />
//               );
//             })}
//           </List>
//         )}
//       </NodeGroup>
//     </Wrapper>
//   );
// };

// export default ListAnimations;
