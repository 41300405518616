import arrayMove from 'array-move';
import { default as Axios, default as axios } from 'axios';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Col } from 'reactstrap';
import Swal from 'sweetalert2';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrow from '../../../assets/images/arrow.png';
import { constructFilemakerBaseUrl, determineCredentials, isDev } from '../../../Common.functions';
import { ordinal_suffix_of } from '../../../SortableList';
import { CenterRow, CustomTable, TDPadding } from './InterviewDateConfirm.styles';
import { CustomButton, EvenRow } from './InterviewDateSelection.styles';

const InterviewDateConfirm = ({
  changeStep,
  decrementStep,
  selectableDates,
  showButtons,
  setHasPreferenceOrderWithNoInterviewDate,
  headerText,
  globalSettings,
  setSelectedableDates,
  setDateList,
  currentSlide,
  setError,
  hasPreferenceOrderWithNoInterviewDate,
  demo,
  selectedDates,
  dateOfInterview,
  scheduledLabel,
  dateIsLocked,
  clientID,
  setDateOfInterview,
  setCurrentSlide,
  listArray,
  setSelectedDates,
}) => {
  const [isSending, setIsSending] = useState(false);
  const [change, setChange] = useState(false);
  const [userHasNoPhoto, setUserHasNoPhoto] = useState(false);
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [displayPhotoLink, setDisplayPhotoLink] = useState(false);

  useEffect(() => {
    const id = clientID;

    let url = `${constructFilemakerBaseUrl()}/candidate/upload/?id=${id}`;

    if (globalSettings.EnableRelay) {
      url = `https://tools2.rezrate.com/api/v2/relay/?id=${id}&endpoint=api/v2/candidate/upload`;
    }

    Axios({
      method: 'get',
      url,
      withCredentials: true,
      headers: {},
    })
      .then((res) => {
        if (res.data && res.data.data && res.data.data.error !== true) {
          if (!res.data.data.Photo || res.data.data.Photo === '') {
            setUserHasNoPhoto(true);
          }
        } else if (res.data.error !== true) {
          if (res.data && (!res.data.Photo || res.data.Photo === '')) {
            setUserHasNoPhoto(true);
          }
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    //TODO: find a better way to confirm this should be displayed than using the text type
    if (
      headerText.includes(`you've been scheduled for the following`) ||
      headerText.includes('waitlisted for the date')
    ) {
      setDisplayPhotoLink(true);
    } else {
      setDisplayPhotoLink(false);
    }
  }, [headerText]);

  const sendRequest = useCallback(async () => {
    // return;
    // don't send again while we are sending
    if (isSending) return;
    // update state
    setIsSending(true);
    setDisplayOverlay(true);
    // send the actual request
    const sortUUIDs = sortData(listArray);
    const UUIDarray = sortUUIDs.map((item) => {
      return `"${item.InterviewDates4Portal__pkUUID_InterviewDate}"`;
    });
    const bodyFormData = new FormData();
    bodyFormData.append('dates', `[${UUIDarray}]`);

    let url = 'https://tools.rezrate.com/rezrate/api/candidateportal/';
    const params = { id: clientID, dev: isDev() };

    if (globalSettings.EnableRelay) {
      url = 'https://tools2.rezrate.com/api/v2/relay/';
      params.endpoint = 'rezrate/api/candidateportal';
    }

    bodyFormData.get('key');

    const postData = await axios({
      method: 'post',
      url,
      withCredentials: determineCredentials(),
      params,
      data: bodyFormData,
      headers: {
        'content-type': 'application/json',
      },
    })
      //TODO add error handling
      .then((res) => {
        if (res.data.error === 429) {
          Swal.fire('Error', res.data.message, 'error');
          setDisplayOverlay(false);
          return;
        }
        window.location.reload();
      })
      .catch((err) => {
        setError(true);
        setDisplayOverlay(false);
      });

    // once the request is sent, update state again
    setIsSending(false);
  }, [isSending]); // update the callback if the state changes

  const test = (changeSlide) => {
    if (changeSlide) {
      changeStep(false, 1);
      setChange(false);
      const listArrayCopy = listArray.slice();
      listArrayCopy.forEach((item) => {
        // item.selected = false;
        // item.preference = null;
      });
      setDateList(listArrayCopy);
      // setDateList((prevDateList) => {
      //   return prevDateList;
      // });
    }
  };

  const handleModifyClick = async (e) => {
    let changeSlide = false;
    e.preventDefault();
    if (dateOfInterview || hasPreferenceOrderWithNoInterviewDate) {
      const dateOfInterviewText =
        'Modifying your selection will result in the loss of your currently scheduled interview date.  Are you sure you want to proceed?';
      const preferenceText =
        'Modifying your selection will move you to the back of the line.  Are you sure you want to proceed?';
      const text = dateOfInterview ? dateOfInterviewText : preferenceText;
      const alertUser = await Swal.fire({
        title: 'Warning!',
        text,
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: `Modify`,
        onAfterClose: () => {
          test(changeSlide);
        },
      }).then((result) => {
        if (result.value) {
          changeSlide = true;
          setTimeout(() => {
            setHasPreferenceOrderWithNoInterviewDate(false);
            setDateOfInterview(false);
          }, 500);
        }
      });
    } else {
      changeStep(false, 1);
    }
  };

  const sortData = (arrayList) => {
    const selectedItems = arrayList.filter((item) => item.selected);
    const sortedItems = selectedItems.sort((a, b) => {
      return a.preference - b.preference;
    });
    return sortedItems;
  };

  const simulateSending = () => {
    Swal.fire(
      `Your selection has been submitted (not really)`,
      `If this was a real invitation, the selection would be sent to the corresponding department.`,
    );
  };
  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSelectedDates(arrayMove(selectedDates, oldIndex, newIndex));
  };

  return (
    <Col>
      <CenterRow style={{ padding: '15px' }}>
        {displayOverlay && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              right: 0,
              backgroundColor: 'rgba(0,0,0,0.5)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                width: '450px',
                height: '150px',
                backgroundColor: '#ffffff',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span>
                <h4 style={{ fontWeight: 700 }}>Submitting request...</h4>
                <FontAwesomeIcon icon={faSpinner} spin size="2x" />
              </span>
            </div>
          </div>
        )}
        {displayPhotoLink && userHasNoPhoto && (
          <h6 style={{ color: 'red' }}>
            There's one more thing you need to do! Upload a photo of yourself by clicking{' '}
            <span>
              <a href={`https://portal.rezrate.com/candidate/photo/${clientID}`} target="_blank">
                here.
              </a>
            </span>
          </h6>
        )}
        <CustomTable>
          <thead>
            <tr>
              <th></th>
              {!dateOfInterview && <th>Choice</th>}
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody style={{}}>
            {dateOfInterview && (
              <tr>
                <TDPadding />
                <td>
                  <div>
                    <Moment format="dddd, MMMM DD, YYYY">{dateOfInterview}</Moment>
                  </div>
                  {scheduledLabel && (
                    <span>
                      <br />
                      {scheduledLabel}
                    </span>
                  )}
                </td>
                <TDPadding />
              </tr>
            )}
            {sortData(listArray).map((item, i) => {
              return (
                <tr key={item.InterviewDates4PortalDate}>
                  <TDPadding />
                  {!dateOfInterview && <td>{ordinal_suffix_of(i + 1)}</td>}
                  <td>
                    {!dateOfInterview && <Moment format="dddd, MMMM DD, YYYY">{item.InterviewDates4PortalDate}</Moment>}
                    {item.InterviewDates4PortalLabel && (
                      <span>
                        <br />
                        {item.InterviewDates4PortalLabel}
                      </span>
                    )}
                  </td>
                  <TDPadding />
                </tr>
              );
            })}
          </tbody>
        </CustomTable>
      </CenterRow>
      {true && (
        <Fragment>
          {sortData(listArray).length === 1 && !dateOfInterview && !hasPreferenceOrderWithNoInterviewDate && (
            <EvenRow style={{ flexDirection: 'column' }}>
              <h4>You selected only one date. Did you know you can select multiple options?</h4>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p style={{ marginBottom: '5px' }}>
                  Click{' '}
                  <span className="clickable" onClick={(e) => changeStep(e, 1)}>
                    modify
                  </span>{' '}
                  to add more dates
                </p>
              </div>
            </EvenRow>
          )}
          <EvenRow>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {/* <EvenRow style={{ flexDirection: 'row', alignItems: 'center' }}>
                <div>
                  <img src={arrow} style={{ width: '40px', height: 'auto' }} />
                  <p>
                    <span className="clickable" onClick={(e) => changeStep(e, 1)}>
                      modify
                    </span>{' '}
                    to add more dates
                  </p>
                </div>
              </EvenRow> */}
              {sortData(listArray).length === 1 && !dateOfInterview && !hasPreferenceOrderWithNoInterviewDate && (
                <div>
                  <img src={arrow} style={{ width: 'auto', height: '27px', position: 'relative', left: '21px' }} />
                </div>
              )}
              {!dateIsLocked && (
                <CustomButton color="warning" onClick={handleModifyClick}>
                  Modify
                </CustomButton>
              )}
            </div>
            {!dateOfInterview && !hasPreferenceOrderWithNoInterviewDate && (
              <div>
                {sortData(listArray).length === 1 && <div style={{ height: '27px' }} />}
                {!demo && (
                  <CustomButton disabled={isSending} onClick={sendRequest} color="success">
                    Confirm
                  </CustomButton>
                )}
                {demo && (
                  <CustomButton onClick={simulateSending} color="success">
                    Confirm
                  </CustomButton>
                )}
              </div>
            )}
          </EvenRow>
        </Fragment>
      )}
    </Col>
  );
};

export default InterviewDateConfirm;
