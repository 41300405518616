// import styled from 'styled-components';

// const EvaulateMobileStyle = {
//   FixedNavigation: styled.div`
//     position: -webkit-sticky;
//     position: sticky;
//     top: 0;
//     background-color: #fff;
//     bottom: 0px;
//     display: flex;
//     z-index: 11;
//     padding-top: 10px;
//     width: 100%;
//     left: 0;
//     height: 92px;
//     justify-content: space-evenly;
//     & div {
//       background-color: rgb(51, 51, 51);
//       // width: 100%;
//       width: 60px;
//       height: 60px;
//       border-radius: 50%;
//       border: 1px solid #fff;
//       color: #fff;
//       & svg {
//         position: relative;
//         top: 12px;
//         // left: 10px;
//       }
//     }
//     & p {
//       color: black;
//       padding: 0;
//       margin: 0;
//       font-size: 11px;
//       text-align: center;
//     }
//   `,
//   CarouselItemWrapper: styled.div`
//     padding: 0 5px 0 5px;
//     width: 100vw;
//   `,
//   TimeoutOverlay: styled.div``,
// };

// export default EvaulateMobileStyle;

import styled from 'styled-components';

const EvaulateMobileStyle = {
  FixedNavigation: styled.div`
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    bottom: 0px;
    background-color: #fff;
    display: flex;
    z-index: 11;
    width: 100%;
    left: 0;
    height: 112;
    justify-content: space-evenly;
  `,
  CarouselItemWrapper: styled.div`
    padding: 0 5px 0 5px;
    width: 100vw;
  `,
  TimeoutOverlay: styled.div``,
  NavigationSub: styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding-top: 10px;
    background-color: #fff;
    padding-bottom: 45px;
    & div {
      background-color: rgb(51, 51, 51);
      // width: 100%;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: 1px solid #fff;
      color: #fff;
      & svg {
        position: relative;
        top: 12px;
        // left: 10px;
      }
    }
    & p {
      color: black;
      padding: 0;
      margin: 0;
      font-size: 11px;
      text-align: center;
    }
  `,
};

export default EvaulateMobileStyle;
