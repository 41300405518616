import { connect } from 'react-redux';
import Reset from './Reset';
import { setName } from '../InterviewDates/InterviewDatesRouter.action';
import { setExpiredToken } from './Login.action';
import { setShowName } from '../RezRATE/Header/Header.action';

const mapDispatchToProps = (dispatch) => ({
  setName: (userObject) => dispatch(setName(userObject)),
  setExpiredToken: (tokenIsExpired) => dispatch(setExpiredToken(tokenIsExpired)),
  setShowName: (value) => dispatch(setShowName(value)),
});

export default connect(
  null,
  mapDispatchToProps,
)(Reset);
