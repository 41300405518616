import React, { useState } from 'react';
import { Button, Table, InputGroup, Input, InputGroupAddon } from 'reactstrap';
import Swal from 'sweetalert2';

const evalCandCommonStyle = {
  textAlign: 'center',
  minHeight: '100px',
  fontWeight: 700,
};

const candidateStyle = {
  backgroundColor: 'rgb(255, 249, 131)',
  color: '#000000',
};

const evaluatorStyle = {
  backgroundColor: 'rgb(0, 45, 153)',
  color: '#ffffff',
};

const determineState = (isAttending) => {
  if (isAttending) {
    return 'Attending';
  } else {
    return 'Vacated';
  }
};

const selectText = (containerid) => {
  if (document.selection) {
    // IE
    var range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(containerid));
    range.select();
  } else if (window.getSelection) {
    var range = document.createRange();
    range.selectNode(document.getElementById(containerid));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
};

const sort = (attendees) => {
  // sort candidate first, and then subsort by attending
  const attendeesSorted = attendees.sort(function(arr1, arr2) {
    //sort first by candidates

    if (!arr1.isAttending && arr2.isAttending) {
      return 1;
    } else if (arr1.isAttending && !arr2.isAttending) {
      return -1;
    } else {
      if (!arr1.iscandidate && arr2.iscandidate) {
        return 1;
      } else if (arr1.iscandidate && !arr2.iscandidate) {
        return -1;
      } else {
        return 0;
      }
    }
  });
  return attendeesSorted;
};

const CandidateEvaluatorDetails = ({ selectedBlock, setShowSelectedBlock }) => {
  const {
    SchedulesAttendance,
    SchedulesCandidate,
    SchedulesEvaluators,
    SchedulesCandidatesAndEvaluators,
    zoomInfo,
  } = selectedBlock;

  useState(() => {
    if (selectedBlock) {
      // selectedBlock.SchedulesCandidate;
    }
  }, [selectedBlock]);

  if (!selectedBlock) {
    return <div />;
  }

  const JoinURL = zoomInfo && zoomInfo.IsActive == true ? zoomInfo.JoinURL : false;

  return (
    <div id="coordinator_atendee_wrapper">
      <div className="attendee_overflow">
        <Table>
          <thead>
            <tr>
              <th>Attendee</th>
              <th>State</th>
            </tr>
          </thead>
          <tbody>
            {sort(selectedBlock.SchedulesAttendance).map((item) => {
              const { attendee, isAttending, isEvaluator, joinedCount, meetingIsActive, iscandidate } = item;
              return (
                <tr
                  key={`${item.fkUUIDAttendee}${item.fkUUIDMeeting}`}
                  style={iscandidate ? candidateStyle : evaluatorStyle}
                >
                  <td>{attendee}</td>
                  <td>{determineState(isAttending)}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Table id="coordinator_atendee_table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Cell Phone</th>
            </tr>
          </thead>
          <tbody>
            {SchedulesCandidatesAndEvaluators.map((scheduleParticipants) => {
              const { CellPhone, Email, First, LastFirst, Last, isCandidate } = scheduleParticipants;
              return (
                <tr
                  style={
                    isCandidate
                      ? { ...candidateStyle, ...evalCandCommonStyle }
                      : { ...evaluatorStyle, ...evalCandCommonStyle }
                  }
                >
                  <td className="vertical_align_middle">{LastFirst}</td>
                  <td
                    className="vertical_align_middle"
                    style={{ cursor: 'pointer', overflowWrap: 'break-word' }}
                    onClick={() => {
                      window.location.href = `mailto:${Email}`;
                    }}
                  >
                    {Email}
                  </td>
                  <td className="vertical_align_middle">{CellPhone}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <InputGroup>
        {/* <div id="selectable" onclick="selectText('selectable')">
          http://example.com/page.htm
        </div> */}

        <h5
          type="text"
          disabled
          style={{ textAlign: 'center', backgroundColor: '#e9ecef', width: '100%', margin: '10px' }}
          name="JoinURL"
          id="JoinURL"
          placeholder=""
          onClick={(e) => selectText('JoinURL')}
        >
          {JoinURL || 'No join meeting link available.'}
        </h5>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          {/* <InputGroupAddon addonType="append">
                    <Button
                      color={isValidTime() ? 'success' : 'secondary'}
                      disabled={!isValidTime()}
                      onClick={() => {
                        setFakeUrl('Fetching URL. . .');
                        setTimeout(() => {
                          setFakeUrl('www.fakeURL.com/room/mblubaskug');
                        }, 3000);
                      }}
                    >
                      Get URL
                    </Button>
                  </InputGroupAddon> */}
          {JoinURL ? (
            <InputGroupAddon addonType="append" style={{ height: 38 }}>
              <Button
                color={'success'}
                onClick={() => {
                  if (JoinURL) {
                    window.open(`${JoinURL}`, '_blank');
                  }
                }}
              >
                Join
              </Button>
            </InputGroupAddon>
          ) : null}
          {/* {JoinURL ? (
          <InputGroupAddon addonType="append" style={{ height: 38 }}>
            <Button
              color={'primary'}
              onClick={() => {
                const input = document.getElementById('JoinURL');
                navigator.clipboard.writeText(JoinURL);
                Swal.fire({
                  title: 'Meeting URL Copied!',
                  html: '',
                  timer: 2000,
                  icon: 'info',
                  showConfirmButton: false,
                  timerProgressBar: true,
                });
              }}
            >
              Copy
            </Button>
          </InputGroupAddon>
        ) : null} */}
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              color="warning"
              onClick={(e) => {
                e.preventDefault();
                setShowSelectedBlock(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </InputGroup>
    </div>
  );
};

export default CandidateEvaluatorDetails;
