// set "global" variables so that they keep their scope when being called from different classes
let product = 'RezRate';
let pageTitle = '';
let departmentName = '';
let organizationName = '';

// sets the document title.  Single or multiple values within the object can be passed in without losing the context of the other values
// for instance,
// titleObject = {
//   departmentName: 'Neurosurgery'
// }
// will only change the department name and leave the other's in tact

const setTitle = (titleObject) => {
  if (titleObject && titleObject.product) product = String(titleObject.product);
  if (titleObject && titleObject.pageTitle) pageTitle = String(titleObject.pageTitle);
  if (titleObject && titleObject.departmentName) departmentName = String(titleObject.departmentName);
  if (titleObject && titleObject.organizationName) organizationName = String(titleObject.organizationName);

  const fullTitle = `${product} ${pageTitle} ${departmentName} ${organizationName}`;

  if (fullTitle.trim().length > 0) {
    document.title = fullTitle.trim();
  } else {
    document.title('fallback option');
  }
};

export default setTitle;
