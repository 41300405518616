import React, { useCallback, useRef, useState } from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import { useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import RezRate from '../assets/images/header/rezRate.gif';
import { sizes } from '../Body/Common.style';
import { isDev, useEventListener } from '../Common.functions';
import { ImageWrapper, TextWrapper, Wrapper } from './header.styles';

const headerText = 'RezRATE';
const subHeaderText = '';

// necessary for mobile.  Logo scales smaller on phone landscape
const initialImgWidth = window.innerWidth <= sizes.phoneLandscape ? 50 : 100;

const Header = (props) => {
  const { user, breakUrl } = props;
  const [logo, setLogo] = useState(RezRate);
  const imgSize = useRef(null);
  const [userState, setUserState] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imgWidth, setImgWidth] = useState(initialImgWidth);
  const location = useLocation();
  // use the default header text until the api return header information
  const organization =
    user && user.Departments4PortalOrganization
      ? `${user.Departments4PortalName ? user.Departments4PortalName : ''} ${
          user.Departments4PortalOrganization ? 'at ' + user.Departments4PortalOrganization : ''
        }`
      : headerText;
  const name =
    user && user.Candidates4PortalNameFirst
      ? `${user.Candidates4PortalNameFirst ? user.Candidates4PortalNameFirst : ''} ${
          user.Candidates4PortalNameLast ? user.Candidates4PortalNameLast : ''
        }`
      : subHeaderText;

  // Sets image size based off of ref.  Ref can not be used with listener in current state
  const getAndSetImgSize = () => {
    // display: none doesn't adjust the image to the container immediately, so calculate the value which must be multiplied by height
    // to make it 100px, and multiply this by width to get the correct width
    const convertHeightTo100 = initialImgWidth / imgSize.current.height;
    if (imgSize && imgSize.current && imgSize.current.width) {
      setImgWidth(imgSize.current.width * convertHeightTo100);
      setLoading(false);
    }
  };

  const handler = useCallback((e) => {
    // can't use ref due to event listener
    const x = document.getElementsByClassName('logo');
    if (x[0] && x[0].width) {
      setImgWidth(x[0].width);
    }
  });

  useEventListener('resize', handler);

  return (
    <div>
      <Wrapper imgWidth={imgWidth}>
        {/* switches out default logo with user's logo when it's done loading into the browser.  Child key must be unique to either element */}
        <ReactCSSTransitionReplace
          transitionName="cross-fade"
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1000}
        >
          <ImageWrapper className="ImageWrapper" key={loading}>
            {user && (user.Departments4PortalLogo || user.Departments4PortalLogoURL) && (
              <img
                className="logo"
                ref={imgSize}
                src={
                  user.Departments4PortalLogoURL === undefined || user.Departments4PortalLogoURL.trim() === ''
                    ? user.Departments4PortalLogo
                    : user.Departments4PortalLogoURL
                }
                style={loading ? { display: 'none', height: '100px', width: '100px' } : {}}
                onLoad={() => getAndSetImgSize()}
              />
            )}
            {loading && <img ref={imgSize} src={logo} />}
          </ImageWrapper>
        </ReactCSSTransitionReplace>
        <ReactCSSTransitionReplace
          transitionName="cross-fade"
          transitionEnterTimeout={1000}
          transitionLeaveTimeout={1000}
        >
          <TextWrapper className="no-text-decoration" key={organization}>
            <h2 className="no-text-decoration">
              {organization}
              <span style={{ color: 'red' }}>{isDev() === 1 ? 'DEV' : ''}</span>
            </h2>
            <div
              className="browser_display"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'flex-end',
              }}
            >
              {name && <h4>{name}</h4>}

              <div style={{ display: 'flex' }}>
                {user && user.Departments4PortalName && user.Departments4PortalWebsite && (
                  <div style={{ paddingBottom: '5px' }}>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(user.Departments4PortalWebsite);
                      }}
                      style={{ marginRight: '20px', whiteSpace: 'none' }}
                      color="primary"
                    >
                      Our Website
                    </Button>
                  </div>
                )}
                {breakUrl && breakUrl.url && breakUrl.url !== '' && breakUrl.buttonText && breakUrl.type !== 0 && (
                  <div>
                    <div>
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(breakUrl.url);
                        }}
                        style={{ marginRight: '20px', whiteSpace: 'none' }}
                        color={breakUrl.type && breakUrl.type === 2 ? 'success' : 'warning'}
                      >
                        {breakUrl.type && breakUrl.type === 2 ? 'Join Meeting' : breakUrl.buttonText}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </TextWrapper>
        </ReactCSSTransitionReplace>
      </Wrapper>

      <div className="mobile_display">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          {name && <h4>{name}</h4>}

          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {user && user.Departments4PortalName && user.Departments4PortalWebsite && (
              <div style={{ paddingBottom: '5px' }}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(user.Departments4PortalWebsite);
                  }}
                  style={{ whiteSpace: 'none' }}
                  color="primary"
                >
                  Our Website
                </Button>
              </div>
            )}
            {breakUrl && breakUrl.url && breakUrl.url !== '' && breakUrl.buttonText && breakUrl.type !== 0 && (
              <div style={{}}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(breakUrl.url);
                  }}
                  style={{ whiteSpace: 'none' }}
                  color={breakUrl.type && breakUrl.type === 2 ? 'success' : 'warning'}
                >
                  {breakUrl.type && breakUrl.type === 2 ? 'Join Meeting' : breakUrl.buttonText}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
