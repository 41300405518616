import { connect } from 'react-redux';
import { setUser } from '../../InterviewDates/InterviewDatesRouter.action';
import Header from './Header';
import { setDate, setHeaderHeight } from './Header.action';

const mapStateToProps = (state) => ({
  user: state.userInfo,
  header: state.header,
});

const mapDispatchToProps = (dispatch) => ({
  setDate: (date) => dispatch(setDate(date)),
  setUser: (userObject) => dispatch(setUser(userObject)),
  setHeaderHeight: (height) => dispatch(setHeaderHeight(height)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
