import React, { useEffect, useState } from 'react';
import { Col, Form, FormGroup, Input, Row } from 'reactstrap';
import sanitizeHtml from 'sanitize-html';
import HtmlToReactParser from 'html-to-react';

const limit = 1700;

const TextQuestion = ({
  question,
  updateEval,
  answerText,
  i,
  CandidateSelected__pkUUID_Candidate,
  setIsTyping,
  includeNote,
}) => {
  const [text, setText] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [questionText, setQuestionText] = useState('');

  // useEffect(() => {
  //   if (CandidateSelected__pkUUID_Candidate) {
  //     setText('');
  //   }
  // }, [CandidateSelected__pkUUID_Candidate]);

  const { QuestionsVLQuestionText } = question;

  useEffect(() => {
    const textSanitized = sanitizeHtml(QuestionsVLQuestionText);
    const newParser = new HtmlToReactParser.Parser();
    const reactElement = newParser.parse(textSanitized);
    setQuestionText(reactElement);
  }, [QuestionsVLQuestionText]);

  useEffect(() => {
    function truncate(answerText = '') {
      if (answerText.length > limit) {
        answerText = answerText.substring(0, limit);
      }
      return answerText;
    }
    setText(truncate(answerText));
  }, []);

  const postAnswer = (uuid, value) => {
    updateEval(uuid, value);
  };

  const changeName = (event) => {
    const placeholder = event.target.value;
    if (placeholder.length <= limit) {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }

      setIsTyping(true);

      setText(event.target.value);
      setTypingTimeout(
        setTimeout(() => {
          postAnswer(question.Answers4Questions__pkUUID_Answer, placeholder);
        }, 2 * 1000),
      );
    }
  };
  return (
    <Row>
      <Col style={{ padding: 0 }} sm="12">
        <p style={{ fontWeight: 700 }}>
          {i + 1}. {question.QuestionsVLRequiredOptional === 'Required' && <span style={{ color: '#ff0000' }}>*</span>}
          {questionText}
        </p>
        {includeNote && includeNote}
      </Col>
      <p>
        Character Limit:{' '}
        <span style={{ fontWeight: '700' }}>
          <span style={{ color: text.length >= limit - 100 ? '#ff0f0f' : '#000000' }}>{text.length}</span> / {limit}{' '}
          {text.length === limit && <span style={{ color: '#ff0f0f' }}> limit reached</span>}
        </span>
      </p>

      <Col sm="12" style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%' }}>
          <Form style={{ width: '100%' }}>
            <FormGroup>
              <Input type="textarea" name="text" id="exampleText" rows="4" value={text} onChange={changeName} />
            </FormGroup>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default TextQuestion;
