import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fetchDataAgnosticWithoutCredentials } from '../../Services/dataApi';

const thumb = styled.div``;

const thumbInner = styled.div``;

const img = styled.img``;

const FileViewer = (props) => {
  const [uri, setUri] = useState(null);
  const [MimeType, setMimeType] = useState(null);
  const [info, setInfo] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  useEffect(() => {
    getFile(props.match.params);
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages({ numPages });
  };

  const correctViewer = () => {
    if (!uri || !MimeType) return <div />;

    if (
      MimeType.toUpperCase().includes('PDF') ||
      MimeType.includes('document') ||
      MimeType.includes('application/octet-stream')
    ) {
      return (
        <div style={{ width: '100vw', height: 'calc(100vh - 48px)' }}>
          {/* <Document file={uri} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document> */}
          <h3></h3>
          <embed src={uri} width="100%" height="100%"></embed>
          {/* <p>
            Page {pageNumber} of {numPages}
          </p> */}
        </div>
      );
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <h2>Your file is downloading</h2>
          {/* <iframe
            // src={`http://docs.google.com/gview?url='${uri}'&embedded=true`}
            frameBorder="0"
            webkitAllowFullScreen
            mozAllowFullScreen
            allowFullScreen
          ></iframe> */}
        </div>
      );
    }
    if (MimeType.includes('document')) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <iframe
            src={`https://view.officeapps.live.com/op/embed.aspx?src=${uri}`}
            width="1366px"
            height="623px"
            frameborder="0"
          >
            This is an embedded{' '}
            <a target="_blank" href="http://office.com">
              Microsoft Office
            </a>{' '}
            document, powered by{' '}
            <a target="_blank" href="http://office.com/webapps">
              Office Online
            </a>
            .
          </iframe>
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <div>
          <div>
            <img style={{ maxWidth: '100vw' }} src={uri} />
          </div>
        </div>
      </div>
    );
  };

  const getFile = (params) => {
    const { department, organization, pk_UUID_FM, seasonID, tempToken, pk_DocumentType, fileName } = params;
    fetchDataAgnosticWithoutCredentials('files/candidateFile', {
      department,
      organization,
      pk_UUID_FM,
      seasonID,
      tempToken: 'blorp',
      pk_DocumentType,
      fileName,
    })
      .then((res) => {
        setInfo(res.data);
        setUri(res.data.uri);
        setMimeType(res.data.MimeType);
        document.title = `${res.data.DocumentTypeName} | ${res.data.Last}, ${res.data.First} | RezRATE`;
      })
      .catch((err) => {});
  };
  if (!info) {
    return <div />;
  }
  return (
    <div>
      <div
        style={{ height: 'auto', display: 'flex', justifyContent: 'center', marginTop: '0.5rem', fontWeight: '7000' }}
      >
        <h6 className="bold">
          <a href={uri} target="_blank" download={`${info.Last}, ${info.First}`}>
            Download
          </a>
        </h6>
        <h6 className="bold" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
          &bull;
        </h6>
        <h6 className="bold">{`${info.DocumentTypeName}`}</h6>
        <h6 className="bold" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
          &bull;
        </h6>
        <h6 className="bold">{`${info.Last}, ${info.First}`}</h6>
        <h6 className="bold" style={{ paddingLeft: '5px', paddingRight: '5px' }}>
          &bull;
        </h6>
        <h6 className="bold">RezRATE</h6>
      </div>
      <div style={{ height: 'calc(100vh - 43px)' }}>{correctViewer()}</div>
    </div>
  );
};

export default FileViewer;
