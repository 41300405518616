const candidates = [
  {
    firstName: 'Jenny',
    lastName: 'Fakename',
    time: new Date(),
    id: 1,
    progress: 'inProgress',
    status: 'complete',
    school: 'University of Ghana School of Medicine and Dentistry',
    img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
    awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
    languages: ['English'],
    hobbies: [
      'Hiking',
      'Interested in history of Ancient Empires',
      'Annapolis Triathon Club',
      'Member of U.S. Masters Swimming',
    ],
  },
  {
    firstName: 'Bob',
    lastName: 'Builder',
    time: new Date(),
    progress: 'inProgress',
    id: 0,
    status: 'complete',
    school: 'University of Ghana School of Medicine and Dentistry',
    img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
    awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
    languages: ['English'],
    hobbies: [
      'Hiking',
      'Interested in history of Ancient Empires',
      'Annapolis Triathon Club',
      'Member of U.S. Masters Swimming',
    ],
  },
  {
    firstName: 'Billy',
    lastName: 'West',
    id: 2,
    time: new Date(),
    progress: 'inProgress',
    status: 'complete',
    school: 'University of Ghana School of Medicine and Dentistry',
    img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
    awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
    languages: ['English'],
    hobbies: [
      'Hiking',
      'Interested in history of Ancient Empires',
      'Annapolis Triathon Club',
      'Member of U.S. Masters Swimming',
    ],
  },
  {
    firstName: 'John',
    lastName: 'Doe',
    id: 3,
    time: new Date(),
    status: 'complete',
    progress: 'inProgress',
    school: 'University of Ghana School of Medicine and Dentistry',
    img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
    awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
    languages: ['English'],
    hobbies: [
      'Hiking',
      'Interested in history of Ancient Empires',
      'Annapolis Triathon Club',
      'Member of U.S. Masters Swimming',
    ],
  },
  {
    firstName: 'Leeroy',
    lastName: 'Jenkins',
    time: new Date(),
    progress: null,
    id: 4,
    status: 'complete',
    school: 'University of Ghana School of Medicine and Dentistry',
    img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
    awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
    languages: ['English'],
    hobbies: [
      'Hiking',
      'Interested in history of Ancient Empires',
      'Annapolis Triathon Club',
      'Member of U.S. Masters Swimming',
    ],
  },
  {
    firstName: 'Kyle',
    lastName: 'Kyleton',
    time: new Date(),
    progress: null,
    id: 5,
    status: 'complete',
    school: 'University of Ghana School of Medicine and Dentistry',
    img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
    awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
    languages: ['English'],
    hobbies: [
      'Hiking',
      'Interested in history of Ancient Empires',
      'Annapolis Triathon Club',
      'Member of U.S. Masters Swimming',
    ],
  },
  {
    firstName: 'Amy',
    lastName: 'Wong',
    time: new Date(),
    progress: null,
    id: 6,
    status: 'complete',
    school: 'University of Ghana School of Medicine and Dentistry',
    img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
    awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
    languages: ['English'],
    hobbies: [
      'Hiking',
      'Interested in history of Ancient Empires',
      'Annapolis Triathon Club',
      'Member of U.S. Masters Swimming',
    ],
  },
  {
    firstName: 'Jen',
    lastName: 'Hoffman',
    time: new Date(),
    progress: 'completed',
    id: 7,
    status: 'complete',
    school: 'University of Ghana School of Medicine and Dentistry',
    img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
    awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
    languages: ['English'],
    hobbies: [
      'Hiking',
      'Interested in history of Ancient Empires',
      'Annapolis Triathon Club',
      'Member of U.S. Masters Swimming',
    ],
  },
  {
    firstName: 'Jen',
    lastName: 'Hoffman',
    time: new Date(),
    progress: 'completed',
    id: 8,
    status: 'complete',
    school: 'University of Ghana School of Medicine and Dentistry',
    img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
    awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
    languages: ['English'],
    hobbies: [
      'Hiking',
      'Interested in history of Ancient Empires',
      'Annapolis Triathon Club',
      'Member of U.S. Masters Swimming',
    ],
  },
  {
    firstName: 'Jen',
    lastName: 'Hoffman',
    time: new Date(),
    progress: 'completed',
    id: 9,
    status: 'complete',
    school: 'University of Ghana School of Medicine and Dentistry',
    img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
    awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
    languages: ['English'],
    hobbies: [
      'Hiking',
      'Interested in history of Ancient Empires',
      'Annapolis Triathon Club',
      'Member of U.S. Masters Swimming',
    ],
  },
  {
    firstName: 'Jen',
    lastName: 'Hoffman',
    time: new Date(),
    progress: 'completed',
    id: 10,
    status: 'complete',
    school: 'University of Ghana School of Medicine and Dentistry',
    img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
    awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
    languages: ['English'],
    hobbies: [
      'Hiking',
      'Interested in history of Ancient Empires',
      'Annapolis Triathon Club',
      'Member of U.S. Masters Swimming',
    ],
  },
  {
    firstName: 'Jen',
    lastName: 'Hoffman',
    time: new Date(),
    progress: 'completed',
    id: 11,
    status: 'complete',
    school: 'University of Ghana School of Medicine and Dentistry',
    img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
    awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
    languages: ['English'],
    hobbies: [
      'Hiking',
      'Interested in history of Ancient Empires',
      'Annapolis Triathon Club',
      'Member of U.S. Masters Swimming',
    ],
  },
  {
    firstName: 'Jen',
    lastName: 'Hoffman',
    time: new Date(),
    progress: 'completed',
    id: 12,
    status: 'complete',
    school: 'University of Ghana School of Medicine and Dentistry',
    img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
    awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
    languages: ['English'],
    hobbies: [
      'Hiking',
      'Interested in history of Ancient Empires',
      'Annapolis Triathon Club',
      'Member of U.S. Masters Swimming',
    ],
  },
  {
    firstName: 'Jen',
    lastName: 'Hoffman',
    time: new Date(),
    progress: null,
    id: 13,
    status: 'complete',
    school: 'University of Ghana School of Medicine and Dentistry',
    img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
    awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
    languages: ['English'],
    hobbies: [
      'Hiking',
      'Interested in history of Ancient Empires',
      'Annapolis Triathon Club',
      'Member of U.S. Masters Swimming',
    ],
  },
  {
    firstName: 'Jen',
    lastName: 'Hoffman',
    time: new Date(),
    progress: null,
    id: 14,
    status: 'complete',
    school: 'University of Ghana School of Medicine and Dentistry',
    img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
    awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
    languages: ['English'],
    hobbies: [
      'Hiking',
      'Interested in history of Ancient Empires',
      'Annapolis Triathon Club',
      'Member of U.S. Masters Swimming',
    ],
  },
];

const user = {
  firstName: 'Cara',
  lastName: 'Hoffman',
  status: 'complete',
  department: 'General Surgery',
  logo:
    'https://tools.rezrate.com/fmi/webd/APP/connector/2/15942/source/Screen_Shot_2018-08-01_at_9.37.22_PM.png_4031.png',
  img: 'https://zenbooks.ca/wp-content/uploads/2017/09/placeholder-female-square.png',
  school: 'University of Medicine and Sciences',
  awards: ['USMLE Step 1:', 'USMLE Step 2 CK:'],
  languages: ['English'],
  hobbies: [
    'Hiking',
    'Interested in history of Ancient Empires',
    'Annapolis Triathon Club',
    'Member of U.S. Masters Swimming',
  ],
};

export { candidates, user };
