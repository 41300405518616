import React from 'react';
import Selected from './Selected';
import Selection from './Selection';

// determines whether or not the item has been selected.  This is necessary to abstract from ListAnimation so that list animation can still use non-toggleable lists
const SelectionFork = ({ ...props }) => {
  const { listArray, listKey, handleMouseDown, isOrdinal } = props;
  const item = listArray[listKey];
  if (item['selected']) {
    return <Selected {...props} />;
  }
  return <Selection {...props} />;
};

export default SelectionFork;
