import axios from 'axios';
import { determineCredentials } from '../Common.functions';

const constructUrl = (url) => {
  if (process.env.NODE_ENV !== 'production') return `http://localhost:8080/${url}`;

  return `https://rezrateapi.azurewebsites.net/${url}`;
};

// generic fetch function
async function fetchData(url, params) {
  const result = await axios({
    url,
    method: 'GET',
    withCredentials: determineCredentials(),
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    params,
  });
  return result;
}

// generic fetch function
async function fetchDataWithoutCredentials(url, params) {
  const result = await axios({
    url,
    method: 'GET',
    withCredentials: determineCredentials(),
    headers: {
      'Content-Type': 'application/json',
    },
    params,
  });
  return result;
}

async function fetchDataAgnostic(url, params, headers) {
  const completeHeaders = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', ...headers };

  const result = await axios({
    url: constructUrl(url),
    method: 'GET',
    withCredentials: determineCredentials(),
    params,
    headers: completeHeaders,
  });
  return result;
}

async function fetchDataAgnosticWithoutCredentials(url, params, headers) {
  const completeHeaders = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*', ...headers };

  const result = await axios({
    url: constructUrl(url),
    method: 'GET',
    params,
    headers: completeHeaders,
  });
  return result;
}

// generic post function
async function postDataFM(url, params, dates) {
  const result = await axios({
    url,
    method: 'POST',
    withCredentials: determineCredentials(),
    params,
    header: { 'Access-Control-Allow-Origin': '*' },
  });
  return result;
}

// generic post function
async function postData(url, params, dates) {
  const result = await axios({
    url,
    method: 'POST',
    withCredentials: determineCredentials(),
    params,
    header: { 'Access-Control-Allow-Origin': '*' },
    data: { dates },
  });
  return result;
}

async function postDataWithoutCredentials(url, params, data, headers) {
  const result = await axios({
    url: url,
    method: 'POST',
    headers: { 'Access-Control-Allow-Origin': '*', ...headers },
    params,
    data,
  });
  return result;
}

async function postDataAgnostic(url, params, data, headers) {
  const result = await axios({
    url: constructUrl(url),
    method: 'POST',
    headers: { 'Access-Control-Allow-Origin': '*', ...headers },
    withCredentials: determineCredentials(),
    params,
    data,
  });
  return result;
}

async function postDataAgnosticWithoutCredentials(url, params, data, headers) {
  const result = await axios({
    url: constructUrl(url),
    method: 'POST',
    headers: { 'Access-Control-Allow-Origin': '*', ...headers },
    params,
    data,
  });
  return result;
}

// const constructApiBaseUrl = (subDirectory) => {
//   const
//   return `string`
// }

export {
  fetchData,
  postDataAgnosticWithoutCredentials,
  postData,
  fetchDataAgnostic,
  postDataFM,
  fetchDataWithoutCredentials,
  postDataWithoutCredentials,
  constructUrl,
  postDataAgnostic,
  fetchDataAgnosticWithoutCredentials,
};
