import { faCheck, faPencilAlt, faSpinner, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Button, Col, Row, Table } from 'reactstrap';
import styled from 'styled-components';
import { fetchDataAgnosticWithoutCredentials } from '../../../../Services/dataApi';
import Swal from 'sweetalert2';
import { determineIcon, informUserEvalLocked } from '../../../../Common.functions';
import LoadingDot from '../../../../LoadingDot/LoadingDot';

const faCheckColor = '#66B131';
const faPencilColor = '#FD9A00';
const faTimesColor = '#FF0000';

const EVAL_STATUS_STRING = 'Evaluation Status';

const evalLockedButton = {
  cursor: 'default',
  backgroundColor: 'grey',
};

const StatusWrapper = {
  display: 'flex',
  maxWidth: '100%',
  alignItems: 'center',
};

const Circle = styled.div`
  position: absolute;
  bottom: 5%;
  right: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid rgb(51, 51, 51);
  background-color: #cc0000;
  width: 40px;
  height: 40px;
`;

const renderScoresAndAwards = (scoresAndAwards) => {
  if (scoresAndAwards) {
    return scoresAndAwards
      .filter((item) => item.Visible === 1)
      .sort((a, b) => a.SortOrder - b.SortOrder)
      .map((item) => {
        return (
          <tr key={item.ScoresAwardsCandTitle}>
            <td>{item.ScoresAwardsCandTitle}</td>
            <td>{item.ScoresAwardsCandScore}</td>
          </tr>
        );
      });
  }
  //   <tr>
  //   <td>USMLE STEP 1:</td>
  //   <td>242</td>
  // </tr>
  // <tr>
  //   <td>USMLE STEP 2 CK:</td>
  //   <td>252</td>
  // </tr>
};

const BlueButton = styled(Button)`
  background-color: rgb(17, 103, 165);
  color: #ffffff;
  border-top-color: #1167a5;
  border-right-color: #1167a5;
  border-bottom-color: #1167a5;
  border-left-color: #1167a5;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-right-radius: 3px 3px;
  border-bottom-right-radius: 3px 3px;
  border-bottom-left-radius: 3px 3px;
  border-top-left-radius: 3px 3px;
`;

const DownloadButton = styled.div`
  height: 50px;
  width: 50px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border: 1px solid #bbb;
  border-radius: 50%;
  display: inline-block;
`;

const getDocuments = (selectedUser) => {
  if (selectedUser && selectedUser.CandidateSelectedDocumentJSON) {
    const jsonParsed = JSON.parse(selectedUser.CandidateSelectedDocumentJSON);
    if (jsonParsed.department) {
      const departments = [jsonParsed.department];

      const departmentsNoHiddenOrDeleted = departments.filter((item) => {
        if (item.Deleted || item.Hide) return false;

        return true;
      });
      return departmentsNoHiddenOrDeleted[0];
    }
  }

  return [];
};

const Portfolio = ({
  selectedUser,
  setCarouselIndex,
  requestZoomMeeting,
  duration,
  handleZoomClick,
  activeTab,
  DNRStatus,
  allDayRoom,
  hideHobbies,
  interviewDateUUID,
  hideLanguages,
  Schedules4EvalJoinManualURL,
  disableJoinMeetingButton,
  setDisableJoinMeetingButton,
  hideDocuments,
  hideScoresAndAwards,
  userInformation,
  seasonUUID,
  getEvalAndChangeTab,
  hideCandidateEvaluations,
  globalSettings,
  userIdOnCurrentInterview,
  meetingType,
  evaluationsLocked,
  mobile,
  Schedules4Eval__pkUUID_Schedule,
  setShowSchedule,
  interviewIsToday,
  carouselIndex,
  Status4SelectedDNR,
  setShowLargePhoto,
  setLargePhoto,
}) => {
  const [showCandidateTab, setShowCandidateTab] = useState(true);
  const [portfolioCarouselIndex, setPortfolioCarouselIndex] = useState(0);
  const [disableButton, setDisableButton] = useState(false);
  const [showPhoto, setShowPhoto] = useState(false);
  const [showMeetingButton, setShowMeetingButton] = useState(false);
  const [documents, setDocuments] = useState(getDocuments(selectedUser));
  const url = '';
  const priorityUrl = Schedules4EvalJoinManualURL;
  const handleEvalClick = (e) => {
    if (evaluationsLocked) {
      informUserEvalLocked();
      return;
    }
    e.preventDefault();
    if (carouselIndex !== 2) {
      setCarouselIndex(2);
    } else {
      setCarouselIndex(0);
    }
    // setShowSchedule(true);
  };
  useEffect(() => {
    setDocuments(getDocuments(selectedUser));
  }, [selectedUser]);

  useEffect(() => {
    if (userIdOnCurrentInterview && selectedUser) {
      setShowMeetingButton(selectedUser.CandidateSelected__pkUUID_Candidate === userIdOnCurrentInterview);
    }
  }, [userIdOnCurrentInterview, selectedUser]);

  const toggleEval = async () => {
    const changed = await getEvalAndChangeTab();
    return changed;
  };

  const determineStatusButton = (candidate) => {
    if (hideCandidateEvaluations) {
      return <div />;
    }
    const onEvalTab = Number(activeTab) === 3;
    if (candidate.CandidateSelectedEvaluationStatus === 'Complete') {
      return (
        <div>
          {!onEvalTab && (
            <Button
              className="longest-button-min-width"
              disabled={disableButton}
              color="warning"
              onClick={(e) => {
                e.preventDefault();
                setDisableButton(true);
                getEvalAndChangeTab()
                  .then((res) => {})
                  .catch((err) => {})
                  .finally(() => {
                    setDisableButton(false);
                  });
              }}
            >
              Modify Evaluation
            </Button>
          )}
        </div>
      );
    }
    if (candidate.CandidateSelectedEvaluationStatus === 'In progress') {
      return (
        <div>
          {!onEvalTab && (
            <Button
              className="longest-button-min-width"
              color="primary"
              style={evaluationsLocked ? evalLockedButton : {}}
              disabled={disableButton}
              onClick={(e) => {
                if (evaluationsLocked) {
                  informUserEvalLocked();
                  return;
                }

                e.preventDefault();
                setDisableButton(true);
                getEvalAndChangeTab()
                  .then((res) => {})
                  .catch((err) => {})
                  .finally(() => {
                    setDisableButton(false);
                  });
              }}
            >
              Continue Evaluation
            </Button>
          )}
        </div>
      );
    }

    return (
      <div>
        {!onEvalTab && (
          <Button
            className="longest-button-min-width"
            color="primary"
            style={evaluationsLocked ? evalLockedButton : {}}
            disabled={disableButton}
            onClick={(e) => {
              if (evaluationsLocked) {
                informUserEvalLocked();
                return;
              }

              e.preventDefault();
              setDisableButton(true);
              getEvalAndChangeTab()
                .then((res) => {})
                .catch((err) => {})
                .finally(() => {
                  setDisableButton(false);
                });
            }}
          >
            Begin Evaluation
          </Button>
        )}
      </div>
    );
  };

  const determineStatusIcon = (candidate) => {
    if (hideCandidateEvaluations) {
      return <div />;
    }
    const onEvalTab = Number(activeTab) === 3;
    if (candidate.CandidateSelectedEvaluationStatus === 'Complete') {
      return (
        <div style={StatusWrapper}>
          <div style={{ color: faCheckColor }}>
            <p data-tip={EVAL_STATUS_STRING}>
              <FontAwesomeIcon color={faCheckColor} icon={faCheck} /> Complete
              {candidate.CandidateSelectedUnableToEvaluate === 1 && (
                <p style={{ margin: 0, fontSize: '0.8em', color: '#000000' }}>(Unable To Evaluate)</p>
              )}
            </p>
          </div>
        </div>
      );
    }
    if (candidate.CandidateSelectedEvaluationStatus === 'In progress') {
      return (
        <Row style={StatusWrapper}>
          <Col md="12" style={{ color: faPencilColor }}>
            <p data-tip={EVAL_STATUS_STRING}>
              <FontAwesomeIcon color={faPencilColor} icon={faPencilAlt} /> In Progress
            </p>
          </Col>
        </Row>
      );
    }

    return (
      <Row style={StatusWrapper}>
        <Col md="6"></Col>
        <Col md="6"></Col>
      </Row>
    );
  };

  const renderDocuments = (document) => {
    if (!document || document.length === 0) {
      return (
        <tr>
          <td>No Assigned Doucments</td>
        </tr>
      );
    }
    return documents.map((document) => {
      const { AccessTokens_fkUUID_Dept } = userInformation;

      const {
        Organization_UUID,
        Department_UUID,
        Season_UUID,
        fk_DocumentType,
        MimeType,
        pk_File,
        Candidate_UUID,
        Name,
      } = document;

      return (
        <tr key={document.Name}>
          <div className="hover_blue icon_wrapper">
            <a
              style={{ textDecoration: 'inherit', color: 'inherit' }}
              target="_blank"
              href={`/viewFile/${Organization_UUID}/${AccessTokens_fkUUID_Dept}/${seasonUUID}/${Candidate_UUID}/ff3cde83-e21a-46da-8ead-5639b660f696/${pk_File}/${fk_DocumentType}/${Name}`}
            >
              <td style={{ display: 'flex', alignItems: 'center' }}>
                {/* path="/viewFile/:organization/:department/:seasonID/:pk_UUID_FM/:tempToken/:pk_File/:pk_DocumentType/:fileName" */}
                {/* <DownloadButton
                  style={{
                    backgroundColor: '#1167a5',
                    // cursor: 'pointer',
                  }}
                > */}
                <FontAwesomeIcon
                  className="font_awesome_icon"
                  size="4x"
                  style={{ color: 'rgb(51, 51, 51)' }}
                  icon={determineIcon(MimeType)}
                />
                {/* </DownloadButton> */}
                <h6 style={{ paddingLeft: '20px' }}>{document.DocumentTypeName}</h6>
              </td>
            </a>
          </div>
        </tr>
      );
    });
  };

  const ButtonText = (progress) => {
    const userOnEval = carouselIndex !== 2;
    if (progress === null) {
      let style = { display: 'flex', width: '100%', justifyContent: 'space-between' };
      if (evalLockedButton) {
        style = { ...style, ...evalLockedButton };
      }

      return (
        <div style={style}>
          {/* <div style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon size="lg" style={{ marginRight: '10px', color: faPencilColor }} icon={faPencilAlt} />
            <h5 style={{ color: faPencilColor }}>In progress</h5>
          </div> */}
          <BlueButton style={{ margin: '10px' }} onClick={handleEvalClick}>
            {userOnEval ? 'Continue Evaluation' : 'Schedule'}
          </BlueButton>
        </div>
      );
    }

    if (progress === 'inProgress') {
      let style = { display: 'flex', width: '100%', justifyContent: 'flex-end' };
      if (evalLockedButton) {
        style = { ...style, ...evalLockedButton };
      }
      return (
        <div style={style}>
          <BlueButton style={{ margin: '10px' }} onClick={handleEvalClick}>
            {userOnEval ? 'Begin Evaluation' : 'Schedule'}
          </BlueButton>
        </div>
      );
    }

    if (progress === 'completed') {
      let style = { display: 'flex', width: '100%', justifyContent: 'space-between' };
      if (evalLockedButton) {
        style = { ...style, ...evalLockedButton };
      }
      return (
        <div style={style}>
          {/* <div style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
            <FontAwesomeIcon size="lg" style={{ marginRight: '10px', color: faCheckColor }} icon={faCheck} />
            <h5 style={{ color: faCheckColor }}>Completed</h5>
          </div> */}
          <BlueButton style={{ margin: '10px' }} onClick={handleEvalClick}>
            {userOnEval ? 'Modify Evaluation' : 'Schedule'}
          </BlueButton>
        </div>
      );
    }
  };

  if (!selectedUser) {
    return <div />;
  }

  if (selectedUser === 'fetching') return <LoadingDot />;

  return (
    <Col style={{ backgroundColor: 'white' }}>
      <ReactTooltip />
      <Row>
        <div style={{ display: 'flex', width: '100%' }}>
          {(selectedUser.CandidateSelectedExternalPhotoURL || selectedUser.CandidateSelectedPhotoSM) && (
            <div
              style={{ width: '50%', position: 'relative', top: 0, cursor: 'pointer' }}
              onClick={() => {
                setShowLargePhoto(true);
                setLargePhoto(selectedUser.CandidateSelectedExternalPhotoURL || selectedUser.CandidateSelectedPhoto);
              }}
            >
              <img
                style={{ height: 'auto', width: '100%', padding: '10px' }}
                src={selectedUser.CandidateSelectedExternalPhotoURL || selectedUser.CandidateSelectedPhotoSM}
              />
              {DNRStatus && (
                <Circle>
                  <p style={{ padding: 0, margin: 0 }}>DNR</p>
                </Circle>
              )}
            </div>
          )}
          {!selectedUser.CandidateSelectedExternalPhotoURL && !selectedUser.CandidateSelectedPhotoSM && (
            <div style={{ width: '50%', position: 'relative', top: 0 }}>
              <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
                <FontAwesomeIcon icon={faUser} size="10x" />
              </div>
            </div>
          )}
          <div style={{ paddingTop: '20px', paddingRight: '10px', paddingBottom: '10px' }}>
            <h4>
              {selectedUser.CandidateSelectedNameLast}, {selectedUser.CandidateSelectedNameFirst}
            </h4>
            <h6>{selectedUser.CandidateSelectedMedSchool}</h6>
            <div style={{ width: '100%' }}>{determineStatusIcon(selectedUser, getEvalAndChangeTab)}</div>
          </div>
        </div>
      </Row>
      <Row style={{ paddingBottom: '20px' }}>{ButtonText(selectedUser.CandidateSelectedEvaluationStatus)}</Row>
      <Row>
        <Col md="6">
          {interviewIsToday && showMeetingButton && meetingType !== 0 && (
            <Button
              className="longest-button-min-width"
              disabled={disableJoinMeetingButton}
              color="success"
              style={{ minWidth: '95px' }}
              // onClick={(e) => {
              //   e.preventDefault();
              //   if (typeof Schedules4EvalJoinManualURL !== 'undefined' && Schedules4EvalJoinManualURL !== '') {
              //     handleZoomClick(Schedules4EvalJoinManualURL);
              //   } else {
              //     requestZoomMeeting(Schedules4Eval__pkUUID_Schedule);
              //   }
              // }}
              onClick={(e) => {
                e.preventDefault();
                const { AzureZoom } = globalSettings;

                if (typeof priorityUrl !== 'undefined' && priorityUrl !== null && priorityUrl === '') {
                  if (AzureZoom) {
                    const { Users4AccessTokenDeptName, AccessTokens_fkUUID_User } = userInformation;
                    if (!Schedules4Eval__pkUUID_Schedule) {
                      handleZoomClick(allDayRoom);
                      return;
                    }
                    fetchDataAgnosticWithoutCredentials('schedule/season/evaluator/interviewDate/virtualMeeting', {
                      scheduleId: Schedules4Eval__pkUUID_Schedule,
                      evaluatorId: AccessTokens_fkUUID_User,
                      duration,
                      departmentName: Users4AccessTokenDeptName,
                    })
                      .then((result) => {
                        const { JoinURL, DialInNumbers, Password } = result.data;
                        handleZoomClick(JoinURL, DialInNumbers, Password);
                      })
                      .catch((err) => {
                        Swal.fire('Error', 'Something went wrong... please try again or contact support.', 'error');
                      })
                      .finally(() => {
                        setDisableJoinMeetingButton(false);
                      });
                    return;
                  }
                  requestZoomMeeting(Schedules4Eval__pkUUID_Schedule);
                } else if (typeof priorityUrl !== 'undefined' && priorityUrl !== null && priorityUrl !== '') {
                  handleZoomClick(priorityUrl);
                } else if (typeof url !== 'undefined' && url !== null && url === '') {
                  handleZoomClick(allDayRoom);
                } else if (typeof url !== 'undefined' && url !== null && url !== '') {
                  handleZoomClick(url);
                } else {
                  handleZoomClick(allDayRoom);
                }
              }}
            >
              {disableJoinMeetingButton ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Join Meeting'}
            </Button>
          )}
        </Col>
        <Col md="6">
          <div style={{ width: '100%' }}>{determineStatusButton(selectedUser, getEvalAndChangeTab)}</div>
        </Col>
      </Row>
      <div>
        {!hideScoresAndAwards && (
          <Row>
            <div style={{ padding: '10px', width: '100%', textAlign: 'left' }}>
              <Table>
                <thead>
                  <tr>
                    <th>Scores and Awards</th>
                  </tr>
                </thead>
                <tbody>{renderScoresAndAwards(selectedUser.SAPortal)}</tbody>
              </Table>
            </div>
          </Row>
        )}
        {!hideDocuments && (
          <Row>
            <div style={{ padding: '10px', width: '100%', textAlign: 'left' }}>
              <Table>
                <thead>
                  <tr>
                    <th>Documents {documents.length > 0 && <span>({documents.length} Available)</span>}</th>
                  </tr>
                </thead>
                <tbody>{renderDocuments(documents)}</tbody>
              </Table>
            </div>
          </Row>
        )}
      </div>
      <div>
        {!hideLanguages && (
          <Row>
            <div style={{ padding: '10px', width: '100%', textAlign: 'left' }}>
              <Table>
                <thead>
                  <tr>
                    <th>Languages</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{selectedUser.CandidateSelectedLanguages}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Row>
        )}
        {!hideHobbies && (
          <Row>
            <div style={{ padding: '10px', width: '100%', textAlign: 'left' }}>
              <Table>
                <thead>
                  <tr>
                    <th>Hobbies and Interests</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{selectedUser.CandidateSelectedHobbies}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Row>
        )}
      </div>
    </Col>
  );
};

export default Portfolio;
