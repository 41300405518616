const initialState = {
  user: null,
  isDemo: false,
};

const InterviewDatesRouterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SET_NAME':
      return {
        ...state,
        name: action.payload,
      };
    case 'SET_IS_DEMO':
      return {
        ...state,
        isDemo: action.payload,
      };
    default:
      return state;
  }
};

export default InterviewDatesRouterReducer;
