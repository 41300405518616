// src/react-auth0-spa.js
import createAuth0Client from '@auth0/auth0-spa-js';
import React, { useContext, useEffect, useState } from 'react';
import { userFirstedLoggedIn } from './authApiCalls';

let clientId = 'zlw5FExMys9BVtY6png5dZGBNTqdDxe5';

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);
const NEW_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, 'https://portal.rezrate.com/');

export const Auth0Context = React.createContext({});
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [loginHint, setLoginHint] = useState('');
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [userChecked, setUserChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userObject, setUserObject] = useState();
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        const auth0FromHook = await createAuth0Client({
          ...initOptions,
          login_hint: loginHint,
          clientId,
          redirect_uri: 'https://portal.rezrate.com/',
        });
        setAuth0(auth0FromHook);

        if (window.location.search.includes('code=') && window.location.search.includes('state=')) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          NEW_REDIRECT_CALLBACK(appState);
        }
        const isAuthenticated = await auth0FromHook.isAuthenticated();

        setIsAuthenticated(isAuthenticated);

        if (isAuthenticated) {
          const user = await auth0FromHook.getUser();
          // checkIfUserExists(user, auth0FromHook);
          userFirstedLoggedIn(user);
          setUser(user);
        }
      } catch (err) {}

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, [loginHint]);

  // const checkIfUserExists = (user, auth0FromHook) => {
  //   // Only need to check once per login

  //   if (userChecked) return;

  //   const { sub } = user;
  //   setUserChecked(true);
  //   const result = (...p) =>
  //     auth0FromHook.getTokenSilently(...p).then((token) => {
  //       dataApi
  //         .postData("/users", { user }, formatBearerToken(token))
  //         .then((res) => {
  //           setUserObject(res.data);
  //         })
  //         .catch((err) => {
  //         });
  //     });
  //   result();
  // };

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        userObject,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        setLoginHint: (...p) => setLoginHint(...p),
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
