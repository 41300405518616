import moment from 'moment';
import React, { Fragment, useState } from 'react';
import { shouldJoinMeetingButtonBeVisisble } from '../../../../Common.functions';
import { createTimeTracker } from '../../../../Common.jsx';

const ScheduleItem = ({
  item = {},
  i,
  differences,
  handleIterativeVariables,
  chosenCandidate,
  interviewIsToday,
  prevItem,
  date,
  userIdOnCurrentInterview,
  firstBlockEarlyJoin,
  heights,
  isMobile,
  showRed,
  isPassingPeriod,
  selectedCandidateIdLocal,
  setDuration,
  duration,
  setUserIdOnCurrentInterview,
  setChosenCandidate,
  getCandidate,
  joinMeetingButton,
  determineEvent,
  determineIcon,
  getPercentageTime,
  currentTime,
  tableRowOpacity,
}) => {
  // need to check if meeting should be visisble locally so it doesn't extend into additional days
  const [showJoinMeetingLocal, setShowJoinMeetingButtonLocal] = useState(false);

  // check if start time or endtime has changed
  const differenceInTime = differences['Schedules4EvalTime' + i] || differences['Schedules4EvalTimeEnd' + i];

  const differenceInName = differences['Candidates4DateNameFull' + i];
  const joinMeetingButtonVisible = shouldJoinMeetingButtonBeVisisble(
    item,
    false,
    5,
    'Schedules4EvalTime',
    'Schedules4EvalTimeEnd',
    prevItem,
    i,
    firstBlockEarlyJoin,
  );

  if (joinMeetingButtonVisible) {
    handleIterativeVariables(item);
    if (showJoinMeetingLocal === false) setShowJoinMeetingButtonLocal(true);
  } else {
    if (showJoinMeetingLocal === true) setShowJoinMeetingButtonLocal(false);
  }

  const uniqueId = `${item.Schedules4Eval_fkUUID_User}${item.Schedules4EvalTime}${item.Schedules4EvalTimeEnd}`;

  const chosenScheduleItem = uniqueId === chosenCandidate;
  const { Candidates4DateEvaluationStatus, Candidates4DateUnableToEvaluate } = item;
  let last = false;
  let dateformatTime;
  let difference;
  let color = null;
  let coverBorder = null;
  // date.InterviewDatesVLDate
  const stringDate = date.InterviewDatesVLDate + ' ' + item.Schedules4PortalTime;
  const numberDate = Date.parse(stringDate);
  dateformatTime = new Date(numberDate);
  difference = getPercentageTime(currentTime, tableRowOpacity, item.time, item.endTime);

  // } else {
  //   const hourLater = new Date(numberDate);
  //   // hourLater.setHours(item.Schedules4EvalTime.getHours());
  //   // hourLater.setSeconds(item.Schedules4EvalTime.getSeconds() + 100);
  //   difference = getPercentageTime(currentTime, tableRowOpacity, item.Schedules4EvalTime, hourLater);
  //   last = true;
  // }

  if (difference.height !== 0) {
    color = '#0C7489';
    coverBorder = '1px solid #0c7489';
  } else {
    color = '#FCDC4D';
    coverBorder = 'none';
  }
  let borderTop = 'auto';

  let fontWeight = 500;

  if (i === 0) {
    borderTop = 'none';
  }

  if (difference.height !== 0 && difference.height !== '100%') {
    fontWeight = 700;
  }

  return (
    /* eslint-disable */
    <Fragment>
      <tr
        key={uniqueId}
        className={item.isPassingPeriod ? 'stripes passing_wrapper' : 'hover_blue'}
        onClick={() => {
          const { Schedules4EvalTimeDuration, Schedules4EvalAltText, Schedules4EvalDisplay, message } = item;

          // if item is a break it should have no click event
          if (
            (Schedules4EvalAltText && Schedules4EvalAltText.toUpperCase() === 'BREAK') ||
            (Schedules4EvalDisplay && Schedules4EvalDisplay.toUpperCase() === 'BREAK') ||
            (message && message.toUpperCase() === 'BREAK')
          ) {
            return;
          }

          setDuration(Schedules4EvalTimeDuration);
          if (item && item.Schedules4Eval_fkUUID_Candidate) {
            if (isMobile && item.Schedules4Eval_fkUUID_Candidate === selectedCandidateIdLocal) {
              changeToCandidateTab();
            }
            getCandidate(item.Schedules4Eval_fkUUID_Candidate, item);
            setChosenCandidate(uniqueId);
          }
        }}
        style={{ cursor: 'pointer', backgroundColor: chosenScheduleItem ? '#d4ebf2' : item.color }}
      >
        {/* <td style={{ ...borderTop, position: 'relative', top: '-13px' }}> */}
        {interviewIsToday && heights.length > 0 && (
          <div style={{ position: 'relative', top: '-13px' }}>
            <div>{createTimeTracker(heights, i, difference, color, coverBorder, true)}</div>
          </div>
        )}
        {/* </td> */}
        <td
          style={{
            borderTop,
            paddingTop: isPassingPeriod ? '0' : 'auto',
            paddingBottom: isPassingPeriod ? '0' : 'auto',
          }}
        >
          <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
            <div className={'red_staging ' + (differenceInTime && showRed ? 'red' : '')}>
              <h5 style={{ fontWeight, margin: 0, color: '#000' }}>{moment(item.time).format('h:mm A')}</h5>
            </div>
          </div>
        </td>
        <td style={{ borderTop, paddingBottom: '12px' }} id={'tableRow' + i}>
          {!isMobile && <div>{determineIcon(item)}</div>}
        </td>
        <td style={{ borderTop }}>
          {!isPassingPeriod && (
            <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
              <div className={'red_staging ' + (differenceInName && showRed ? 'red' : '')}>
                {determineEvent(item, Candidates4DateEvaluationStatus, Candidates4DateUnableToEvaluate)}
              </div>
            </div>
          )}
        </td>
        {/* if it's mobile have to to combine rows */}
        <td style={{ cursor: 'pointer', backgroundColor: chosenScheduleItem ? '#d4ebf2' : '' }}>
          {joinMeetingButton(joinMeetingButtonVisible, interviewIsToday, item)}
        </td>
      </tr>
    </Fragment>
    /* eslint-enable */
  );
};

export default ScheduleItem;
