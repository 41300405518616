import styled from 'styled-components';
import { cssSizes } from '../Body/Common.style';

const ImageWrapper = styled.div`
  height: 100px;
  width: auto;
  ${cssSizes.phoneLandscape` 
    height: 50px;
  `}
  & > img {
    height: 100%;
    width: auto;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.imgWidth}px auto;
  ${cssSizes.phoneLandscape` 
    grid-template-columns: ${(props) => props.imgWidth}px auto;
  `}
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-left: 10px;
  height: 100%;
  h4 {
    ${cssSizes.phoneLandscape` 
    font-size: 0.7rem;
  `}
  }
  h2 {
    ${cssSizes.phoneLandscape` 
    font-size: 1.0rem;
  `}
  }
`;
export { ImageWrapper, Wrapper, TextWrapper };
