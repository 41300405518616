import { connect } from 'react-redux';
import { setUser } from './InterviewDatesRouter.action';
import { setOrder, setDateList } from '../Animation/ListAnimation.action';
import InterviewDateRouter from './InterviewDatesRouter';

const createNumArray = (listArray) => {
  if (listArray) {
    const order = [];
    for (let i = 0; i < listArray.length; i++) {
      order.push(i);
    }
    return order;
  }
  return null;
};

const mapStateToProps = (state) => ({
  user: state.userInfo.user,
  order: state.animation.order,
  listArray: state.animation.listArray,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (userObject) => dispatch(setUser(userObject)),
  setOrder: (listArray) => {
    return dispatch(setOrder(createNumArray(listArray)));
  },
  setDateList: (listArray) => {
    return dispatch(setDateList(listArray));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InterviewDateRouter);
