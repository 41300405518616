import { faCalendarAlt, faLock, faPencilAlt, faUser, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Col, Row, TabContent, TabPane } from 'reactstrap';
import Swal from 'sweetalert2';
import { informUserEvalLocked, smoothScrollTop } from '../../../../../Common.functions';
import CandidatesAlphabetical from '../CandidatesAlphabetical';
import Evaluation from '../Evaluation';
import Portfolio from '../Portfolio';
import Schedule from '../Schedule';
import Style from './EvaluateMobile.style';

const disabledColor = '#a8a8a8';
const iconSize = '2x';

const EvaluateMobile = ({
  userInformation,
  interviewDateUUID,
  selectedUser,
  selectedCandidateId,
  candidateInfo,
  allDayRoom,
  hideDocuments,
  hideCandidateEvaluations,
  hideScoresAndAwards,
  token,
  evaluationsLocked,
  setCandidateEvaluation,
  differences,
  alarms,
  informUserMeetingIsStartingLocally,
  setAlarms,
  filterCandidateInfo,
  setSelectedUser,
  incompleteCandidates,
  meetingType,
  hideHobbies,
  hideLanguages,
  interviewHasPassed,
  globalSettings,
  globalSettingsLoading,
  seasonUUID,
  setPopupDescription,
  setDuration,
  duration,
  requestZoomMeeting,
  handleZoomClick,
  disableJoinMeetingButton,
  setDisableJoinMeetingButton,
  currentlyGettingEval,
  Schedules4Eval__pkUUID_Schedule,
  firstBlockEarlyJoin,
  Schedules4EvalJoinManualURL,
  interviewIsToday,
  setSchedules4EvalJoinManualURL,
  setSchedules4Eval__pkUUID_Schedule,
  setShowLargePhoto,
  setUserIdOnCurrentInterview,
  setShowRed,
  currentCandidateSortType,
  sortCandAscending,
  showRed,
  userIdOnCurrentInterview,
  DNRStatus,
  setDNRStatus,
  setEvalStatus,
  evalStatus,
  setLargePhoto,
  date,
  getCandidateEvaluation,
  getInfo,
  schedule,
  timeoutDurationInMinutes,
  postRequest,
  getCandidate,
  candidateEvaluation,
}) => {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [activeTab, setActiveTab] = useState('1');
  const [hasSchedule, setHasSchedule] = useState(null);
  const [selectedCandidateIdLocal, setSelectedCandidateIdLocal] = useState(null);
  const [disableEvaluation, setDisableEvaluation] = useState(true);
  const [error, setError] = useState(false);
  const [disableScreen, setDisableScreen] = useState(false);
  const [showSchedule, setShowSchedule] = useState(false);
  const [renderEval, setRenderEval] = useState(false);
  const [chosenCandidate, setChosenCandidate] = useState({ uniqueId: null, candidate: null });
  const [firstUserRecieved, setFirstUserRecieved] = useState(false);
  const stylething = { width: '100vw', display: 'none' };

  useEffect(() => {
    if (evalStatus === 'Complete') {
      getInfo();
      setActiveTab('3');
    }
  }, [evalStatus]);

  const getDefaultTab = () => {
    if (date === 'Prescreen') {
      toggle('2');
      return;
    }
    if (hasSchedule) {
      toggle('1');
    } else {
      toggle('2');
    }
  };

  useEffect(() => {
    if (schedule && schedule.length > 0) {
      setHasSchedule(true);
    } else {
      setHasSchedule(false);
    }
  }, [schedule]);

  useEffect(() => {
    if (hasSchedule !== null) getDefaultTab();
  }, [date, hasSchedule]);

  useEffect(() => {
    if (selectedUser && selectedUser !== 'fetching') {
      if (selectedUser.CandidateSelected__pkUUID_Candidate !== selectedCandidateIdLocal) {
        setFirstUserRecieved(true);
        setActiveTab('3');
        smoothScrollTop();
        setSelectedCandidateIdLocal(selectedUser.CandidateSelected__pkUUID_Candidate);
      }
    }

    if ((!selectedUser && disableEvaluation === false) || selectedUser === 'fetching') {
      setDisableEvaluation(true);
    } else if (selectedUser && selectedUser !== 'fetching' && disableEvaluation === true) {
      setDisableEvaluation(false);
    }
  }, [selectedUser]);

  const changeEvalTab = () => {
    setRenderEval(false);
    return getCandidateEvaluation(selectedUser.CandidateSelected__pkUUID_Candidate).then((res) => {
      setRenderEval(true);
      smoothScrollTop();
      if (!res.error) {
        setActiveTab('4');
        return true;
      } else {
        setError(true);
        return false;
      }
    });
  };

  const changeToCandidateTab = () => {
    setActiveTab('3');
  };

  const getEvalAndChangeTab = () => {
    if (selectedUser.CandidateSelectedEvaluationStatus === 'Complete') {
      return Swal.fire({
        title: 'Do you want to modify this evaluation?',
        text: `Status will be set to "In progress."`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Modify',
      }).then((result) => {
        if (result.value === true) {
          // need to put current user to in progress
          setSelectedUser((prevState) => {
            // eslint-disable-next-line no-undef
            const clone = structuredClone(prevState);
            clone.CandidateSelectedEvaluationStatus = 'In progress';
            return clone;
          });
          return changeEvalTab();
        }
      });
    } else {
      return changeEvalTab();
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div style={{ top: 0 }} className="mobile_wrapper">
      <div style={{ minHeight: 'calc(100vh - 186px)' }}>
        <TabContent activeTab={activeTab} style={{ width: '100%' }}>
          {schedule && (
            <TabPane tabId="1">
              <Schedule
                date={date}
                requestZoomMeeting={requestZoomMeeting}
                handleZoomClick={handleZoomClick}
                getCandidate={getCandidate}
                isMobile={true}
                changeToCandidateTab={changeToCandidateTab}
                firstBlockEarlyJoin={firstBlockEarlyJoin}
                hideCandidateEvaluations={hideCandidateEvaluations}
                userInformation={userInformation}
                alarms={alarms}
                setDuration={setDuration}
                duration={duration}
                hideCandidateEvaluations={hideCandidateEvaluations}
                setAlarms={setAlarms}
                informUserMeetingIsStartingLocally={informUserMeetingIsStartingLocally}
                interviewDateUUID={interviewDateUUID}
                differences={differences}
                globalSettings={globalSettings}
                globalSettingsLoading={globalSettingsLoading}
                setShowRed={setShowRed}
                setSelectedUser={setSelectedUser}
                showRed={showRed}
                meetingType={meetingType}
                selectedCandidateIdLocal={selectedCandidateIdLocal}
                disableJoinMeetingButton={disableJoinMeetingButton}
                setPopupDescription={setPopupDescription}
                setDisableJoinMeetingButton={setDisableJoinMeetingButton}
                Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
                setSchedules4EvalJoinManualURL={setSchedules4EvalJoinManualURL}
                Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
                setSchedules4Eval__pkUUID_Schedule={setSchedules4Eval__pkUUID_Schedule}
                setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
                userIdOnCurrentInterview={userIdOnCurrentInterview}
                interviewIsToday={interviewIsToday}
                schedule={schedule}
                postRequest={postRequest}
              />
            </TabPane>
          )}
          <TabPane tabId="2">
            <Row>
              <Col md="12">
                <CandidatesAlphabetical
                  selectedUser={selectedUser}
                  getCandidate={getCandidate}
                  Schedules4Eval__pkUUID_Schedule={Schedules4Eval__pkUUID_Schedule}
                  setSchedules4Eval__pkUUID_Schedule={setSchedules4Eval__pkUUID_Schedule}
                  setChosenCandidate={setChosenCandidate}
                  chosenCandidate={chosenCandidate}
                  isMobile={true}
                  disableJoinMeetingButton={disableJoinMeetingButton}
                  setDisableJoinMeetingButton={setDisableJoinMeetingButton}
                  hideCandidateEvaluations={hideCandidateEvaluations}
                  setDNRStatus={setDNRStatus}
                  changeToCandidateTab={changeToCandidateTab}
                  selectedCandidateIdLocal={selectedCandidateIdLocal}
                  Schedules4EvalJoinManualURL={Schedules4EvalJoinManualURL}
                  setSchedules4EvalJoinManualURL={setSchedules4EvalJoinManualURL}
                  candidateInfo={candidateInfo}
                  setUserIdOnCurrentInterview={setUserIdOnCurrentInterview}
                  userIdOnCurrentInterview={userIdOnCurrentInterview}
                  requestZoomMeeting={requestZoomMeeting}
                  interviewIsToday={interviewIsToday}
                  handleZoomClick={handleZoomClick}
                  postRequest={postRequest}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Portfolio
              selectedUser={selectedUser}
              setCarouselIndex={setCarouselIndex}
              mobile="true"
              // TODO: need to set this up for mobile
              getEvalAndChangeTab={() => setActiveTab('3')}
              DNRStatus={DNRStatus}
              interviewDateUUID={interviewDateUUID}
              allDayRoom={allDayRoom}
              meetingType={meetingType}
              hideDocuments={hideDocuments}
              hideScoresAndAwards={hideScoresAndAwards}
              hideCandidateEvaluations={hideCandidateEvaluations}
              userInformation={userInformation}
              disableJoinMeetingButton={disableJoinMeetingButton}
              setDisableJoinMeetingButton={setDisableJoinMeetingButton}
              globalSettings={globalSettings}
              globalSettingsLoading={globalSettingsLoading}
              evaluationsLocked={evaluationsLocked}
              seasonUUID={seasonUUID}
              setDuration={setDuration}
              duration={duration}
              activeTab={activeTab}
              hideHobbies={hideHobbies}
              hideLanguages={hideLanguages}
              setDNRStatus={setDNRStatus}
              setChosenCandidate={setChosenCandidate}
              Status4SelectedDNR={candidateEvaluation ? candidateEvaluation.Status4SelectedDNR : false}
              chosenCandidate={chosenCandidate}
              setShowSchedule={setShowSchedule}
              setShowLargePhoto={setShowLargePhoto}
              setLargePhoto={setLargePhoto}
            />
          </TabPane>
          <TabPane tabId="4">
            {selectedUser && (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <img style={{ height: '50px', width: 'auto' }} src={selectedUser.CandidateSelectedPhotoSM} />
                <p style={{ padding: 0, margin: 0 }}>{selectedUser.CandidateSelectedNameFull}</p>
              </div>
            )}
            {!disableEvaluation && renderEval && !evaluationsLocked && !hideCandidateEvaluations && (
              <Evaluation
                candidateEvaluation={candidateEvaluation}
                candidateInfo={candidateInfo}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                evalTab="4"
                userInformation={userInformation}
                date={date}
                setDisableScreen={setDisableScreen}
                setSelectedUser={setSelectedUser}
                schedule={schedule}
                getInfo={getInfo}
                DNRStatus={DNRStatus}
                setCandidateEvaluation={setCandidateEvaluation}
                toggleToSchedule={() => {
                  if (schedule && schedule.length) {
                    setActiveTab('1');
                  } else {
                    setActiveTab('2');
                  }
                }}
                setDNRStatus={setDNRStatus}
                setEvalStatus={setEvalStatus}
                selectedUser={selectedUser}
                evalStatus={evalStatus}
                token={token}
                timeoutDurationInMinute={timeoutDurationInMinutes}
                typeOfEvent="touchstart"
                selectedCandidateId={selectedCandidateId}
                currentlyGettingEval={currentlyGettingEval}
                getCandidateEvaluation={getCandidateEvaluation}
                Status4SelectedDNR={candidateEvaluation ? candidateEvaluation.Status4SelectedDNR : false}
              />
            )}
          </TabPane>
        </TabContent>
      </div>
      <Style.FixedNavigation>
        <Style.NavigationSub>
          <div>
            <div
              onClick={() => {
                if (hasSchedule && activeTab !== '1') {
                  setCarouselIndex(0);
                  smoothScrollTop();
                  setActiveTab('1');
                }
              }}
              style={{ backgroundColor: hasSchedule ? '#084887' : disabledColor }}
            >
              <FontAwesomeIcon style={{ left: '16px' }} size={iconSize} icon={faCalendarAlt} />
            </div>
            <p>Schedule</p>
          </div>
          <div>
            <div
              onClick={() => {
                if (activeTab !== '2') {
                  setCarouselIndex(1);
                  smoothScrollTop();
                  setActiveTab('2');
                }
              }}
              style={{ backgroundColor: '#D64933' }}
            >
              <FontAwesomeIcon style={{ left: '11px' }} size={iconSize} icon={faUsers} />
            </div>
            <p>Candidates</p>
          </div>
          <div>
            <div
              onClick={() => {
                getCandidateEvaluation(selectedUser.CandidateSelected__pkUUID_Candidate).then((res) => {
                  if (!res.error) {
                    setActiveTab('3');
                  } else {
                    setError(true);
                  }
                });
              }}
              style={{ backgroundColor: firstUserRecieved && selectedUser !== 'fetching' ? '#ADC698' : disabledColor }}
            >
              <FontAwesomeIcon style={{ left: '15px' }} size={iconSize} icon={faUser} />
            </div>
            <p>Details</p>
          </div>
          <div>
            <div
              onClick={() => {
                if (hideCandidateEvaluations) {
                  return;
                }
                if (evaluationsLocked) {
                  informUserEvalLocked();
                  return;
                }

                if (disableEvaluation) return;
                getEvalAndChangeTab();
              }}
              style={{
                backgroundColor:
                  firstUserRecieved && selectedUser !== 'fetching' && !evaluationsLocked && !hideCandidateEvaluations
                    ? '#F7B538'
                    : disabledColor,
              }}
            >
              <FontAwesomeIcon
                style={{ left: informUserEvalLocked ? '16px' : '14px' }}
                size={iconSize}
                icon={informUserEvalLocked ? faLock : faPencilAlt}
              />
            </div>
            <p>Evaluation</p>
          </div>
        </Style.NavigationSub>
      </Style.FixedNavigation>
    </div>
  );
};

export default EvaluateMobile;
