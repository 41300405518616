import { faCloudUploadAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Col, Row } from 'reactstrap';
import Swal from 'sweetalert2';
import { fetchDataAgnosticWithoutCredentials } from '../../Services/dataApi';
import Error from '../../Body/Statuses/Error';
import Warning from '../../Body/Statuses/Warning';
import Loading from '../../Body/Statuses/Loading';
import { constructFilemakerBaseUrl } from '../../Common.functions';

const thumb = {};

const thumbInner = {};

const CandidatePhoto = ({ match }) => {
  const [hasPhoto, setHasPhoto] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isDemo, setIsDemo] = useState(false);
  const [isPosting, setIsPosting] = useState(false);
  const [globalSettings, setGlobalSettings] = useState({});
  const [globalSettingsLoading, setGlobalSettingsLoading] = useState(true);
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [currentProgress, setCurrentProgress] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [files, setFiles] = useState([]);
  const { id } = match.params;

  useEffect(() => {
    const url = 'groupInfo/settings/global';
    fetchDataAgnosticWithoutCredentials(url, null)
      .then((result) => {
        setGlobalSettings(result.data);
      })
      .catch((err) => {})
      .finally(() => {
        setGlobalSettingsLoading(false);
      });
  }, []);

  const thumbs = () => {
    return files.map((file) => (
      <div style={thumb} key={file.name}>
        <div style={thumbInner}>
          <img src={file.preview} style={{ width: '100%' }} />
        </div>
      </div>
    ));
  };

  const singleCandidateObject = (files) => {
    const formData = new FormData();
    for (var i in files) {
      formData.append(`photo`, files[i]);
      formData.append('w', 1);
      formData.append('h', 1);
      formData.append('x', 1);
      formData.append('y', 1);
    }
    return formData;
  };

  const fileUpload = (formData) => {
    // return;
    // const url = 'https://rezrateapi.azurewebsites.net/files';
    const isLocal = window.location.href.includes('localhost:3000/');
    const url = isLocal
      ? 'http://localhost:8080/files/candidate'
      : 'https://rezrateapi.azurewebsites.net/files/candidate';
    setIsPosting(true);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setCurrentProgress(percentCompleted);
      },
    };
    return Axios.post(url, formData, config)
      .then((res) => {
        postToFM(res.data.uri);
      })
      .catch((err) => {
        Swal.fire('Oops', 'Something went wrong. Please try again or contact support', 'error').then(() => {});
        return false;
      })
      .finally(() => {
        setIsPosting(false);
      });
  };

  const avatarObject = (files, documentTypePk) => {
    const file = files[0];

    const formData = new FormData();
    for (var i in file) {
      const jsonToSend = `{ "name":"${file.name}", "checked": "true", "candidateId": "${currentUser.__pkUUID_Candidate}", "container":"avatar", "avatar": "true"}`;
      formData.append(`files`, file, jsonToSend);
    }
    return formData;
  };

  const avatarUpload = (formData) => {
    // return;
    // const url = 'https://rezrateapi.azurewebsites.net/files';
    const isLocal = window.location.href.includes('localhost:3000/');
    const url = isLocal
      ? 'http://localhost:8080/files/candidate'
      : 'https://rezrateapi.azurewebsites.net/files/candidate';
    setIsPosting(true);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
      },
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setCurrentProgress(percentCompleted);
      },
    };
    return Axios.post(url, formData, config)
      .then((res) => {
        setCurrentUser((prevState) => {
          const copy = JSON.parse(JSON.stringify(prevState));
          copy.ExternalPhotoURL = res.data.uri;
          return copy;
        });
        setHasPhoto(true);
        postToFM(res.data.uri);
      })
      .catch((err) => {
        Swal.fire('Oops', 'Something went wrong. Please try again or contact support', 'error').then(() => {});
        return false;
      })
      .finally(() => {
        setIsPosting(false);
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const incorrectLength = acceptedFiles.length !== 1;
      const incorrectBytes = acceptedFiles[0].size > 5000000;
      if (incorrectBytes) {
        Swal.fire('Oops...', `It looks like the file might be a little too big, make sure it's under 5mb`, 'error');
        return;
      }

      if (incorrectLength) {
        Swal.fire('Oops,', 'Please make sure you only upload a single image file', 'error');
        return;
      }
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );

      const fileData = avatarObject(acceptedFiles);
      avatarUpload(fileData);
    },
  });

  const succesMessage = () => {
    Swal.fire('Success!', 'Your new file has been uploaded', 'success');
  };

  const errorMessage = () => {
    Swal.fire('Oops,', 'Something went wrong, please try again later or contact support', 'error');
  };

  const postToFM = (uri) => {
    let url = `https://tools.rezrate.com/api/v2/candidate/photo/?id=${currentUser.__pkUUID_Candidate}`;

    if (globalSettings && globalSettings.EnableRelay) {
      url = `https://tools2.rezrate.com/api/v2/relay/?id=${currentUser.__pkUUID_Candidate}&endpoint=api/v2/candidate/photo`;
    }

    const formData = new FormData();

    formData.append('photoURI', uri);
    Axios.post(url, formData)
      .then((result) => {
        return true;
      })
      .catch((err) => {
        return false;
      });
  };

  const getCandidateThroughFilemaker = () => {
    let url = `${constructFilemakerBaseUrl()}/candidate/upload/?id=${id}`;

    if (globalSettings && globalSettings.EnableRelay) {
      url = `https://tools2.rezrate.com/api/v2/relay/?id=${id}&endpoint=api/v2/candidate/upload`;
    }

    Axios({
      method: 'get',
      url,
      withCredentials: true,
      headers: {},
    })
      .then((res) => {
        if (res.data.error && res.data.error === 429) {
          setShowWarning(true);
          setWarningMessage({
            header: res.data.message,
            subHeader: '',
          });
          return;
        }
        if (res.data && res.data.data && res.data.data.error !== true) {
          setCurrentUser(res.data.data);
          if (res.data.data.ExternalPhotoURL && res.data.data.ExternalPhotoURL !== '') {
            setHasPhoto(true);
          }
        } else if (res.data.error !== true) {
          setCurrentUser(res.data);
          if (res.data && res.data.ExternalPhotoURL && res.data.ExternalPhotoURL !== '') {
            setHasPhoto(true);
          }
        }
      })
      .catch((err) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (globalSettingsLoading) {
      return;
    }
    if (id.toUpperCase() === 'DEMO') {
      setIsDemo(true);
      setCurrentUser({});
      setIsLoading(false);
      return;
    }

    fetchDataAgnosticWithoutCredentials('candidate/photo', { id })
      .then((res) => {
        if (res.status === 204) {
          // if no candidate was found go the traditional route through
          // filemaker
          getCandidateThroughFilemaker();
        } else {
          setCurrentUser(res.data);
          if (res.data.ExternalPhotoURL && res.data.ExternalPhotoURL.trim() !== '') {
            setHasPhoto(true);
          } else {
            setHasPhoto(false);
          }
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [globalSettingsLoading]);

  if (showWarning) {
    return <Warning header={warningMessage.header} subHeader={warningMessage.subHeader} />;
  }

  if (isLoading) {
    return <Loading />;
  }

  if ((currentUser === null || !currentUser) && !isDemo) {
    return <Error header="Error" subHeader="We couldn't find a user with that ID" />;
  }

  return (
    <Row style={{ padding: '15px' }}>
      {/* <Col md={6}></Col> */}
      <Col
        md={{ size: 6, offset: 3 }}
        offset
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '' }}
      >
        <Row>
          <div style={{ width: '100%' }}>
            <div style={{ margin: '5px', border: '1px dashed #000', width: '100%', height: 'auto', minHeight: '50vh' }}>
              {isUploading && (
                <div
                  style={{
                    minHeight: '50vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <FontAwesomeIcon icon={faSpinner} size="2x" spin />
                  <p style={{ paddingTop: '5px' }}>Uploading...</p>
                </div>
              )}
              {!isUploading && (
                <div
                  className="hover_blue"
                  {...getRootProps()}
                  style={{
                    height: '100%',
                  }}
                >
                  <input {...getInputProps()} />
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      minHeight: '50vh',
                      textAlign: 'center',
                    }}
                  >
                    {hasPhoto && (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img
                          src={currentUser.ExternalPhotoURL}
                          style={{ maxHeight: '200px', maxWidth: '200px', width: 'auto', height: 'auto' }}
                        />
                        <div style={{ paddingTop: '10px' }}>
                          <FontAwesomeIcon icon={faCloudUploadAlt} size={'lg'} color="#009ACD" />
                          <p>Click or Drag</p>
                        </div>
                      </div>
                    )}
                    {!hasPhoto && (
                      <div style={{ padding: '50px' }}>
                        <FontAwesomeIcon icon={faCloudUploadAlt} size="6x" color="#009ACD" />
                        <p>Click or Drag</p>
                      </div>
                    )}
                    {/* <p style={{ fontWeight: '700' }}>{item.DocumentTypeName}</p> */}
                    {/* {item.Hide && (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div style={{ paddingRight: '5px' }}>
                          <FontAwesomeIcon icon={faEyeSlash} />
                        </div>
                        <h6 style={{ margin: 0 }}>Hidden</h6>
                      </div>
                    )} */}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div style={{ padding: '15px' }}>
            {!hasPhoto && (
              <h5>
                <span className="bold">{currentUser.NameFirst || 'Welcome'}</span>, here you can upload a photo of
                yourself that will be used within RezRATE. This photo will be seen by those you interview with, so make
                sure you look your best!
              </h5>
            )}
            {hasPhoto && (
              <h5>
                <span className="bold">{currentUser.NameFirst || 'Welcome'}</span>, it looks like we already have a
                photo for you. You can replace it if you have a better one.
              </h5>
            )}
          </div>
        </Row>
      </Col>
    </Row>
  );
};

export default CandidatePhoto;

// documents dissapearing
// inversion
// ship bell
// schedule showing
// status not updating
