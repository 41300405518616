import React from 'react';
import Moment from 'react-moment';
import { DraggableItemWrapper } from '../../../Common.style';
import { Date } from './Selection.style';

const Selection = ({ listKey, data, state, lastPressed, isPressed, mouseY, listArray, handleClickTransfer }) => {
  const numberKey = Number(listKey);
  const item = listArray[numberKey];

  const { shadow, scale, y } = state;
  const transY = lastPressed === data && isPressed ? mouseY : y;
  // define conditional css attributes
  const selected = item.selected;
  const backgroundColor = 'rgb(255, 255, 255)';
  const color = 'rgb(64, 64, 64)';
  const action = 'add';

  // handles UI elements for pre-selected items
  return (
    <DraggableItemWrapper
      key={listKey}
      className="draggable_item"
      onClick={(e) => handleClickTransfer('add', data)}
      // need to use style instead of styled-components to keep animation smooth (styled-components creates a new component everytime there's a css change)
      style={{
        boxShadow: `rgba(0, 0, 0, 0.4) 0px ${shadow}px ${2 * shadow}px 0px`,
        transform: `translate3d(0, ${transY}px, 0) scale(${scale})`,
        WebkitTransform: `translate3d(0, ${transY}px, 0) scale(${scale})`,
        zIndex: data === lastPressed ? 99 : data,
        backgroundColor: backgroundColor,
      }}
      backgroundColor={backgroundColor}
    >
      <div>
        <Date className="date" style={{ color: color }}>
          <Moment className="mobile_display" format="ddd, MMM DD, YYYY">
            {item.InterviewDates4PortalDate}
          </Moment>
          <Moment className="browser_display" format="dddd, MMMM DD, YYYY">
            {item.InterviewDates4PortalDate}
          </Moment>
        </Date>
        {item.InterviewDates4PortalLabel && <div style={{ color: '#000' }}>{item.InterviewDates4PortalLabel}</div>}
      </div>
    </DraggableItemWrapper>
  );
};

export default Selection;
