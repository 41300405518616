const initialState = {
  email: '',
  expiredToken: false,
  isAdmin: false,
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EMAIL':
      return {
        ...state,
        email: action.payload,
      };
    case 'EXPIRED_TOKEN':
      return {
        ...state,
        expiredToken: action.payload,
      };
    case 'SET_EVALUATOR_TOKEN':
      return {
        ...state,
        evaluatorToken: action.payload,
      };
    case 'SET_IS_ADMIN':
      return {
        ...state,
        isAdmin: action.payload,
      };
    default:
      return state;
  }
};

export default LoginReducer;
