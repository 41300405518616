const initialState = {
  showName: false,
  date: null,
  dateArray: [],
  breakRoomUrl: '',
  headerHeight: 0,
  zoomBreakRoom: null,
  partDocumentTitle: '',
};

const HeaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SHOW_NAME':
      return {
        ...state,
        showName: action.payload,
      };
    case 'SET_ZOOM_BREAK_ROOM':
      return {
        ...state,
        zoomBreakRoom: action.payload,
      };
    case 'SET_PART_DOCUMENT_TITLE':
      return {
        ...state,
        partDocumentTitle: action.payload,
      };
    case 'SET_DATE':
      return {
        ...state,
        date: action.payload,
      };
    case 'SET_DATE_ARRAY':
      return {
        ...state,
        dateArray: action.payload,
      };
    case 'SET_BREAK_ROOM_URL':
      return {
        ...state,
        breakRoomUrl: action.payload,
      };
    case 'SET_SEASON_TYPE':
      return {
        ...state,
        seasonType: action.payload,
      };
    case 'SET_HEADER_HEIGHT':
      return {
        ...state,
        headerHeight: action.payload,
      };
    default:
      return state;
  }
};

export default HeaderReducer;
