import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import { fetchDataAgnosticWithoutCredentials } from '../../../Services/dataApi';
import React, { Fragment, useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { Button, Table } from 'reactstrap';
import moment from 'moment';
import CandidateEvaluatorDetails from './CandidateEvaluatorDetails';
import CoordinatorScheduleRow from './CoordinatorScheduleRow';

const CoordinatorSchedule = ({ match }) => {
  const [schedule, setSchedule] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [showSelectedBlock, setShowSelectedBlock] = useState(false);
  const [scheduleStartTime, setScheduleStartTime] = useState(null);
  const [scheduleEndTime, setScheduleEndTime] = useState(null);
  const [rightNow, setRightNow] = useState(moment(new Date()));
  const [evaluators, setEvaluators] = useState([]);
  const [intervalId, setIntervalId] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRightNow(moment(new Date()));
    }, 30 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  const getInfo = (id) => {
    fetchDataAgnosticWithoutCredentials(`schedule?id=${id}`, {})
      .then(async (results) => {
        if (results.data && results.data.Timeslots) {
          results.data.Timeslots.forEach((item) => {
            const { TimeslotsTimestampEndUTC, TimeslotsTimestampStartUTC } = item;

            const MomentTimeslotsTimestampStartUTC = moment(new Date(`${TimeslotsTimestampStartUTC} UTC`));
            const MomentTimeslotsTimestampEndUTC = moment(new Date(`${TimeslotsTimestampEndUTC} UTC`));
            item.MomentTimeslotsTimestampStartUTC = MomentTimeslotsTimestampStartUTC;
            item.MomentTimeslotsTimestampEndUTC = MomentTimeslotsTimestampEndUTC;
          });
        }

        if (scheduleStartTime !== results.data.ScheduleStartTimestampUTC) {
          setScheduleStartTime(results.data.ScheduleStartTimestampUTC);
        }

        if (scheduleEndTime !== results.data.ScheduleEndTimestampUTC) {
          setScheduleEndTime(results.data.ScheduleEndTimestampUTC);
        }
        if (results && results.data && results.data.Timeslots) {
          const newEvaluators = {};

          await results.data.Timeslots.forEach((timeslot) => {
            timeslot.Schedules.forEach((schedule) => {
              const { SchedulesCandidate, SchedulesEvaluators } = schedule;
              if (SchedulesCandidate && Object.keys(SchedulesCandidate).length > 0) {
                schedule.hasCandidate = true;
                // since these are all represented on one table, it's easier just to concat them all initially
                // and note which one(s) are a candidate
                schedule.SchedulesCandidatesAndEvaluators = [{ ...SchedulesCandidate, isCandidate: true }].concat(
                  SchedulesEvaluators,
                );
              }
            });
          });

          const evalSchedule = results.data.Timeslots[0].Schedules.map((item) => {
            return {
              SchedulesDisplay: item.SchedulesEvaluatorNames,
              SchedulesCandidateColor: '#000000',
              Schedules__pkUUID_Schedule: item.SchedulesEvaluatorNames,
            };
          });

          newEvaluators.TimeslotsTimeDisplay = 'Time';
          newEvaluators.SchedulesCandidateBG = '#FFFFFF';
          newEvaluators.Schedules = evalSchedule;
          setEvaluators(newEvaluators);
          setSchedule(results.data.Timeslots);
          setLoading(false);
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
      });
  };

  const createScheduleWatcher = (intervalId) => {
    const { id } = match.params;

    // interval has already been created
    if (intervalId !== 0) {
      return;
    }

    const newIntervalId = setInterval(() => {
      getInfo(id);
    }, 30 * 1000);
    setIntervalId(newIntervalId);
  };

  const check = () => {
    // times are UTC but are indicated as such, so add UTC to the end
    const rightNow = moment(new Date());
    const startTimeAsDate = moment(new Date(`${scheduleStartTime} UTC`));
    const endTimeAsDate = moment(new Date(`${scheduleEndTime} UTC`));
    // want to start checking with an hour of meeting in either direction,
    // so set times out appropriately
    startTimeAsDate.subtract(1, 'hour');
    endTimeAsDate.add(1, 'hour');

    const needsChecks = rightNow.isBetween(startTimeAsDate, endTimeAsDate);
    if (needsChecks) {
      createScheduleWatcher(intervalId);
    } else {
      if (intervalId !== 0) {
        clearInterval(intervalId);
        setIntervalId(0);
      }
    }
  };
  useEffect(() => {
    if (!loading) {
      check();
    }
  }, [loading]);

  useEffect(() => {
    const interviewDateCheck = setInterval(() => {
      check();
    }, 5 * 60 * 1000);
    return () => clearInterval(interviewDateCheck);
  }, [scheduleStartTime, scheduleEndTime, intervalId]);

  useEffect(() => {
    const { id } = match.params;
    getInfo(id);

    // need to clear interval on unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (error) {
    return (
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <h4> Schedule is not available</h4>
        <Button
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            window.location.reload();
          }}
        >
          Refresh
        </Button>
      </div>
    );
  }
  if (loading) {
    return (
      <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <FontAwesomeIcon icon={faSpinner} size="3x" spin />
      </div>
    );
  }

  return (
    <Fragment>
      <div>
        <Popup
          open={showSelectedBlock}
          onClose={() => {
            setShowSelectedBlock(false);
            setSelectedBlock(null);
          }}
          style={{ width: '100vw' }}
        >
          <CandidateEvaluatorDetails selectedBlock={selectedBlock} setShowSelectedBlock={setShowSelectedBlock} />
        </Popup>
      </div>
      <Table style={{ position: 'relative', top: 0, maxWidth: '100vw' }}>
        <thead>
          <CoordinatorScheduleRow scheduleRow={evaluators} isHeader />
        </thead>
        <tbody>
          {schedule.map((scheduleRow) => {
            return (
              <CoordinatorScheduleRow
                setSelectedBlock={setSelectedBlock}
                rightNow={rightNow}
                setShowSelectedBlock={setShowSelectedBlock}
                key={`${scheduleRow.TimeslotsTimestamp}${scheduleRow.TimeslotsTimestampEnd}`}
                scheduleRow={scheduleRow}
              />
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default CoordinatorSchedule;
