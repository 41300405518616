import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Form, FormGroup, Input } from 'reactstrap';
import Style from './DateDisplay.style';

let stopInfiniteLoop = 0;

const DateDisplay = ({ mockUser, header, setDate, dateArray, seasonType }) => {
  const [chosenDate, setChosenDate] = useState(null);
  const [isPrescreen, setIsPrescreen] = useState(false);

  useState(() => {
    if (seasonType === 'Prescreen') {
      setIsPrescreen(true);
    } else {
      setIsPrescreen(false);
    }
  }, [seasonType]);

  const getSelectedOrDefaultDate = () => {
    // If user is accessing prescreen just return prescreen as the value.
    // Need to add the key InterviewDatesVL__pkUUID_InterviewDate so all
    // options have uniform data
    if (isPrescreen) {
      return { InterviewDatesVL__pkUUID_InterviewDate: 'Prescreen' };
    }
    const rightNow = new Date(Date.now());
    const correctDate = dateArray.find((date, i) => {
      return date.InterviewDatesVLDefault === 1;
      // const differenceInTime = new Date(date.InterviewDatesVLDate).getTime() - rightNow.getTime();
      // const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      // // If the next upcoming date is within 5 days, set this as the default
      // if (differenceInDays > 0 && differenceInDays <= 5) {
      //   return true;
      // }

      // if (date[i + 1]) {
      //   const nextDate = date[i + 1];
      //   const differenceOfNextDateInDays = new Date(nextDate.InterviewDatesVLDate).getTime() - rightNow.getTime();

      //   // need to make sure the next date doesn't satisfy this condition.  If it does, just return false
      //   // and next itteration it will return true
      //   if (differenceOfNextDateInDays > 0 && differenceOfNextDateInDays <= 5) {
      //     return false;
      //   }
      // }

      // if (differenceInDays > -10 && differenceInDays < 0) {
      //   return true;
      // }

      // return false;
    });

    if (correctDate) {
      return correctDate;
    } else {
      // if none of the coniditions are true just return the last date
      const lastDate = dateArray[dateArray.length - 1];
      return lastDate;
    }
  };

  const handleChange = (e) => {
    // If user has selected Prescreen no need to match with a date id.  Just set it to Prescreen
    if (e.target.value === 'Prescreen') {
      setChosenDate(e.target.value);
      setDate(e.target.value);
      return;
    }

    if (dateArray) {
      const newDate = dateArray.find((item) => {
        return item.InterviewDatesVL__pkUUID_InterviewDate === e.target.value;
      });
      setChosenDate(e.target.value);
      setDate(newDate);
    }
  };

  useEffect(() => {
    if (!chosenDate) setChosenDate(getSelectedOrDefaultDate());
  }, []);

  if (!chosenDate) {
    return <div></div>;
  }

  //if there's only one date no need to use a dropdown

  if (dateArray.length === 1) {
    return (
      <div style={{ color: '#fff' }}>{moment(dateArray[0].InterviewDatesVLDate).format('ddd., MMM. D, YYYY')}</div>
    );
  }
  return (
    <Style.Wrapper>
      <Form style={{ width: '100%' }}>
        <FormGroup>
          <Input
            type="select"
            name="select"
            className="options"
            value={chosenDate.InterviewDatesVL__pkUUID_InterviewDate}
            onChange={handleChange}
          >
            {isPrescreen && <option value="Prescreen">Prescreen</option>}
            {dateArray.map((item) => {
              return (
                <option
                  key={item.InterviewDatesVL__pkUUID_InterviewDate}
                  value={item.InterviewDatesVL__pkUUID_InterviewDate}
                >
                  {moment(item.InterviewDatesVLDate).format('ddd., MMM. D, YYYY')} (
                  {item.InterviewDatesVLCandidateCount4Date} candidates)
                </option>
              );
            })}
          </Input>
        </FormGroup>
      </Form>
      {/* <p>{moment(new Date(chosenDate.InterviewDatesVLDate)).format('MM-DD-YYYY')}</p> */}
    </Style.Wrapper>
  );
};

export default DateDisplay;
